import Reducer from './reducer'

import actionTypes from '../actions/action_types'

import agentsListService from 'common_services/agents/agents_list'

const INITIAL_STATE = {
    agentsList: agentsListService.serialize,
}

const agentsReducer = new Reducer(INITIAL_STATE)

agentsReducer.add(actionTypes.agents.loadAgentsPage, 'agentsList')
agentsReducer.add(actionTypes.agents.changePageSize, 'agentsList')
agentsReducer.add(actionTypes.agents.activateAgent, 'agentsList')
agentsReducer.add(actionTypes.agents.deactivateAgent, 'agentsList')

agentsReducer.add(actionTypes.agents.loadAgentByUUID, 'selectedAgent')

export default agentsReducer.reducersFunction
