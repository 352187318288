import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'
import PaginationService from '../pagination'

import MlsAgent from './mls_agent'

const mlsAgentsApi = new Api(API_DOMAINS.leadsApi, 'mls-data', 'mls-agents')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
])
class MlsAgentsListService extends PaginationService {
    async loadPage(page, {ordering, search, filters} = {}, pageSize) {
        const newOrdering = await super.createOrderingParams(ordering, {
            'fullName': ['first_name', 'last_name'],
        })

        await super.loadPage(
            page,
            {
                ordering: newOrdering,
                search, filters,
            },
            pageSize
        )

        return this.serialize
    }

    async changePageSize(...args) {
        await super.changePageSize(...args)

        return this.serialize
    }
}

export default new MlsAgentsListService(MlsAgent, mlsAgentsApi, 10)
