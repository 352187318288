import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import cn from 'classnames'

import 'react-datepicker/dist/react-datepicker.css'

import Tooltip from 'common_components/tooltip'

import DropdownIcon from 'common_svg/dropdown.svg?jsx'

import Label from '../label'
import ErrorMessage from '../error_message'
import RequiredLabel from '../required_label'

import styles from './styles'

export default function CustomDate({...props}) {
    return (
        <Field
            component={CustomDatePicker}
            {...props}
            type="date"
        />
    )
}

CustomDate.moment = moment

function CustomDatePicker({
    input,
    descriptionText,
    descriptionTextClassName,
    className,
    externalTextError,
    meta: {error, visited, touched, active, ...meta},
    isImportant,
    tooltip,
    maxDate,
    ...props
}) {
    const isError = !!getError(error)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const selectedDate = new Date(input.value)
    const [displayedMonth, setDisplayedMonth] = useState(selectedDate.getMonth())

    return (
        <div
            className={cn(
                styles['date-block'],
                className
            )}
        >
            <Label className={styles['date-label']} invalid={`${isError}`}>
                {
                    descriptionText && (
                        <div className="flex align-center mb-xs">
                            <p
                                className={
                                    cn(
                                        'text-xs proxima-bold uppercase m-zero',
                                        descriptionTextClassName
                                    )
                                }
                            >
                                {descriptionText}
                            </p>
                            {
                                isImportant && (
                                    <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                )
                            }
                            {
                                !!tooltip && (
                                    <Tooltip
                                        className="ml-xs"
                                        text={tooltip}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <DropdownIcon
                    className={cn(
                        styles['dropdown-icon'],
                        {
                            [styles['dropdown-icon-active']]: isDatePickerOpen,
                        }
                    )}
                />
                <DatePicker
                    className={cn(styles.date, 'text-md letter-md text-dark-grey proxima-medium')}
                    wrapperClassName={styles['date-picker-wrapper']}
                    calendarClassName={styles['date-picker']}
                    dayClassName={date => cn(
                        styles['date-picker-day'],
                        'proxima-bold text-md',
                        {
                            'text-white-important': date.toDateString() == selectedDate.toDateString(),
                            'text-dark-grey-blue-important': date.getMonth() == displayedMonth,
                            'text-light-blue-grey-important': (
                                date.getMonth() != displayedMonth || date > maxDate
                            ),
                        }
                    )}
                    showDisabledMonthNavigation

                    onChange={date => {
                        setDisplayedMonth(date.getMonth())
                        input.onChange(date)
                    }}
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                    onMonthChange={date => setDisplayedMonth(date.getMonth())}

                    selected={input.value}
                    popperPlacement="bottom-end"
                    maxDate={maxDate}
                    {...props}
                />
                {
                    externalTextError
                        ? (
                            <ErrorMessage>
                                {externalTextError}
                            </ErrorMessage>
                        )
                        : (
                            isError && error && error.text && (
                                <ErrorMessage text={error.text} />
                            )
                        )
                }
            </Label>
        </div>
    )

    function getError() {
        if (error) {
            if (error.params && error.params.afterBlur) {
                return touched && error.text
            }
            return visited && error.text
        }
        return null
    }
}

CustomDatePicker.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    descriptionText: PropTypes.string,
    descriptionTextClassName: PropTypes.string,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    externalTextError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    isImportant: PropTypes.bool,
    tooltip: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
}
