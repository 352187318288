import API_DOMAINS from 'API_DOMAINS'

import moment from 'moment'

import modelSerialize from 'common_utils/model_serialize'

import Api from 'common_services/api'
import userService from 'common_services/user'
import analyticsService from 'common_services/analytics'

import BillingPlan from './billing_plan'
import billingPlanListService from './billing_plans_list'

import Coupon from './coupon'

const billingApi = new Api(API_DOMAINS.usersApi, 'billing')

@modelSerialize([
    {
        fieldName: 'id',
        serializeField: 'customerId',
        originField: 'id',
    },
    'account_balance',
    'created',
    'currency',
    'default_source',
    'delinquent',
    'description',
    'discount',
    'email',
    'invoice_prefix',
    'invoice_settings',
    'livemode',
    'metadata',
    'object',
    'shipping',
    'sources',
    'subscriptions',
    'tax_info',
    'tax_info_verification',
    'defaultCustomerCard',
    'allSubscriptions',
    'currentSubscription',
    'billingHistory',
    'upcomingInvoice',
])
class CustomerService {
    id = null
    account_balance = 0
    created = new Date
    currency = null
    default_source = null
    delinquent = false
    description = ''
    discount = null
    email = null
    invoice_prefix = null
    invoice_settings = null
    livemode = false
    metadata = null
    object = null
    shipping = null
    sources = null
    subscriptions = null
    tax_info = null
    tax_info_verification = null
    billingHistory = []
    upcomingInvoice = null

    get customerId() {
        return userService.billing.stripe_customer || this.id
    }

    set customerId(customerId) {
        this.id = customerId

        return this.customerId
    }

    get defaultCustomerCard() {
        return this.id && this.invoice_settings.default_payment_method
            ? {
                id: this.invoice_settings.default_payment_method.id,
                ...this.invoice_settings.default_payment_method.card,
            }
            : null
    }

    get allSubscriptions() {
        return this.subscriptions?.data
            .filter(({object, status: subscriptionStatus}) => (
                object == 'subscription' && (subscriptionStatus == 'active' || subscriptionStatus == 'trialing')
            ))
            .map(subscription => {
                subscription.trialDaysLeft = subscription.trial_end
                    ? moment(
                        moment(subscription.trial_end * 1000).diff(Date.now())
                    ).utc()
                        .dayOfYear()
                    : null

                if (subscription.plan && !(subscription.plan instanceof BillingPlan)) {
                    subscription.plan = new BillingPlan(subscription.plan)
                }

                if (subscription.discount?.coupon
                    && !(subscription.discount.coupon instanceof Coupon)
                ) {
                    subscription.discount.coupon = new Coupon(subscription.discount.coupon)
                }

                return subscription
            })
            .sort(({plan: {metadata: {sorting_order: a = 0}}}, {plan: {metadata: {sorting_order: b = 0}}}) => b - a)
            || []
    }

    get currentSubscription() {
        const currentSubscription = this.id
            ? this.subscriptions.data.find(({object, status: subscriptionStatus}) => (
                object == 'subscription' && (subscriptionStatus == 'active' || subscriptionStatus == 'trialing')
            ))
            : null

        if (currentSubscription) {
            currentSubscription.trialDaysLeft = currentSubscription.trial_end
                ? moment(
                    moment(currentSubscription.trial_end * 1000).diff(Date.now())
                ).utc()
                    .dayOfYear()
                : null

            if (currentSubscription.plan && !(currentSubscription.plan instanceof BillingPlan)) {
                currentSubscription.plan = new BillingPlan(currentSubscription.plan)
            }

            if (currentSubscription.discount?.coupon
                && !(currentSubscription.discount.coupon instanceof Coupon)
            ) {
                currentSubscription.discount.coupon = new Coupon(currentSubscription.discount.coupon)
            }
        }

        return currentSubscription
    }

    async addCustomerCard(paymentMethodId) {
        await billingApi.getApiWithName('payment-method').post({
            payment_method_id: paymentMethodId,
        })

        analyticsService.track('Added Payment Information')

        return this.loadCustomer()
    }

    async removeCustomerCard(paymentMethodId) {
        await billingApi.getApiWithName('payment-method').delete(null, {
            data: {
                payment_method_id: paymentMethodId,
            },
        })

        analyticsService.track('Removed Payment Information')

        return this.loadCustomer()
    }

    async subscribeToPlan(planId, couponId) {
        await billingApi.getApiWithName('subscribe').post({
            plan_id: planId,
            coupon: couponId || undefined,
        })

        await userService.authenticate()

        return this.loadCustomer()
    }

    async cancelSubscription() {
        const {plans} = await billingPlanListService.loadAllBillingPlans()

        const freePlan = plans.find(plan => plan.nickname == 'Free')

        await billingApi.getApiWithName('subscribe').post({
            plan_id: freePlan?.id,
        })

        await userService.authenticate()

        return this.loadCustomer()
    }

    async loadCustomer(params, config) {
        this.serialize = (await billingApi.getApiWithName('customer').get(params, config)).data

        if (this.invoice_settings.default_payment_method) {
            this.invoice_settings.default_payment_method =
                (await billingApi.getApiWithName('payment-method').get(null, config)).data
        }

        return this.serialize
    }

    async loadBillingHistory(config) {
        this.billingHistory = (await billingApi.getApiWithName('invoices').get(
            {
                limit: 100,
            },
            config
        )).data.data

        return this.serialize
    }

    async loadUpcomingInvoice(config) {
        this.upcomingInvoice = (await billingApi.getApiWithName('invoices/upcoming').get(null, config)).data

        return this.serialize
    }

    async getSetupIntentToken() {
        return (await billingApi.getApiWithName('get-intent-client-secret').get()).data
    }

    addCouponToSubscription(couponId) {
        return this.sendBillingAction('add_coupon_to_subscription', {
            coupon: couponId,
        })
    }

    removeCouponFromSubscription() {
        return this.sendBillingAction('delete_coupon_from_subscription')
    }

    markCardAsAuthenticated() {
        return this.sendBillingAction('mark_card_auth_success')
    }

    markCardAsNotAuthenticated() {
        return this.sendBillingAction('mark_card_auth_failure')
    }

    async sendBillingAction(actionName, actionParams) {
        await billingApi.getApiWithName('actions').post({
            action: actionName,
            ...actionParams,
        })

        if (actionName == 'mark_card_auth_success') {
            await userService.authenticate()
        }

        return this.loadCustomer()
    }
}

export default new CustomerService
