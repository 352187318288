import API_DOMAINS from 'API_DOMAINS'

import moment from 'moment'

import modelSerialize from 'common_utils/model_serialize'

import Api from 'common_services/api'
import {UserService} from 'common_services/user'
import {MlsVendorSearch} from 'common_services/mls_vendor_search'
import {FollowUsBossIntegration} from 'common_services/follow_us_boss_integration'
import analyticsService from 'common_services/analytics'
import PartnerInvitedAgent from 'common_services/partner_agents/partner_invited_agent'

import {getDefaultFilters} from 'common_utils/create_backend_filters'
import {createRange} from 'common_utils/range'

const buyerApi = new Api(API_DOMAINS.leadsApi, 'buyers') // TODO: check can we use v2
const autoSharedBuyerApi = new Api(API_DOMAINS.leadsApi, 'buyers', 'buyer-auto-shared', 2)
const buyerPortalLambdaApi = new Api(API_DOMAINS.leadsLambdaApi, 'buyer-portal', undefined, 2)
const buyerPortalApi = new Api(API_DOMAINS.leadsApi, 'portals', 'buyer-portals', 2)

const defaultFilters = getDefaultFilters()

@modelSerialize([
    'activities',
    'additional_info',
    'agent',
    'agents',
    {
        serializeField: 'area',
        originField: 'area',
        type: area => area || {type: 'MultiPolygon', coordinates: []},
    },
    'bathrooms',
    'bathrooms_exact_match',
    'bedrooms',
    'bedrooms_exact_match',
    'category',
    'cities',
    'contacts',
    'date_agent_assigned',
    {
        serializeField: 'date_created',
        originField: 'date_created',
        type: Date,
    },
    {
        serializeField: 'date_updated',
        originField: 'date_updated',
        type: Date,
    },
    'hoa_max',
    'is_closed',
    'keywords',
    'last_activity',
    'lead_source',
    'lead_status',
    'lot_size_range_sqft',
    'matches_count',
    'in_progress_matches_count',
    'past_matches_count',
    {
        serializeField: 'owner',
        originField: 'owner',
        type: owner => ({
            ...(new UserService(owner)).serialize,
            phone: owner?.phone && owner.phone.indexOf('+') == -1
                ? `+1 ${owner.phone}`
                : owner?.phone,
            phone2: owner?.phone2 && owner.phone2.indexOf('+') == -1
                ? `+1 ${owner.phone2}`
                : owner?.phone2,
        }),
    },
    {
        serializeField: 'price_range',
        originField: 'price_range',
        type: price_range => createRange(price_range, defaultFilters.price_range),
    },
    'purpose_of_request',
    'size_range_sqft',
    'team',
    'timeframe',
    'uuid',
    'year_built_range',
    'notes',
    'match_category',
    'zipcodes',
    'auto_share_frequency',
    'is_auto_share',
    'is_auto_share_bcc',
    'auto_share_emails',
    'auto_share_link',
    'is_auto_share_notify',
    'stories',
    'subtypes',
    'loan_type',
    'listing_statuses',
    'partners',
    'loanOfficer',
    'hasSomeAgents',
    'is_prequalified',
    'date_prequalified',
    'amount_prequalified',
    'activity_score',
    'date_last_activity',
    'mls_vendors',
    'canWeShowLoanOfficer',
    {
        serializeField: 'invites',
        originField: 'invites',
        convertValue: invites => invites.map(invite => new PartnerInvitedAgent(invite)),
    },
    'created_by',
])
class BuyerService {
    activities = []
    additional_info = null
    agent = ''
    agents = []
    area = null
    bathrooms = 1
    bathrooms_exact_match = false
    bedrooms = 1
    bedrooms_exact_match = false
    category = ''
    cities = []
    contacts = []
    date_agent_assigned = null
    date_created = null
    date_updated = null
    hoa_max = 0
    is_closed = null
    keywords = []
    last_activity = null
    lead_source = null
    lead_status = null
    lot_size_range_sqft = null
    matches_count = 0
    new_matches_count = 0
    past_matches_count = 0
    in_progress_matches_count = 0
    owner = null
    price_range = null
    purpose_of_request = null
    size_range_sqft = null
    team = ''
    timeframe = null
    uuid = ''
    year_built_range = null
    notes = ''
    match_category = ''
    zipcodes = []
    auto_share_frequency = null
    is_auto_share = false
    is_auto_share_bcc = false
    auto_share_emails = []
    auto_share_link = ''
    is_auto_share_notify = false
    stories = false
    subtypes = []
    loan_type = null
    listing_statuses = []
    partners = []
    is_prequalified = false
    date_prequalified = null
    amount_prequalified = 0
    activity_score = 0
    date_last_activity = null
    mls_vendors = []
    invites = []
    created_by = null

    constructor(data) {
        if (data) {
            if (data.contacts) {
                data.contacts = data.contacts.map(
                    ({phone, phone2, ...contact}) => ({
                        ...contact,
                        phone: phone && phone.indexOf('+') == -1
                            ? `+1 ${phone}`
                            : phone,
                        phone2: phone2 && phone2.indexOf('+') == -1
                            ? `+1 ${phone2}`
                            : phone2,
                    })
                )
            }

            this.serialize = data

            this.bedrooms = data.bedrooms || 1
            this.bathrooms = data.bathrooms || 1
        }
    }

    get hasSomeAgents() {
        return this.agents.some(({agent}) => !!agent) || !!this.agent
    }

    get loanOfficer() {
        return this.partners.find(({partner_type, user}) => partner_type == 'loan_officer')?.user
    }

    get canWeShowLoanOfficer() {
        return this.mls_vendors.every(({is_advertising_allowed}) => is_advertising_allowed)
    }

    async loadBuyerByUUID(uuid, config) {
        this.serialize = await BuyerService.getBuyerByUUID(uuid || this.uuid, config)

        return this.serialize
    }

    async loadAutoShareBuyerByUUID(uuid) {
        this.serialize = await BuyerService.getAutoShareBuyerByUUID(uuid || this.uuid)

        return this.serialize
    }

    async loadAllAgent() {
        const newAgentsList = []
            .concat(
                typeof this.agent == 'string' ? this.agent : [],
                this.agents.map(({agent: agentUUID}) => typeof agentUUID == 'string' ? agentUUID : null),
                this.partners.map(({user: userUUID}) => typeof userUUID == 'string' ? userUUID : null),
                this.activities.map(({agent: agentUUID}) => typeof agentUUID == 'string' ? agentUUID : null),
                this.invites.map(({invitedAgentUUID}) => invitedAgentUUID)
            )
            .filter(el => !!el)

        const buyerAgents = newAgentsList.length
            ? await UserService.searchPublicUserInfo(undefined, {uuid: newAgentsList})
            : []

        buyerAgents.forEach(currentAgent => {
            if (currentAgent.uuid == this.agent || currentAgent.uuid == this.agent?.uuid) {
                this.agent = currentAgent
            }

            const currentCoAgentIndex = this.agents.findIndex(coAgent => (
                currentAgent.uuid == coAgent.agent || currentAgent.uuid == coAgent.agent?.uuid
            ))

            if (currentCoAgentIndex != -1) {
                this.agents[currentCoAgentIndex].agent = currentAgent
            }

            const currentPartnerIndex = this.partners.findIndex(partner => (
                currentAgent.uuid == partner.user || currentAgent.uuid == partner.user?.uuid
            ))

            if (currentPartnerIndex != -1) {
                this.partners[currentPartnerIndex].user = currentAgent
            }
        })

        this.activities = this.activities.map(activity => ({
            ...activity,
            agent: typeof activity.agent == 'string'
                ? buyerAgents.find(currentAgent => currentAgent.uuid == activity.agent) || activity.agent
                : activity.agent,
        }))

        this.agents = this.agents
            .filter(coagent => coagent.agent && !coagent.is_primary)

        this.invites.forEach(partnerInvitedAgent => {
            partnerInvitedAgent.invitedAgent = (
                buyerAgents.find(agent => agent.uuid == partnerInvitedAgent.invitedAgentUUID)
                    || partnerInvitedAgent.invitedAgent
            )
        })

        return this.serialize
    }

    async loadPrimaryAgent() {
        this.agent = typeof this.agent == 'string'
            ? await UserService.getPublicUserInfoByUuid(this.agent)
            : this.agent

        return this.serialize
    }

    async changeCoAgents(newCoAgentsList) {
        const coAgents = this.agents

        const listOfChangesInCoAgents = Object.keys(newCoAgentsList).reduce(
            (currentList, nextAgentUuid) => {
                if (newCoAgentsList[nextAgentUuid]) {
                    currentList.addCoAgents.push({agent: nextAgentUuid})
                }
                else {
                    currentList.removeCoAgents.push({agent: nextAgentUuid})
                }

                return currentList
            },
            {
                addCoAgents: [],
                removeCoAgents: [],
            }
        )

        await Promise.all(
            []
                .concat(
                    listOfChangesInCoAgents.addCoAgents.length
                        ? this.sendBuyerAction('add_co_agents', {agents: listOfChangesInCoAgents.addCoAgents})
                        : []
                )
                .concat(
                    listOfChangesInCoAgents.removeCoAgents.length
                        ? this.sendBuyerAction('remove_co_agents', {agents: listOfChangesInCoAgents.removeCoAgents})
                        : []
                )
        )

        this.agents = this.agents.map(({agent: agentUUID, ...data}) => ({
            ...data,
            agent: coAgents.find(({agent}) => agent.uuid == agentUUID)?.agent || agentUUID,
        }))

        return this.loadAllAgent()
    }

    async changePartners(newPartnersList) {
        const partners = this.partners

        const listOfChangesInPartners = Object.keys(newPartnersList).reduce(
            (currentList, nextPartnerUuid) => {
                if (newPartnersList[nextPartnerUuid]) {
                    currentList.addPartners.push({user: nextPartnerUuid, partner_type: 'loan_officer'})
                }
                else {
                    currentList.removePartners.push({user: nextPartnerUuid, partner_type: 'loan_officer'})
                }

                return currentList
            },
            {
                addPartners: [],
                removePartners: [],
            }
        )

        if (listOfChangesInPartners.removePartners.length) {
            await this.sendBuyerAction('remove_partners', {partners: listOfChangesInPartners.removePartners})
        }
        if (listOfChangesInPartners.addPartners.length) {
            await this.sendBuyerAction('add_partners', {partners: listOfChangesInPartners.addPartners})
        }

        this.partners = this.partners.map(({user: userUUID, ...data}) => ({
            ...data,
            user: partners.find(({user}) => user.uuid == userUUID)?.user || userUUID,
        }))

        return this.loadAllAgent()
    }

    @keepAllAgentsAndPartnersAndMlsVendors
    async sendBuyerAction(actionName, params) {
        this.serialize = (await buyerApi.getApiWithName('actions').put(this.uuid, {
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async sendBuyerPortalAction(actionName, params) {
        this.serialize = (await buyerPortalApi.getApiWithName('buyer-portals/actions').put(this.uuid, {
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async sendCustomerActions(actionName, params) {
        this.serialize = (await buyerApi.getApiWithName('customer-actions').put(this.uuid, {
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async sendInviteToAgent({
        inviteEmail,
        firstName,
        lastName,
        message,
    }) {
        const res = await this.sendBuyerAction(
            'add_invites',
            {
                invites: [{
                    email: inviteEmail,
                    first_name: firstName,
                    last_name: lastName,
                    message,
                }],
            }
        )

        analyticsService.track(
            'Assigned And Invited',
            {
                agent_email: inviteEmail,
                agent_first_name: firstName,
                agent_last_name: lastName,
                agent_message: message,
                buyer_uuid: this.uuid,
            }
        )

        return res
    }
    removeAgentsInvite(inviteEmail) {
        return this.sendBuyerAction(
            'remove_invites',
            {
                agents_invites: [inviteEmail],
            }
        )
    }

    sendRequestTour({
        full_name,
        phone,
        email,
        message,
        propertyUUID,
        date_scheduled,
        tour_type,
    }) {
        return this.sendCustomerActions(
            'request_tour',
            {
                full_name,
                phone,
                email,
                message,
                seller: propertyUUID,
                date_scheduled: moment(date_scheduled).format('YYYY-MM-DDTHH:mm:ss'),
                tour_type,
            }
        )
    }

    sendMessageToAgent({
        full_name,
        phone,
        email,
        message,
        propertyUUID,
    }) {
        return this.sendCustomerActions(
            'contact_agent',
            {
                full_name,
                phone,
                email,
                message,
                seller: propertyUUID,
            }
        )
    }

    sendGetPreQualifiedRequest(propertyUUID) {
        return this.sendBuyerPortalAction('bp_get_prequalified_email', {seller: propertyUUID})
    }

    async save(newData) {
        const dataToSave = this.createDataToSave({
            ...this,
            ...newData,
        })

        const {agent: currentAgetn} = this

        this.serialize = (await (
            this.uuid
                ? buyerApi.patch(this.uuid, dataToSave)
                : buyerApi.post(dataToSave)
        )).data

        if (currentAgetn?.uuid != this.agent) {
            await this.loadAllAgent()
        }
        else {
            this.agent = currentAgetn
        }

        return this.serialize
    }

    @keepAllAgentsAndPartnersAndMlsVendors
    async updateSomeFields(newData, forceUpdateInstance = false) {
        const dataToSave = this.createDataToSave(newData, true)

        if (forceUpdateInstance) {
            this.serialize = dataToSave
        }

        this.serialize = (await buyerApi.patch(this.uuid, dataToSave)).data

        return this.serialize
    }

    createDataToSave({owner, is_auto_share, is_auto_share_bcc, ...lead}, partialData = false) {
        const phone = owner?.phone
        const phone2 = owner?.phone2
        const currentLead = {
            ...lead,
            is_auto_share_bcc: is_auto_share && is_auto_share_bcc,
            is_auto_share: is_auto_share,
            owner: owner
                ? {
                    ...owner,
                    phone: phone?.notEmptyPhone || (typeof phone == 'string' ? phone : undefined),
                    phone2: phone2?.notEmptyPhone || (typeof phone2 == 'string' ? phone2 : undefined),
                }
                : undefined,
        }

        Object.keys(lead).forEach(keyLead => {
            switch (keyLead) {
                case 'cities':
                    currentLead.cities = lead.cities
                        .filter(city => !!city.name)
                        .map(city => ({
                            ...city,
                            index: undefined,
                        }))
                    break
                case 'zipcodes':
                    currentLead.zipcodes = lead.zipcodes
                        .filter(zipcode => !!zipcode.name)
                        .map(zipcode => ({
                            ...zipcode,
                            name: `${zipcode.name}`,
                            index: undefined,
                        }))
                    break
                case 'bathrooms':
                case 'bedrooms':
                    currentLead[keyLead] = lead[keyLead] == Infinity ? 1 : lead[keyLead]
                    break
                case 'keywords':
                    currentLead.keywords = lead.keywords.filter(el => el !== '')
                    break
                case 'size_range_sqft':
                case 'lot_size_range_sqft':
                case 'year_built_range':
                    currentLead[keyLead] = {
                        low: lead[keyLead].low !== ''
                            ? lead[keyLead].low
                            : defaultFilters[keyLead].low,
                        high: lead[keyLead].high !== ''
                            ? lead[keyLead].high
                            : defaultFilters[keyLead].high,
                    }
                    break
                case 'hoa_max':
                case 'no_hoa_max':
                    currentLead.hoa_max = lead?.no_hoa_max
                        ? 0
                        : lead?.hoa_max != null ? lead?.hoa_max : defaultFilters.hoa_max.high
                    break
            }
        })

        if (
            !currentLead.owner
                || (
                    currentLead.owner
                        && Object.keys(currentLead.owner).filter(objKey => !!currentLead.owner[objKey]).length == 0
                )
        ) {
            delete currentLead.owner
        }

        if (partialData) {
            Object.keys(currentLead).forEach(key => {
                if (currentLead[key] === undefined) {
                    delete currentLead[key]
                }
            })
        }

        return currentLead
    }

    @keepAllAgentsAndPartnersAndMlsVendors
    loadSearchPortalShortLink() {
        return this.sendBuyerAction('get_buyer_auto_share_link')
    }

    @keepAllAgentsAndPartnersAndMlsVendors
    setAllMatchesToRead() {
        return this.sendBuyerAction('buyer_active_matches_read')
    }

    createFollowUsBossIntegrationObject(personID) {
        return this.sendBuyerAction(
            'add_integration',
            {
                name: FollowUsBossIntegration.INTEGRATION_NAME,
                key: personID,
            }
        )
    }

    removeFollowUsBossIntegrationObject(personID) {
        return this.sendBuyerAction(
            'remove_integration',
            {
                name: FollowUsBossIntegration.INTEGRATION_NAME,
                key: personID,
            }
        )
    }

    static async getBuyerByUUID(uuid, config) {
        return new BuyerService((await buyerApi.getByKey(uuid, undefined, config)).data)
    }

    static async getAutoShareBuyerByUUID(uuid) {
        return new BuyerService((await autoSharedBuyerApi.getByKey(uuid)).data)
    }

    static async createBuyerForPortal(agentUUID, {owner: {phone, ...ownerInfo}, ...buyerInfo}) {
        return new BuyerService((
            await buyerPortalLambdaApi.getApiWithName('add-lead').post({
                action: 'create_buyer_lead',
                agent: agentUUID,
                bathrooms: defaultFilters.bathrooms.low,
                bedrooms: defaultFilters.bedrooms.low,
                hoa_max: defaultFilters.hoa_max.high,
                lot_size_range_sqft: {
                    low: defaultFilters.lot_size_range_sqft.low,
                    high: defaultFilters.lot_size_range_sqft.high,
                },
                size_range_sqft: {
                    low: defaultFilters.size_range_sqft.low,
                    high: defaultFilters.size_range_sqft.high,
                },
                year_built_range: {
                    low: defaultFilters.year_built_range.low,
                    high: defaultFilters.year_built_range.high - 1,
                },
                ...buyerInfo,
                owner: {
                    ...ownerInfo,
                    phone: phone?.notEmptyPhone || (typeof phone == 'string' ? phone : undefined),
                },
            })
        ).data)
    }

    static async sendCommonActions(actionName, params) {
        return (await buyerApi.getApiWithName('actions-common').post({
            action: actionName,
            ...params,
        })).data
    }

    static acceptAgentsInvite(inviteUUID) {
        return BuyerService.sendCommonActions(
            'accept_invite',
            {
                invite: inviteUUID,
            }
        )
    }

    @keepAllAgentsAndPartnersAndMlsVendors
    async updatePortalBuyer(buyerInfo, forceUpdateLocalBuyerObject = false, cb) {
        const newBuyerInfo = {...buyerInfo}

        Object.keys(buyerInfo).forEach(keyBuyerInfo => {
            switch (keyBuyerInfo) {
                case 'cities':
                    newBuyerInfo.cities = buyerInfo.cities
                        .filter(city => !!city.name)
                        .map(city => ({
                            ...city,
                            index: undefined,
                        }))
                    break
                case 'zipcodes':
                    newBuyerInfo.zipcodes = buyerInfo.zipcodes
                        .filter(zipcode => !!zipcode.name)
                        .map(zipcode => ({
                            ...zipcode,
                            name: `${zipcode.name}`,
                            index: undefined,
                        }))
                    break
                case 'bathrooms':
                case 'bedrooms':
                    newBuyerInfo[keyBuyerInfo] = buyerInfo[keyBuyerInfo] == Infinity ? 1 : buyerInfo[keyBuyerInfo]
                    break
                case 'keywords':
                    newBuyerInfo.keywords = buyerInfo.keywords.filter(el => el !== '')
                    break
                case 'size_range_sqft':
                case 'lot_size_range_sqft':
                case 'year_built_range':
                    newBuyerInfo[keyBuyerInfo] = {
                        low: buyerInfo[keyBuyerInfo].low !== ''
                            ? buyerInfo[keyBuyerInfo].low
                            : defaultFilters[keyBuyerInfo].low,
                        high: buyerInfo[keyBuyerInfo].high !== ''
                            ? buyerInfo[keyBuyerInfo].high
                            : defaultFilters[keyBuyerInfo].high,
                    }
                    break
                case 'hoa_max':
                case 'no_hoa_max':
                    newBuyerInfo.hoa_max = buyerInfo?.no_hoa_max
                        ? 0
                        : buyerInfo?.hoa_max != null ? buyerInfo?.hoa_max : defaultFilters.hoa_max.high
                    break
            }
        })

        if (forceUpdateLocalBuyerObject) {
            this.serialize = newBuyerInfo

            buyerApi.getApiWithName('buyer-auto-shared').patch(this.uuid, newBuyerInfo)
                .then(cb)
        }
        else {
            this.serialize = new BuyerService((
                await buyerApi.getApiWithName('buyer-auto-shared').patch(this.uuid, newBuyerInfo)
            ).data)
        }

        return this.serialize
    }

    async loadBuyerMlsVendors() {
        this.mls_vendors = (await Promise.all(
            this.mls_vendors.map(mlsVendor => MlsVendorSearch.getMlsVendorByName(
                mlsVendor?.name || mlsVendor,
                undefined,
                {
                    cache: {
                        maxAge: Number.MAX_VALUE,
                    },
                }
            ))
        )).map(({data}) => data)

        return this.serialize
    }
}

export default BuyerService

function keepAllAgentsAndPartnersAndMlsVendors(target, property, descriptor) {
    const originalMethod = descriptor.value

    descriptor.value = async function(...args) {
        const {
            agents: currentAgents,
            agent: currentPrimaryAgent,
            partners: currentPartners,
            activities,
            mls_vendors: currentMlsVendors,
            invites: oldInvites,
        } = this
        const oldActivitiesAgents = activities
            .map(({agent}) => agent)
            .filter(Boolean)

        await originalMethod.call(this, ...args)

        const newActivitiesAgentsUUID = this.activities.map(({agent}) => agent)

        if (
            JSON.stringify(currentAgents.map(({agent: {uuid}}) => uuid))
                == JSON.stringify(this.agents.map(({agent}) => agent))
                && (
                    JSON.stringify(currentPartners.map(({user: {uuid}}) => uuid))
                        == JSON.stringify(this.partners.map(({user}) => user))
                )
                && (
                    JSON.stringify(oldActivitiesAgents.map(({uuid}) => uuid))
                        == JSON.stringify(newActivitiesAgentsUUID)
                )
                && (
                    JSON.stringify(currentMlsVendors.map(({name}) => name))
                        == JSON.stringify(this.mls_vendors)
                )
                && (
                    JSON.stringify(oldInvites.map(({invitedAgentUUID}) => invitedAgentUUID).sort())
                        == JSON.stringify(this.invites.map(({invitedAgentUUID}) => invitedAgentUUID).sort())
                )
        ) {
            this.agent = currentPrimaryAgent
            this.agents = currentAgents
            this.partners = currentPartners
            this.invites = oldInvites
            this.activities = this.activities.map(activity => ({
                ...activity,
                agent: typeof activity.agent == 'string'
                    ? oldActivitiesAgents.find(currentAgent => currentAgent.uuid == activity.agent)
                        || activity.agent
                    : activity.agent,
            }))
            this.mls_vendors = currentMlsVendors
        }
        else {
            await Promise.all([
                this.loadAllAgent(),
                this.loadBuyerMlsVendors(),
            ])
        }

        return this.serialize
    }

    return descriptor
}
