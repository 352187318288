import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'

import PortalProperty from './portal_property.js'
import {PropertiesListService} from './properties_list'

const searchPropertiesApi = new Api(API_DOMAINS.leadsApi, 'properties', 'search-portal', 2)

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
    'extraData',
    'propertyCounts',
])
class SearchPropertiesListService extends PropertiesListService {
    loadPage(page, {ordering, search, filters}, pageSize, {cancelSource}) {
        return super.loadPage(page, {ordering, search, filters}, pageSize, {isLoadAgents: false, cancelSource})
    }
}

export default createNewSearchPropertiesListService(20)

export function createNewSearchPropertiesListService(pageSize) {
    return new SearchPropertiesListService(
        PortalProperty,
        searchPropertiesApi,
        pageSize
    )
}
