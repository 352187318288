import React, {Component} from 'react'
import {autobind} from 'core-decorators'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import cn from 'classnames'

import {isEmail} from 'common_utils/validators'

import InviteMemberFields from './components/invite_member_fields'

import styles from './styles'

class InviteMembers extends Component {
    static propTypes = {
        input: PropTypes.object.isRequired,
        searchMlsVendors: PropTypes.func.isRequired,
        mlsVendors: PropTypes.object.isRequired,
        commonUserChoices: PropTypes.object.isRequired,

        syncErrors: PropTypes.array,
        className: PropTypes.string,
        teamName: PropTypes.string,
        inviteMembersError: PropTypes.array,
        sentInvitations: PropTypes.array,
        initialCountMembers: PropTypes.number,
        isSuperuser: PropTypes.bool,
    }

    static defaultProps = {
        initialCountMembers: 1,
    }

    state = {
        members: [].concat(createMembersObject(this.props.initialCountMembers)),
    }

    elements = []
    scrollBlock = React.createRef()

    static getDerivedStateFromProps(props, state) {
        const newMember = state.members.map(member => {
            if (member.email) {
                const invite = props.sentInvitations.find(
                    currentInvite => currentInvite.email == member.email && currentInvite.role == member.role
                )

                return invite
                    ? {
                        ...member,
                        invitationSent: true,
                    }
                    : member
            }

            return member
        })

        return JSON.stringify(newMember) != JSON.stringify(state.members)
            ? {
                ...state,
                members: newMember,
            }
            : null
    }

    render() {
        const {
            className,
            commonUserChoices,
            teamName,
            inviteMembersError,
            searchMlsVendors,
            mlsVendors,
            syncErrors,
            isSuperuser,
        } = this.props
        const {members} = this.state

        inviteMembersError.forEach(({email, text}) => {
            const member = members.find(currentMember => currentMember.email == email)
            if (member) {
                member.textError = text
            }
        })

        return (
            <div className={className}>
                {
                    teamName && (
                        <p className="text-xs proxima-bold m-zero">
                            INVITE MEMBERS {teamName ? `TO ${teamName}` : ''}
                        </p>
                    )
                }
                <div
                    className={cn({
                        [styles.scroll]: members.length > 7}
                    )}
                    ref={this.scrollBlock}
                >
                    {
                        this.state.members.map((member, idx) => (
                            <InviteMemberFields
                                key={member.id}
                                ref={el => this.elements[idx] = el}
                                name={`members[${idx}]`}
                                member={member}
                                onMemberChange={this.updateMember}
                                teamMemberRoles={commonUserChoices.TEAM_MEMBER_ROLES}
                                scrollingElement={
                                    members.length > 7
                                        ? this.scrollBlock.current
                                        : undefined
                                }
                                searchMlsVendors={searchMlsVendors}
                                mlsVendors={mlsVendors}
                                syncErrors={syncErrors?.[idx]}
                                isSuperuser={isSuperuser}
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.props.input.onChange(this.members)
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.input.value) != JSON.stringify(this.members)) {
            this.props.input.onChange(this.members)
        }
    }

    get members() {
        return this.state.members.filter(member => member.email && !member.invitationSent)
    }

    @autobind
    addMemberObject() {
        this.setState(
            ({members}) => ({
                members: members.concat(createMembersObject(1)),
            }),
            () => {
                this.scrollBlock.current.scrollTop = this.elements[this.elements.length - 1].offsetTop
            }
        )
    }

    @autobind
    updateMember(newMember) {
        this.setState(
            ({members}) => ({
                members: members.map(currentMember => currentMember.id == newMember.id ? newMember : currentMember),
            }),
            () => {
                const memberWithCorrectEmail = this.state.members.filter(member => isEmail(member.email))

                if (memberWithCorrectEmail.length == this.state.members.length) {
                    this.addMemberObject()
                }
            }
        )
    }
}

export default function InviteMembersFormComponent({...props}) {
    return <Field component={InviteMembers} {...props} />
}

export function createMembersObject(countMembers) {
    const res = []

    for (;countMembers--;) {
        res.push({
            id: Math.trunc(Math.random() * 10000),
            email: '',
            role: null,
            invitationSent: false,
            first_name: undefined,
            last_name: undefined,
            phone: undefined,
            mls_licenses: [{
                license_number: undefined,
                license_state: undefined,
            }],
            mls_ids: [{
                mls_id: undefined,
                mls_vendor: undefined,
            }],
            autoAcceptInvite: false,
        })
    }

    return res
}
