export default function createAction({
    actionTypeSpace,
    action,
    createPayload,
}) {
    return dispatch => {
        const actionPromise = action()
        dispatch({
            type: actionTypeSpace.start,
            actionTypeSpace: actionTypeSpace,
            promise: actionPromise,
        })

        const promise = actionPromise
            .then(createPayload)
            .then(payload => {
                dispatch({
                    type: actionTypeSpace.success,
                    actionTypeSpace: actionTypeSpace,
                    payload: payload,
                })
                return payload
            })
            .catch(res => {
                dispatch({
                    type: actionTypeSpace.error,
                    actionTypeSpace: actionTypeSpace,
                    error: res,
                })
                return Promise.reject(res)
            })

        promise.cancel = actionPromise.cancel

        return promise
    }
}

export function createEmptyAction(actionType) {
    return dispatch => {
        dispatch({
            type: actionType,
        })
    }
}

export function createSimpleAction({
    actionType,
    action,
    createPayload = arg => arg,
}) {
    return dispatch => {
        const payload = action ? createPayload(action()) : null
        dispatch({
            type: actionType,
            payload,
        })
    }
}
