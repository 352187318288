export function formatPriceRange([low, high]) {
    return [
        formatPriceRangeLevel(low),
        formatPriceRangeLevel(high),
    ]
}

export function formatPriceRangeLevel(value) {
    if (value >= 0 && value <= 1000000) {
        return value / 10000
    } if (value > 1000000 && value <= 5000000) {
        return (value - 1000000) / 250000 + 100
    } if (value > 5000000 && value <= 11000000) {
        return (value - 5000000) / 6000000 + 116
    } if (value > 11000000 && value <= 20000000) {
        return (value - 11000000) / 1000000 + 117
    } if (value > 20000000 && value <= 500000000) {
        return (value - 20000000) / 480000000 + 126
    }
    return 127
}

export function normalizePricePange({low, high}) {
    return {
        low: normalizePricePangeLevel(low),
        high: normalizePricePangeLevel(high),
    }
}

export function normalizePricePangeLevel(value) {
    if (value >= 0 && value <= 100) {
        return Math.round(value * 10000)
    } if (value > 100 && value <= 116) {
        return Math.round((value - 100) * 250000 + 1000000)
    } if (value > 116 && value < 117) { //special case when value are between 5000000 and 11000000
        return Math.round((value - 116) * 6000000 + 5000000)
    } if (value === 117) {
        return Math.round((value - 116) * 6000000 + 5000000)
    } if (value > 117 && value <= 126) {
        return Math.round((value - 117) * 1000000 + 11000000)
    } if (value > 126 && value <= 127) {
        return Math.round((value - 126) * 480000000 + 20000000)
    }
    return value
}

export function formatLotRangeSqFeet([low, high]) {
    return [
        formatLotRangeSqFeetLevel(low),
        formatLotRangeSqFeetLevel(high),
    ]
}

export function formatLotRangeSqFeetLevel(value) {
    if (value <= 50000) {
        return value / 1000
    }
    if (value > 50000) {
        return ((value - 50000) / 10000) + 50
    }
    return 70
}

export function normalizeLotRangeSqFeet({low, high}) {
    return {
        low: normalizeLotRangeSqFeetLevel(low),
        high: normalizeLotRangeSqFeetLevel(high),
    }
}

export function normalizeLotRangeSqFeetLevel(value) {
    if (value <= 50) {
        return value * 1000
    }
    if (value > 50 && value <= 70) {
        return ((value - 50) * 10000) + 50000
    }
    return value
}

export function createRange(value, defaulValue) {
    return {
        low: value?.low || value?.[0] || defaulValue?.low || 0,
        high: value?.high || value?.[1] || defaulValue?.high || 0,
    }
}
