import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import cn from 'classnames'

import copyValue from 'common_utils/copy_value'

import analyticsService from 'common_services/analytics'

import Button from 'common_components/button'
import Toast from 'common_components/toast'
import Tooltip from 'common_components/tooltip'

import Label from '../label'
import ErrorMessage from '../error_message'
import RequiredLabel from '../required_label'

import styles from './styles'

export default function CustomField({...props}) {
    return <Field component={field} {...props} />
}

function field({
    input,
    descriptionText,
    descriptionTextClassName,
    className,
    inputClassName,
    externalTextError,
    meta: {error, visited, touched, active, ...meta},
    isImportant,
    isOptional,
    defaultValue,
    withCopyButton,
    withButton,
    analytics,
    tooltip,
    type,
    useCapsLockInfo,
    ...props
}) {
    const [showPassword, setShowPassword] = useState(false)
    const [showCapsLockInfo, setShowCapsLockInfo] = useState(false)

    const isError = !!getError(error)

    if (defaultValue && !visited && !touched && !active && !input.value) {
        input.onChange(defaultValue)
    }

    const inputComponent = (
        <Input
            {...{
                ...input,
                ...props,
            }}
            type={
                type == 'password'
                    ? (
                        showPassword
                            ? 'text'
                            : 'password'
                    )
                    : type
            }
            className={inputClassName}
            invalid={`${isError}`}
            analytics={analytics}
            onKeyDown={event => {
                if (useCapsLockInfo && event.key == 'CapsLock') {
                    setShowCapsLockInfo(!showCapsLockInfo)
                }
            }}
            onKeyUp={event => {
                if (useCapsLockInfo && event.key == 'CapsLock') {
                    setShowCapsLockInfo(!showCapsLockInfo)
                }
            }}
            onKeyPress={event => {
                if (useCapsLockInfo) {
                    setShowCapsLockInfo(event.getModifierState('CapsLock'))
                }
            }}
        />
    )

    return (
        <div
            className={cn(
                styles['input-block'],
                {
                    [styles['input-with-copy-button']]: withCopyButton || withButton,
                },
                className
            )}
        >
            <Label className={styles['input-label']} invalid={`${isError}`}>
                {
                    descriptionText && (
                        <div className="flex align-center space-between mb-xs">
                            <div className="flex align-center">
                                <p
                                    className={cn(
                                        'text-xs proxima-bold uppercase m-zero',
                                        descriptionTextClassName
                                    )}
                                >
                                    {descriptionText}
                                </p>
                                {
                                    isImportant && (
                                        <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                    )
                                }
                                {
                                    isOptional && (
                                        <p className="proxima text-bluey-grey my-zero ml-xs text-xs">
                                            OPTIONAL
                                        </p>
                                    )
                                }
                                {
                                    !!tooltip && (
                                        <Tooltip
                                            className="ml-xs"
                                            text={tooltip}
                                        />
                                    )
                                }
                            </div>
                            {
                                type == 'password' && (
                                    <Button.text
                                        title={showPassword ? 'hide password' : 'show password'}
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="proxima-medium text-xs text-turquoise-blue p-zero"
                                        tabIndex="-1"
                                    />
                                )
                            }
                        </div>
                    )
                }
                {inputComponent}
                {
                    showCapsLockInfo && (
                        <p
                            className={cn(
                                'proxima text-13 mt-xxs',
                                {
                                    [styles['capslock-info']]: !(externalTextError || isError),
                                    'mb-xxs': externalTextError || isError,
                                }
                            )}
                        >
                            Caps lock key is enabled.
                        </p>
                    )
                }
                {
                    externalTextError
                        ? (
                            <ErrorMessage>
                                {externalTextError}
                            </ErrorMessage>
                        )
                        : (
                            isError && error && error.text && (
                                <ErrorMessage text={error.text} />
                            )
                        )
                }
            </Label>
            {
                withCopyButton && (
                    <Button
                        isText
                        title="copy link"
                        className={cn(
                            styles['copy-button'],
                            'text-turquoise-blue proxima-medium text-sm px-zero py-zero'
                        )}
                        onClick={() => copyInputValue(input.value)}
                        analyticEvent={{
                            ...analytics.onClickCopyButton,
                        }}
                    />
                )
            }
            {
                withButton && (
                    <Button
                        isText
                        className={cn(
                            styles['copy-button'],
                            'proxima-medium text-sm px-zero py-zero',
                            {
                                'text-turquoise-blue': !withButton.disabled,
                                'text-bluey-grey': withButton.disabled,
                            }
                        )}
                        {...withButton}
                    />
                )
            }
        </div>
    )

    function getError() {
        if (error) {
            if (error.params && error.params.afterBlur) {
                return touched && error.text
            }
            return visited && error.text
        }
        return null
    }
}

field.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    descriptionText: PropTypes.string,
    descriptionTextClassName: PropTypes.string,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    externalTextError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    isImportant: PropTypes.bool,
    isOptional: PropTypes.bool,
    withCopyButton: PropTypes.bool,
    withButton: PropTypes.object,
    analytics: PropTypes.shape({
        onClickCopyButton: PropTypes.shape({
            eventName: PropTypes.string.isRequired,
            properties: PropTypes.object,
        }),
    }),
    tooltip: PropTypes.string,
    type: PropTypes.string,
    useCapsLockInfo: PropTypes.bool,
}

field.defaultProps = {
    analytics: {},
}

export function Input({
    className,
    isSearchField,
    isReverseSearchField,
    analytics,
    onFocus,
    startSimbol,
    inputRef,
    ...props
}) {
    const input = (
        <input
            className={
                cn(
                    styles.input,
                    'text-md letter-md text-dark-grey proxima-medium',
                    className,
                    {
                        [styles.search]: isSearchField,
                        [styles['reverse-search']]: isReverseSearchField,
                    }
                )
            }
            onFocus={onFocusEvent}
            ref={inputRef}
            {...props}
        />
    )

    return !startSimbol
        ? input
        : (
            <div className={styles['input-wrapper']}>
                <span className={cn(styles['input-start-simbol'], 'text-md letter-md text-dark-grey proxima-medium')}>
                    {startSimbol}
                </span>
                {input}
            </div>
        )

    function onFocusEvent(event) {
        if (analytics.onFocus) {
            analyticsService.track(analytics.onFocus.eventName, analytics.onFocus.properties)
        }

        if (onFocus) {
            onFocus(event)
        }
    }
}

Input.propTypes = {
    className: PropTypes.string,
    isSearchField: PropTypes.bool,
    isReverseSearchField: PropTypes.bool,
    onFocus: PropTypes.func,
    analytics: PropTypes.shape({
        onFocus: PropTypes.shape({
            eventName: PropTypes.string.isRequired,
            properties: PropTypes.object,
        }),
    }),
    startSimbol: PropTypes.string,
    inputRef: PropTypes.func,
}

Input.defaultProps = {
    analytics: {},
}

function copyInputValue(value) {
    copyValue(value)
        .then(() => {
            Toast.show.success('Copied to clipboard.')
        })
        .catch(err => {
            Toast.show.error('Failed to copy to clipboard.')
        })
}
