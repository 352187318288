import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Avatar from 'common_components/avatar'
import TextHeader from 'common_components/text_header'

import DoneArrowIcon from 'common_svg/done_arrow_with_bg.svg?jsx'
import CrossWithCircleIcon from 'common_svg/cross_with_circle.svg?jsx'

import AgentAvatar from './images/user.png'

import styles from './styles.styl'

export default function FeaturesDetails({withProPlan, className}) {
    const featuresList = [
        {
            title: 'CLIENT COLLABORATION',
            condition: withProPlan,
            list: [
                {
                    featureName: 'Branded IDX home search portal(s)',
                },
                {
                    featureName: 'Like & dislike properties',
                },
                {
                    featureName: 'Search by drawing on a map',
                },
                {
                    featureName: 'Saved searches & listing alert emails',
                },
                {
                    featureName: 'Simple login',
                },
                {
                    featureName: 'Designed for mobile, no download required',
                },
                {
                    featureName: 'Homebuyer activity feed',
                },
                {
                    featureName: 'Client search insights & notifications',
                },
                {
                    featureName: 'Self registration',
                },
            ],
        },
        {
            title: 'AGENT COLLABORATION',
            condition: true,
            list: [
                {
                    featureName: 'Unlimited buyer & seller input',
                },
                {
                    featureName: 'Buyer demand preview',
                },
                {
                    featureName: 'Unlimited matching',
                },
                {
                    featureName: 'Multi-state matching',
                },
                {
                    featureName: 'Co-agents & teams collaboration',
                },
                {
                    featureName: 'Syndication of active MLS listings',
                },
                {
                    featureName: 'Mobile & web applications',
                },
                {
                    featureName: 'Listing management',
                },
            ],
        },
    ]

    return (
        <div className={cn('px-md-lg py-md-lg flex flex-column', className)}>
            <div className="mb-md-lg">
                <TextHeader.h3
                    className="text-white my-zero"
                    text="Experience the Best of Raven"
                    bold
                />
            </div>
            {
                featuresList.map(({title, condition, list}) => (
                    <div key={title} className="mb-md-lg">
                        <p className="proxima text-sm mt-zero mb-sm">
                            {title}
                        </p>
                        {
                            list.map(({featureName}) => (
                                <div
                                    key={featureName}
                                    className={cn(
                                        'flex mt-sm',
                                        {
                                            [styles['disabled']]: !condition,
                                        },
                                    )}
                                >
                                    {
                                        condition
                                            ? (
                                                <DoneArrowIcon
                                                    className="mr-xs"
                                                />
                                            )
                                            : (
                                                <CrossWithCircleIcon
                                                    className={cn(
                                                        styles['disabled-features-icon'],
                                                        'mr-xs'
                                                    )}
                                                />
                                            )
                                    }
                                    <p className="proxima my-zero mr-xs">
                                        {featureName}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
            <p className="proxima text-md mt-md-lg mb-zero letter-normal">
                “The more we all collaborate, the more value we provide
                for our clients and ourselves and Raven is a tool to do just that.”
            </p>
            <div className="flex align-center text-left mt-sm">
                <Avatar.lg src={AgentAvatar} />
                <div className="proxima ml-sm text-xs">
                    <p className="proxima-bold mt-zero mb-xxs">
                        Shen Shultz
                    </p>
                    <p className="mt-zero mb-xxs">
                        Sotheby’s International Realty
                    </p>
                </div>
            </div>
        </div>
    )
}

FeaturesDetails.propTypes = {
    withProPlan: PropTypes.bool,
    className: PropTypes.string,
}
