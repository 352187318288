import ENVIRONMENT from 'ENVIRONMENT'
import IS_DEMO_ENVIRONMENT from 'IS_DEMO_ENVIRONMENT'

import React, {Component, Fragment} from 'react'
import {autobind} from 'core-decorators'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import cn from 'classnames'
import qs from 'qs'
import mixinDeep from 'mixin-deep'

import supportService from 'common_services/support'
import QueryMiddleware from 'common_services/query_middleware'

import actionTypes from 'common_redux/actions/action_types'
import usersActions from 'common_redux/actions/user'
import billingActions from 'common_redux/actions/billing'
import properties2Action from 'common_redux/actions/properties2'
import buyerAction from 'common_redux/actions/buyer'
import mlsVendor from 'common_redux/actions/mls_vendor_search'

import {properties2Reducer} from 'common_redux/reducers/properties2'
import {buyerReducer} from 'common_redux/reducers/buyer'
import {buyers2Reducer} from 'common_redux/reducers/buyers2'
import {buyerReducer as buyerDetail} from 'common_redux/reducers/buyer_detail'
import {matchesReducer} from 'common_redux/reducers/matches'

import Link from 'common_components/link'
import Button from 'common_components/button'
import Avatar from 'common_components/avatar'
import Modal from 'common_components/modal'
import Logo from 'common_components/logo'

import buyerPortalsPropertyListConfigs from 'buyerPortalsProj/pages/properties/properties_list/property_list_configs'

import CloseIcon from 'common_svg/close.svg?jsx'

import Dropdown from 'adminProj/components/dropdown'
import FeedbackDropdown from 'adminProj/components/feedback_dropdown'
import TeamCreate from 'adminProj/components/team/team_create'
import LoginAsAnotherUser from 'adminProj/components/login_as_another_user'
import SubscriptionModal from 'adminProj/components/subscription'
import MlsIdVerification from 'adminProj/components/user_verification/mls_id_verification'
import Footer from 'adminProj/components/page/components/footer'

import NavBarLink from './components/nav_bar_link'
import SubNavBarLink from './components/sub_nav_bar_link'
import DropdownLink from './components/dropdown_link'

import styles from './styles.styl'

class NavBar extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        loadCustomer: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        query: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        loginAsAnotherUser: PropTypes.func.isRequired,
        propertyCounts: PropTypes.object.isRequired,
        buyerCounts: PropTypes.object.isRequired,
        loadPropertyCounts: PropTypes.func.isRequired,
        loadBuyerCounts: PropTypes.func.isRequired,
        saveMyProfile: PropTypes.func.isRequired,
        mlsVendors: PropTypes.object.isRequired,
        searchMlsVendors: PropTypes.func.isRequired,
        saveMlsId: PropTypes.func.isRequired,

        className: PropTypes.string,
    }

    state = {
        openModal: false,
        showLoginAsModal: false,
        subNavBar: null,
        showSubscriptionModal: false,
        showMlsIdVerificationModal: false,
        showCreateLeadsModal: false,
        hideWithoutVerifiedMlsIDBlock: this.props.user.profile?.details_json?.is_verified_mls_id_dissmised,
    }

    render() {
        const {
            openModal,
            showLoginAsModal,
            subNavBar,
            showSubscriptionModal,
            showMlsIdVerificationModal,
            showCreateLeadsModal,
            hideWithoutVerifiedMlsIDBlock,
        } = this.state
        const {
            user,
            query,
            loginAsAnotherUser,
            className,
            mlsVendors,
            searchMlsVendors,
            saveMlsId,
            history: {location: {pathname}},
        } = this.props
        const excludedPathToShowMlsIDBar = ['/subscription', '/mls-id']
        const subscriptionPage = pathname == '/subscription'

        return (
            <div className={className}>
                {
                    user.isAuthorized
                        && !hideWithoutVerifiedMlsIDBlock
                        && excludedPathToShowMlsIDBar.indexOf(pathname) == -1
                        && (
                            <div
                                className={cn(
                                    styles['without-verified-mls-id'],
                                    'flex align-center just-center',
                                    {
                                        [styles['has-not-verified-mls-id']]: user.hasMlsId && !user.isHaveVerifiedMlsId,
                                        [styles['has-verified-mls-id']]: user.isHaveVerifiedMlsId,
                                        [styles['without-verified-mls-id-with-border']]: IS_DEMO_ENVIRONMENT,
                                    }
                                )}
                            >
                                {
                                    !user.hasMlsId && (
                                        <Fragment>
                                            <p className="proxima text-white text-lg letter-sm m-zero">
                                                Your MLS ID is required to verify your account. Matching may be limited.
                                            </p>
                                            <Button.text
                                                title="ADD MLS ID"
                                                className={cn(
                                                    styles['add-mls-id-button'],
                                                    'proxima text-turquoise-blue text-13 letter-sm',
                                                    'ml-sm-md flex align-center just-center pointer'
                                                )}
                                                onClick={this.toggleMlsIdVerificationModal}
                                            />
                                        </Fragment>
                                    )
                                }
                                {
                                    user.hasMlsId && !user.isHaveVerifiedMlsId && (
                                        <p className="proxima text-white text-lg letter-sm m-zero">
                                            Your account is being verified - matches may be limited.
                                            Questions? Email us at
                                            {' '}
                                            <Link
                                                path="mailto:support@raven.re"
                                                text="support@raven.re"
                                                className={styles['link']}
                                            />
                                        </p>
                                    )
                                }
                                {
                                    user.isHaveVerifiedMlsId && (
                                        <Fragment>
                                            <p className="proxima text-white text-lg letter-sm m-zero">
                                                You᾿ve been verified!
                                            </p>
                                            <Button.text
                                                title="GET STARTED"
                                                className={cn(
                                                    styles['add-mls-id-button'],
                                                    'proxima text-turquoise-blue text-13 letter-sm',
                                                    'ml-sm-md flex align-center just-center pointer'
                                                )}
                                                onClick={this.toggleCreateLeadsModal}
                                            />
                                            <Button.text
                                                title={
                                                    <CloseIcon
                                                        className={cn(styles['close-icon-without-verified-mls-id'])}
                                                    />
                                                }
                                                className={cn(
                                                    styles['button-close-without-verified-mls-id'],
                                                    'p-zero flex flex-component-center'
                                                )}
                                                onClick={this.hideWithoutVerifiedMlsIDBlock}
                                            />
                                        </Fragment>
                                    )
                                }
                            </div>
                        )
                }
                <div className={cn(styles['nav-bar'], 'px-md-lg flex flex-row align-center space-between')}>
                    <div className="flex flex-row align-center just-start">
                        <Logo
                            withoutName={user.isAuthorized}
                            className={cn(user.isAuthorized && 'mr-lg')}
                        />
                        {
                            !subscriptionPage && (
                                <this.LeftBlock />
                            )
                        }
                    </div>
                    <div className="flex flex-row align-center just-end sm-flex-hide">
                        <this.RightBlockForAnonymous />
                        <this.RightBlockForAuthorized />
                    </div>
                    <div className="flex flex-row align-center just-end sm-flex-visible">
                        <this.MobileDropdown />
                    </div>
                    <Modal
                        show={openModal}
                        onClose={this.closeCreateTeamModal}
                        content={
                            <TeamCreate
                                onSubmitSuccess={this.closeCreateTeamModal}
                                isNewUser={!!query.search.isNewUser}
                                onClose={this.closeCreateTeamModal}
                            />
                        }
                        size={Modal.size.xs}
                    />
                    <Modal
                        size={Modal.size.fixedXsSm}
                        show={showLoginAsModal}
                        onClose={this.toggleLoginAsModal}
                        content={
                            <LoginAsAnotherUser
                                loginAsAnotherUser={loginAsAnotherUser}
                                closeModal={this.toggleLoginAsModal}
                                onSubmitSuccess={this.toggleLoginAsModal}
                            />
                        }
                    />
                    <SubscriptionModal
                        onSubmitSuccess={this.onSubscriptionSuccessful}
                        showModal={showSubscriptionModal}
                        closeModal={this.closeSubscriptionModal}
                    />
                </div>
                {
                    !!subNavBar && (
                        <div className={cn(styles['sub-nav-bar'], 'px-huge flex flex-row align-center just-start')}>
                            {
                                subNavBar.map((props, idx) => (
                                    <SubNavBarLink
                                        key={idx}
                                        {...props}
                                    />
                                ))
                            }
                        </div>
                    )
                }
                <Modal
                    size={Modal.size.xs}
                    show={showMlsIdVerificationModal}
                    onClose={this.toggleMlsIdVerificationModal}
                    content={
                        <MlsIdVerification
                            searchMlsVendors={searchMlsVendors}
                            mlsVendors={mlsVendors}
                            saveMlsId={saveMlsId}
                            hideSkipButton
                            onSubmitSuccess={this.toggleMlsIdVerificationModal}
                        />
                    }
                />
                <Modal
                    size={Modal.size.sm}
                    show={showCreateLeadsModal}
                    onClose={this.toggleCreateLeadsModal}
                    content={
                        <div className="text-center text-dark-grey-blue">
                            <p className="proxima-bold text-xl letter-lg mt-zero mb-md">
                                Add a Seller or Buyer Need
                            </p>
                            <p className="proxima text-lg mt-zero mb-md-lg">
                                Add a seller or buyer need and start generating matches.
                            </p>
                            <Link
                                text="ADD SELLER"
                                path="/properties?open_add_modal=true"
                                className={cn(styles['link-out'], 'text-center mt-md-lg mb-sm')}
                                onMouseDown={this.hideWithoutVerifiedMlsIDBlock}
                            />
                            <Link
                                text="ADD BUYER NEED"
                                path="/buyers?open_add_modal=true"
                                className={cn(styles['link-out'], 'text-center mb-md')}
                                onMouseDown={this.hideWithoutVerifiedMlsIDBlock}
                            />
                            <Button.text
                                title="CANCEL"
                                className="proxima-medium text-turquoise-blue"
                                onClick={this.toggleCreateLeadsModal}
                            />
                        </div>
                    }
                />
            </div>
        )
    }

    async componentDidMount() {
        const {query: {search}, user, loadCustomer} = this.props

        properties2Reducer.subscribeToAction(
            [
                actionTypes.properties2.deleteProperty.success,
                actionTypes.properties2.updateAndSaveProperty.success,
                actionTypes.properties2.createPropertyActivity.success,
                actionTypes.properties2.updatePropertyActivity.success,
                actionTypes.properties2.deletePropertyActivity.success,
                actionTypes.properties2.saveProperty.success,
                actionTypes.properties2.assignPropertyAgent.success,
                actionTypes.properties2.setAllMatchesToRead.success,
            ],
            this.updateAllCounts
        )

        buyerReducer.subscribeToAction(
            [
                actionTypes.buyer.saveBuyer.success,
                actionTypes.buyer.createBuyerActivity.success,
                actionTypes.buyer.updateBuyerActivity.success,
                actionTypes.buyer.deleteBuyerActivity.success,
                actionTypes.buyer.assignBuyerAgent.success,
            ],
            this.updateAllCounts
        )
        buyers2Reducer.subscribeToAction(
            [
                actionTypes.buyers2.setAllMatchesToRead.success,
            ],
            this.updateAllCounts
        )
        buyerDetail.subscribeToAction(
            [
                actionTypes.buyer.deleteBuyer.success,
            ],
            this.updateAllCounts
        )
        matchesReducer.subscribeToAction(
            [
                actionTypes.matches.selectedMatchAction.success,
                actionTypes.matches.sendPublicMatchAction.success,
                actionTypes.matches.matchReadAction.success,
            ],
            this.updateAllCounts
        )

        if (user.isAuthorized) {
            if (search.brokerage || search.title_company || search.company_rep) {
                this.updateUserProfile(search)
            }
            this.updateLeadsCounts()
            await loadCustomer()

            if (search.next == '/search' && !user.permissions.hasProFeatures) {
                this.openSubscriptionModal()
            }
        }

        this.showModalForNewUser()
    }

    componentDidUpdate(prevProps) {
        this.showModalForNewUser()

        this.updateLeadsCounts(prevProps)
    }

    componentWillUnmount() {
        properties2Reducer.unsubscribeToAction(
            [
                actionTypes.properties2.deleteProperty.success,
                actionTypes.properties2.updateAndSaveProperty.success,
                actionTypes.properties2.createPropertyActivity.success,
                actionTypes.properties2.updatePropertyActivity.success,
                actionTypes.properties2.deletePropertyActivity.success,
                actionTypes.properties2.saveProperty.success,
                actionTypes.properties2.assignPropertyAgent.success,
                actionTypes.properties2.setAllMatchesToRead.success,
            ],
            this.updateAllCounts
        )
        buyerReducer.unsubscribeToAction(
            [
                actionTypes.buyer.saveBuyer.success,
                actionTypes.buyer.createBuyerActivity.success,
                actionTypes.buyer.updateBuyerActivity.success,
                actionTypes.buyer.deleteBuyerActivity.success,
                actionTypes.buyer.assignBuyerAgent.success,
            ],
            this.updateAllCounts
        )
        buyers2Reducer.unsubscribeToAction(
            [
                actionTypes.buyers2.setAllMatchesToRead.success,
            ],
            this.updateAllCounts
        )
        buyerDetail.unsubscribeToAction(
            [
                actionTypes.buyer.deleteBuyer.success,
            ],
            this.updateAllCounts
        )
        matchesReducer.unsubscribeToAction(
            [
                actionTypes.matches.selectedMatchAction.success,
                actionTypes.matches.sendPublicMatchAction.success,
                actionTypes.matches.matchReadAction.success,
            ],
            this.updateAllCounts
        )
    }

    @autobind
    updateUserProfile({brokerage, title_company, company_rep}) {
        this.props.saveMyProfile({
            'profile': {
                ...(brokerage && {
                    'brokerage': {
                        'name': brokerage,
                    },
                }),
                ...(title_company && {
                    'title_company': {
                        'uuid': title_company,
                    },
                }),
                ...(company_rep && {
                    'company_rep': {
                        'uuid': company_rep,
                    },
                }),
            },
        })
    }

    @autobind
    updateLeadsCounts(prevProps, force = false) {
        const prevFilters = prevProps?.query?.search?.filters
        const newFilters = this.props?.query?.search?.filters
        const isFilterUpdated = JSON.stringify(prevFilters) != JSON.stringify(newFilters)
        const propertyURLs = ['/properties', '/active-listing', '/matches-listing']
        const buyerURLs = ['/matches-buyers', '/buyers']
        const {
            history: {location: {pathname}},
            propertyCounts,
            buyerCounts,
        } = this.props

        if (
            this.props.user.isAuthorized
                && !this.props.user.permissions.isSuperuser
                && (
                    prevProps
                        ? isFilterUpdated || force
                        : true
                )
        ) {
            if (
                buyerURLs.indexOf(pathname) != -1
                    || Object.keys(buyerCounts) == 0
                    || (
                        Object.keys(buyerCounts) != 0
                            && Object.keys(buyerCounts.filters) != 0
                    )
                    || (isFilterUpdated && buyerURLs.indexOf(pathname) != -1)
                    || force
            ) {
                this.props.loadBuyerCounts({
                    filters: this.props.query.search.filters,
                })
            }
            if (
                propertyURLs.indexOf(pathname) != -1
                    || Object.keys(propertyCounts) == 0
                    || (
                        Object.keys(propertyCounts) != 0
                            && Object.keys(propertyCounts.filters) != 0
                    )
                    || (isFilterUpdated && propertyURLs.indexOf(pathname) != -1)
                    || force
            ) {
                this.props.loadPropertyCounts({
                    filters: this.props.query.search.filters,
                })
            }
        }
    }

    @autobind
    onSubscriptionSuccessful() {
        const {history, query: {search}} = this.props

        if (search?.next == '/search') {
            history.replace(search.next)
        }
    }

    @autobind
    updateAllCounts() {
        setTimeout(() => this.updateLeadsCounts(null, true))
    }

    showModalForNewUser() {
        if (this.props.query.search.isNewUser && !this.state.openModal) {
            this.setState({
                openModal: true,
            })
        }
    }

    @autobind
    toggleLoginAsModal() {
        this.setState(({showLoginAsModal}) => ({
            showLoginAsModal: !showLoginAsModal,
        }))
    }

    @autobind
    toggleMlsIdVerificationModal() {
        this.setState(({showMlsIdVerificationModal}) => ({
            showMlsIdVerificationModal: !showMlsIdVerificationModal,
        }))
    }

    @autobind
    toggleCreateLeadsModal() {
        this.setState(({showCreateLeadsModal}) => ({
            showCreateLeadsModal: !showCreateLeadsModal,
        }))
    }

    @autobind
    openSubscriptionModal() {
        this.setState({
            showSubscriptionModal: true,
        })
    }

    @autobind
    closeSubscriptionModal() {
        const {history, query: {search}} = this.props

        history.push({
            search: qs.stringify({
                ...search,
                next: search?.next == '/search'
                    ? undefined
                    : search?.next,
            }),
        })

        this.setState({
            showSubscriptionModal: false,
        })
    }

    @autobind
    openCreateTeamModal() {
        this.setState({
            openModal: true,
        })
    }

    @autobind
    closeCreateTeamModal() {
        const {history, query} = this.props

        if (query.search.isNewUser) {
            history.replace(history.location.pathname)
        }

        this.setState({
            openModal: false,
        })
    }

    @autobind
    LeftBlock() {
        const {user} = this.props
        const adminSearchPageLocalStorageKey = `admin-search-${buyerPortalsPropertyListConfigs.adminSearchTab.pageIdentifier}`

        return user.isAuthorized && (
            <Fragment>
                <NavBarLink.Sellers
                    path="/properties"
                    text="SELLERS"
                    className="letter-lg"
                    showSubNavBar={this.showSubNavBar}
                    // subNavBarLinks={
                    //     [
                    //         {
                    //             path: '/matches-listing',
                    //             text: 'MATCHES',
                    //             count: propertyCounts.count_needs_review_matches,
                    //             tooltip: 'How many matches need review?',
                    //         },
                    //         {
                    //             path: '/properties',
                    //             text: 'PROPERTIES',
                    //         },
                    //     ]
                    // }
                />
                <NavBarLink.Buyers
                    path="/buyers"
                    text="BUYERS"
                    className="letter-lg"
                    showSubNavBar={this.showSubNavBar}
                    // subNavBarLinks={
                    //     [
                    //         {
                    //             path: '/matches-buyers',
                    //             text: 'MATCHES',
                    //             count: buyerCounts.count_needs_review_matches,
                    //             tooltip: 'How many matches need review?',
                    //         },
                    //         {
                    //             path: '/buyers',
                    //             text: 'BUYER NEEDS',
                    //         },
                    //     ]
                    // }
                />
                {
                    // Temporarily hiding Lead Routing tab
                    // user.myRoleInTeam == 'owner' || user.myRoleInTeam == 'admin'
                    //     ? (
                    //         <Fragment>
                    //             <NavBarLink
                    //                 path="/lead-routing"
                    //                 text="LEAD ROUTING"
                    //                 className="letter-lg"
                    //             />
                    //             {
                    //                 // TODO: uncomment when the design and API will be updated
                    //                 // <NavBarLink
                    //                 //     path="/agents"
                    //                 //     text="AGENTS"
                    //                 //     className="letter-lg"
                    //                 // />
                    //             }
                    //         </Fragment>
                    //     )
                    //     : null
                }
                {
                    user.permissions.isSuperuser
                        ? (
                            <NavBarLink
                                path="/mls-agents"
                                text="MLS AGENTS"
                                className="letter-lg"
                            />
                        )
                        : null
                }
                <NavBarLink.Search
                    onClick={this.requireProSubscription}
                    path={`/search${
                        localStorage.getItem(adminSearchPageLocalStorageKey)
                            ? `?${localStorage.getItem(adminSearchPageLocalStorageKey)}`
                            : ''
                    }`}
                    text="SEARCH"
                    className="letter-lg"
                    badge={!user.permissions.hasProFeatures}
                    dropdownLinks={[
                        {
                            path: `/search${
                                localStorage.getItem(adminSearchPageLocalStorageKey)
                                    ? `?${qs.stringify(mixinDeep(
                                        QueryMiddleware.parse(localStorage.getItem(adminSearchPageLocalStorageKey)),
                                        {
                                            filters: {
                                                listing_statuses: ['all'],
                                            },
                                            isLoadAllProperies: undefined,
                                        }
                                    ))}`
                                    : ''
                            }`,
                            text: 'All Listings',
                        },
                        {
                            path: `/search${
                                localStorage.getItem(adminSearchPageLocalStorageKey)
                                    ? `?${qs.stringify(mixinDeep(
                                        QueryMiddleware.parse(localStorage.getItem(adminSearchPageLocalStorageKey)),
                                        {
                                            filters: {
                                                listing_statuses: ['office_exclusive'],
                                            },
                                            area: undefined,
                                            cities: undefined,
                                            zipcodes: undefined,
                                            isLoadAllProperies: true,
                                        },
                                    ))}`
                                    : ''
                            }`,
                            text: 'Office Exclusives',
                        },
                    ]}
                />
                <NavBarLink.Partners
                    path="/partners"
                    text="PARTNERS"
                    className="letter-lg"
                    showSubNavBar={this.showSubNavBar}
                    subNavBarLinks={
                        [
                            {
                                path: '/partners',
                                text: 'MY PARTNERS',
                            },
                        ]
                    }
                />
                <NavBarLink.Training
                    path="/training"
                    text="TRAINING"
                    className="letter-lg"
                />
            </Fragment>
        )
    }

    @autobind
    requireProSubscription(event) {
        const {user: {permissions}} = this.props

        if (!permissions.hasProFeatures) {
            event.preventDefault()

            this.props.history.push({
                search: qs.stringify({
                    ...this.props.query.search,
                    next: '/search',
                }),
            })

            this.openSubscriptionModal()
        }
    }

    @autobind
    RightBlockForAnonymous() {
        const {user} = this.props

        return user.isAnonymous && (
            <Fragment>
                <NavBarLink
                    className="ml-md"
                    path="https://raven.re/training"
                    text="SCHEDULE TRAINING"
                    target="_blank"
                    button={[
                        Link.Button.turquoiseBlue,
                    ]}
                    isWithoutBold
                />
            </Fragment>
        )
    }

    @autobind
    RightBlockForAuthorized() {
        const {
            customer: {currentSubscription},
            user: {isAuthorized, permissions, hasUsedProTrial},
            history: {location: {pathname}},
        } = this.props
        const trialDaysLeft = currentSubscription?.trialDaysLeft
        const subscriptionPage = pathname == '/subscription'

        return isAuthorized && (
            <Fragment>
                {
                    trialDaysLeft || permissions.canIBuyPlan
                        ? (
                            <Fragment>
                                <p
                                    className={cn(
                                        styles['trial-text'],
                                        'proxima text-xs letter-lg text-white mr-sm',
                                        {
                                            hide: !trialDaysLeft,
                                        }
                                    )}
                                >
                                    Free Trial expires in
                                    <br />
                                    <span className="proxima-bold">
                                        {trialDaysLeft} days
                                    </span>
                                </p>
                                {
                                    // Temporarily hiding upgrade button
                                    // !team.uuid
                                    //     ? (
                                    //         <NavBarLink
                                    //             path="/price-plans"
                                    //             text="UPGRADE"
                                    //             button={[
                                    //                 Link.Button.turquoiseBlue,
                                    //                 Link.Button.bold,
                                    //             ]}
                                    //         />
                                    //     )
                                    //     : null
                                }
                            </Fragment>
                        )
                        : null
                }
                <FeedbackDropdown className={cn({'mr-md': !subscriptionPage})} />
                {
                    permissions.hasProFeatures
                        ? (
                            <NavBarLink
                                className={cn(styles['navbar-button'], 'ml-md')}
                                path="https://raven.re/training"
                                text="SCHEDULE TRAINING"
                                target="_blank"
                                button={[
                                    Link.Button.turquoiseBlue,
                                ]}
                                isWithoutBold
                            />
                        )
                        : !subscriptionPage && (
                            <Button
                                className={styles['navbar-button']}
                                onClick={this.openSubscriptionModal}
                            >
                                <p className="proxima text-13 uppercase m-zero">
                                    {hasUsedProTrial ? 'Upgrade to Professional' : 'Try Pro For Free'}
                                </p>
                            </Button>
                        )
                }
                <this.UserDropdown />
            </Fragment>
        )
    }

    @autobind
    MobileDropdown() {
        const {user, history} = this.props

        return (
            <Dropdown
                className="mx-md"
                contentClassName={cn(styles.dropdown, 'p-zero mt-sm')}
                triggerClassName={styles['dropdown-trigger']}
                trigger={<div />}
                content={
                    user.isAnonymous
                        ? (
                            <Fragment>
                                <Dropdown.Separator className={styles.separator} />
                                <div className="my-md">
                                    <DropdownLink.MenuItem
                                        text="LOGIN"
                                        path={`/login${history.location.search}`}
                                    />
                                    <Dropdown.Separator />
                                    <DropdownLink.MenuItem
                                        text="SIGN UP"
                                        path={`/register${history.location.search}`}
                                    />
                                    <Dropdown.Separator />
                                    <DropdownLink.MenuItem
                                        text="SCHEDULE DEMO"
                                        path="https://raven.re/demo"
                                    />
                                </div>
                            </Fragment>
                        )
                        : (
                            <Fragment>
                                <div className="my-md">
                                    {
                                        ENVIRONMENT != 'production'
                                            ? (
                                                <Fragment>
                                                    <DropdownLink.MenuItem
                                                        path="/active-listing"
                                                        text="LISTINGS"
                                                    />
                                                    <Dropdown.Separator />
                                                </Fragment>
                                            )
                                            : null
                                    }
                                    <DropdownLink.MenuItem
                                        path="/properties"
                                        text="SELLERS"
                                    />
                                    <Dropdown.Separator />
                                    <DropdownLink.MenuItem
                                        path="/buyers"
                                        text="BUYERS"
                                    />
                                    <Dropdown.Separator />
                                    {
                                        // Temporarily hiding Lead Routing tab
                                        // user.myRoleInTeam == 'owner' || user.myRoleInTeam == 'admin'
                                        //     ? (
                                        //         <Fragment>
                                        //             <DropdownLink.MenuItem
                                        //                 path="/lead-routing"
                                        //                 text="LEAD ROUTING"
                                        //             />
                                        //             <Dropdown.Separator />
                                        //             {
                                        //                 // TODO: uncomment when the design and API will be updated
                                        //                 // <DropdownLink.MenuItem
                                        //                 //     path="/agents"
                                        //                 //     text="AGENTS"
                                        //                 // />
                                        //                 // <Dropdown.Separator />
                                        //             }
                                        //         </Fragment>
                                        //     )
                                        //     : null
                                    }
                                    {
                                        user.permissions.isSuperuser && (
                                            <Fragment>
                                                <DropdownLink.MenuItem
                                                    path="/mls-agents"
                                                    text="MLS AGENTS"
                                                />
                                                <Dropdown.Separator />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        // Temporarily hiding upgrade button
                                        // !user.team.uuid
                                        //     ? (
                                        //         <Fragment>
                                        //             <DropdownLink.MenuItem
                                        //                 path="/price-plans"
                                        //                 text="UPGRADE"
                                        //                 button={[
                                        //                     Link.Button.turquoiseBlue,
                                        //                     Link.Button.bold,
                                        //                 ]}
                                        //             />
                                        //             <Dropdown.Separator />
                                        //         </Fragment>
                                        //     )
                                        //     : null
                                    }
                                    <DropdownLink.MenuItem
                                        text="SETTINGS"
                                        path="/settings"
                                    />
                                    <Dropdown.Separator />
                                    {
                                        // Temporarily hide 'create team' option for agents
                                        // user.team.uuid
                                        //     ? (
                                        //         user.permissions.canIchangeMyTeam && (
                                        //             <Fragment>
                                        //                 <DropdownLink.MenuItem
                                        //                     text="TEAM SETTINGS"
                                        //                     path="/team-settings"
                                        //                 />
                                        //                 <Dropdown.Separator />
                                        //             </Fragment>
                                        //         )
                                        //     )
                                        //     : (
                                        //         <Fragment>
                                        //             <DropdownLink.MenuItem
                                        //                 text="CREATE TEAM"
                                        //                 path="/subscription/team-plan"
                                        //             />
                                        //             <Dropdown.Separator />
                                        //         </Fragment>
                                        //     )
                                        user.team.uuid && user.permissions.canIchangeMyTeam && (
                                            <Fragment>
                                                <DropdownLink.MenuItem
                                                    text="TEAM SETTINGS"
                                                    path="/team-settings"
                                                />
                                                <Dropdown.Separator />
                                            </Fragment>
                                        )
                                    }
                                    <DropdownLink.MenuItem
                                        text="SUPPORT"
                                        onClick={supportService.showSupportModal}
                                    />
                                    <Dropdown.Separator />
                                    {
                                        user.permissions.canILoginAsAnotherUser && (
                                            <Fragment>
                                                <DropdownLink.MenuItem
                                                    text="LOGIN AS"
                                                    onClick={this.toggleLoginAsModal}
                                                />
                                                <Dropdown.Separator />
                                            </Fragment>
                                        )
                                    }
                                    <DropdownLink.MenuItem
                                        text="LOGOUT"
                                        onClick={this.userLogout}
                                    />
                                </div>
                            </Fragment>
                        )
                }
                isMenu
            />
        )
    }

    @autobind
    UserDropdown() {
        const {user, history: {location: {pathname}}} = this.props
        const subscriptionPage = pathname == '/subscription'

        return (
            <Dropdown
                className="ml-md"
                trigger={
                    <Avatar.sm
                        src={user.avatarUrl}
                        isProUser={user.permissions.hasProFeatures}
                    />
                }
                contentClassName={styles['dropdown-content']}
                content={
                    <Fragment>
                        <div
                            className="flex flex-row align-center just-start"
                            style={{width: 263}}
                        >
                            <Avatar.md
                                className="mr-sm"
                                src={user.avatarUrl}
                                isProUser={user.permissions.hasProFeatures}
                            />
                            <div>
                                <p className="proxima-bold text-md text-dark-grey mt-zero mb-xs">
                                    {user.fullName.toUpperCase()}
                                </p>
                                <p className="m-zero proxima-medium text-13 text-bluey-grey">
                                    {user.email}
                                </p>
                            </div>
                        </div>
                        <div className="mt-md">
                            {
                                !subscriptionPage && (
                                    <DropdownLink.Settings
                                        text="Settings"
                                        path="/settings/profile"
                                        showSubNavBar={this.showSubNavBar}
                                        subNavBarLinks={[
                                            {
                                                path: '/settings/profile',
                                                text: 'PROFILE',
                                            },
                                        ]}
                                    />
                                )
                            }
                            {
                                // Temporarily hide 'create team' option for agents
                                // user.team.uuid
                                //     ? (
                                //         user.permissions.canIchangeMyTeam && (
                                //             <DropdownLink.TeamSettings
                                //                 text="Team Settings"
                                //                 path="/team-settings"
                                //             />
                                //         )
                                //     )
                                //     : (
                                //         <DropdownLink.TeamSettings
                                //             text="Create Team"
                                //             path="/subscription/team-plan"
                                //         />
                                //     )
                                user.team.uuid && user.permissions.canIchangeMyTeam && (
                                    <DropdownLink.TeamSettings
                                        text="Team Settings"
                                        path="/team-settings"
                                    />
                                )
                            }
                            <DropdownLink.Support
                                text="Support"
                                onClick={supportService.showSupportModal}
                            />
                            {
                                user.permissions.canILoginAsAnotherUser && (
                                    <DropdownLink.Login
                                        text="Login as"
                                        onClick={this.toggleLoginAsModal}
                                    />
                                )
                            }
                            <DropdownLink.Logout
                                text="Logout"
                                onClick={this.userLogout}
                            />
                        </div>
                        <Dropdown.Separator />
                        <Footer className={cn(styles['dropdown-footer'], 'invert-mt-sm invert-mx-sm')} />
                    </Fragment>
                }
            />
        )
    }

    @autobind
    showSubNavBar(newSubNavBar) {
        newSubNavBar = newSubNavBar.map(subLink => ({
            ...subLink,
            className: cn(subLink.className, 'ml-md-lg'),
        }))

        if (JSON.stringify(this.state.subNavBar) != JSON.stringify(newSubNavBar)) {
            this.setState({subNavBar: newSubNavBar})
        }
    }

    @autobind
    async userLogout() {
        const {logout, history} = this.props
        const searchString = qs.stringify({
            next: `${history.location.pathname}${history.location.search}`,
        })

        await logout()

        history.push({
            pathname: '/login',
            search: searchString,
        })
    }

    @autobind
    hideWithoutVerifiedMlsIDBlock() {
        this.setState({
            hideWithoutVerifiedMlsIDBlock: true,
        })
        this.props.saveMyProfile(
            {
                profile: {
                    details_json: {
                        is_verified_mls_id_dissmised: true,
                    },
                },
            },
            true
        )
    }
}

export default connect(
    ({
        user,
        billing: {customer},
        properties2: {propertiesList: {propertyCounts}},
        buyer: {buyersList: {buyerCounts}},
        mlsVendors,
    }) => ({
        user, customer, propertyCounts, buyerCounts, mlsVendors,
    }),
    dispatch => bindActionCreators(
        {
            ...usersActions,
            ...billingActions,
            ...properties2Action,
            ...buyerAction,
            ...mlsVendor,
        },
        dispatch
    ),
)(NavBar)
