import React from 'react' // eslint-disable-line no-unused-vars

import EmptyList from 'adminProj/components/empty_list'

export default {
    leads: {
        requiredOrdering: [],
        defaultOrdering: [
            {
                id: 'date_created',
                desc: false,
            },
        ],

        requiredFilters: {},
        defaultFilters: {},

        analytics: {
            openFilterEventName: 'Viewed Buyer Filter Screen',
            applyFilterEventName: 'Applied Buyer Filter',
            openAddLeadEventName: 'Viewed Add Buyer Screen',
            buyerDetailsModelName: 'Listing',
        },
        textInformation: {
            searchInputPlaceholder: 'Search Buyer Needs',
            listTitle: 'Buyer Needs',
            EmptyListComponent: EmptyList.buyer,
            loaderText: 'Loading Buyer Needs ...',
            editBuyerLeadHeader: 'Edit Buyer Need',
            emptyMatchesList: 'No matches found for this listing yet. Please check back again later.',
        },
        createFuncAllowed: true,
        tableColumnsListConfig: [
            'BUYER',
            // 'ACTIVITY',
            // 'SEGMENT_LAST_ACTIVITY',
            'MATCHES',
            'REGIONS',
            'PRICE_RANGE',
            'DATE_CREATED',
            'PRIMARY_AGENT',
        ],
    },
    matchesListing: {
        requiredOrdering: [],
        defaultOrdering: [
            {
                id: 'matches_count', // aliasing there common_services/buyer/buyers_list.js:54
                desc: false,
            },
        ],

        requiredFilters: {
            has_matches: true,
        },
        defaultFilters: {},

        analytics: {
            openFilterEventName: 'Viewed Buyer Match Filter Screen',
            applyFilterEventName: 'Applied  Buyer Match Filter',
            openAddLeadEventName: 'Viewed Add Match Screen',
            buyerDetailsModelName: 'Buyer Matches Listing',
        },
        textInformation: {
            searchInputPlaceholder: 'Search Matches',
            listTitle: 'Matches',
            EmptyListComponent: EmptyList.buyerMatchesListing,
            loaderText: 'Loading Buyer Leads ...',
            editBuyerLeadHeader: 'Edit Buyer Need',
            emptyMatchesList: 'No matches found for this listing yet. Please check back again later.',
        },
        createFuncAllowed: true,
        tableColumnsListConfig: [
            'BUYER',
            // 'ACTIVITY',
            // 'SEGMENT_LAST_ACTIVITY',
            'MATCHES',
            'REGIONS',
            'PRICE_RANGE',
            'DATE_CREATED',
            'PRIMARY_AGENT',
        ],
    },
}
