import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import commonChoicesActions from 'common_redux/actions/common_choices'
import billingActions from 'common_redux/actions/billing'

import PageWithSentryProfiler from 'common_components/page/page_with_sentry_profiler'
import FullScreen from 'common_components/full_screen'

import DataLoader from 'adminProj/components/data_loader'

import AdminPageWrapper from './components/admin_page_wrapper'

function AdminPage({
    pageProps,
    loadCommonLeadsChoices,
    loadCommonUserChoices,
    loadCommonChoices,
    loadStripePlanGroups,
    loadAllBillingPlans,
    ...props
}) {
    return (
        <PageWithSentryProfiler
            Loader={
                <FullScreen>
                    <DataLoader
                        className="position-center"
                        mainText={
                            <span className="text-xl">
                                Loading Page ...
                            </span>
                        }
                    />
                </FullScreen>
            }
            loadingRequiredData={loadingRequiredData}
            PageWrapper={AdminPageWrapper}
            pageProps={pageProps}
            {...props}
        />
    )

    function loadingRequiredData() {
        const promiseArray = []
        const {requiredData} = pageProps

        if (requiredData?.requiredCommonChoices?.users) {
            promiseArray.push(loadCommonUserChoices())
        }
        if (requiredData?.requiredCommonChoices?.leads) {
            promiseArray.push(loadCommonLeadsChoices())
        }
        if (requiredData?.requiredCommonChoices?.all || requiredData?.requiredCommonChoices === true) {
            promiseArray.push(loadCommonChoices())
        }
        if (requiredData?.requiredBillingData?.stripePlanGroups) {
            promiseArray.push(loadStripePlanGroups())
        }
        if (requiredData?.requiredBillingData?.stripePlans) {
            promiseArray.push(loadAllBillingPlans())
        }

        return Promise.all(promiseArray)
    }
}

AdminPage.propTypes = {
    pageProps: PropTypes.object,
    loadCommonLeadsChoices: PropTypes.func.isRequired,
    loadCommonUserChoices: PropTypes.func.isRequired,
    loadCommonChoices: PropTypes.func.isRequired,
    loadStripePlanGroups: PropTypes.func.isRequired,
    loadAllBillingPlans: PropTypes.func.isRequired,
}

AdminPage.defaultProps = {
    pageProps: {
        requiredData: {},
    },
}

export default connect(
    () => ({}),
    dispatch => bindActionCreators({...commonChoicesActions, ...billingActions}, dispatch)
)(AdminPage)
