import qs from 'qs'

export default class QueryMiddleware {
    constructor(history) {
        this._history = history
    }

    get search() {
        this.id = Math.random()
        return QueryMiddleware.parse(this._history.location.search)
    }

    static parse(string) {
        return qs.parse(string, {
            ignoreQueryPrefix: true,
            decoder: (value, decode) => {
                const decodeValue = decode(value)

                try {
                    return JSON.parse(decodeValue)
                }
                catch {} // eslint-disable-line no-empty

                if (decodeValue == 'Infinity') {
                    return Infinity
                }
                if (decodeValue == '-Infinity') {
                    return -Infinity
                }

                return decodeValue
            },
        }) || {}
    }
}
