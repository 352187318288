import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import {Field} from 'redux-form'
import cn from 'classnames'
import Slider, {Range} from 'rc-slider'
import 'rc-slider/assets/index.css'

import {priceFormat, numberFormat} from 'common_utils/string_format'

import Label from '../label'

import styles from './styles.styl'

class SelectRange extends Component {
    static propTypes = {
        descriptionText: PropTypes.string,
        className: PropTypes.string,

        displayTextFunction: PropTypes.func,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        startMinValue: PropTypes.number,
        startMaxValue: PropTypes.number,
        numberStep: PropTypes.number,

        isLogStep: PropTypes.bool,
        startFixed: PropTypes.bool,

        input: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
                PropTypes.number,
            ]),
            onChange: PropTypes.func,
        }),
        step: PropTypes.number,
        customValueCalculation: PropTypes.func,
        minValueCalculationAdditionArg: PropTypes.object,
        maxValueCalculationAdditionArg: PropTypes.object,
    }

    static defaultProps = {
        displayTextFunction: defaultDisplayTextFunction,
    }

    constructor(props) {
        super(props)

        this.logRuler = calcLogRuler(props.minValue, props.maxValue, props.numberStep)
        this.defaultValue = [
            props.isLogStep ? log(props.startMinValue, this.logRuler) : props.startMinValue,
            props.isLogStep ? log(props.startMaxValue, this.logRuler) : props.startMaxValue,
        ]
    }

    @autobind
    onChange(value) {
        if (this.props.startFixed) {
            this.props.input.onChange(Math.round(value))
        }
        else {
            this.setValue(...value)
        }
    }

    componentDidMount() {
        this.setValue(...this.defaultValue)
    }

    setValue(minValue, maxValue) {
        const {
            minValueCalculationAdditionArg,
            maxValueCalculationAdditionArg,
        } = this.props
        if (this.props.startFixed) {
            this.props.input.onChange(Math.round(maxValue))
        }
        else if (this.props.customValueCalculation) {
            this.props.input.onChange({
                low: this.props.customValueCalculation(minValue, minValueCalculationAdditionArg || {}),
                high: this.props.customValueCalculation(maxValue, maxValueCalculationAdditionArg || {}),
            })
        }
        else {
            this.props.input.onChange({
                low: Math.round(this.props.isLogStep ? Math.pow(this.logRuler, minValue) : minValue),
                high: Math.round(this.props.isLogStep ? Math.pow(this.logRuler, maxValue) : maxValue),
            })
        }
    }

    render() {
        const {
            maxValue,
            minValue,
            startMaxValue,
            descriptionText,
            className,
            startFixed,
            displayTextFunction,
            input: {value},
            customValueCalculation,
            maxValueCalculationAdditionArg,
            ...props
        } = this.props
        const displayText = startFixed
            ? displayTextFunction({low: 0, high: value, maxValue: maxValue})
            : displayTextFunction(
                {
                    ...value,
                    maxValue: customValueCalculation
                        ? customValueCalculation(maxValue, maxValueCalculationAdditionArg || {})
                        : maxValue,
                }
            )
        return (
            <div className={cn(styles['input-block'], className)}>
                <Label className={styles['input-label']}>
                    <div className="flex flex-row align-center space-between mb-md">
                        <p className="text-xs proxima uppercase m-zero">
                            {descriptionText}
                        </p>
                        <p className="text-sm proxima uppercase m-zero">
                            {displayText}
                        </p>
                    </div>
                    {
                        startFixed
                            ? (
                                <Slider
                                    min={0}
                                    max={maxValue}
                                    defaultValue={startMaxValue}
                                    onChange={this.onChange}
                                    {...props}
                                />
                            ) : (
                                <Range
                                    min={minValue}
                                    max={maxValue}
                                    defaultValue={this.defaultValue}
                                    onChange={this.onChange}
                                    pushable={2}
                                    {...props}
                                />
                            )
                    }
                </Label>
            </div>
        )
    }
}

function calcLogRuler(minValue, maxValue, numberStep) {
    const max = maxValue - minValue

    return Math.exp(Math.log(max) / numberStep)
}

function log(val, base) {
    return Math.log(val) / Math.log(base)
}

export default function ReduxSelectRange({...props}) {
    return <Field component={SelectRange} {...props} />
}

ReduxSelectRange.showPrice = ({low, high, maxValue}) => `${priceFormat(low)} - ${high >= maxValue ? 'ANY' : priceFormat(high)}` || ''
ReduxSelectRange.showPricePlain = ({low, high, maxValue}) => `$${numberFormat(low)} - ${high >= maxValue ? 'ANY' : `$${numberFormat(high)}`}` || ''
ReduxSelectRange.showSqrt = ({low, high, maxValue}) => `${numberFormat(low)} - ${high === maxValue ? 'ANY' : numberFormat(high)}` || ''
ReduxSelectRange.showYear = ({low, high}) => `${low} - ${new Date().getFullYear() === high ? 'PRESENT' : high}`
ReduxSelectRange.defaultDisplayTextFunction = defaultDisplayTextFunction

function defaultDisplayTextFunction({low, high, maxValue}) {
    return `${low} - ${high === maxValue ? 'ANY' : high}`
}
