import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../../api'
import PaginationService from '../../pagination'

import Member from './member'

const membersApi = new Api(API_DOMAINS.usersApi, 'teams', 'members')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
])
class MembersListService extends PaginationService {
    async loadPage(...args) {
        await super.loadPage(...args)

        return this.serialize
    }

    async changePageSize(...args) {
        await super.changePageSize(...args)

        return this.serialize
    }
}

export default new MembersListService(Member, membersApi, 10)
