import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import cn from 'classnames'

import Spinner from 'common_components/spinner'

import Label from '../label'

import styles from './styles'

export default function CustomCheckbox({...props}) {
    return (
        <Field
            component={checkbox}
            {...props}
            type="checkbox"
        />
    )
}

function checkbox({
    input,
    ...props
}) {
    return (
        <Checkbox
            {...{
                ...input,
                ...props,
            }}
        />
    )
}

checkbox.propTypes = {
    input: PropTypes.object,
}

export function Checkbox({
    className,
    lableText,
    lableTextPosition,
    textSize,
    disabled,
    isSquare,
    inProgress,
    ...props
}) {
    return (
        <Label
            className={cn(
                'flex',
                {
                    pointer: !disabled,
                },
                lableTextPosition,
                className
            )}
        >
            <span>
                <input
                    className={cn(
                        styles.checkbox,
                        {
                            [styles.square]: isSquare,
                        }
                    )}
                    type="checkbox"
                    disabled={disabled}
                    {...props}
                />
                {
                    inProgress
                        ? <Spinner />
                        : <div />
                }
            </span>
            <span
                className={cn(
                    'proxima-medium text-dark-grey-blue select-none',
                    textSize,
                    {
                        'ml-sm': (
                            lableTextPosition == Checkbox.lableTextPosition.right
                            || lableTextPosition == Checkbox.lableTextPosition.topRight
                        ),
                        'mr-sm': lableTextPosition == Checkbox.lableTextPosition.left,
                    }
                )}
            >
                {lableText}
            </span>
        </Label>
    )
}

Checkbox.lableTextPosition = {
    left: 'align-center flex-row-reverse',
    right: 'align-center',
    all: 'align-center space-between',
    allReverse: 'align-center space-between flex-row-reverse',
    topRight: 'align-start',
}
CustomCheckbox.lableTextPosition = Checkbox.lableTextPosition

Checkbox.propTypes = {
    lableTextPosition: PropTypes.oneOf(Object.values(Checkbox.lableTextPosition)),
    lableText: PropTypes.string,
    className: PropTypes.string,
    textSize: PropTypes.string,
    disabled: PropTypes.bool,
    isSquare: PropTypes.bool,
    inProgress: PropTypes.bool,
}

Checkbox.defaultProps = {
    lableTextPosition: Checkbox.lableTextPosition.right,
    textSize: 'text-md',
}
