import API_DOMAINS from 'API_DOMAINS'

// import {oneLine} from 'common-tags'
// import {deprecate} from 'core-decorators'

import {UserService} from 'common_services/user'
import MlsAgentService from 'common_services/mls_agents/mls_agent'
import Api from 'common_services/api'

import modelSerialize from 'common_utils/model_serialize'

import PropertyService from './property'

// const mlsDataApi = new Api(API_DOMAINS.leadsApi, 'mls-data', 'mlsproperties', 2)
const propertiesApi = new Api(API_DOMAINS.leadsApi, 'properties', undefined, 2)
// const prePropertyApi = new Api(API_DOMAINS.leadsApi, 'pre_property', null, 2)
// const propertiesActionsApi = new Api(API_DOMAINS.leadsApi, 'properties', 'actions')
// const propertyContactApi = new Api(API_DOMAINS.leadsApi, 'properties-contacts')
// const propertyActivityApi = new Api(API_DOMAINS.leadsApi, 'properties-activities')

@modelSerialize([
    ...PropertyService.serializedFields,
    'match_attitude',
    'match_uuid',
    'isPreInterested',
    'isInterested',
    'isNotInterested',
])
class PortalPropertyService extends PropertyService {
    match_attitude = null
    match_uuid = null

    constructor(data) {
        super(data)

        if (data) {
            if (data.contacts) {
                data.contacts = data.contacts.map(
                    contact => ({
                        ...contact,
                        phone: contact.phone && contact.phone.indexOf('+') == -1
                            ? `+1 ${contact.phone}`
                            : contact.phone,
                    })
                )
            }

            this.serialize = data
        }
    }

    get isPreInterested() {
        return this.match_attitude == 'pre_interested'
            || this.match_attitude == 'no_matches'
            || this.match_attitude == null
    }

    get isInterested() {
        return this.match_attitude == 'interested'
    }

    get isNotInterested() {
        return this.match_attitude == 'not_interested'
    }

    async loadPortalPropertyByUUID(buyerUUID, propertyUUID, config) {
        this.serialize = await PortalPropertyService.getPortalPropertyByUUID(
            buyerUUID,
            propertyUUID || this.uuid,
            config
        )

        try {
            if (this.agent) {
                this.agent = await UserService.getPublicUserInfoByUuid(this.agent, config)
            }
            if (this.mls_agent) {
                this.mls_agent = await MlsAgentService.getPublicMlsAgentByUUID(this.mls_agent, config)
            }
        }
        catch {} // eslint-disable-line no-empty

        return this.serialize
    }

    static async getPortalPropertyByUUID(buyerUUID, propertyUUID, config) {
        return buyerUUID
            ? new PortalPropertyService(
                (await propertiesApi.getApiWithName(`buyer-auto-shared/${buyerUUID}`).getByKey(propertyUUID, undefined, config)).data
            )
            : new PortalPropertyService(
                (await propertiesApi.getApiWithName('search-portal').getByKey(propertyUUID, undefined, config)).data
            )
    }

    async sharePortalProperty() {
        const {short_link_data} = (await propertiesApi.getApiWithName('search-portal/actions').put(this.uuid, {
            action: 'get_property_share_link',
        })).data

        this.serialize = {
            share_link: short_link_data.short_link,
        }

        return this.serialize
    }
}

export default PortalPropertyService
