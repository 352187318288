import React, {useState} from 'react'
import PropTypes from 'prop-types'

import FormFactory from 'common_components/form'
import TextHeader from 'common_components/text_header'
import Toast from 'common_components/toast'
import Link from 'common_components/link'

import styles from './styles.styl'

const emailValidators = [
    FormFactory.validators.required(),
    FormFactory.validators.email(),
]

function LoginAsAnotherUser({handleSubmit, loginAsAnotherUser}) {
    const [inProgress, setInProgress] = useState(false)

    return (
        <form onSubmit={handleSubmit(loginAs)}>
            <TextHeader.h3
                text="Login As"
                className="text-center mt-zero mb-md"
            />
            <p className="text-center text-dark-grey proxima text-lg mt-zero mb-md-lg">
                Please enter the email address of the user you want to login as.
            </p>
            <FormFactory.Field
                name="email"
                type="email"
                placeholder="example@email.com"
                validate={emailValidators}
                descriptionText="EMAIL ADDRESS"
                className="text-lg my-md"
                descriptionTextClassName="text-dark-grey proxima-bold text-xs"
            />
            <FormFactory.Button
                type="submit"
                title={inProgress ? 'LOGGING IN...' : 'LOGIN'}
                isFullSize
                disabled={inProgress}
            />
        </form>
    )

    async function loginAs({email}) {
        setInProgress(true)

        try {
            await loginAsAnotherUser(email)

            window.location.reload()
        }
        catch (error) {
            setInProgress(false)

            if (error?.response) {
                Toast.show.error(
                    <p className="proxima text-md text-white">
                        {
                            error.response.status == 403
                                ? 'Sorry, this email address is outside of your brokerage.'
                                : 'Sorry, you don’t have access to this account.'
                        }
                        {' '}
                        Questions? Contact us at
                        {' '}
                        <Link
                            className={styles['link']}
                            text="support@raven.re"
                            path="mailto:support@raven.re"
                        />
                    </p>
                )
            }

            throw new Error(error)
        }
    }
}

LoginAsAnotherUser.propTypes = {
    loginAsAnotherUser: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
}

export default FormFactory.registerForm(
    LoginAsAnotherUser,
    {
        form: 'loginAsAnotherUser',
        enableReinitialize: true,
    }
)
