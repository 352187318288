import createAction, {createSimpleAction} from '../create_action'
import actionTypes from '../action_types'

export default selectedProperty => {
    return {
        updateAndSaveProperty(newPropertyData, forceUpdateInstance) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.updateAndSaveProperty,
                action: () => getCurrentSelectedProperty().updateSomeFields(newPropertyData, forceUpdateInstance),
            })
        },

        loadPropertyByUUID(uuid, config) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadPropertyByUUID,
                action: () => getCurrentSelectedProperty().loadPropertyByUUID(uuid, config),
            })
        },

        loadPublicPropertyByUUID(uuid) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadPublicPropertyByUUID,
                action: () => getCurrentSelectedProperty().loadPublicPropertyByUUID(uuid),
            })
        },

        loadBuyerMatches() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadBuyerMatches,
                action: () => getCurrentSelectedProperty().loadBuyerMatches(),
            })
        },

        loadAllAgent() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadAllAgent,
                action: () => getCurrentSelectedProperty().loadAllAgent(),
            })
        },

        loadAllPhotoAgents() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadAllPhotoAgents,
                action: () => getCurrentSelectedProperty().loadAllPhotoAgents(),
            })
        },

        loadAllListingAgents() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadAllListingAgents,
                action: () => getCurrentSelectedProperty().loadAllListingAgents(),
            })
        },

        loadAllMlsAgent() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadAllMlsAgent,
                action: () => getCurrentSelectedProperty().loadAllMlsAgent(),
            })
        },

        loadMLSpropertiesData(config) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadMLSpropertiesData,
                action: () => getCurrentSelectedProperty().loadMLSpropertiesData(config),
            })
        },

        shareProperty() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.shareProperty,
                action: () => getCurrentSelectedProperty().shareProperty(),
            })
        },

        toggleSharePropertyLink() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.toggleSharePropertyLink,
                action: () => getCurrentSelectedProperty().toggleSharePropertyLink(),
            })
        },

        deleteProperty() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.deleteProperty,
                action: () => getCurrentSelectedProperty().deleteProperty(),
            })
        },

        loadPropertyFromMLS(address, place_id, unit, zipcode) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadPropertyFromMLS,
                action: () => getCurrentSelectedProperty().loadPropertyFromMLS(address, place_id, unit, zipcode),
            })
        },

        saveProperty(newData) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.saveProperty,
                action: () => getCurrentSelectedProperty().save(newData),
            })
        },

        changeCoAgents(newCoAgentsList) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.changeCoAgents,
                action: () => getCurrentSelectedProperty().changeCoAgents(newCoAgentsList),
            })
        },

        loadPortalPropertyByUUID(buyerUUID, propertyUUID, config) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadPortalPropertyByUUID,
                action: () => getCurrentSelectedProperty().loadPortalPropertyByUUID(buyerUUID, propertyUUID, config),
            })
        },

        loadPublicSearchPropertyByUUID(uuid) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.loadPublicSearchPropertyByUUID,
                action: () => getCurrentSelectedProperty().loadPublicSearchPropertyByUUID(uuid),
            })
        },

        sharePortalProperty() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.sharePortalProperty,
                action: () => getCurrentSelectedProperty().sharePortalProperty(),
            })
        },

        localUpdateSelectedProperty(newProperty) {
            return createSimpleAction({
                actionType: actionTypes.properties2.localUpdateSelectedProperty,
                action: () => newProperty,
                createPayload: () => {
                    getCurrentSelectedProperty().serialize = newProperty

                    return getCurrentSelectedProperty().serialize
                },
            })
        },

        createFollowUsBossIntegrationObject(personID) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.followUsBossIntegration.createFollowUsBossIntegrationObject,
                action: () => getCurrentSelectedProperty().createFollowUsBossIntegrationObject(personID),
            })
        },

        removeFollowUsBossIntegrationObject(personID) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.followUsBossIntegration.removeFollowUsBossIntegrationObject,
                action: () => getCurrentSelectedProperty().removeFollowUsBossIntegrationObject(personID),
            })
        },

        setAllMatchesToRead() {
            return createAction({
                actionTypeSpace: actionTypes.properties2.setAllMatchesToRead,
                action: () => getCurrentSelectedProperty().setAllMatchesToRead(),
            })
        },

        sendMessageToAgent({
            full_name,
            phone,
            email,
            message,
            propertyUUID,
            buyerUUID,
            agentUUID,
        }) {
            return createAction({
                actionTypeSpace: actionTypes.properties2.sendMessageToAgent,
                action: () => getCurrentSelectedProperty().sendMessageToAgent({
                    full_name,
                    phone,
                    email,
                    message,
                    propertyUUID,
                    buyerUUID,
                    agentUUID,
                }),
            })
        },
    }

    function getCurrentSelectedProperty() {
        return typeof selectedProperty == 'function'
            ? selectedProperty()
            : selectedProperty
    }
}
