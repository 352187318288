import React, {Component, Fragment} from 'react'
import cn from 'classnames'
import {autobind} from 'core-decorators'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'

import Button from 'common_components/button'
import {Input} from 'common_components/form/components/field'
import ErrorMessage from 'common_components/form/components/error_message'

import Dropdown from 'adminProj/components/dropdown'
import DataLoader from 'adminProj/components/data_loader'

import Separator from 'adminProj/components/separator'

import styles from './styles.styl'

const analyticPropType = PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    properties: PropTypes.object,
})

class Search extends Component {
    static propTypes = {
        id: PropTypes.any,
        searchOnType: PropTypes.func,
        createSelectedValue: PropTypes.func,
        mainDataField: PropTypes.string,
        secondaryDataField: PropTypes.string,
        searchInputPlaceholder: PropTypes.string,
        searchList: PropTypes.object,
        closeAddModal: PropTypes.func,
        analytics: PropTypes.shape({
            onSearchStart: analyticPropType,
            onPickedSearchResult: analyticPropType,
        }),
        isForm: PropTypes.bool,
        input: PropTypes.object,
        className: PropTypes.string,
        inputClassName: PropTypes.string,
        triggerClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        isNotCloseAfterAction: PropTypes.bool,
        isSearchIconEnabled: PropTypes.bool,
        meta: PropTypes.object,
        acceptValueOtherThanDropdown: PropTypes.bool,
        searchStringLength: PropTypes.number,
        descriptionText: PropTypes.string,
        descriptionTextClassName: PropTypes.string,
    }

    state = {
        isLoading: false,
        searchText: '',
        searchStarted: false, //added to stop showing Not Found at the start
    }

    static defaultProps = {
        searchStringLength: 2,
    }

    @autobind
    async getSearchResults(event) {
        const value = event.target.value
        this.setState({
            searchText: value,
        })
        if (this.timeForStartSearch) {
            clearTimeout(this.timeForStartSearch)
        }
        if (this.props.isForm && !this.props.acceptValueOtherThanDropdown) {
            this.props.input.onChange('')
        }
        else if (this.props.isForm && this.props.acceptValueOtherThanDropdown) {
            this.props.input.onChange(value)
        }

        if (value.length > this.props.searchStringLength) {
            this.timeForStartSearch = setTimeout(
                async () => {
                    this.setState({isLoading: true, searchStarted: true})
                    await this.props.searchOnType(0, value)
                    this.setState({isLoading: false})
                },
                1000
            )
        }
    }

    componentDidMount() {
        if (this.props.isForm && !!this.props.input.value) {
            const inputValue = typeof this.props.input.value == 'object'
                ? this.props.input.value[this.props.mainDataField]
                    || this.props.input.value[this.props.secondaryDataField]
                    || this.props.input.value['mainData']
                : this.props.input.value

            this.setState({
                searchText: inputValue,
            })
        }
    }

    @autobind
    onSelect(data) {
        const {
            isForm,
            input,
        } = this.props
        const value = this.props.createSelectedValue
            ? this.props.createSelectedValue(data)
            : data.uuid

        //search and assign some other value to input
        if (isForm) {
            this.setState(
                {
                    searchText: value,
                },
                input.onChange(value)
            )
        }
        else {
            this.props.closeAddModal(value)
        }
    }

    render() {
        const {isLoading, searchText, searchStarted} = this.state
        const {
            searchInputPlaceholder,
            searchList,
            closeAddModal,
            analytics,
            className,
            isNotCloseAfterAction,
            isSearchIconEnabled,
            isForm,
            descriptionText,
            descriptionTextClassName,
            input,
            id,
            mainDataField,
            secondaryDataField,
            inputClassName,
            triggerClassName,
            contentClassName,
        } = this.props
        const inputValue = typeof (input?.value || searchText) == 'object'
            ? (input?.value || searchText)[mainDataField]
                || (input?.value || searchText)[secondaryDataField]
                || (input?.value || searchText)['mainData']
            : (input?.value || searchText)

        let error = {}
        let visited = false
        let touched = false
        let isError = false

        if (isForm) {
            ({
                error,
                visited,
                touched,
            } = this.props.meta)
            isError = !!getError(error)
        }

        return (
            <div className={className}>
                {
                    descriptionText && (
                        <p
                            className={cn(
                                'text-xs proxima-bold uppercase mt-zero mb-xs',
                                descriptionTextClassName
                            )}
                        >
                            {descriptionText}
                        </p>
                    )
                }
                <Dropdown
                    trigger={
                        <div className={styles['trigger-div-container']}>
                            <div className={styles['trigger-div']}>
                                <Input
                                    id={id}
                                    {...input}
                                    autoComplete="off"
                                    className={cn(
                                        styles['search-input'],
                                        'proxima text-md letter-sm',
                                        {
                                            [styles['form-input']]: isForm,
                                        },
                                        inputClassName
                                    )}
                                    placeholder={searchInputPlaceholder}
                                    type="text"
                                    isSearchField={isSearchIconEnabled}
                                    onChange={this.getSearchResults}
                                    analytics={{
                                        onFocus: analytics?.onSearchStart,
                                    }}
                                    value={inputValue}
                                />
                            </div>
                            {
                                !!isError && (error && error.text)
                                    ? (
                                        <ErrorMessage text={error.text} />
                                    )
                                    : null
                            }
                        </div>
                    }
                    content={
                        !isLoading
                            ? (
                                searchList && searchList.count
                                    ? (
                                        this.renderSearchList(
                                            searchList.list,
                                            closeAddModal,
                                            analytics?.onPickedSearchResult
                                        )
                                    )
                                    : (
                                        inputValue?.length > 2 && searchStarted
                                            ? (
                                                <p className="proxima-medium text-md px-sm">
                                                    Nothing Found
                                                </p>
                                            )
                                            : null
                                    )
                            )
                            : (
                                <DataLoader mainText="Loading Search Results ..." />
                            )
                    }
                    triggerClassName={cn(
                        styles['dropdown-trigger-block'],
                        styles['trigger-list'],
                        {
                            [styles['remove-border-if-form']]: isForm,
                        },
                        triggerClassName
                    )}
                    contentClassName={cn(
                        styles['dropdown-content-block'],
                        styles['content-list'],
                        styles['is-form'],
                        'p-zero',
                        {
                            [styles['dropdown-content-form-block']]: isForm && !!isError,
                        },
                        contentClassName
                    )}
                    isNotCloseAfterAction={isNotCloseAfterAction}
                    triggerHasInput
                />
            </div>
        )

        function getError() {
            if (error) {
                if (error.params && error.params.afterBlur) {
                    return touched && error.text
                }
                return visited && error.text
            }
            return null
        }
    }

    renderSearchList(searchList, closeAddModal, analyticEvent) {
        const {
            mainDataField: mainData = 'mainData',
            secondaryDataField: secondaryData = 'secondaryData',
        } = this.props

        return searchList.map((data, idx) => (
            <Fragment key={data.uuid}>
                <Button.text
                    className={cn(styles['option-dropdown'], 'flex space-between py-sm-md px-sm letter-sm')}
                    onClick={() => this.onSelect(data)}
                    key={data.uuid}
                    analyticEvent = {analyticEvent}
                >
                    <span className="proxima-medium text-md text-turquoise-blue text-left">
                        {data[mainData]}
                    </span>
                    <span className="proxima text-sm">
                        {data[secondaryData]}
                    </span>
                </Button.text>
                {
                    idx != searchList.length - 1
                        ? <Separator />
                        : null
                }
            </Fragment>
        ))
    }
}

export default function ReduxSearch({...props}) {
    return props.isForm ? <Field component={Search} {...props} /> : <Search {...props} />
}

ReduxSearch.propTypes = {
    isForm: PropTypes.bool,
}
