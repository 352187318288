import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import analyticsService from 'common_services/analytics'

import styles from './styles.styl'

function CustomLink({
    className,
    children,
    text,
    path = '',
    button,
    isWithoutBold,
    onClick,
    analyticEvent,
    ...props
}) {
    const patternExternalLinks = new RegExp(
        [
            '://',
            '^mailto:',
            '^tel:',
        ]
            .map(el => `(${el})`)
            .join('|')
    )

    const linkProps = {
        className: (
            cn(
                styles.link,
                className,
                {
                    [styles['button']]: button != null,
                    [styles['without-bold']]: isWithoutBold,
                },
                button
            )
        ),
        onClick: onClickEvent,
        ...props,
    }

    return path && patternExternalLinks.test(path)
        ? <a href={path} {...linkProps}>{text || children}</a>
        : <Link to={path} {...linkProps}>{text || children}</Link>

    function onClickEvent(event) {
        if (analyticEvent && analyticEvent.eventName) {
            analyticsService.track(analyticEvent.eventName, analyticEvent.properties)
        }

        if (onClick) {
            onClick(event)
        }
    }
}

CustomLink.Button = {
    bold: styles['bold'],
    boldHover: styles['bold-hover'],
    slate: styles['slate'],
    slateHover: styles['slate-hover'],
    turquoiseBlue: styles['turquoise-blue'],
    turquoiseBlueHover: styles['turquoise-blue-hover'],
    turquoiseBlueInvert: styles['turquoise-blue-invert'],
    turquoiseBlueInvertHover: styles['turquoise-blue-invert-hover'],
    noBorder: styles['no-border'],
    noBorderHover: styles['no-border-hover'],
    blueyGrey: styles['bluey-grey'],
}

CustomLink.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    path: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    button: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    isWithoutBold: PropTypes.bool,
    onClick: PropTypes.func,
    analyticEvent: PropTypes.shape({
        eventName: PropTypes.string.isRequired,
        properties: PropTypes.object,
    }),
}

export default CustomLink
