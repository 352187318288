import buyerService from 'common_services/buyer'
import selectedBuyer from 'common_services/buyer/selected_buyer'
import buyersListService from 'common_services/buyer/buyers_list'
import buyerSearchService from 'common_services/buyer/buyer_search'

import createAction, {createEmptyAction} from './create_action'

import actionTypes from './action_types'

export default {
    buyerList(offset, limit, filters) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.buyerList,
            action: () => buyerService.buyerList(offset, limit, filters),
        })
    },
    loadBuyersPage(page, pageParams, pageSize) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.loadBuyersPage,
            action: () => buyersListService.loadPage(page, pageParams, pageSize),
        })
    },
    changePageSize(pageSize, pageParams) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.changePageSize,
            action: () => buyersListService.changePageSize(pageSize, pageParams),
        })
    },
    getBuyer(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.getBuyer,
            action: () => buyerService.getBuyer(uuid),
        })
    },
    saveBuyer(uuid, lead) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.saveBuyer,
            action: () => buyerService.saveBuyer(uuid, lead),
        })
    },
    resetSelectedBuyer(fieldName) {
        return createEmptyAction({
            actionType: actionTypes.buyer.resetSelectedBuyer,
        })
    },
    updateBuyer(buyerUuid, data) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.updateBuyerDetail,
            action: () => buyerService.updateBuyer(buyerUuid, data),
        })
    },
    createBuyerContact(data) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.createBuyerContact,
            action: () => buyerService.createBuyerContact(data),
        })
    },
    updateBuyerContact(uuid, data) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.updateBuyerContact,
            action: () => buyerService.updateBuyerContact(uuid, data),
        })
    },
    createBuyerActivity(data) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.createBuyerActivity,
            action: () => buyerService.createBuyerActivity(data),
        })
    },
    updateBuyerActivity(uuid, data) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.updateBuyerActivity,
            action: () => buyerService.updateBuyerActivity(uuid, data),
        })
    },
    deleteBuyer(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.deleteBuyer,
            action: () => buyerService.deleteBuyer(uuid),
        })
    },
    assignBuyerAgent(uuid, agent, action) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.assignBuyerAgent,
            action: () => buyerService.assignBuyerAgent(uuid, agent, action),
        })
    },
    loadSearchingBuyers(page, search) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.loadSearchingBuyers,
            action: () => buyerSearchService.loadSearchingBuyers(page, search),
        })
    },
    deleteBuyerContact(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.deleteBuyerContact,
            action: () => buyerService.deleteBuyerContact(uuid),
        })
    },
    deleteBuyerActivity(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.deleteBuyerActivity,
            action: () => buyerService.deleteBuyerActivity(uuid),
        })
    },
    loadBuyerCounts({ordering, search, filters} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.buyer.loadBuyerCounts,
            action: () => buyersListService.loadBuyerCounts({ordering, search, filters}),
        })
    },

    selectedBuyerActions: {
        loadBuyerByUUID(uuid, config) {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.loadBuyerByUUID,
                action: () => selectedBuyer.loadBuyerByUUID(uuid, config),
            })
        },

        loadAllAgent() {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.loadAllAgent,
                action: () => selectedBuyer.loadAllAgent(),
            })
        },

        changeCoAgents(newCoAgentsList) {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.changeCoAgents,
                action: () => selectedBuyer.changeCoAgents(newCoAgentsList),
            })
        },

        changePartners(newPartnersList) {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.changePartners,
                action: () => selectedBuyer.changePartners(newPartnersList),
            })
        },

        saveBuyer(newData) {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.saveBuyer,
                action: () => selectedBuyer.save(newData),
            })
        },

        updateSomeFields(newData) {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.updateSomeFields,
                action: () => selectedBuyer.updateSomeFields(newData),
            })
        },

        loadSearchPortalShortLink() {
            return createAction({
                actionTypeSpace: actionTypes.buyer.selectedBuyerActions.loadSearchPortalShortLink,
                action: () => selectedBuyer.loadSearchPortalShortLink(),
            })
        },

        setAllMatchesToRead() {
            return createAction({
                actionTypeSpace: actionTypes.buyers2.setAllMatchesToRead,
                action: () => selectedBuyer.setAllMatchesToRead(),
            })
        },

        sendInviteToAgent({inviteEmail, firstName, lastName, message}) {
            return createAction({
                actionTypeSpace: (
                    actionTypes.buyer.selectedBuyerActions.sendInviteToAgent
                ),
                action: () => selectedBuyer.sendInviteToAgent({inviteEmail, firstName, lastName, message}),
            })
        },
        removeAgentsInvite(inviteEmail) {
            return createAction({
                actionTypeSpace: (
                    actionTypes.buyer.selectedBuyerActions.removeAgentsInvite
                ),
                action: () => selectedBuyer.removeAgentsInvite(inviteEmail),
            })
        },
    },
}
