import Reducer from './reducer'

import actionTypes from '../actions/action_types'

import propertiesListService from 'common_services/properties2/properties_list'
import portalPropertiesListService from 'common_services/properties2/portal_properties_list'
import {getCurrentSelectedProperty} from 'common_services/properties2/selected_property'
import propertySearchService from 'common_services/properties2/properties_search'

import {portalPropertiesListServiceForMap} from 'common_redux/actions/properties2/portal_properties_list_for_map'

const INITIAL_STATE = {
    selectedProperty: getCurrentSelectedProperty().serialize,
    propertiesList: propertiesListService.serialize,
    portalPropertiesList: portalPropertiesListService.serialize,
    portalPropertiesListForMap: portalPropertiesListServiceForMap.serialize,
    searchResults: propertySearchService.serialize,
}

const properties2Reducer = new Reducer(INITIAL_STATE)

properties2Reducer.add(actionTypes.properties2.loadPropertiesPage, 'propertiesList')
properties2Reducer.add(actionTypes.properties2.changePageSize, 'propertiesList')
properties2Reducer.add(actionTypes.properties2.loadPropertyCounts, 'propertiesList')

properties2Reducer.addLocalAction(actionTypes.properties2.portalPropertiesList.setLead, 'portalPropertiesList')
properties2Reducer.add(actionTypes.properties2.portalPropertiesList.loadPortalPropertiesPage, 'portalPropertiesList')
properties2Reducer.add(
    actionTypes.properties2.portalPropertiesList.loadMissingItemsOnCurrentPage,
    'portalPropertiesList'
)
properties2Reducer.addLocalAction(
    actionTypes.properties2.portalPropertiesList.localUpdatePortalPropertyInList,
    'portalPropertiesList',
    undefined,
    true
)
properties2Reducer.addLocalAction(
    actionTypes.properties2.portalPropertiesList.localRemovePortalPropertyInList,
    'portalPropertiesList',
    undefined,
    true
)

properties2Reducer.addLocalAction(
    actionTypes.properties2.portalPropertiesListForMap.setLead,
    'portalPropertiesListForMap'
)
properties2Reducer.add(
    actionTypes.properties2.portalPropertiesListForMap.loadPortalPropertiesPage,
    'portalPropertiesListForMap'
)
properties2Reducer.addLocalAction(
    actionTypes.properties2.portalPropertiesListForMap.localUpdatePortalPropertyInList,
    'portalPropertiesListForMap',
    undefined,
    true
)
properties2Reducer.addLocalAction(
    actionTypes.properties2.portalPropertiesListForMap.localRemovePortalPropertyInList,
    'portalPropertiesListForMap',
    undefined,
    true
)

properties2Reducer.add(actionTypes.properties2.loadPropertyByUUID, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadPublicPropertyByUUID, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadMLSpropertiesData, 'selectedProperty')
properties2Reducer.addLocalAction(actionTypes.properties2.resetSelectedProperty, 'selectedProperty', undefined, true)
properties2Reducer.add(actionTypes.properties2.updateAndSaveProperty, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadBuyerMatches, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadAllAgent, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadAllPhotoAgents, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadAllMlsAgent, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.shareProperty, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.toggleSharePropertyLink, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.deleteProperty, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadPropertyFromMLS, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.saveProperty, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.changeCoAgents, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadAllListingAgents, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.setAllMatchesToRead, 'selectedProperty')

properties2Reducer.add(actionTypes.properties2.loadPortalPropertyByUUID, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.loadPublicSearchPropertyByUUID, 'selectedProperty')
properties2Reducer.add(actionTypes.properties2.sharePortalProperty, 'selectedProperty')
properties2Reducer.addLocalAction(
    actionTypes.properties2.localUpdateSelectedProperty,
    'selectedProperty',
    undefined,
    true
)

properties2Reducer.add(
    actionTypes.properties2.followUsBossIntegration.createFollowUsBossIntegrationObject,
    'selectedProperty'
)
properties2Reducer.add(
    actionTypes.properties2.followUsBossIntegration.removeFollowUsBossIntegrationObject,
    'selectedProperty'
)

properties2Reducer.add(actionTypes.properties2.assignPropertyAgent)
properties2Reducer.add(actionTypes.properties2.unassignPropertyAgent)
properties2Reducer.add(actionTypes.properties2.createPropertyContact)
properties2Reducer.add(actionTypes.properties2.updatePropertyContact)
properties2Reducer.add(actionTypes.properties2.deletePropertyContact)
properties2Reducer.add(actionTypes.properties2.createPropertyActivity)
properties2Reducer.add(actionTypes.properties2.updatePropertyActivity)
properties2Reducer.add(actionTypes.properties2.deletePropertyActivity)

properties2Reducer.add(actionTypes.properties2.loadSearchingProperties, 'searchResults')

export default properties2Reducer.reducersFunction

export {properties2Reducer}
