import React from 'react'
import PropTypes from 'prop-types'

import Close from 'common_svg/close.svg?jsx'

import styles from './styles.styl'

export default function CloseButton({closeToast}) {
    return <Close className={styles.close} onClick={closeToast} />
}

CloseButton.propTypes = {
    closeToast: PropTypes.func,
}
