import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Tooltip from 'common_components/tooltip'

export default function ErrorMessage({className, text, children, ...props}) {
    return (
        <p
            className={
                cn(
                    'my-xxs text-sm text-error proxima flex align-center',
                    {
                        hide: typeof (text || children) === 'boolean',
                    },
                    className
                )
            }
            {...props}
        >
            {text?.message || text || children}
            {
                !!text?.tooltip && (
                    <Tooltip.error
                        className="ml-xs"
                        text={text.tooltip}
                        tooltipWidth={150}
                    />
                )
            }
        </p>
    )
}

ErrorMessage.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    className: PropTypes.string,
    children: PropTypes.node,
}
