import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import analyticsService from 'common_services/analytics'

import parseStylusExport from 'common_utils/parse_stylus_export'

import Tooltip from 'common_components/tooltip'

import styles from './styles'

const stylesVariable = parseStylusExport(styles)

export default function Button({
    type,
    title,
    children,
    className,
    buttonSize,
    buttonStyle,
    isInvert,
    isFullSize,
    isWithoutBorder,
    isText,
    isVirtualDisabled,
    buttonWrapper,
    analyticEvent,
    onClick,
    tooltip,
    isWithoutBold,
    isWithoutMinWidth,
    customStylesObj,
    ...props
}) {
    const curretnStylesObj = customStylesObj || styles

    const button = (
        <button
            className={cn(
                className,
                {
                    [curretnStylesObj[`button-${buttonSize}-${buttonStyle}`]]: !isText,
                    [curretnStylesObj.text]: isText,
                    [curretnStylesObj.invert]: isInvert,
                    [curretnStylesObj['without-border']]: isWithoutBorder,
                    [curretnStylesObj['virtual-disabled']]: isVirtualDisabled,
                    [curretnStylesObj['without-bold']]: isWithoutBold,
                    [curretnStylesObj['without-min-width']]: isWithoutMinWidth,
                }
            )}
            type={type}
            onClick={onClickEvent}
            {...props}
        >
            {
                tooltip
                    ? (
                        <Tooltip
                            text={tooltip}
                            tooltipIcon={title || children}
                            tooltipBlockProps={{
                                style: {
                                    width: 'auto',
                                    minWidth: 'auto',
                                },
                            }}
                        />
                    )
                    : (title || children)
            }
        </button>
    )

    return (
        (buttonSize == 'all' || isFullSize) && !isText ? (
            <div
                className={cn(
                    buttonWrapper,
                    {
                        [curretnStylesObj['is-full-size']]: isFullSize,
                    }
                )}
            >
                {button}
            </div>
        ) : button
    )

    function onClickEvent(event) {
        if (analyticEvent && analyticEvent.eventName) {
            analyticsService.track(analyticEvent.eventName, analyticEvent.properties)
        }

        if (onClick) {
            onClick(event)
        }
    }
}

Button.propTypes = {
    className: PropTypes.string,
    buttonWrapper: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    isInvert: PropTypes.bool,
    isFullSize: PropTypes.bool,
    isWithoutBorder: PropTypes.bool,
    isText: PropTypes.bool,
    isVirtualDisabled: PropTypes.bool,
    buttonSize: PropTypes.oneOf(Object.keys(stylesVariable.buttonSize)),
    buttonStyle: PropTypes.oneOf(Object.keys(stylesVariable.buttonStyle)),
    onClick: PropTypes.func,
    analyticEvent: PropTypes.shape({
        eventName: PropTypes.string.isRequired,
        properties: PropTypes.object,
    }),
    tooltip: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
        PropTypes.string,
    ]),
    isWithoutBold: PropTypes.bool,
    isWithoutMinWidth: PropTypes.bool,
    customStylesObj: PropTypes.object,
}

Button.defaultProps = {
    type: 'button',
    buttonSize: 'all',
    buttonStyle: 'primary',
}

Object.keys(stylesVariable.buttonSize).forEach(buttonSize => {
    Button[buttonSize] = props => ( // eslint-disable-line react/display-name
        <Button buttonSize={buttonSize} {...props} />
    )
    // display-name for previos component
    Button[buttonSize].displayName = `Button.${buttonSize}`

    Object.keys(stylesVariable.buttonStyle).forEach(buttonStyle => {
        Button[buttonSize][buttonStyle] = props => ( // eslint-disable-line react/display-name
            <Button buttonSize={buttonSize} buttonStyle={buttonStyle} {...props} />
        )
        // display-name for previos component
        Button[buttonSize][buttonStyle].displayName = `Button.${buttonSize}.${buttonStyle}`
    })
})

Button.text = props => ( // eslint-disable-line react/display-name
    <Button isText {...props} />
)
// display-name for previos component
Button.text.displayName = 'Button.text'
