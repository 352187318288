/* global Intercom */

import {autobind} from 'core-decorators'

import analyticsService from './analytics'

class SupportService {
    updateUserInformation() {
        return analyticsService.setCurrentUser()
    }

    @autobind
    showSupportModal() {
        this.runIntercom('show')
    }

    @autobind
    hideSupportModal() {
        this.runIntercom('hide')
    }

    runIntercom(...args) {
        if (analyticsService._segmentApi.Integrations.Intercom) {
            Intercom(...args)
        }
    }
}

export default new SupportService
