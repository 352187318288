import commonChoicesService from 'common_services/common_choices'

import createAction from './create_action'

import actionTypes from './action_types'

export default {
    loadCommonLeadsChoices() {
        return createAction({
            actionTypeSpace: actionTypes.commonChoices.loadCommonLeadsChoices,
            action: () => commonChoicesService.loadCommonLeadsChoices(),
        })
    },

    loadCommonUserChoices() {
        return createAction({
            actionTypeSpace: actionTypes.commonChoices.loadCommonUserChoices,
            action: () => commonChoicesService.loadCommonUserChoices(),
        })
    },

    loadCommonChoices() {
        return createAction({
            actionTypeSpace: actionTypes.commonChoices.loadCommonChoices,
            action: () => commonChoicesService.loadCommonChoices(),
        })
    },
}
