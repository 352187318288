import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.styl'

export default function TextHeader({text, className, children, ElementType, bold, ...props}) {
    return (
        <ElementType
            className={cn(
                styles[ElementType],
                {
                    'text-dark-blue-grey': ElementType != 'h5',
                    'text-bluey-grey': ElementType == 'h5',
                    [styles.bold]: bold,
                },
                className
            )}
            {...props}
        >
            {text || children}
        </ElementType>
    )
}

TextHeader.propTypes = {
    text: PropTypes.string.isRequired,
    ElementType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
    className: PropTypes.string,
    children: PropTypes.node,
    bold: PropTypes.bool,
}

TextHeader.defaultProps = {
    ElementType: 'h1',
}

Object.keys(styles).forEach(headerLevel => {
    TextHeader[headerLevel] = props => ( // eslint-disable-line react/display-name
        <TextHeader ElementType={headerLevel} {...props} />
    )
    // display-name for previos component
    TextHeader[headerLevel].displayName = `TextHeader.${headerLevel}`
})
