import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import cn from 'classnames'
import {SubmissionError, clearSubmitErrors} from 'redux-form'

import componentActionCreators from 'common_redux/component_action_creators'
import billingActions from 'common_redux/actions/billing'

import FormFactory from 'common_components/form'
import Toast from 'common_components/toast'

import analyticsService from 'common_services/analytics'

import DataLoader from 'adminProj/components/data_loader'

import BillingProvider from '../provider'
import BillingElement from '../element'

class PaymentDetailsForm extends Component {
    static propTypes = {
        formData: PropTypes.object,
        // use in 147 line
        addCustomerCard: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
        handleSubmit: PropTypes.func.isRequired,
        clearSubmitErrors: PropTypes.func.isRequired,
        className: PropTypes.string,
        // use in 131 line
        addingCardFailed: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
        // use in 142 line
        addingCardInProgress: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    }

    static getDerivedStateFromProps({formData}) {
        const newState = {}

        if (formData?.submitErrors?.stripeError) {
            const stripeError = formData.submitErrors.stripeError

            newState.fieldsError = {
                [stripeError.code]: stripeError.message,
            }
        }
        if (formData?.submitErrors?.integrationError) {
            const integrationError = formData.submitErrors.integrationError

            newState.integrationError = !!integrationError
        }

        return Object.keys(newState) == 0
            ? null
            : newState
    }

    state = {
        fieldsError: {},
    }

    render() {
        const {className, handleSubmit} = this.props
        const {integrationError} = this.state

        return integrationError
            ? (
                <DataLoader mainText="Reloading the form ..." />
            )
            : (
                <form
                    className={cn('flex flex-column space-between', className)}
                    onSubmit={handleSubmit}
                >
                    <BillingElement.CardNumberElement
                        onChange={this.clearFieldError}
                        onPaste={this.clearFieldError}
                        externalTextError={this.cartNumberError}
                    />
                    <div className="row my-md row--no-gutter">
                        <div className="col col--s-4-of-12 pr-sm-md">
                            <BillingElement.CardExpiryElement
                                onChange={this.clearFieldError}
                                onPaste={this.clearFieldError}
                                externalTextError={this.cartExpiryError}
                            />
                        </div>
                        <div className="col col--s-4-of-12 pr-sm-md">
                            <BillingElement.CardCVCElement
                                onChange={this.clearFieldError}
                                onPaste={this.clearFieldError}
                                externalTextError={this.cartCVCError}
                            />
                        </div>
                        <div className="col col--s-4-of-12">
                            <BillingElement.PostalCodeElement />
                        </div>
                    </div>
                </form>
            )
    }

    componentDidUpdate() {
        if (this.state.integrationError) {
            this.props.clearSubmitErrors('PaymentDetailsForm')

            this.setState({
                integrationError: false,
            })
        }
    }

    @autobind
    clearFieldError() {
        if (this.props.formData?.submitErrors) {
            this.props.clearSubmitErrors('PaymentDetailsForm')
        }

        this.setState({
            fieldsError: {},
        })
    }

    get cartNumberError() {
        return this.state.fieldsError.incomplete_number
            || this.state.fieldsError.invalid_number
            || this.state.fieldsError.card_declined
    }

    get cartExpiryError() {
        return this.state.fieldsError.incomplete_expiry
            || this.state.fieldsError.invalid_expiry_year_past
            || this.state.fieldsError.expired_card
    }

    get cartCVCError() {
        return this.state.fieldsError.incomplete_cvc
            || this.state.fieldsError.invalid_cvc
            || this.state.fieldsError.incorrect_cvc
    }
}

export default BillingProvider(FormFactory.registerForm(
    PaymentDetailsForm,
    {
        form: 'PaymentDetailsForm',
        onSubmit: async (fields, dispatch, formProps) => {
            const {stripe, addCustomerCard, getSetupIntentToken, addingCardFailed, addingCardInProgress} = formProps

            addingCardInProgress && addingCardInProgress()

            const {client_secret} = await getSetupIntentToken()
            let createResponse

            try {
                createResponse = await stripe.createPaymentMethod('card', {
                    billing_details: {
                        address: {postal_code: fields.postal_code},
                    },
                })
            }
            catch (error) {
                addingCardFailed && addingCardFailed()

                if (error.name == 'IntegrationError') {
                    Toast.show.error('Something went wrong. Try again 🙏')

                    throw new SubmissionError({integrationError: true})
                }
                else {
                    throw new Error(error)
                }
            }

            if (createResponse.error) {
                addingCardFailed && addingCardFailed()
                throw new SubmissionError({stripeError: createResponse.error})
            }

            try {
                const confirmResponse = await stripe.confirmCardSetup(client_secret, {
                    payment_method: createResponse.paymentMethod.id,
                })

                if (confirmResponse.error) {
                    throw new SubmissionError(confirmResponse.error)
                }

                return await addCustomerCard(createResponse.paymentMethod.id)
            }
            catch (error) {
                if (error instanceof SubmissionError) {
                    const {errors: {message, code, doc_url}} = error

                    Toast.show.error(message)

                    addingCardFailed && addingCardFailed()

                    analyticsService.track(
                        'Card Failed',
                        {
                            card_code: code,
                            card_message: message,
                            card_doc_url: doc_url,
                        }
                    )

                    throw new SubmissionError({stripeError: error.errors})
                }
                else {
                    const {response: {data: stripeError}} = error

                    Toast.show.error(stripeError.detail)
                }

                addingCardFailed && addingCardFailed()
                throw new Error(error)
            }
        },
    },
    () => ({}),
    dispatch => componentActionCreators({...billingActions, clearSubmitErrors}, dispatch)
))
