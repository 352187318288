import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import cn from 'classnames'

import Button from 'common_components/button'

import Modal from '../base_modal'

export default class ConfirmModal extends Component {
    static propTypes = {
        mainHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]).isRequired,
        subHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]),
        cancelText: PropTypes.string,
        cancelFunc: PropTypes.func,
        doneText: PropTypes.string,
        doneButtonType: PropTypes.func,
        doneFunc: PropTypes.func,
        inProgressText: PropTypes.string,
        hideClose: PropTypes.bool,
    }

    static defaultProps = {
        cancelText: 'CANCEL',
        doneText: 'OK',
        doneButtonType: Button.medium.warning,
        hideClose: true,
    }

    state = {
        inProgress: false,
    }

    render() {
        const {
            mainHeader,
            subHeader,
            cancelText,
            cancelFunc,
            doneText,
            doneFunc, // eslint-disable-line no-unused-vars
            inProgressText,
            doneButtonType: DoneButton,
            hideClose,
            ...props
        } = this.props
        const {inProgress} = this.state

        return (
            <Modal.center
                content={
                    <Fragment>
                        <div
                            className={cn(
                                'capitalize proxima-bold text-xl letter-xl text-center',
                                {
                                    'mb-md': !subHeader,
                                }
                            )}
                        >
                            {mainHeader}
                        </div>
                        {
                            !!subHeader && (
                                <div className="proxima text-lg my-md text-center">
                                    {subHeader}
                                </div>
                            )
                        }
                        <div className="flex flex-column just-center">
                            <DoneButton
                                title={
                                    inProgress
                                        ? inProgressText || `${doneText} ...`
                                        : doneText
                                }
                                onClick={this.doneFunc}
                                disabled={inProgress}
                            />
                            <Button.text
                                title={cancelText}
                                onClick={cancelFunc}
                                className="text-turquoise-blue proxima-bold text-md letter-sm mt-md"
                            />
                        </div>
                    </Fragment>
                }
                size={Modal.size.xs}
                hideClose={hideClose}
                {...props}
            />
        )
    }

    @autobind
    async doneFunc() {
        let resDoneFunc = this.props.doneFunc()

        if (resDoneFunc instanceof Promise) {
            this.setState({
                inProgress: true,
            })

            resDoneFunc = await resDoneFunc

            this.setState({
                inProgress: false,
            })
        }

        return resDoneFunc
    }
}
