import ENVIRONMENT from 'ENVIRONMENT'

export default (message, isError = false) => {
    if (typeof message == 'string' || isError) {
        return target => applyDecoretor(target, message, isError)
    }

    const TargetClass = message

    return applyDecoretor(TargetClass)
}

function applyDecoretor(TargetClass, message, isError) {
    if (ENVIRONMENT == 'production') {
        return TargetClass
    }

    return (...args) => {
        if (ENVIRONMENT != 'production') {
            const logMethod = isError ? console.error : console.warn // eslint-disable-line no-console

            logMethod(`DEPRECATION: CLASS (${TargetClass.name}) IS DEPRECATED!!! DO NOT USE IT!!! ${message || ''}`)
        }

        return new TargetClass(...args)
    }
}
