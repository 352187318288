import API_DOMAINS from 'API_DOMAINS'

import Api from '../api'
import {UserService} from '../user'

import modelSerialize from 'common_utils/model_serialize'

const routingRulesApi = new Api(API_DOMAINS.leadsApi, 'routing', 'rules')
const routingRulesActionApi = new Api(API_DOMAINS.leadsApi, 'routing', 'actions')

@modelSerialize([
    'agents',
    'uuid',
    'city',
    'price_range',
    'is_active',
    'lead_type',
    'type',
    'user',
    'zipcode',
])
class RoutingRuleService {
    agents = []
    uuid = ''
    city = {}
    price_range = {}
    is_active = false
    lead_type= ''
    zipcode={}

    constructor(data = {}) {
        this.serialize = data
    }

    createRoutingRules(data) {
        return routingRulesApi.post(data)
    }

    async getRoutingRulesDetails(uuid) {
        const {data} = await routingRulesApi.getByKey(uuid)
            .then(async res => {
                if (res.data.agents.length) {
                    res.data.agents = await UserService.searchUsers({uuid: res.data.agents.map(val => val.agent)})
                }
                return res
            })
        this.serialize = data

        return this.serialize
    }

    static async getRoutingRulesByAgent(agentUuid) {
        return routingRulesApi.get({
            agent: agentUuid,
            limit: 1000,
        })
    }

    deleteRoutingRule(uuid) {
        return routingRulesApi.delete(uuid)
    }

    updateRoutingRule(uuid, data) {
        return routingRulesApi.patch(uuid, data)
    }

    updateRoutingRuleStatus(uuid, data) {
        return routingRulesActionApi.patch(uuid, data)
    }

    assignRoutingRuleAgent(uuid, agents) {
        return routingRulesActionApi.put(uuid, {
            action: 'assign_agents',
            agents,
        })
    }

    unassignRoutingRuleAgent(uuid, agents) {
        return routingRulesActionApi.put(uuid, {
            action: 'un_assign_agents',
            agents,
        })
    }
}

export default new RoutingRuleService

export {RoutingRuleService}
