import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import TextareaAutosize from 'react-autosize-textarea'
import cn from 'classnames'

import ErrorMessage from '../error_message'
import RequiredLabel from '../required_label'

import styles from './styles'

export default function CustomTextarea(props) {
    return <Field component={field} {...props} />
}

function field({
    input,
    descriptionText,
    descriptionTextClassName,
    meta: {error, visited, touched, active},
    externalTextError,
    isImportant,
    maxLength,
    ...props
}) {
    const isError = !!getError(error)

    return (
        <Fragment>
            {
                (descriptionText || !!maxLength) && (
                    <div
                        className={cn(
                            'flex align-center',
                            {
                                'space-between': descriptionText,
                                'just-end': !descriptionText,
                            }
                        )}
                    >
                        {
                            descriptionText && (
                                <div className="flex align-center mb-xs">
                                    <p
                                        className={cn(
                                            'text-xs proxima-bold uppercase m-zero',
                                            descriptionTextClassName
                                        )}
                                    >
                                        {descriptionText}
                                    </p>
                                    {
                                        isImportant && (
                                            <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            !!maxLength && (
                                <p className="proxima text-xs text-bluey-grey letter-sm m-zero">
                                    max {maxLength} characters
                                </p>
                            )
                        }
                    </div>
                )
            }
            <Textarea
                {...{...input, ...props}}
                maxLength={maxLength}
                invalid={`${isError}`}
            />
            {
                externalTextError
                    ? (
                        <ErrorMessage>
                            {externalTextError}
                        </ErrorMessage>
                    )
                    : (
                        isError && error && error.text && (
                            <ErrorMessage text={error.text} />
                        )
                    )
            }
        </Fragment>
    )

    function getError() {
        if (error) {
            if (error.params && error.params.afterBlur) {
                return touched && error.text
            }
            return visited && error.text
        }
        return null
    }
}

field.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    externalTextError: PropTypes.string,
    descriptionText: PropTypes.string,
    descriptionTextClassName: PropTypes.string,
    isImportant: PropTypes.bool,
    maxLength: PropTypes.number,
}

function Textarea({
    className,
    height,
    autoSize,
    ...props
}) {
    return autoSize
        ? (
            <TextareaAutosize
                style={{height}}
                className={cn(styles.textarea, 'proxima text-sm', className)}
                {...props}
            />
        )
        : (
            <textarea
                style={{height}}
                className={cn(styles.textarea, 'proxima text-sm', className)}
                {...props}
            />
        )
}

Textarea.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    autoSize: PropTypes.bool,
}
