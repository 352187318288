import React, {Component} from 'react'
import {autobind} from 'core-decorators'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FormFactory from 'common_components/form'
import Button from 'common_components/button'
import ErrorMessage from 'common_components/form/components/error_message'

import DropdownIcon from 'common_svg/dropdown_with_circle.svg?jsx'
import DoneArrowWithBgIcon from 'common_svg/done_arrow_with_bg.svg?jsx'
import WarningCircleIcon from 'common_svg/warning_circle.svg?jsx'

import states from 'common_constants/states.json'

import SearchComponent from 'adminProj/components/search'

import RoleDropdown from '../../../role_dropdown'

import styles from './styles'

export default class InviteMemberFields extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        member: PropTypes.object.isRequired,
        teamMemberRoles: PropTypes.object.isRequired,
        onMemberChange: PropTypes.func.isRequired,
        searchMlsVendors: PropTypes.func.isRequired,
        mlsVendors: PropTypes.object.isRequired,

        syncErrors: PropTypes.array,
        scrollingElement: PropTypes.node,
        isSuperuser: PropTypes.bool,
    }

    static emailValidators = [
        FormFactory.validators.email(undefined, undefined, true),
    ]

    static nameValidators = [
        FormFactory.validators.name(undefined, undefined, true),
    ]

    static phoneNumberValidators = [
        FormFactory.validators.phoneNumber(undefined, undefined, true),
    ]

    static licenseStateValidators = [
        FormFactory.validators.usaState(false, undefined, undefined, true),
    ]

    state = {
        openDetails: false,
        searchList: {
            count: 0,
            list: [],
        },
    }

    normalizeSearchMlsProvider(searchDataArray) {
        return {
            ...searchDataArray,
            list: searchDataArray.list.map(val => ({
                uuid: val.name,
                ...val,
            })),
        }
    }

    @autobind
    async searchOnType(pageNumber, searchText = '') {
        const search_result = states.filter(value =>
            (value.name + value.uuid).toLowerCase().indexOf(searchText.toLowerCase()) != -1
        ).map(value => ({
            uuid: value.uuid,
            mainData: value.name,
            secondaryData: value.uuid,
        }))

        this.setState(prevState => ({
            searchList: {
                ...prevState.searchList,
                count: search_result.length,
                list: search_result,
            },
        }))
    }

    render() {
        const {
            name,
            member: {
                role,
                textError,
                mls_licenses: [{license_number, license_state}],
                mls_ids: [{mls_id, mls_vendor}],
            },
            teamMemberRoles,
            scrollingElement,
            onMemberChange, // eslint-disable-line
            syncErrors,
            searchMlsVendors,
            mlsVendors,
            isSuperuser,
            ...props
        } = this.props
        const {openDetails, searchList} = this.state

        return (
            <div {...props}>
                <div className={cn(styles['member-wrapper'], 'mt-sm-md')}>
                    <FormFactory.Field
                        name={`${name}.email`}
                        type="email"
                        placeholder="Work Email Address"
                        validate={InviteMemberFields.emailValidators}
                        onChange={({target: {value}}) => this.onChange({
                            email: value,
                        })}
                        className={styles.input}
                    />
                    <RoleDropdown
                        className={styles['role-selector']}
                        contentClassName={styles['role-selects-block']}
                        teamMemberRoles={teamMemberRoles}
                        currentMemberRole={role}
                        onClickRole={memberRole => this.onChange({
                            role: memberRole,
                        })}
                        scrollingElement={scrollingElement}
                    />
                    <Button.text
                        className={styles['dropdown-button']}
                        title={
                            this.hasMemberAllInformation
                                ? (
                                    <DoneArrowWithBgIcon className={styles['dropdown-button-icon']} />
                                )
                                : (
                                    syncErrors
                                        ? (
                                            <WarningCircleIcon className={styles['dropdown-button-icon']} />
                                        )
                                        : (
                                            <DropdownIcon
                                                className={cn(
                                                    styles['dropdown-button-icon'],
                                                    {
                                                        [styles['dropdown-button-icon-revert']]: openDetails,
                                                    }
                                                )}
                                            />
                                        )
                                )
                        }
                        onClick={this.toggleMemberDetails}
                    />
                </div>
                {
                    textError && (
                        <ErrorMessage>
                            {textError}
                        </ErrorMessage>
                    )
                }
                <div
                    className={cn(
                        styles['agent-detail'],
                        'mt-sm px-xs py-sm',
                        {hide: !openDetails}
                    )}
                >
                    <div className="row row--no-gutter">
                        <div className="col col--m-6-of-12">
                            <FormFactory.Field
                                name={`${name}.first_name`}
                                type="text"
                                placeholder="i.e. Jane"
                                validate={InviteMemberFields.nameValidators}
                                descriptionText="FIRST NAME"
                                className="mr-sm"
                                maxLength={32}
                                onChange={({target: {value}}) => this.onChange({
                                    first_name: value,
                                })}
                            />
                        </div>
                        <div className="col col--m-6-of-12">
                            <FormFactory.Field
                                name={`${name}.last_name`}
                                type="text"
                                placeholder="i.e. Smith"
                                validate={InviteMemberFields.nameValidators}
                                descriptionText="LAST NAME"
                                className="ml-sm"
                                maxLength={32}
                                onChange={({target: {value}}) => this.onChange({
                                    last_name: value,
                                })}
                            />
                        </div>
                    </div>
                    <FormFactory.Phone
                        name={`${name}.phone`}
                        descriptionText="PHONE"
                        className="mt-sm"
                        validate={InviteMemberFields.phoneNumberValidators}
                        onChange={({notEmptyPhone} = {}) => this.onChange({
                            phone: notEmptyPhone,
                        })}
                    />
                    <div className="row row--no-gutter mt-sm">
                        <div className="col col--m-6-of-12">
                            <FormFactory.Field
                                name={`${name}.mls_licenses.license_number`}
                                type="text"
                                placeholder="enter license number"
                                descriptionText="LICENSE NUMBER"
                                className="mr-sm"
                                onChange={({target: {value}}) => this.onChange({
                                    mls_licenses: [{
                                        license_number: value,
                                        license_state,
                                    }],
                                })}
                            />
                        </div>
                        <div className="col col--m-6-of-12">
                            <SearchComponent
                                name={`${name}.mls_licenses.license_state`}
                                inputName={`${name}.mls_licenses.license_state`}
                                descriptionText="LICENSE STATE"
                                searchInputPlaceholder="license state"
                                searchOnType={this.searchOnType}
                                searchList={searchList}
                                className="ml-sm"
                                inputClassName={styles['mls-vendor']}
                                contentClassName={styles['mls-vendor-dropdown']}
                                isForm
                                searchStringLength={1}
                                onChange={(event, value) => this.onChange({
                                    mls_licenses: [{
                                        license_number,
                                        license_state: value.substr(0, 2),
                                    }],
                                })}
                                acceptValueOtherThanDropdown
                                validate={InviteMemberFields.licenseStateValidators}
                            />
                        </div>
                    </div>
                    <div className="row row--no-gutter mt-sm">
                        <div className="col col--m-6-of-12">
                            <FormFactory.Field
                                name={`${name}.mls_ids.mls_id`}
                                type="text"
                                placeholder="enter your mls id"
                                descriptionText="MLS ID"
                                className="mr-sm"
                                onChange={({target: {value}}) => this.onChange({
                                    mls_ids: [{
                                        mls_id: value,
                                        mls_vendor,
                                    }],
                                })}
                            />
                        </div>
                        <div className="col col--m-6-of-12 text-left">
                            <SearchComponent
                                name={`${name}.mls_ids.mls_vendor`}
                                inputName={`${name}.mls_ids.mls_vendor`}
                                descriptionText="MLS VENDOR"
                                searchInputPlaceholder="enter mls provider"
                                searchOnType={(pageNumber, searchText) => searchMlsVendors(searchText)}
                                searchList={this.normalizeSearchMlsProvider(mlsVendors)}
                                isForm
                                className="ml-sm"
                                inputClassName={styles['mls-vendor']}
                                contentClassName={styles['mls-vendor-dropdown']}
                                acceptValueOtherThanDropdown
                                mainDataField="long_name"
                                secondaryDataField="name"
                                createSelectedValue={value => value}
                                onChange={(event, value) => this.onChange({
                                    mls_ids: [{
                                        mls_id,
                                        mls_vendor: typeof value == 'object'
                                            ? value
                                            : {
                                                name: value,
                                                long_name: null,
                                            },
                                    }],
                                })}
                            />
                        </div>
                    </div>
                    {
                        isSuperuser && (
                            <FormFactory.Checkbox
                                name={`${name}.autoAcceptInvite`}
                                className="mt-sm-md"
                                lableText="Auto-accept invite"
                                onChange={({target: {checked}}) => this.onChange({
                                    autoAcceptInvite: checked,
                                })}
                            />
                        )
                    }
                </div>
            </div>
        )
    }

    get hasMemberAllInformation() {
        const {
            member: {
                role,
                email,
                first_name,
                last_name,
                phone,
                mls_licenses: [{license_number, license_state}],
                mls_ids: [{mls_id, mls_vendor}],
            },
            syncErrors,
        } = this.props

        return !!(
            role
                && email
                && first_name
                && last_name
                && phone
                && license_number
                && license_state
                && mls_id
                && mls_vendor
                && !syncErrors
        )
    }

    @autobind
    toggleMemberDetails() {
        this.setState(({openDetails}) => ({
            openDetails: !openDetails,
        }))
    }

    @autobind
    onChange(newValue) {
        const {
            member: {id, email, role, first_name, last_name, phone, mls_licenses, mls_ids, autoAcceptInvite},
            onMemberChange,
        } = this.props

        if (newValue?.mls_ids?.[0]?.mls_vendor?.uuid) {
            delete newValue.mls_ids[0].mls_vendor.uuid
        }

        return onMemberChange({
            id,
            email,
            role: role || 'agent',
            invitationSent: false,
            first_name,
            last_name,
            phone,
            mls_licenses,
            mls_ids,
            autoAcceptInvite,
            ...newValue,
        })
    }
}
