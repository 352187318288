import {autobind, nonenumerable} from 'core-decorators'
import mixinDeep from 'mixin-deep'
import cloneDeep from 'clone-deep'
import * as Sentry from '@sentry/react'

import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import {setAuthorizationToken} from '../http'
import Api from '../api'
import analyticsService from '../analytics'
import {FollowUsBossIntegration} from '../follow_us_boss_integration'

import Token from './token'
import UDID from './udid_generator'
import TeamService from './team/index'

const accountsApi = new Api(API_DOMAINS.usersApi, 'accounts')
const profileApi = new Api(API_DOMAINS.usersApi, 'accounts', 'profile', 2)
const resetApi = new Api(API_DOMAINS.usersApi, 'accounts', 'password/reset')
const resetConfirmApi = new Api(API_DOMAINS.usersApi, 'accounts', 'password/reset/confirm')

const mainUsersApi = new Api(API_DOMAINS.usersApi, 'users')
const usersApi = new Api(API_DOMAINS.usersApi, 'users', 'users')
const userActionsApi = new Api(API_DOMAINS.usersApi, 'users', 'action')
const mlsLicenseApi = new Api(API_DOMAINS.usersApi, 'users', 'mlslicense')
const mlsidsApi = new Api(API_DOMAINS.usersApi, 'users', 'mlsid', 2)
const userMlsAgentInviteTokenApi = new Api(API_DOMAINS.usersApi, 'users', 'mls-agent-invites')
const nmlsIdsApi = new Api(API_DOMAINS.usersApi, 'users', 'nmlsids', 2)

const partnersApi = new Api(API_DOMAINS.usersApi, 'partners')
const partnerActionApi = new Api(API_DOMAINS.usersApi, 'users', 'partner/action')

const teamInviteTokenApi = new Api(API_DOMAINS.usersApi, 'teams', 'invite/readonly')

const notificationsApi = new Api(API_DOMAINS.leadsApi, 'common', 'notifications-conf')

@modelSerialize([
    'udid',
    'uuid',
    'username',

    'isAuthenticated',
    'isAuthorized',
    'isAnonymous',

    {
        serializeField: 'firstName',
        originField: 'first_name',
    },
    {
        serializeField: 'lastName',
        originField: 'last_name',
    },
    'fullName',
    'abbreviatedName',
    'email',
    'profile',
    {
        serializeField: 'dateJoined',
        originField: 'date_joined',
        type: Date,
    },
    'avatarUrl',
    {
        serializeField: 'team',
        originField: 'team',
        convertingToType: TeamService,
    },
    {
        fieldName: 'myRoleInTeam',
        serializeField: 'authToken.authTokenData.team.role',
    },
    'phone',
    'billing',
    {
        serializeField: 'intercomId',
        originField: 'intercom_id',
    },
    {
        serializeField: 'mlsLicenses',
        originField: 'mls_licenses',
    },
    {
        serializeField: 'mlsIds',
        originField: 'mls_ids',
    },
    'hasLicenseNumber',
    'hasMlsId',
    'permissions',
    {
        serializeField: 'lastLogin',
        originField: 'last_login',
        type: Date,
    },
    'inviteTokenInfo',
    'isHasVerifiedLicense',
    'isHaveVerifiedMlsId',
    '_isHaveVerifiedMlsId',
    'officeAddress',
    'officeCityAndState',
    'buyer_search_portal_link',
    'hasUsedProTrial',
    'stateID',
    {
        serializeField: 'nmlsIds',
        originField: 'nmls_ids',
    },
    {
        serializeField: 'userGroups',
        originField: 'user_groups',
    },
    'partner_invite_link',
    'promotionLinkSlug',
    'pairedUsers',
    'followUsBossIntegration',
    'notifications',
])
class UserService {
    static availableUserGroups = {
        agents: {
            agent: 'agent',
        },
        partners: {
            partnerFree: 'partner_free',
            loanOfficer: 'loan_officer',
            titleRep: 'title_rep',
        },
        brokerageAdmin: 'brokerage_admin',
    }

    @nonenumerable
    udid = UDID
    @nonenumerable
    authToken = new Token('AUTH_TOKEN')
    profile = {}
    dateJoined = null
    uuid = null
    username = ''
    email = ''
    firstName = ''
    lastName = ''
    team = null
    billing = {}
    intercomId = null
    mlsLicenses = []
    mlsIds = []
    lastLogin = null
    inviteTokenInfo = null
    _isHaveVerifiedMlsId = false
    buyer_search_portal_link = null
    #userGroups = null
    nmlsIds = []
    partner_invite_link = null
    pairedUsers = []
    followUsBossIntegration = null
    notifications = {}

    constructor(data = {}) {
        this.serialize = data
    }

    get avatarUrl() {
        return this.profile?.images_json?.avatar
    }

    get phone() {
        return this.profile?.details_json?.phone
    }

    get fullName() {
        return `${this.firstName || ''} ${this.lastName || ''}`.trim()
    }

    get abbreviatedName() {
        const firstPart = this.firstName || this.lastName
        const secondPart = firstPart != this.lastName
            ? this.lastName
            : null

        return `${firstPart || ''}${secondPart ? ` ${secondPart.substring(0, 1)}.` : ''}`.trim()
    }

    get isAuthenticated() {
        return this.authToken.isNotExpired
    }

    get isAuthorized() {
        return this.isAuthenticated && !!this.authToken.authTokenData.uuid
    }

    get isAnonymous() {
        return !this.isAuthorized
    }

    get hasLicenseNumber() {
        return !!this.mlsLicenses.length
    }

    get hasMlsId() {
        return !!this.mlsIds.length
    }

    get isHasVerifiedLicense() {
        return this.mlsLicenses.some(({verification_status}) => verification_status === 'verified')
    }

    get isHaveVerifiedMlsId() {
        return this.mlsIds.some(({verification_status}) => verification_status === 'verified')
            || this._isHaveVerifiedMlsId
    }

    get hasUsedProTrial() {
        return this.authToken.authTokenData?.billing?.has_used_trial
    }

    set userGroups(value) {
        this.#userGroups = value

        return this.userGroups
    }

    get userGroups() {
        return this.authToken.authTokenData?.user_groups || this.#userGroups
    }

    get stateID() {
        return this.profile?.state
    }

    get promotionLinkSlug() {
        return this.profile?.promotion_link?.match(/^https:\/\/.*\/(.*)/)?.[1]
    }

    get officeAddress() {
        const {profile: {office_address_json}} = this

        if (office_address_json?.address) {
            const {address, suite} = office_address_json
            const [streetAddress, ...rest] = address.split(',')

            return suite
                ? `${streetAddress} #${suite}, ${rest.join(',')}`
                : address
        }

        return ''
    }

    get officeCityAndState() {
        const officeAddress = this.officeAddress

        if (officeAddress) {
            const addressTokens = officeAddress.split(',')
            const [city, state] = addressTokens.slice(addressTokens.length - 3, addressTokens.length - 1)

            return `${city}, ${state}`
        }

        return ''
    }

    get permissions() {
        const permissionsObject = Object.create(this)
        const isGrandfatheredTeam = this.authToken.authTokenData?.billing?.stripe_plan == 'grandfathered_team'
        const permissionsList = [
            {
                permissionName: 'canIBuyPlan',
                func: () => {
                    const myRoleInTeam = this.authToken.authTokenData?.team?.role

                    return (myRoleInTeam == 'owner' || myRoleInTeam == undefined) && !isGrandfatheredTeam
                },
            },
            {
                permissionName: 'isGrandfatheredTeam',
                func: () => isGrandfatheredTeam,
            },
            {
                permissionName: 'isSuperuser',
                func: () => this.authToken.authTokenData.is_superuser,
            },
            {
                permissionName: 'isIBuyerPaid',
                func: () => this.authToken.authTokenData.is_ibuyer_paid,
            },
            {
                permissionName: 'isIBuyerTrained',
                func: () => this.authToken.authTokenData.is_ibuyer_trained,
            },
            {
                permissionName: 'canIchangeMyTeam',
                func: () => {
                    const myRoleInTeam = this.authToken?.authTokenData?.team?.role || ''
                    return myRoleInTeam == 'owner' || myRoleInTeam == 'admin'
                },
            },
            {
                permissionName: 'hasProFeatures',
                func: () => (
                    this.billing.has_pro_features
                        || this.billing.has_ios_subscription
                        || this.billing.is_in_billing_retry_period
                        || this.authToken.authTokenData.is_superuser
                ),
            },
            {
                permissionName: 'hasIosSubscription',
                func: () => (
                    this.billing.has_ios_subscription
                        || this.billing.is_in_billing_retry_period
                ),
            },
            {
                permissionName: 'isAgent',
                func: () => (
                    Array.isArray(this.userGroups)
                        ? this.userGroups.some(
                            userGroup => Object.values(UserService.availableUserGroups.agents).indexOf(userGroup) != -1
                        )
                        : null
                ),
            },
            {
                permissionName: 'isPartner',
                func: () => (
                    Array.isArray(this.userGroups)
                        ? this.userGroups.some(
                            userGroup => Object.values(
                                UserService.availableUserGroups.partners
                            ).indexOf(userGroup) != -1
                        )
                        : null
                ),
            },
            {
                permissionName: 'isLoanOfficer',
                func: () => (
                    Array.isArray(this.userGroups)
                        ? this.userGroups.some(
                            userGroup => userGroup == UserService.availableUserGroups.partners.loanOfficer
                        )
                        : null
                ),
            },
            {
                permissionName: 'isTitleRep',
                func: () => (
                    Array.isArray(this.userGroups)
                        ? this.userGroups.some(
                            userGroup => userGroup == UserService.availableUserGroups.partners.titleRep
                        )
                        : null
                ),
            },
            {
                permissionName: 'isBrokerageAdmin',
                func: () => (
                    Array.isArray(this.userGroups)
                        ? this.userGroups.some(
                            userGroup => userGroup == UserService.availableUserGroups.brokerageAdmin
                        )
                        : null
                ),
            },
            {
                permissionName: 'canILoginAsAnotherUser',
                func: () => (
                    this.permissions.isSuperuser
                        || this.permissions.isBrokerageAdmin
                ),
            },
        ]

        permissionsList.forEach(permissionObj => {
            Object.defineProperty(permissionsObject, permissionObj.permissionName, {
                enumerable: true,
                get: permissionObj.func,
            })
        })

        return permissionsObject
    }

    @autobind
    async loginAsAnotherUser(email) {
        const {data: {token}} = await accountsApi.getApiWithName('login_as').post(
            {email},
            {
                withCredentials: true,
            }
        )

        await analyticsService.track('Switched User', {
            [this.permissions.isSuperuser ? 'superAdmin' : 'brokerageAdmin']: this.email,
            customer: email,
        })

        this._setAuthorizationToken(token)

        await analyticsService.setCurrentUser({
            loginPlatform: 'web',
        }, true)
    }

    @autobind
    authenticate() {
        return accountsApi.getApiWithName('jwt').post(
            {
                udid: this.udid,
            },
            {
                withCredentials: true,
            }
        )
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)
                return this.serialize
            })
            .then(() => (
                this.isAuthorized
                    ? this.loadUserAllInforamation()
                    : this.serialize
            ))
            .then(async () => {
                await analyticsService.setCurrentUser()
                await analyticsService.track('Application Opened')

                return this.serialize
            })
    }

    login(email, password) {
        return accountsApi.getApiWithName('login').post(
            {
                email,
                password,
                udid: this.udid,
            },
            {
                withCredentials: true,
            }
        )
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)
                return this.serialize
            })
            .then(this.loadUserAllInforamation)
            .then(async () => {
                const {userData} = analyticsService

                await analyticsService.setCurrentUser({
                    loginPlatform: 'web',
                })
                await analyticsService.track('Login', {
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    name: userData.name,
                    phone: userData.phone,
                    loginPlatform: 'web',
                    via: 'password',
                })

                return this.serialize
            })
    }

    register({
        userGroup = 'core_agent',
        firstName,
        lastName,
        phone,
        nmlsId,
        stateID,
        invitedbyText,
        email,
        password,
        confirmPassword,
        pair_with_title_rep,
    }) {
        return accountsApi.getApiWithName('register').post(
            {
                user_group: userGroup,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone?.notEmptyPhone,
                state: stateID,
                nmls_id: nmlsId,
                password1: password,
                password2: confirmPassword,
                udid: this.udid,
                pair_with_title_rep: pair_with_title_rep || undefined,
            },
            {
                withCredentials: true,
            }
        )
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)
                return this.serialize
            })
            .then(this.loadUserAllInforamation)
            .then(async () => {
                const {userData} = analyticsService

                await analyticsService.setCurrentUser({
                    signupPlatform: 'web',
                })
                await analyticsService.track('Signup', {
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    name: userData.name,
                    phone: userData.phone,
                    signupPlatform: 'web',
                    flow: 'default',
                    userType: userData.userType,
                    state: userData.state,
                    invitedby_text: invitedbyText ? invitedbyText : undefined,
                })

                return this.serialize
            })
    }

    registerMlsAgent(inviteToken, {firstName, lastName, phone, password, confirmPassword}) {
        return userMlsAgentInviteTokenApi.put(
            inviteToken,
            {
                udid: this.udid,
                first_name: firstName,
                last_name: lastName,
                password1: password,
                password2: confirmPassword,
                phone: phone?.notEmptyPhone,
            },
            {
                withCredentials: true,
            }
        )
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)
                return this.serialize
            })
            .then(this.loadUserAllInforamation)
            .then(async () => {
                const {userData} = analyticsService

                await analyticsService.setCurrentUser({
                    signupPlatform: 'web',
                })
                await analyticsService.track('Signup', {
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    name: userData.name,
                    phone: userData.phone,
                    signupPlatform: 'web',
                    flow: 'active listing match',
                })

                return this.serialize
            })
    }

    logout() {
        return accountsApi.getApiWithName('logout').post({
            udid: this.udid,
        }, {
            withCredentials: true,
        })
            .then(async ({data: {token}}) => {
                await analyticsService.track('Logout')
                await analyticsService.resetUser()
                this.serialize = new UserService
                this._setAuthorizationToken(token)
                return this.serialize
            })
    }

    confirmMagicLogin(magicToken, userId) {
        return accountsApi.getApiWithName('magic-login/confirm').post({
            udid: this.udid,
            token: magicToken,
            uid: userId,
        }, {
            withCredentials: true,
        })
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)
                return this.serialize
            })
            .then(this.loadUserAllInforamation)
            .then(async () => {
                const {userData} = analyticsService

                await analyticsService.setCurrentUser({
                    loginPlatform: 'web',
                })
                await analyticsService.track('Login', {
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    name: userData.name,
                    phone: userData.phone,
                    loginPlatform: 'web',
                    via: 'magic',
                })

                return this.serialize
            })
    }

    @autobind
    async loadUserAllInforamation() {
        await Promise.all([
            this.loadMyProfile(),
            this.loadMyTeam(),
        ])

        return this.serialize
    }

    loadMyProfile() {
        return this.isAuthorized
            ? profileApi.get()
                .then(({data}) => {
                    if (data?.profile?.details_json?.phone) {
                        const {phone} = data.profile.details_json
                        data.profile.details_json.phone = phone.indexOf('+') != -1
                            ? phone
                            : `+1 ${phone}`
                    }
                    this.serialize = data

                    if (Sentry) {
                        Sentry.setUser(this.serialize)
                    }

                    return this.serialize
                })
            : Promise.resolve(this.serialize)
    }

    async loadInviteTokenInfo(inviteToken) {
        const res = await teamInviteTokenApi.getByKey(inviteToken, {
            is_valid: true,
            is_used: false,
        })

        this.serialize = {
            inviteTokenInfo: {
                ...res.data.data_json,
                firstName: res.data.data_json.first_name,
                lastName: res.data.data_json.last_name,
            },
        }

        return this.serialize
    }

    saveMyProfile({firstName, lastName, phone, email, stateID, ...changes}, forceUpdateInstance) {
        const {userId: analyticUserId} = analyticsService.userData
        const dataToSave = mixinDeep(
            cloneDeep(this.serialize),
            {
                ...changes,
                email,
                first_name: firstName,
                last_name: lastName,
                phone: phone?.notEmptyPhone || undefined,
                profile: {
                    ...(changes.profile || {}),
                    state: stateID,
                },
                partner_invite_link: undefined, // to remove due to request
            }
        )
        const promise = accountsApi.getApiWithName('profile').patch(null, dataToSave)
            .then(this.authenticate)
            .then(async () => {
                await analyticsService.setCurrentUser(null, true)
                await analyticsService.updateUserEmail(analyticUserId != email ? analyticUserId : null)

                return this.serialize
            })

        if (forceUpdateInstance) {
            this.serialize = dataToSave

            return Promise.resolve(this.serialize)
        }

        return promise
    }

    changePassword(currentPassword, newPassword, confirmNewPassword) {
        return accountsApi.getApiWithName('password/change').post({
            old_password: currentPassword,
            new_password1: newPassword,
            new_password2: confirmNewPassword,
        }, {
            withCredentials: true,
        })
            .then(this.authenticate)
    }

    async loadNotifications() {
        this.notifications = (await notificationsApi.get()).data

        return this.serialize
    }

    async saveNotification(newNotifications) {
        this.notifications = (await notificationsApi.patch(undefined, newNotifications)).data

        return this.serialize
    }

    async createTeam(teamName, inviteMembers = []) {
        this.team = new TeamService

        await this.team.createTeam(teamName, inviteMembers = [])

        return this.authenticate()
    }

    async loadMyTeam() {
        await (this.team.uuid ? this.team.loadTeam() : Promise.resolve())

        return this.serialize
    }

    async updateTeam(data) {
        await this.team.updateTeam(data)

        return this.serialize
    }

    async addMembers(addMembers) {
        await this.team.addMembers(addMembers)

        return this.serialize
    }

    async inviteMembers(inviteMembers) {
        await this.team.inviteMembers(inviteMembers)

        return this.serialize
    }

    async resendInviteMember(resendInviteMember) {
        await this.team.resendInviteMember(resendInviteMember)

        return this.serialize
    }

    async removeInviteMember(inviteToken) {
        await this.team.removeInviteMember(inviteToken)

        return this.serialize
    }

    async loadMembersInvite() {
        await this.team.loadMembersInvite()

        return this.serialize
    }

    async addMyUserToTheTeam(token, newUserObj) {
        await this.team.addMyUserToTheTeam(token, newUserObj && {
            ...newUserObj,
            udid: this.udid,
        })
        const {data: {token: jwtToken}} = await accountsApi.getApiWithName('jwt').post({
            udid: this.udid,
        }, {
            withCredentials: true,
        })

        this._setAuthorizationToken(jwtToken)

        await this.loadUserAllInforamation()

        const {userData} = analyticsService

        await analyticsService.setCurrentUser({
            signupPlatform: 'web',
        })

        if (newUserObj) {
            await analyticsService.track('Signup', {
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                name: userData.name,
                phone: userData.phone,
                signupPlatform: 'web',
                flow: 'team invite',
            })
        }

        await analyticsService.track('Accepted Invite')

        return this.serialize
    }

    async changeMemberRole(member, role) {
        await this.team.changeMemberRole(member, role)

        return this.serialize
    }

    async removeMember(member) {
        await this.team.removeMember(member)

        return this.serialize
    }

    _setAuthorizationToken(jwt) {
        this.authToken.jwt = jwt
        this.serialize = this.authToken.serialize
        setAuthorizationToken()
        return this.authToken.jwt
    }

    resetConfirmPassword({new_password1, new_password2, uid, token: resetToken}) {
        return resetConfirmApi.post({
            new_password1,
            new_password2,
            uid,
            token: resetToken,
            udid: this.udid,
        }, {
            withCredentials: true,
        })
            .then(({data: {token}}) => {
                this._setAuthorizationToken(token)

                return this.serialize
            })
            .then(this.loadUserAllInforamation)
            .then(async () => {
                await analyticsService.setCurrentUser()

                return this.serialize
            })
    }

    saveLicenseNumber(uuid = null, licenseData) {
        return !uuid
            ? mlsLicenseApi.post({
                ...licenseData,
            }).then(async ({data}) => {
                if (this.mlsIds.length === 0) {
                    await analyticsService.setCurrentUser(
                        {
                            licenseNumber: licenseData.license_number,
                            licenseState: licenseData.state,
                        },
                        true
                    )
                }
                this.mlsLicenses = [...this.mlsLicenses, {...data}]
                return this.serialize
            })
            : mlsLicenseApi.put(uuid, {
                ...licenseData,
            }).then(({data}) => {
                this.mlsLicenses = this.mlsLicenses.map(val => val.uuid != data.uuid ? val : data)
                return this.serialize
            })
    }

    saveMlsId(uuid = null, {mls_provider, ...mlsIdData}) {
        if (typeof mls_provider == 'string') {
            mls_provider = {
                name: mls_provider,
                long_name: null,
            }
        }

        delete mls_provider.uuid

        return !uuid
            ? mlsidsApi.post({
                mls_provider,
                ...mlsIdData,
            }).then(async ({data}) => {
                if (this.mlsIds.length === 0) {
                    await analyticsService.setCurrentUser(
                        {
                            mlsId: mlsIdData.mls_id,
                            mlsVendor: mlsIdData.mls_provider,
                        },
                        true
                    )
                }
                this.mlsIds = [...this.mlsIds, {...data}]
                return this.serialize
            })
            : mlsidsApi.put(uuid, {
                mls_provider,
                ...mlsIdData,
            }).then(({data}) => {
                this.mlsIds = this.mlsIds.map(val => val.uuid != data.uuid ? val : data)
                return this.serialize
            })
    }

    deleteMlsLicenseNumber(uuid) {
        return mlsLicenseApi.delete(uuid).then(res => {
            this.mlsLicenses = this.mlsLicenses.filter(val => val.uuid != uuid)
            return this.serialize
        })
    }

    deleteMlsId(uuid) {
        return mlsidsApi.delete(uuid).then(res => {
            this.mlsIds = this.mlsIds.filter(val => val.uuid != uuid)
            return this.serialize
        })
    }

    async saveNmlsId(uuid, newNmlsIdData) {
        const {data: newNmlsId} = await (
            uuid
                ? nmlsIdsApi.patch(uuid, {nmls_id: newNmlsIdData.nmlsId})
                : nmlsIdsApi.post({nmls_id: newNmlsIdData.nmlsId})
        )

        if (uuid) {
            this.nmlsIds = this.nmlsIds.map(nmlsId => nmlsId.uuid == uuid
                ? newNmlsId
                : nmlsId
            )
        }
        else {
            this.nmlsIds.push(newNmlsId)
        }

        return this.serialize
    }

    async deleteNmlsId(uuid) {
        await nmlsIdsApi.delete(uuid)

        this.nmlsIds = this.nmlsIds.filter(nmlsId => nmlsId.uuid != uuid)

        return Promise.resolve(this.serialize)
    }

    generateShortBuyerPortalLink() {
        return this.sendUserAction('get_buyer_search_portal_link')
    }

    generatePartnerInviteLink() {
        return this.sendPartnerAction('create_partner_invite_link')
    }

    sendPartnerInviteLinkToAgents(emailList, message) {
        return partnersApi.getApiWithName('invites').post(emailList.map(inviteObj => ({
            ...inviteObj,
            message,
        })))
    }

    async pairWithPartner(partnerUUID, agentInvite) {
        return (await partnersApi.getApiWithName('pair-with-partner').post({
            partner: partnerUUID,
            agent_invite: agentInvite,
        })).data
    }

    static async checkIfPartnerCanInviteAgent(agentEmail, currentPartner, forAssignInviteFlow) {
        const [invitedAgent] = await UserService.searchPublicUserInfo(agentEmail)

        if (invitedAgent) {
            const isYourAgent = !!invitedAgent.pairedUsers.find(({uuid}) => currentPartner.uuid == uuid)

            if (invitedAgent.permissions.isPartner) {
                return Promise.reject(`Partner with ${agentEmail} is already a member.`)
            }
            if (isYourAgent) {
                return Promise.reject(
                    `You are already paired with this agent.${forAssignInviteFlow ? 'Please go back and assign a buyer\'s agent instead.' : ''}`
                )
            }
        }

        return true
    }

    static async searchPairedUsers({search, ...filters} = {}, config) {
        const {data} = (await mainUsersApi.getApiWithName('paired-users').get({
            search,
            partner_type: 'all',
            ...filters,
        }, config))
        return (data.results || data).map(userData => new UserService(userData))
    }

    async loadAllMyPairedUsers({filters = {}, ...config}) {
        this.pairedUsers = await UserService.searchPairedUsers(filters, config)

        return this.serialize
    }

    savePartnerPromotionLink(newPromotionLinkSlug) {
        return this.sendUserAction(
            'save_partner_promotion_link',
            {
                promotion_url_slug: newPromotionLinkSlug,
            }
        )
    }

    async sendUserAction(actionName, params) {
        this.serialize = (await userActionsApi.post({
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async sendPartnerAction(actionName, params) {
        this.serialize = (await partnerActionApi.post({
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async createFollowUsBossIntegrationObject(accountID) {
        this.followUsBossIntegration = await UserService.createIntegrationObject(
            accountID,
            FollowUsBossIntegration.INTEGRATION_NAME
        )

        return this.serialize
    }

    async loadFollowUsBossIntegrationObject(accountID) {
        try {
            this.followUsBossIntegration = await UserService.loadIntegrationObject(
                accountID,
                FollowUsBossIntegration.INTEGRATION_NAME
            )
        }
        catch (error) {
            if (error?.response?.status == 404) {
                this.followUsBossIntegration = null
            }
        }

        return this.serialize
    }

    static createIntegrationObject(integrationKey, integrationName) {
        return mainUsersApi.getApiWithName('integrations').post({
            key: integrationKey,
            name: integrationName,
        })
    }

    static async loadIntegrationObject(integrationKey, integrationName) {
        return (await mainUsersApi.getApiWithName('integrations').getByKey(`${integrationName}/${integrationKey}`)).data
    }

    static async searchUsers(
        {email, first_name, last_name, groups, search, uuid = [], ...filters},
        {cancelSource} = {}
    ) {
        const unicUserUUID = uuid.reduce((currentUuidList, nextUuid) => (
            currentUuidList.indexOf(nextUuid) == -1
                ? currentUuidList.concat(nextUuid)
                : currentUuidList
        ), [])

        return email || first_name || last_name || groups || search || unicUserUUID.length
            ? (
                await usersApi.get(
                    {
                        email, first_name, last_name, groups, search,
                        uuid: unicUserUUID,
                        ...filters,
                    },
                    {cancelSource}
                )
            ).data.map(user => new UserService(user))
            : []
    }

    static async getUserByUuid(uuid) {
        return new UserService(uuid ? (await usersApi.getByKey(uuid)).data : {})
    }

    static async getPublicUserInfoByUuid(uuid, config) {
        const {data: userData} = await mainUsersApi.getApiWithName('public-info').getByKey(uuid, undefined, config)

        return new UserService(mixinDeep(
            userData,
            {
                profile: {
                    office_address_json: userData.office,
                    details_json: {
                        phone: userData.phone,
                    },
                    images_json: {
                        avatar: userData.agent_photo,
                    },
                },
            }
        ))
    }

    static async searchPublicUserInfo(search, {uuid = [], ...filters} = {}) {
        const unicUserUUID = uuid.reduce((currentUuidList, nextUuid) => (
            currentUuidList.indexOf(nextUuid) == -1
                ? currentUuidList.concat(nextUuid)
                : currentUuidList
        ), [])

        const searchedUsers = (
            await mainUsersApi.getApiWithName('public-info').get(
                {
                    search,
                    uuid: unicUserUUID,
                    ...filters,
                },
            )
        ).data

        return searchedUsers.map(({phone, agent_photo, team_name, is_verified, office, pairings, ...user}) => (
            new UserService(mixinDeep(
                user,
                {
                    _isHaveVerifiedMlsId: is_verified,
                    team: {
                        name: team_name,
                    },
                    profile: {
                        details_json: {
                            phone: phone,
                        },
                        images_json: {
                            avatar: agent_photo,
                        },
                        office_address_json: office,
                    },
                    pairedUsers: pairings?.map(({partner}) => new UserService(partner)) || [],
                },
            ))
        ))
    }

    static resetPassword(email) {
        return resetApi.post({email})
    }

    static generateMagicLoginLink(email) {
        analyticsService.track('Requested Magic Login Link', {
            platform: 'web',
        })

        return accountsApi.getApiWithName('magic-login').post({email})
    }

    static sendReportToAgent(agent, report) {
        const mlsLicenses = agent.mlsLicenses || agent.license_number

        return mainUsersApi.getApiWithName('report-agent').post({
            name: agent.fullName,
            description: report,
            phone: agent.phone,
            email: agent.email,
            license_state: mlsLicenses ? mlsLicenses[0]?.state : undefined,
            license_number: mlsLicenses ? mlsLicenses[0]?.license_number : undefined,
        })
    }

    static sendMessageToUser({
        yourFullName,
        yourMessage,
        yourEmail,
        yourPhone,
        userUUID,
        buyerUUID,
        propertyUUID,
    }) {
        return mainUsersApi.getApiWithName('common-action').post({
            action: 'contact_user',
            full_name: yourFullName,
            message: yourMessage,
            email: yourEmail,
            phone: yourPhone,
            user: userUUID,
            buyerUUID,
            propertyUUID,
        })
    }
}

export default new UserService

export {UserService}
