import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import AppVersion from 'common_components/app_version'
import Link from 'common_components/link'

import styles from './styles.styl'

function Footer({className}) {
    return (
        <p className={cn(styles.footer, 'text-center proxima text-sm mb-sm', className)}>
            © {new Date().getFullYear()} Raven {
                <Link
                    path="https://raven.re/terms"
                    text="Terms of Use"
                    target="_blank"
                    className="proxima-bold"
                />
            }  |  {
                <Link
                    path="https://raven.re/privacy"
                    text="Privacy Policy"
                    target="_blank"
                    className="proxima-bold"
                />
            }
            {' '}
            <AppVersion />
        </p>
    )
}

Footer.propTypes = {
    className: PropTypes.string,
}

export default Footer
