import React, {Component} from 'react'
import cn from 'classnames'
import {autobind} from 'core-decorators'
import PropTypes from 'prop-types'

import supportService from 'common_services/support'

import FormFactory from 'common_components/form'
import Button from 'common_components/button'

import MlsVendor from 'adminProj/components/search'

import styles from '../styles.styl'

class MlsIdVerification extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        query: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func,
        searchMlsVendors: PropTypes.func,
        mlsVendors: PropTypes.object,
        saveMlsId: PropTypes.func,
        submitting: PropTypes.bool,
        hideSkipButton: PropTypes.bool,
    }

    static mlsProviderValidator = [
        FormFactory.validators.required(),
    ]

    static mlsIdValidator = [
        FormFactory.validators.required(),
        FormFactory.validators.containsOnlyCharacters(
            'a-zA-Z0-9-_.',
            'Only contain alphanumeric characters in addition to -, _ and . allowed'
        ),
    ]

    @autobind
    submitLicenseData(values) {
        return this.props.saveMlsId(null, values)
    }

    normalizeSearchData(searchDataArray) {
        return {
            ...searchDataArray,
            list: searchDataArray.list.map(val => ({
                uuid: val.name,
                ...val,
            })),
        }
    }

    render() {
        const {
            submitting,
            searchMlsVendors,
            mlsVendors,
            history,
            hideSkipButton,
        } = this.props

        return (
            <form onSubmit={this.props.handleSubmit(this.submitLicenseData)}>
                <div className="proxima-bold text-30 text-dark-grey-blue text-center mb-md">
                    Sync your MLS Listings
                </div>
                <div className="proxima text-lg text-dark-grey-blue text-center mb-xlg">
                    Enter your MLS ID below to automatically sync your listings to Raven.
                </div>
                <p
                    className="text-dark-grey proxima-bold text-xs mb-xs"
                >
                    MLS NAME
                </p>
                <MlsVendor
                    name="mls_provider"
                    inputName="mls_provider.long_name"
                    searchInputPlaceholder="enter mls name"
                    searchOnType={(pageNumber, searchText) => searchMlsVendors(searchText)}
                    searchList={this.normalizeSearchData(mlsVendors)}
                    closeAddModal={this.closeAddModal}
                    className="text-lg mb-md"
                    isForm
                    validate={MlsIdVerification.mlsProviderValidator}
                    acceptValueOtherThanDropdown
                    mainDataField="long_name"
                    secondaryDataField="name"
                    createSelectedValue={value => value}
                />
                <FormFactory.Field
                    name="mls_id"
                    type="text"
                    placeholder="enter your mls id"
                    descriptionText="MLS ID"
                    className="text-lg mt-md"
                    descriptionTextClassName="text-dark-grey proxima-bold text-xs"
                    validate={MlsIdVerification.mlsIdValidator}
                    maxLength={32}
                    tooltip="This is the username you use to login to your MLS."
                />
                <FormFactory.Button.full
                    type="submit"
                    title="CONTINUE"
                    className="mt-md-lg"
                    disabled={submitting}
                />
                <Button.text
                    onClick={() => history.push(this.props.query.search.next || '/properties')}
                    title="SKIP"
                    className={cn(
                        styles['full-width'],
                        'text-md text-turquoise-blue proxima-bold mt-md-lg',
                        {
                            'hide': hideSkipButton,
                        }
                    )}
                />
                <p className="proxima text-md text-center text-bluey-grey mt-md-lg">
                    Are you stuck and need help finding your MLS information?
                </p>
                <Button.text
                    onClick={() => supportService.showSupportModal()}
                    title="Ask us for help"
                    className={
                        cn(
                            styles['full-width'],
                            'text-md text-turquoise-blue proxima-bold mb-md-lg'
                        )
                    }
                    isInvert
                />
            </form>
        )
    }
}

export default FormFactory.registerForm(
    MlsIdVerification,
    {
        form: 'MlsIdVerification',
    },
    ({user}) => ({
        user,
    }),
)
