import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {getCurrentRoute} from 'common_utils/routes_utils.js'

import Link from 'common_components/link'
import Tooltip from 'common_components/tooltip'

import styles from './styles.styl'

function SubNavBarLink({className, path, text, count, tooltip, ...props}) {
    const isActive = isCurrentPage(path)
    const countComponent = (
        <span
            className={cn(
                styles.count,
                'my-xs mx-xs',
                {
                    hide: !count,
                }
            )}
        >
            {count > 9 ? '9+' : count}
        </span>
    )

    return (
        <Link
            className={cn(
                'text-xs letter-lg flex align-center',
                {
                    'text-bluey-grey-important': !isActive,
                    'text-turquoise-blue-important': isActive,
                    [styles['link-border']]: isActive,
                    [styles.active]: isActive,
                },
                styles['sub-nav-bar-link'],
                className
            )}
            path={path}
            text={(
                <Fragment>
                    {text}
                    {
                        tooltip
                            ? (
                                <Tooltip
                                    text={tooltip}
                                    tooltipIcon={countComponent}
                                />
                            )
                            : countComponent
                    }
                </Fragment>
            )}
            {...props}
        />
    )
}

SubNavBarLink.propTypes = {
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,

    tooltip: PropTypes.string,
    className: PropTypes.string,
    count: PropTypes.number,
}

export default SubNavBarLink

function isCurrentPage(path) {
    return getCurrentRoute().path == path
}
