import React from 'react'
import {StripeProvider, Elements, injectStripe} from 'react-stripe-elements'

import STRIPE_PK from 'STRIPE_PK'

export default function StripeProviderWrapper(StripeForm) {
    StripeForm = injectStripe(StripeForm)

    return function StripeProviderForm(props) {
        return (
            <StripeProvider apiKey={STRIPE_PK}>
                <Elements>
                    <StripeForm {...props} />
                </Elements>
            </StripeProvider>
        )
    }
}
