import {connect} from 'react-redux'
import {reduxForm, change} from 'redux-form'

import * as validators from './validators'

import Button from 'common_components/button'

import Field from './components/field'
import SelectRange from './components/range'
import Select from './components/select'
import Textarea from './components/textarea'
import Label from './components/label'
import RequiredLabel from './components/required_label'
import Switch from './components/switch'
import Radio from './components/radio'
import Checkbox from './components/checkbox'
import RadioList from './components/radio_list'
import Phone from './components/phone'
import Slider from './components/slider'
import DatePicker from './components/date'
import NumberRange from './components/number_range'

import ErrorMessage from './components/error_message'

export default class FormFactory {
    static Field = Field
    static SelectRange = SelectRange
    static Select = Select
    static Textarea = Textarea
    static RequiredLabel = RequiredLabel
    static Label = Label
    static ErrorMessage = ErrorMessage
    static Button = Button
    static Switch = Switch
    static Radio = Radio
    static Checkbox = Checkbox
    static RadioList = RadioList
    static Phone = Phone
    static Slider = Slider
    static DatePicker = DatePicker
    static NumberRange = NumberRange

    static validators = validators

    static registerForm(formComponent, reduxFormParams, mapStateToProps, mapDispatchToProps, mergeProps, options) {
        const formName = reduxFormParams.form
        const currentReduxForm = reduxForm(reduxFormParams)(formComponent)
        if (mapStateToProps || mapDispatchToProps || mergeProps || options) {
            return connect(
                (state, ownProps) => ({
                    formData: state.form[ownProps.form || formName],
                    ...mapStateToProps(state, ownProps),
                }),
                mapDispatchToProps,
                mergeProps,
                {
                    forwardRef: true,
                    ...options,
                }
            )(currentReduxForm)
        }
        return currentReduxForm
    }

    static handleSubmitWithError(onSubmit, onSubmitSuccess, onSubmitError) {
        return (...args) => onSubmit(...args)
            .then(res => onSubmitSuccess ? onSubmitSuccess(res) : res)
            .catch(err => onSubmitError ? onSubmitError(err) : Promise.reject(err))
    }

    getFieldsError(formData) {
        const res = {}

        if (formData) {
            for (const fieldName in formData.syncErrors) {
                const fieldError = formData.syncErrors[fieldName]
                const fieldInfo = formData.fields && formData.fields[fieldName]
                if (fieldInfo) {
                    const errorObj = fieldError.params && fieldError.params.afterBlur
                        ? fieldInfo.touched && fieldError
                        : fieldInfo.visited && fieldError
                    if (errorObj) {
                        res[fieldName] = errorObj
                    }
                }
            }
        }

        return res
    }

    changeFieldValue(formProps, fieldName, value) {
        formProps.dispatch(change(formProps.form, fieldName, value))
    }
}
