import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import cn from 'classnames'

import Button from 'common_components/button'

import Modal from '../base_modal'

export default class AlertModal extends Component {
    static propTypes = {
        mainHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]).isRequired,
        subHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]).isRequired,
        okText: PropTypes.string,
        okFunc: PropTypes.func,
        inProgressText: PropTypes.string,
    }

    static defaultProps = {
        okText: 'OK',
    }

    state = {
        inProgress: false,
    }

    render() {
        const {
            mainHeader,
            subHeader,
            okText,
            okFunc, // eslint-disable-line no-unused-vars
            inProgressText,
            ...props
        } = this.props
        const {inProgress} = this.state

        return (
            <Modal.center
                content={
                    <Fragment>
                        <div
                            className={cn(
                                'capitalize proxima-bold text-xl letter-xl',
                                {
                                    'mb-md': !subHeader,
                                }
                            )}
                        >
                            {mainHeader}
                        </div>
                        {
                            !!subHeader && (
                                <div className="proxima text-lg my-md">
                                    {subHeader}
                                </div>
                            )
                        }
                        <div className="flex flex-column just-center">
                            <Button.full
                                title={
                                    inProgress
                                        ? inProgressText || `${okText} ...`
                                        : okText
                                }
                                onClick={this.okFunc}
                                disabled={inProgress}
                                isWithoutBold
                            />
                        </div>
                    </Fragment>
                }
                size={Modal.size.xs}
                hideClose
                {...props}
            />
        )
    }

    @autobind
    async okFunc() {
        let resOkFunc = this.props.okFunc()

        if (resOkFunc instanceof Promise) {
            this.setState({
                inProgress: true,
            })

            resOkFunc = await resOkFunc

            this.setState({
                inProgress: false,
            })
        }

        return resOkFunc
    }
}
