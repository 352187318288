import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import cn from 'classnames'

import Label from '../label'

import styles from './styles'

export default function CustomRadio({...props}) {
    return (
        <Field
            component={radio}
            {...props}
            type="radio"
        />
    )
}

function radio({
    input,
    ...props
}) {
    return (
        <Radio
            {...{
                ...input,
                ...props,
            }}
        />
    )
}

radio.propTypes = {
    input: PropTypes.object,
}

export function Radio({
    className,
    lableClassName,
    lableText,
    ...props
}) {
    return (
        <Label
            className={cn(styles.radio, lableClassName)}
        >
            <input
                {...props}
            />
            <span
                className={cn(
                    'proxima letter-xs text-13 pointer',
                    styles['radio-label'],
                    className
                )}
            >
                {lableText}
            </span>
        </Label>
    )
}

Radio.propTypes = {
    lableText: PropTypes.string.isRequired,

    lableClassName: PropTypes.string,
    className: PropTypes.string,
}
