import modelSerialize from 'common_utils/model_serialize'

import {UserService} from '../index'

@modelSerialize([
    'role',
    {
        serializeField: 'user',
        originField: 'user',
        type: UserService,
    },
    'is_active',
])
class MemberService {
    role = null
    user = null
    is_active = false

    constructor(data) {
        if (data) {
            this.serialize = data
        }
    }
}

export default MemberService
