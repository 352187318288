import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Field} from 'redux-form'

import Tooltip from 'common_components/tooltip'
import Button from 'common_components/button'

import ErrorMessage from '../error_message'

import Dropdown, {closeSomeDropdown} from 'adminProj/components/dropdown'

import styles from './styles.styl'

export default function CustomField(props) {
    return <Field component={Select} {...props} />
}

export function Select({
    multiselect,
    input,
    descriptionText,
    descriptionComponent,
    options,
    className,
    externalTextError,
    disabled,
    disabledEmptyValue,
    component: SelectComponent,
    meta: {error, visited, touched, active, ...meta} = {},
    onChange,
    ignoreReduxOnChange,
    forceOnChange,
    tooltip,
    tooltipProps,
    useCustomSelect,
    custopSelectProps: {customTrigger, ...custopSelectProps} = {},
    customSelectWithDoneButton,
    isInvalid,
    ...props
}) {
    const isError = !!getError(error)
    const format = useCustomSelect
        ? value => value
        : value => Array.isArray(value) ? value[0] : (value ? value : '')
    const normalize = useCustomSelect
        ? value => value
        : value => multiselect ? [value] : value
    const shownValue = Array.isArray(input.value)
        ? input.value
            .map(currentValueKey => options[currentValueKey])
        : options[input.value]
    const shownValueString = Array.isArray(shownValue)
        ? shownValue.join(', ')
        : shownValue

    const [customSelectId] = useState(`custom_select_${input.name}_${Math.trunc(Math.random() * 1000)}`)

    return (
        <div
            className={cn(
                styles['input-block'],
                {
                    [styles['input-disabled']]: disabled,
                },
                className
            )}
            {...props}
        >
            <div className="flex align-center mb-xs">
                {
                    descriptionComponent
                        || (
                            !!descriptionText && (
                                <p className={cn('text-xs proxima-bold uppercase m-zero', {'text-error': isError})}>
                                    {descriptionText}
                                </p>
                            )
                        )
                }
                {
                    !!tooltip && (
                        <Tooltip
                            className="ml-xs"
                            text={tooltip}
                            {...tooltipProps}
                        />
                    )
                }
            </div>
            {
                useCustomSelect
                    ? (
                        <Dropdown
                            id={customSelectId}
                            className={styles['custom-select']}
                            trigger={
                                customTrigger
                                    ? customTrigger({shownValue, shownValueString})
                                    : (
                                        <p
                                            className={cn(
                                                styles['trigger'],
                                                'proxima-medium text-md letter-sm m-zero',
                                                {
                                                    'text-dark-grey-blue': shownValueString && !(isError || isInvalid),
                                                    'text-bluey-grey': !shownValueString && !(isError || isInvalid),
                                                    'text-error': isError || isInvalid,
                                                }
                                            )}
                                        >
                                            {shownValueString || 'Select'}
                                        </p>
                                    )
                            }
                            content={
                                !!options && (
                                    <Fragment>
                                        {Object.keys(options).map(key => (
                                            <Button.text
                                                key={key}
                                                className={cn(
                                                    styles['custom-option'],
                                                    {
                                                        [styles['selected-option']]: Array.isArray(input.value)
                                                            ? input.value.indexOf(key) != -1
                                                            : input.value == key,
                                                        [styles['multiselect-option']]: multiselect,
                                                        'my-sm': multiselect,
                                                        'my-xs': !multiselect,
                                                    },
                                                    'proxima text-sm text-md-on-md-screen text-left my-xs',
                                                )}
                                                onClick={() => {
                                                    if (ignoreReduxOnChange && forceOnChange) {
                                                        forceOnChange(key)
                                                    }
                                                    else if (input.onChange) {
                                                        if (multiselect) {
                                                            let newValue = []

                                                            if (input.value.indexOf(key) == -1) {
                                                                newValue = [...input.value, key]
                                                            }
                                                            else {
                                                                newValue = [...input.value]
                                                                newValue.splice(input.value.indexOf(key), 1)
                                                            }

                                                            input.onChange(newValue)
                                                        }
                                                        else {
                                                            input.onChange(key)
                                                        }
                                                    }
                                                }}
                                            >
                                                {options[key]}
                                            </Button.text>
                                        ))}
                                        {
                                            customSelectWithDoneButton && (
                                                <div
                                                    className={cn(
                                                        styles['apply-button-in-dropdown'],
                                                        'flex flex-row-reverse py-sm px-sm-md'
                                                    )}
                                                >
                                                    <Button.custom
                                                        className={cn(styles['done-button'], 'letter-sm text-sm')}
                                                        title="DONE"
                                                        onClick={() => closeSomeDropdown(customSelectId)}
                                                    />
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                            contentClassName={styles['content']}
                            isNotCloseAfterAction={multiselect}
                            {...custopSelectProps}
                        />
                    )
                    : (
                        <SelectComponent
                            component="select"
                            format={format}
                            normalize={normalize}
                            className={cn(
                                'proxima-medium text-dark-grey text-md letter-sm',
                                {
                                    [styles['select-is-empty']]: input.value == '',
                                    'text-error': isError,
                                }
                            )}
                            disabled={disabled}
                            {...input}
                            {...props}
                            onChange={
                                ignoreReduxOnChange
                                    ? forceOnChange
                                    : input.onChange
                            }
                        >
                            <option disabled={disabled || disabledEmptyValue} value="">Select</option>
                            {
                                options && (
                                    Object.keys(options).map(key => (
                                        <option
                                            key={key}
                                            value={key}
                                            className="proxima"
                                        >
                                            {options[key]}
                                        </option>
                                    ))
                                )
                            }
                        </SelectComponent>
                    )
            }
            {
                externalTextError
                    ? (
                        <ErrorMessage>
                            {externalTextError}
                        </ErrorMessage>
                    )
                    : (
                        isError && error && error.text && (
                            <ErrorMessage text={error.text} />
                        )
                    )
            }
        </div>
    )

    function getError() {
        if (error) {
            if (error.params && error.params.afterBlur) {
                return touched && error.text
            }
            return visited && error.text
        }
        return null
    }
}

Select.propTypes = {
    multiselect: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.object.isRequired,
    descriptionText: PropTypes.string,
    descriptionComponent: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledEmptyValue: PropTypes.bool,
    externalTextError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    onChange: PropTypes.func,
    forceOnChange: PropTypes.func,
    ignoreReduxOnChange: PropTypes.bool,
    component: PropTypes.func,
    tooltip: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
        PropTypes.string,
    ]),
    tooltipProps: PropTypes.object,
    useCustomSelect: PropTypes.bool,
    custopSelectProps: PropTypes.shape({
        customTrigger: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
            PropTypes.string,
        ]),
    }),
    customSelectWithDoneButton: PropTypes.bool,
    isInvalid: PropTypes.bool,
}

Select.defaultProps = {
    component: Field,
    tooltipProps: {},
}
