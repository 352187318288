import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import IS_CLIENT_SIDE from 'IS_CLIENT_SIDE'

import Loader from 'common_components/loader'
import Modal from 'common_components/modal'
import Link from 'common_components/link'

import analyticsService from 'common_services/analytics'
import QueryMiddleware from 'common_services/query_middleware'
import {globalInterceptors} from 'common_services/http'

import usersActions from 'common_redux/actions/user'

let isIgnoreZeroRequestError = false

class Page extends Component {
    constructor(props) {
        super(props)

        this.query = new QueryMiddleware(props.history)
        this.state = {
            module: null,
            redirectUrl: null,
            userIsAuthenticated: props.user.isAuthenticated,
            isLoadingRequiredData: false,
            showBadInternetAlert: false,
            query: {
                ...this.query,
                search: this.query.search,
            },
        }
        props.history.listen(() => {
            this.updateQueryObject()
        })

        globalInterceptors.addResponseInterceptor(
            response => response,
            error => {
                if (!error.response && error.request.status == 0 && !isIgnoreZeroRequestError) {
                    this.setState({showBadInternetAlert: true})
                }

                return Promise.reject(error)
            }
        )
    }

    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        pageProps: PropTypes.object,
        authenticate: PropTypes.func.isRequired,
        redirects: PropTypes.array,
        Loader: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
        loadingRequiredData: PropTypes.func,
        hideProgressBar: PropTypes.bool,
        loadComponent: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
        component: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
        PageWrapper: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
        pageTitle: PropTypes.string.isRequired,
        AlertModalComponent: PropTypes.func,
    }

    static defaultProps = {
        redirects: [],
        AlertModalComponent: Modal.alert,
    }

    initPage() {
        if (IS_CLIENT_SIDE) {
            if (!this.state.userIsAuthenticated) {
                return this.props.authenticate().then(() => {
                    this.setState({
                        userIsAuthenticated: true,
                    })
                })
            }
        }
        else {
            this.setState({
                userIsAuthenticated: true,
            })
        }

        return Promise.resolve()
    }

    loadPage() {
        const {pageProps: {redirectBeforeLoadingRequiredData}} = this.props

        if (this.state.userIsAuthenticated) {
            const redirectUrl = this.getRedirectUrl(this.props)
            if (!this.state.redirectUrl && redirectUrl && redirectBeforeLoadingRequiredData) {
                this.setState({redirectUrl})
            }
            else if (
                !(
                    redirectBeforeLoadingRequiredData ? (this.state.redirectUrl || redirectUrl) : false
                )
                && !this.state.module
            ) {
                this.showCurrentComponent(this.props)
            }
        }
    }

    setPageTitle(pageTitle) {
        document.title = pageTitle
    }

    getRedirectUrl(props) {
        let redirectUrl
        this.props.redirects.every(redirect => {
            if (redirect) {
                redirectUrl = redirect(props.history, props.match, props.user)
            }
            return !redirectUrl
        })
        return redirectUrl
    }

    showCurrentComponent({component, loadComponent, loadingRequiredData}) {
        const {pageProps: {redirectBeforeLoadingRequiredData}} = this.props

        if (!this.state.isLoadingRequiredData) {
            this.setState({
                isLoadingRequiredData: true,
            })

            const promise = loadingRequiredData ? loadingRequiredData() : Promise.resolve()

            promise
                .then(() => {
                    if (!redirectBeforeLoadingRequiredData) {
                        const redirectUrl = this.getRedirectUrl(this.props)
                        if (!this.state.redirectUrl && redirectUrl) {
                            this.setState({redirectUrl})

                            return redirectUrl
                        }
                    }
                })
                .then(redirectUrl => {
                    if (!redirectUrl) {
                        if (component) {
                            this.setState({
                                module: this.getComponent(component),
                                isLoadingRequiredData: false,
                            })
                            if (this.props.pageProps?.analytics?.openPage) {
                                analyticsService.track(this.props.pageProps.analytics.openPage.eventName)
                            }
                        }
                        else if (loadComponent) {
                            this.load(loadComponent)
                        }
                    }
                })
        }
    }

    load(loadComponent) {
        loadComponent(module => {
            this.setState({
                module: this.getComponent(module),
                isLoadingRequiredData: false,
            })
            if (this.props.pageProps?.analytics?.openPage) {
                analyticsService.track(this.props.pageProps.analytics.openPage.eventName)
            }
        })
    }

    getComponent(componentObj) {
        return componentObj.default ? componentObj.default : componentObj
    }

    get Loader() {
        return this.props.Loader
            ? (
                typeof this.props.Loader == 'function'
                    ? <this.props.Loader {...this.props} />
                    : this.props.Loader
            )
            : <Loader />
    }

    get PageComponent() {
        const pageComponentProps = {
            ...this.props,
        }

        delete pageComponentProps.user
        Object.keys(usersActions)
            .forEach(key => delete pageComponentProps[key])

        return this.state.module
            ? <this.state.module {...pageComponentProps} query={this.query} />
            : this.Loader
    }

    render() {
        if (this.state.redirectUrl && this.state.redirectUrl.indexOf('http') != -1) {
            window.location.href = this.state.redirectUrl
        }

        const {PageWrapper, AlertModalComponent} = this.props
        const {showBadInternetAlert} = this.state
        const pageComponent = (
            this.state.userIsAuthenticated
                ? (
                    this.state.redirectUrl
                        ? (
                            this.state.redirectUrl.indexOf('http') != -1
                                ? this.Loader
                                : <Redirect to={this.state.redirectUrl} />
                        )
                        : this.PageComponent
                ) : this.Loader
        )

        return (
            <Fragment>
                {
                    PageWrapper
                        ? (
                            <PageWrapper
                                pageComponent={pageComponent}
                                isLoading={!this.state.userIsAuthenticated}
                                query={this.state.query}
                                history={this.props.history}
                                match={this.props.match}
                                pageProps={this.props.pageProps}
                            />
                        )
                        : pageComponent
                }
                <AlertModalComponent
                    show={showBadInternetAlert}
                    mainHeader={
                        <p className="text-center m-zero">
                            Unable to complete request
                        </p>
                    }
                    subHeader={
                        <p className="text-center m-zero">
                            Please contact
                            {' '}
                            <Link
                                path="mailto:support@raven.re"
                                text="support@raven.re"
                            />.
                        </p>
                    }
                    okFunc={() => this.setState({showBadInternetAlert: false})}
                    onClose={() => this.setState({showBadInternetAlert: false})}
                />
            </Fragment>
        )
    }

    async componentDidMount() {
        this.setPageTitle(this.props.pageTitle)
        await this.initPage()
        this.loadPage()
    }

    componentDidUpdate() {
        this.setPageTitle(this.props.pageTitle)
        this.loadPage()
    }

    updateQueryObject() {
        this.setState({
            query: {
                ...this.query,
                search: this.query.search,
            },
        })
    }
}

export default connect(
    state => ({
        user: state.user,
    }),
    dispatch => bindActionCreators(usersActions, dispatch)
)(Page)

export function enableIgnoreZeroRequestError() {
    isIgnoreZeroRequestError = false
}

export function disableIgnoreZeroRequestError() {
    isIgnoreZeroRequestError = true
}
