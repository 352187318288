/* global module */

import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

export function configureStore(rootReducer) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer()

    const store = createStore(
        rootReducer,
        Sentry.getCurrentHub().getClient()
            ? composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
            : composeWithDevTools(applyMiddleware(thunk))
    )

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').default
            store.replaceReducer(nextRootReducer)
        })
    }

    return store
}
