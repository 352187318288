import React from 'react'
import PropTypes from 'prop-types'
import sha256 from 'sha256'
import cn from 'classnames'

import Radio from '../radio'

import styles from './styles.styl'

export default function RadioList({
    name,
    className,
    radioList,
    labelText,
    descriptionText,
    containerClassName,
    ...props
}) {
    return (
        <div className={containerClassName}>
            {
                labelText && (
                    <p className="proxima-medium text-dark-grey-blue text-md letter-sm m-zero">
                        {labelText}
                    </p>
                )
            }
            <div className={cn(styles['radio-list'], 'flex align-center space-between my-xs')}>
                {
                    radioList.map(radioEl => (
                        <Radio
                            key={sha256(JSON.stringify(radioEl))}
                            name={name}
                            {...radioEl}
                            {...props}
                            className={cn(
                                className,
                                styles['radio-el'],
                                'py-xs',
                                radioEl.className
                            )}
                            lableClassName="flex-grow text-center"
                        />
                    ))
                }
            </div>
            {
                descriptionText && (
                    <p className="proxima text-dark-grey-blue text-sm m-zero">
                        {descriptionText}
                    </p>
                )
            }
        </div>
    )
}

RadioList.propTypes = {
    name: PropTypes.string.isRequired,
    radioList: PropTypes.array.isRequired,

    containerClassName: PropTypes.string,
    labelText: PropTypes.string,
    descriptionText: PropTypes.string,
    className: PropTypes.string,
}
