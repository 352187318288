import userService, {UserService} from 'common_services/user'

import createAction from './create_action'

import actionTypes from './action_types'

export default {
    login(email, password) {
        return createAction({
            actionTypeSpace: actionTypes.users.login,
            action: () => userService.login(email, password),
        })
    },

    register(userData) {
        return createAction({
            actionTypeSpace: actionTypes.users.register,
            action: () => userService.register(userData),
        })
    },

    registerMlsAgent(inviteToken, mlsAgentData) {
        return createAction({
            actionTypeSpace: actionTypes.users.registerMlsAgent,
            action: () => userService.registerMlsAgent(inviteToken, mlsAgentData),
        })
    },

    authenticate() {
        return createAction({
            actionTypeSpace: actionTypes.users.authenticate,
            action: () => userService.authenticate(),
        })
    },

    logout() {
        return createAction({
            actionTypeSpace: actionTypes.users.logout,
            action: () => userService.logout(),
        })
    },

    loadMyProfile() {
        return createAction({
            actionTypeSpace: actionTypes.users.loadMyProfile,
            action: () => userService.loadMyProfile(),
        })
    },

    saveMyProfile(changes, forceUpdateInstance) {
        return createAction({
            actionTypeSpace: actionTypes.users.saveMyProfile,
            action: () => userService.saveMyProfile(changes, forceUpdateInstance),
        })
    },

    changePassword(currentPassword, newPassword, confirmNewPassword) {
        return createAction({
            actionTypeSpace: actionTypes.users.changePassword,
            action: () => userService.changePassword(currentPassword, newPassword, confirmNewPassword),
        })
    },

    resetPassword(email) {
        return createAction({
            actionTypeSpace: actionTypes.users.resetPassword,
            action: () => UserService.resetPassword(email),
        })
    },

    resetConfirmPassword({new_password1, new_password2, uid, token}) {
        return createAction({
            actionTypeSpace: actionTypes.users.resetConfirmPassword,
            action: () => userService.resetConfirmPassword({new_password1, new_password2, uid, token}),
        })
    },

    generateMagicLoginLink(email) {
        return createAction({
            actionTypeSpace: actionTypes.users.generateMagicLoginLink,
            action: () => UserService.generateMagicLoginLink(email),
        })
    },

    confirmMagicLogin(magicToken, userId) {
        return createAction({
            actionTypeSpace: actionTypes.users.confirmMagicLogin,
            action: () => userService.confirmMagicLogin(magicToken, userId),
        })
    },

    createTeam(teamName, inviteMembers) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.createTeam,
            action: () => userService.createTeam(teamName, inviteMembers),
        })
    },

    loadMyTeam() {
        return createAction({
            actionTypeSpace: actionTypes.users.team.loadMyTeam,
            action: () => userService.loadMyTeam(),
        })
    },

    updateTeam(data) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.loadMyTeam,
            action: () => userService.updateTeam(data),
        })
    },

    inviteMembers(inviteMembers) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.inviteMembers,
            action: () => userService.inviteMembers(inviteMembers),
        })
    },

    addMembers(addMembers) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.addMembers,
            action: () => userService.addMembers(addMembers),
        })
    },

    resendInviteMember(resendInviteMember) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.resendInviteMember,
            action: () => userService.resendInviteMember(resendInviteMember),
        })
    },

    removeInviteMember(inviteToken) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.removeInviteMember,
            action: () => userService.removeInviteMember(inviteToken),
        })
    },

    loadMembersInvite() {
        return createAction({
            actionTypeSpace: actionTypes.users.team.loadMembersInvite,
            action: () => userService.loadMembersInvite(),
        })
    },

    changeMemberRole(member, role) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.inviteMembers,
            action: () => userService.changeMemberRole(member, role),
        })
    },

    removeMember(member) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.inviteMembers,
            action: () => userService.removeMember(member),
        })
    },

    addMyUserToTheTeam(token, newUserObj) {
        return createAction({
            actionTypeSpace: actionTypes.users.team.addMyUserToTheTeam,
            action: () => userService.addMyUserToTheTeam(token, newUserObj),
        })
    },

    saveLicenseNumber(uuid = null, data) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.saveLicenseNumber,
            action: () => userService.saveLicenseNumber(uuid, data),
        })
    },

    saveMlsId(uuid = null, data) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.saveMlsId,
            action: () => userService.saveMlsId(uuid, data),
        })
    },

    deleteMlsLicenseNumber(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.deleteMlsLicenseNumber,
            action: () => userService.deleteMlsLicenseNumber(uuid),
        })
    },

    deleteMlsId(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.deleteMlsId,
            action: () => userService.deleteMlsId(uuid),
        })
    },

    saveNmlsId(uuid, newNmlsIdData) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.saveNmlsId,
            action: () => userService.saveNmlsId(uuid, newNmlsIdData),
        })
    },

    deleteNmlsId(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.users.verification.deleteNmlsId,
            action: () => userService.deleteNmlsId(uuid),
        })
    },

    loginAsAnotherUser(email) {
        return createAction({
            actionTypeSpace: actionTypes.users.loginAsAnotherUser,
            action: () => userService.loginAsAnotherUser(email),
        })
    },

    loadInviteTokenInfo(inviteToken) {
        return createAction({
            actionTypeSpace: actionTypes.users.loadInviteTokenInfo,
            action: () => userService.loadInviteTokenInfo(inviteToken),
        })
    },

    generateShortBuyerPortalLink() {
        return createAction({
            actionTypeSpace: actionTypes.users.generateShortBuyerPortalLink,
            action: () => userService.generateShortBuyerPortalLink(),
        })
    },

    pairWithPartner(partnerUUID, agentInvite) {
        return createAction({
            actionTypeSpace: actionTypes.users.pairWithPartner,
            action: () => userService.pairWithPartner(partnerUUID, agentInvite),
        })
    },

    loadAllMyPairedUsers(config) {
        return createAction({
            actionTypeSpace: actionTypes.users.loadAllMyPairedUsers,
            action: () => userService.loadAllMyPairedUsers(config),
        })
    },

    generatePartnerInviteLink() {
        return createAction({
            actionTypeSpace: actionTypes.users.generatePartnerInviteLink,
            action: () => userService.generatePartnerInviteLink(),
        })
    },

    savePartnerPromotionLink(newPromotionLinkSlug) {
        return createAction({
            actionTypeSpace: actionTypes.users.savePartnerPromotionLink,
            action: () => userService.savePartnerPromotionLink(newPromotionLinkSlug),
        })
    },

    sendPartnerInviteLinkToAgents(emailList, massage) {
        return createAction({
            actionTypeSpace: actionTypes.users.sendPartnerInviteLinkToAgents,
            action: () => userService.sendPartnerInviteLinkToAgents(emailList, massage),
        })
    },

    createFollowUsBossIntegrationObject(accountID) {
        return createAction({
            actionTypeSpace: actionTypes.users.followUsBossIntegration.createFollowUsBossIntegrationObject,
            action: () => userService.createFollowUsBossIntegrationObject(accountID),
        })
    },

    loadFollowUsBossIntegrationObject(accountID) {
        return createAction({
            actionTypeSpace: actionTypes.users.followUsBossIntegration.loadFollowUsBossIntegrationObject,
            action: () => userService.loadFollowUsBossIntegrationObject(accountID),
        })
    },

    loadNotifications() {
        return createAction({
            actionTypeSpace: actionTypes.users.loadNotifications,
            action: () => userService.loadNotifications(),
        })
    },

    saveNotification(newNotifications) {
        return createAction({
            actionTypeSpace: actionTypes.users.saveNotification,
            action: () => userService.saveNotification(newNotifications),
        })
    },
}
