import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Link from 'common_components/link'
import Button from 'common_components/button'

import {isCurrentPage} from 'common_utils/routes_utils'

import styles from './styles.styl'

function DropdownLink({className, path, text, onClick, showSubNavBar, subNavBarLinks, ...props}) {
    const allLinksPath = [].concat(
        path,
        subNavBarLinks
            ? subNavBarLinks.map(({path: linkPath}) => linkPath)
            : []
    )

    if (showSubNavBar && subNavBarLinks && isCurrentPage(allLinksPath)) {
        setTimeout(() => showSubNavBar(subNavBarLinks))
    }

    return (
        onClick
            ? (
                <Button.text
                    className={cn(styles['dropdown-link'], className)}
                    onClick={onClick}
                    title={text}
                    {...props}
                />
            )
            : (
                <Link
                    className={cn(styles['dropdown-link'], className)}
                    path={path}
                    text={text}
                    {...props}
                />
            )
    )
}

DropdownLink.propTypes = {
    text: PropTypes.string.isRequired,

    className: PropTypes.string,
    path: PropTypes.string,
    onClick: PropTypes.func,
    showSubNavBar: PropTypes.func,
    subNavBarLinks: PropTypes.array,
}

const customDropdownLinkList = ([
    {
        name: 'Settings',
        className: styles['settings'],
    },
    {
        name: 'TeamSettings',
        className: styles['team-settings'],
    },
    {
        name: 'Support',
        className: styles['support'],
    },
    {
        name: 'Login',
        className: styles['login'],
    },
    {
        name: 'Logout',
        className: styles['logout'],
    },
])
customDropdownLinkList.map(({name, className}) => {
    DropdownLink[name] = props => ( // eslint-disable-line react/display-name
        <DropdownLink className={className} {...props} />
    )
    // display-name for previos component
    DropdownLink[name].displayName = `DropdownLink.${name}`
})

DropdownLink.MenuItem = props => (
    <DropdownLink
        className={cn(styles.menu, 'flex flex-row just-start align-center text-white p-zero')}
        {...props}
    />
)
DropdownLink.MenuItem.displayName = 'DropdownLink.text'

export default DropdownLink
