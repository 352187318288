import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import parseStylusExport from 'common_utils/parse_stylus_export'

import DefaultUserAvatar from './svg/default_user_avatar.svg?svg'

import styles from './styles.styl'

const stylesVariable = parseStylusExport(styles)

export default class Avatar extends Component {
    static propTypes = {
        className: PropTypes.string,
        src: PropTypes.string,
        size: PropTypes.oneOf(Object.keys(stylesVariable.avatarSize)),
        isProUser: PropTypes.bool,
        customSize: PropTypes.number,
    }

    static defaultProps = {
        src: DefaultUserAvatar,
        size: 'xs',
    }

    state = {
        avatarIsCorrect: true,
    }

    render() {
        const {
            src,
            size,
            className,
            isProUser,
            customSize,
            ...props
        } = this.props
        const {avatarIsCorrect} = this.state

        return (
            <div
                className={cn(styles.avatar, styles[size], className)}
                style={{
                    width: customSize,
                    height: customSize,
                }}
                {...props}
            >
                <img
                    className={cn(
                        styles[size],
                        {
                            [styles.pro]: isProUser,
                        }
                    )}
                    src={avatarIsCorrect ? src || DefaultUserAvatar : DefaultUserAvatar}
                    style={{
                        width: customSize,
                        height: customSize,
                    }}
                    onError={() => this.setState({avatarIsCorrect: false})}
                />
            </div>
        )
    }
}

Object.keys(stylesVariable.avatarSize).forEach(avatarSize => {
    Avatar[avatarSize] = props => ( // eslint-disable-line react/display-name
        <Avatar size={avatarSize} {...props} />
    )
    // display-name for previos component
    Avatar[avatarSize].displayName = `Avatar.${avatarSize}`
})
