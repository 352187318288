import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-stickynode'
import cn from 'classnames'

import styles from './styles.styl'

export default function DemoBar({className, withoutSticky, ...props}) {
    const demoBarComponent = (
        <div className={cn(styles['demo-bar'], 'flex align-center just-center', className)}>
            <p className="proxima text-white text-lg letter-sm m-zero">
                THIS IS A DEMO ENVIRONMENT
            </p>
        </div>
    )

    return withoutSticky
        ? demoBarComponent
        : (
            <Sticky innerClass={styles['sticky']}>
                {demoBarComponent}
            </Sticky>
        )
}

DemoBar.propTypes = {
    className: PropTypes.string,
    withoutSticky: PropTypes.bool,
}

DemoBar.defaultProps = {
    withoutSticky: false,
}
