import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from './api'

const commonChoicesLeadsApi = new Api(API_DOMAINS.leadsApi, 'common', 'choices')
const commonChoicesUsersApi = new Api(API_DOMAINS.usersApi, 'common', 'choices')

@modelSerialize([
    'properties',
    'buyers',
    'matches',
    'buyer_portal',
    'users',
    'notifications',
])
class CommonChoicesService {
    properties = null
    buyers = null
    matches = null
    buyer_portal = null
    users = null
    notifications = null

    async loadCommonLeadsChoices() {
        this.serialize = (await commonChoicesLeadsApi.get()).data

        return this.serialize
    }

    async loadCommonUserChoices() {
        this.users = (await commonChoicesUsersApi.get()).data

        return this.serialize
    }

    async loadCommonChoices() {
        await Promise.all([
            this.loadCommonLeadsChoices(),
            this.loadCommonUserChoices(),
        ])

        return this.serialize
    }
}

export default new CommonChoicesService
