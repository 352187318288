import {bindActionCreators} from 'redux'

export default function componentActionCreators(actions, dispatch) {
    return parseActionsArray(bindAction(actions, dispatch))
}

function bindAction(actions, dispatch) {
    return Object.keys(actions).map(key => (
        typeof actions[key] == 'function'
            ? {key: key, [key]: bindActionCreators(actions[key], dispatch)}
            : {key: key, [key]: bindAction(actions[key], dispatch)}
    ))
}

function parseActionsArray(actions) {
    return actions.reduce((currentObj, nextObj) => ({
        ...currentObj,
        [nextObj.key]: Array.isArray(nextObj[nextObj.key])
            ? parseActionsArray(nextObj[nextObj.key])
            : nextObj[nextObj.key],
    }), {})
}
