import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import cn from 'classnames'

import Separator from './components/separator'

import DropdownIcon from 'common_svg/dropdown.svg?jsx'
import Menu from 'common_svg/menu.svg?jsx'
import Close from 'common_svg/close.svg?jsx'

import styles from './styles.styl'

const listOfDropdown = []
const CONTENT_DIRECTION = {
    left: 'CONTENT_DIRECTION_LEFT',
    right: 'CONTENT_DIRECTION_RIGHT',
}

export default class Dropdown extends PureComponent {
    static contentDirection = CONTENT_DIRECTION
    static Separator = Separator

    static propTypes = {
        trigger: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
        content: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
        ]),
        className: PropTypes.string,
        isNotCloseAfterAction: PropTypes.bool,
        triggerClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        contentClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        isMenu: PropTypes.bool,
        triggerHasInput: PropTypes.bool,
        isOverflow: PropTypes.bool,
        justFallDown: PropTypes.bool,
        justFallUp: PropTypes.bool,
        hideArrow: PropTypes.bool,
        onToggle: PropTypes.func,
        scrollingElement: PropTypes.object,
        areaReduction: PropTypes.shape({
            top: PropTypes.number,
            bottom: PropTypes.number,
        }),
        id: PropTypes.string,
        contentDirection: PropTypes.oneOf(Object.values(CONTENT_DIRECTION)),
    }

    static defaultProps = {
        scrollingElement: document.scrollingElement,
        contentDirection: CONTENT_DIRECTION.left,
    }

    state = {
        isOpen: false,
        contentPosition: {},
        invertPosition: false,
    }

    dropdown = React.createRef()
    content = React.createRef()

    render() {
        const {
            trigger,
            content,
            triggerClassName,
            contentClassName,
            isNotCloseAfterAction,
            className,
            isMenu,
            isOverflow,
            triggerHasInput, // eslint-disable-line no-unused-vars
            scrollingElement, // eslint-disable-line no-unused-vars
            hideArrow,
            contentDirection,
            ...props
        } = this.props

        return (
            <div
                className={cn(styles.dropdown, className)}
                ref={this.dropdown}
                onClick={event => !isNotCloseAfterAction ? this.changeOpenState(event) : null}
                {...props}
            >
                <div
                    className={cn(styles.trigger, 'flex flex-row align-center space-between', triggerClassName)}
                    onClick={event => isNotCloseAfterAction ? this.changeOpenState(event) : null}
                >
                    {
                        isMenu
                            ? (
                                this.state.isOpen ? <Close /> : <Menu />
                            )
                            : (
                                <Fragment>
                                    {
                                        typeof trigger == 'function'
                                            ? trigger(
                                                this.state,
                                                {
                                                    closeDropdown: this.closeDropdown,
                                                }
                                            )
                                            : trigger
                                    }
                                    <DropdownIcon
                                        className={
                                            cn(
                                                styles['dropdown-icon'],
                                                this.state.isOpen && styles['dropdown-icon-active'],
                                                {
                                                    hide: hideArrow,
                                                }
                                            )
                                        }
                                    />
                                </Fragment>
                            )
                    }
                </div>
                <div
                    className={cn(
                        styles.content,
                        {
                            [styles['content-active']]: this.state.isOpen && !!content,
                            [styles['invert-content']]: this.state.invertPosition,
                            [styles['is-overflow']]: isOverflow,
                            [styles['content-direction-left']]: contentDirection == CONTENT_DIRECTION.left,
                            [styles['content-direction-right']]: contentDirection == CONTENT_DIRECTION.right,
                        },
                        contentClassName
                    )}
                    style={{
                        top: isOverflow && this.state.contentPosition.top,
                        right: isOverflow && this.state.contentPosition.right,
                        maxHeight: this.state.maxHeight ? this.state.maxHeight - 10 : null,
                        overflow: this.state.maxHeight ? 'auto' : null,
                    }}
                    ref={this.content}
                >
                    {
                        typeof content == 'function'
                            ? content(
                                this.state,
                                {
                                    closeDropdown: this.closeDropdown,
                                }
                            )
                            : content
                    }
                </div>
            </div>
        )
    }

    componentDidMount() {
        window.addEventListener('click', this.clickOutOfDropdown)
        window.addEventListener('touchstart', this.clickOutOfDropdown)

        if (this.props.isOverflow) {
            window.addEventListener('resize', this.windowResize)
        }

        listOfDropdown.push(this)

        this.id = this.props.id || listOfDropdown.length - 1
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOutOfDropdown)
        window.removeEventListener('touchstart', this.clickOutOfDropdown)
        window.removeEventListener('resize', this.windowResize)

        listOfDropdown.splice(this, 1)
    }

    @autobind
    changeOpenState(event) {
        const {
            scrollingElement,
            onToggle,
            areaReduction: {
                top: areaReductionTop = 0,
                bottom: areaReductionBottom = 0,
            } = {},
        } = this.props
        const triggerInfo = this.dropdown.current.getBoundingClientRect()
        const {scrollHeight} = scrollingElement
        const scrollingElementInfo = scrollingElement.getBoundingClientRect()

        this.content.current.style.visibility = 'hidden'
        this.content.current.style.display = 'block'

        const contentInfo = this.content.current.getBoundingClientRect()

        this.content.current.style.removeProperty('display')
        this.content.current.style.removeProperty('visibility')

        if (onToggle) {
            onToggle()
        }

        if (!this.state.isOpen) {
            this.setState(changeState)
        }
        else if (this.props.triggerHasInput && event?.target?.localName != 'input') {
            this.setState(changeState)
        }
        else if (!this.props.triggerHasInput) {
            this.setState(changeState)
        }

        function changeState(prevState) {
            const topSpace = triggerInfo.top - areaReductionTop
                + scrollingElement.scrollTop - 15 - scrollingElementInfo.top
            const bottomSpace = scrollHeight - areaReductionBottom
                - (contentInfo.top - scrollingElementInfo.top + scrollingElement.scrollTop)
            const maxSpace = Math.max(topSpace, bottomSpace)

            return {
                invertPosition: this.props.justFallUp
                    || (
                        !this.props.justFallDown
                            && contentInfo.top + scrollingElement.scrollTop + contentInfo.height
                                > scrollHeight - areaReductionBottom
                            && maxSpace == topSpace
                    ),
                maxHeight: maxSpace,
                isOpen: !prevState.isOpen,
                contentPosition: {
                    top: triggerInfo.bottom + 3,
                    right: document.body.clientWidth - triggerInfo.right,
                },
            }
        }
    }

    @autobind
    closeDropdown() {
        const {onToggle} = this.props

        this.setState({
            isOpen: false,
        })

        if (onToggle) {
            onToggle()
        }
    }

    @autobind
    windowResize(event) {
        const triggerInfo = this.dropdown.current.getBoundingClientRect()

        this.setState(({contentPosition}) => ({
            contentPosition: {
                ...contentPosition,
                right: document.body.clientWidth - triggerInfo.right,
            },
        }))
    }

    @autobind
    clickOutOfDropdown(event) {
        if (
            event.target !== this.dropdown.current
                && event.target.localName !== 'svg'
                && !this.dropdown.current.contains(event.target)
                && document.body.contains(event.target)
                && this.state.isOpen
        ) {
            this.changeOpenState(event)
        }
    }
}

export function closeAllDropdown() {
    listOfDropdown.forEach(dropdownItem => dropdownItem.closeDropdown())
}

export function openSomeDropdown(dropdownId) {
    const dropdown = listOfDropdown.find(({id}) => dropdownId == id)

    if (dropdown) {
        dropdown.changeOpenState()
    }
}

export function closeSomeDropdown(dropdownId) {
    const dropdown = listOfDropdown.find(({id}) => dropdownId == id)

    if (dropdown) {
        dropdown.closeDropdown()
    }
}
