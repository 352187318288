import portalPropertiesListService from 'common_services/properties2/portal_properties_list'
import searchPropertiesListService from 'common_services/properties2/portal_search_properties_list'

import createAction, {createSimpleAction} from '../create_action'
import actionTypes from '../action_types'

import {createCancelSource} from 'common_services/http'

export default {
    setLead(portalBuyerUUID, keepPreviousList) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesList.setLead,
            action: () => portalPropertiesListService.setLead(portalBuyerUUID, keepPreviousList),
        })
    },

    loadPortalPropertiesPage(page, {ordering, search, filters} = {}, pageSize, isBuyerPortal) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.portalPropertiesList.loadPortalPropertiesPage,
            action: () => {
                const cancelSource = createCancelSource()

                const propertiesListService = isBuyerPortal
                    ? portalPropertiesListService
                    : searchPropertiesListService

                const promise = propertiesListService.loadPage(
                    page,
                    {ordering, search, filters},
                    pageSize,
                    {cancelSource}
                )

                promise.cancel = cancelSource.cancel

                return promise
            },
        })
    },

    loadMissingItemsOnCurrentPage({ordering, search, filters} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.portalPropertiesList.loadMissingItemsOnCurrentPage,
            action: () => {
                const cancelSource = createCancelSource()

                const promise = portalPropertiesListService.loadMissingItemsOnCurrentPage(
                    {ordering, search, filters},
                    {cancelSource}
                )

                promise.cancel = cancelSource.cancel

                return promise
            },
        })
    },

    localUpdatePortalPropertyInList(newProperty) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesList.localUpdatePortalPropertyInList,
            action: () => newProperty,
            createPayload: () => portalPropertiesListService.updateObjectInList(newProperty),
        })
    },

    localRemovePortalPropertyInList(propertyUUID) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesList.localRemovePortalPropertyInList,
            action: () => propertyUUID,
            createPayload: () => portalPropertiesListService.removeFromList({
                uuid: propertyUUID,
            }),
        })
    },
}
