import Reducer from './reducer'

import actionTypes from '../actions/action_types'

import mlsAgentsListService from 'common_services/mls_agents/mls_agents_list'
import selectedMlsAgentService from 'common_services/mls_agents/selected_mls_agent'

const INITIAL_STATE = {
    mlsAgentsList: mlsAgentsListService.serialize,
    selectedMlsAgent: selectedMlsAgentService.serialize,
}

const mlsAgentsReducer = new Reducer(INITIAL_STATE)

mlsAgentsReducer.add(actionTypes.mlsAgents.loadMlsAgentsPage, 'mlsAgentsList')
mlsAgentsReducer.add(actionTypes.mlsAgents.changePageSize, 'mlsAgentsList')

mlsAgentsReducer.add(actionTypes.mlsAgents.loadMlsAgentByUUID, 'selectedMlsAgent')
mlsAgentsReducer.resetField(actionTypes.mlsAgents.resetSelectedMlsAgent, 'selectedMlsAgent')
mlsAgentsReducer.add(actionTypes.mlsAgents.loadMlsAgentByInviteToken, 'selectedMlsAgent')
mlsAgentsReducer.add(actionTypes.mlsAgents.loadMlsAgentProperties, 'selectedMlsAgent')
mlsAgentsReducer.add(actionTypes.mlsAgents.saveMlsAgent, 'selectedMlsAgent')
mlsAgentsReducer.add(actionTypes.mlsAgents.createMlsAgent, 'selectedMlsAgent')
mlsAgentsReducer.resetField(actionTypes.mlsAgents.deleteMlsAgent, 'selectedMlsAgent')

export default mlsAgentsReducer.reducersFunction
