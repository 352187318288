import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import {Field} from 'redux-form'
import Slider, {Range} from 'rc-slider'
import cn from 'classnames'
import dotProp from 'dot-prop'
import 'rc-slider/assets/index.css'

import {priceFormat, numberFormat} from 'common_utils/string_format'

import Button from 'common_components/button'

import Input from '../field'
import RequiredLabel from '../required_label'
import * as validators from '../../validators'

import styles from './styles.styl' // eslint-disable-line no-unused-vars

class CustomSlider extends Component {
    static propTypes = {
        input: PropTypes.object.isRequired,
        originalMinValue: PropTypes.number.isRequired,
        minValue: PropTypes.number.isRequired,
        originalMaxValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,

        isStartFixed: PropTypes.bool,
        displayTextFunction: PropTypes.func,
        normalizeValue: PropTypes.func,
        formatValue: PropTypes.func,
        descriptionText: PropTypes.string,
        className: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.number,
        ]),
        isImportant: PropTypes.bool,
    }

    static defaultProps = {
        displayTextFunction: defaultDisplayTextFunction,
    }

    state = {
        manualInput: false,
    }

    render() {
        const {
            originalMinValue,
            minValue,
            originalMaxValue,
            maxValue,
            input: {value, ...input},
            className,
            descriptionText,
            isStartFixed,
            displayTextFunction,
            normalizeValue,
            isImportant,
            ...props
        } = this.props

        const displayText = isStartFixed
            ? displayTextFunction({
                low: originalMinValue,
                high: (typeof value == 'undefined' ? {} : value).hasOwnProperty('high') ? value.high : value,
                minValue: minValue,
                maxValue: originalMaxValue,
            })
            : displayTextFunction(
                {
                    ...normalizeValue(value),
                    minValue: minValue,
                    maxValue: originalMaxValue,
                }
            )

        return (
            <div className={className}>
                {
                    (descriptionText || displayText) && (
                        <div className="flex align-center mb-xs">
                            {
                                descriptionText && (
                                    <p className="text-xs proxima-bold uppercase m-zero">
                                        {descriptionText}
                                    </p>
                                )
                            }
                            {
                                displayText && (
                                    <p className="text-sm proxima-bold uppercase m-zero">
                                        {displayText}
                                    </p>
                                )
                            }
                            {
                                isImportant && (
                                    <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                )
                            }
                        </div>
                    )
                }
                {
                    isStartFixed
                        ? (
                            <Slider
                                min={minValue}
                                max={maxValue}
                                value={
                                    (typeof value == 'undefined' ? {} : value).hasOwnProperty('high')
                                        ? value.high
                                        : value
                                }
                                {...input}
                                {...props}
                            />
                        ) : (
                            <Range
                                min={minValue}
                                max={maxValue}
                                pushable={2}
                                value={value}
                                {...input}
                                {...props}
                            />
                        )
                }
            </div>
        )
    }

    componentDidMount() {
        const {input: {value, onChange}} = this.props

        onChange(value)
    }
}

export function createReduxCustomSliderClass(InputComponent) {
    return class ReduxCustomSlider extends Component {
        static propTypes = {
            name: PropTypes.string.isRequired,
            minValue: PropTypes.number.isRequired,
            maxValue: PropTypes.number.isRequired,

            className: PropTypes.string,
            isStartFixed: PropTypes.bool,
            withDelimiter: PropTypes.bool,
            defaultValue: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.number,
            ]),
            format: PropTypes.func,
            normalize: PropTypes.func,
            withManualInput: PropTypes.bool,
            forceManualInput: PropTypes.bool,
            manualInputProps: PropTypes.shape({
                value: PropTypes.object.isRequired,
                syncErrors: PropTypes.any,
            }),
            maxDisplayValue: PropTypes.number,
            prePopulatedFunctions: PropTypes.shape({
                low: PropTypes.func.isRequired,
                high: PropTypes.func.isRequired,
            }),
            descriptionText: PropTypes.string,
            isImportant: PropTypes.bool,
        }

        static showPrice = ({low, high, minValue, maxValue}) =>
            `${priceFormat(low === '' ? minValue : low)} - ${high >= maxValue || high === '' ? 'ANY' : priceFormat(high)}` || ''
        static showShortPrice = ({low, high, minValue, maxValue}) =>
            `${priceFormat(low === '' ? minValue : low, 1)} - ${high >= maxValue || high === '' ? 'ANY' : priceFormat(high, 1)}` || ''
        static showPricePlain = ({low, high, minValue, maxValue}) =>
            `$${numberFormat(low === '' ? minValue : low)} - ${high >= maxValue || high === '' ? 'ANY' : `$${numberFormat(high)}`}` || ''
        static showSqrt = ({low, high, minValue, maxValue}) =>
            `${numberFormat(low === '' ? minValue : low)} - ${high >= maxValue || high === '' ? 'ANY' : numberFormat(high)}` || ''
        static showYear = ({low, high, minValue, maxValue}) =>
            `${low === '' ? minValue : low} - ${new Date().getFullYear() === high || high === '' ? 'PRESENT' : high}`
        static defaultDisplayTextFunction = defaultDisplayTextFunction

        static prePopulatedFunctions = {
            price: {
                low: ({low, high}) => (
                    low !== undefined && high != undefined
                        ? (new Array(
                            Math.min(
                                11,
                                Math.max(
                                    Math.ceil(
                                        high / Math.pow(10, 5)
                                    ),
                                    2
                                )
                            )
                        ))
                            .fill(1)
                            .map((el, idx) => idx * Math.pow(10, 5))
                        : []
                ),
                high: ({low, high}) => (
                    low !== undefined && high != undefined
                        ? (new Array(Math.min(10)))
                            .fill(1)
                            .map((el, idx) => (
                                Math.floor(low / Math.pow(10, 5)) * Math.pow(10, 5) + (idx + 1) * Math.pow(10, 5)
                            ))
                        : []
                ),
            },
        }

        state = {
            manualInput: this.props.forceManualInput,
            showLowPrePopulatedButtons: true,
            showHighPrePopulatedButtons: false,
        }

        render() {
            const {
                minValue,
                maxValue,
                name,

                className,
                defaultValue,
                format,
                normalize,
                isStartFixed,
                withManualInput,
                withDelimiter,
                maxDisplayValue,
                prePopulatedFunctions,
                manualInputProps: {minProps, maxProps, syncErrors, ...manualInputProps} = {},
                descriptionText,
                isImportant,
                ...props
            } = this.props
            const {manualInput, showLowPrePopulatedButtons, showHighPrePopulatedButtons} = this.state

            let formatedMinValue, formatedMaxValue
            if (format) {
                [formatedMinValue, formatedMaxValue] = format([minValue, maxValue])
            }

            const formatValue = currentValue => reduxCustomSliderFormat(
                currentValue,
                defaultValue || (
                    isStartFixed
                        ? maxValue
                        : [minValue, maxValue]
                ),
                isStartFixed,
                format
            )
            const normalizeValue = (currentValue, previousValue) => reduxCustomSliderNormalize(
                currentValue,
                previousValue,
                isStartFixed,
                minValue,
                normalize
            )

            const inputMinProps = {
                ...manualInputProps,
                value: manualInputProps?.value?.low,
                ...minProps,
            }
            const inputMaxProps = {
                ...manualInputProps,
                value: manualInputProps?.value?.high,
                ...maxProps,
            }

            const prePopulatedButtonClassName = cn(
                'proxima-medium text-sm text-dark-grey-blue letter-sm py-xs px-sm-md text-left',
                styles['pre-populated-button']
            )

            return (
                <Fragment>
                    {
                        manualInput && descriptionText && (
                            <div className="flex align-center mb-xs">
                                {
                                    descriptionText && (
                                        <p className="text-xs proxima-bold uppercase m-zero">
                                            {descriptionText}
                                        </p>
                                    )
                                }
                                {
                                    isImportant && (
                                        <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        manualInput
                            ? (
                                <div className={cn('flex space-between', className)}>
                                    <div
                                        className={cn(
                                            styles['manual-input-block'],
                                            {
                                                'flex-grow': withDelimiter,
                                            }
                                        )}
                                    >
                                        <InputComponent
                                            name={`${name}.low`}
                                            className={cn(
                                                styles['manual-input'],
                                                {
                                                    'mr-xs': !withDelimiter,
                                                    'flex-grow': withDelimiter,
                                                }
                                            )}
                                            descriptionText="MIN"
                                            validate={
                                                rangeCheckerValidator(
                                                    maxValue,
                                                    minValue,
                                                    inputMinProps.format
                                                ).low
                                            }
                                            {...inputMinProps}
                                            onClick={() => this.setState({
                                                showLowPrePopulatedButtons: true,
                                                showHighPrePopulatedButtons: false,
                                            })}
                                        />
                                        {
                                            !!prePopulatedFunctions
                                                && !!manualInputProps.value
                                                && (
                                                    <div
                                                        className={cn(
                                                            styles['pre-populated-list'],
                                                            'flex flex-column mt-sm md-flex-hide',
                                                            {
                                                                hide: !showLowPrePopulatedButtons,
                                                            }
                                                        )}
                                                    >
                                                        {
                                                            prePopulatedFunctions.low(manualInputProps.value)
                                                                .map((el, idx) => (
                                                                    <Button.text
                                                                        key={`${el}-${idx}`}
                                                                        title={inputMinProps.format(el)}
                                                                        className={prePopulatedButtonClassName}
                                                                        onClick={() => this.setLow(el)}
                                                                    />
                                                                ))
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>
                                    {
                                        withDelimiter && (
                                            <div className={cn(styles['delimiter'], 'mx-sm')} />
                                        )
                                    }
                                    <div
                                        className={cn(
                                            styles['manual-input-block'],
                                            {
                                                'flex-grow': withDelimiter,
                                            }
                                        )}
                                    >
                                        <InputComponent
                                            name={`${name}.high`}
                                            className={cn(
                                                styles['manual-input'],
                                                {
                                                    'flex-grow': withDelimiter,
                                                }
                                            )}
                                            descriptionText="MAX"
                                            validate={
                                                rangeCheckerValidator(
                                                    maxValue,
                                                    minValue,
                                                    inputMaxProps.format
                                                ).high
                                            }
                                            {...inputMaxProps}
                                            onClick={() => this.setState({
                                                showLowPrePopulatedButtons: false,
                                                showHighPrePopulatedButtons: true,
                                            })}
                                        />
                                        {
                                            !!prePopulatedFunctions
                                                && !!manualInputProps.value
                                                && (
                                                    <div
                                                        className={cn(
                                                            styles['pre-populated-list'],
                                                            'flex flex-column mt-sm md-flex-hide',
                                                            {
                                                                hide: !showHighPrePopulatedButtons,
                                                            }
                                                        )}
                                                    >
                                                        {
                                                            prePopulatedFunctions.high(manualInputProps.value)
                                                                .map((el, idx) => (
                                                                    <Button.text
                                                                        key={`${el}-${idx}`}
                                                                        title={inputMinProps.format(el)}
                                                                        className={prePopulatedButtonClassName}
                                                                        onClick={() => this.setHigh(el)}
                                                                    />
                                                                ))
                                                        }
                                                        <Button.text
                                                            title="Any Price"
                                                            className={prePopulatedButtonClassName}
                                                            onClick={() => this.setHigh(maxValue)}
                                                        />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            )
                            : (
                                <Field
                                    className={className}
                                    name={name}
                                    component={CustomSlider}
                                    format={formatValue}
                                    normalize={normalizeValue}
                                    defaultValue={defaultValue}
                                    originalMinValue={minValue}
                                    minValue={formatedMinValue || minValue}
                                    originalMaxValue={maxDisplayValue || maxValue}
                                    maxValue={formatedMaxValue || maxValue}
                                    props={{formatValue, normalizeValue}}
                                    isStartFixed={isStartFixed}
                                    descriptionText={descriptionText}
                                    isImportant={isImportant}
                                    {...props}
                                />
                            )
                    }
                    {
                        withManualInput && (
                            <div className="flex align-center just-end mt-sm">
                                <Button.small
                                    title={manualInput ? 'Slider Input' : 'Manual Input'}
                                    className="text-turquoise-blue capitalize mt-xs"
                                    onClick={this.switchManualInput}
                                    isInvert
                                    disabled={syncErrors}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )
        }

        @autobind
        switchManualInput() {
            this.setState(({manualInput}) => ({
                manualInput: !manualInput,
            }))
        }

        @autobind
        setLow(low) {
            const {manualInputProps: {setValue, value}} = this.props

            setValue({
                ...value,
                low,
            })

            this.setState({
                showLowPrePopulatedButtons: false,
                showHighPrePopulatedButtons: true,
            })
        }

        @autobind
        setHigh(high) {
            const {manualInputProps: {setValue, value}} = this.props

            setValue({
                ...value,
                high,
            })

            this.setState({
                showLowPrePopulatedButtons: true,
                showHighPrePopulatedButtons: false,
            })
        }
    }
}

export default createReduxCustomSliderClass(Input)

function reduxCustomSliderFormat(value, defaultValue, isStartFixed, cb) {
    value = typeof value == 'string' || typeof value == 'undefined'
        ? defaultValue
        : (
            isStartFixed
                ? (typeof value == 'undefined' ? {} : value).hasOwnProperty('high') ? value.high : value
                : [
                    value.low === '' ? defaultValue?.[0] : value.low,
                    value.high === '' ? defaultValue?.[1] : value.high,
                ]
        )

    return cb ? cb(value) : value
}

function reduxCustomSliderNormalize(value, previousValue, isStartFixed, minValue, cb) {
    value = typeof value != 'undefined'
        ? (
            isStartFixed
                ? {
                    low: minValue,
                    high: (typeof value == 'undefined' ? {} : value).hasOwnProperty('high') ? value.high : value,
                }
                : {
                    low: value.hasOwnProperty(0) ? value[0] : value.low,
                    high: value.hasOwnProperty(1) ? value[1] : value.high,
                }
        )
        : previousValue

    return cb ? cb(value) : value
}

function defaultDisplayTextFunction({low, high, maxValue}) {
    return `${low} - ${high === maxValue ? 'ANY' : high}`
}

const rangeValidators = {}

function rangeCheckerValidator(maxValue, minValue, valueStringFormat) {
    const key = `${maxValue}-${minValue}`

    if (!rangeValidators[key]) {
        rangeValidators[key] = {
            low: [
                value => validators.minValue(
                    minValue,
                    `Value cannot be lower than ${valueStringFormat ? valueStringFormat(minValue) : minValue}`,
                    false,
                    true,
                )(value),
                (value, allValue, props, name) => {
                    const originalFieldName = name.replace(/\.low$/, '')

                    return validators.range(
                        dotProp.get(allValue, originalFieldName) || {},
                        'Min cannot be greater than max',
                        false,
                        emptyValue => emptyValue === '' || emptyValue == undefined || emptyValue == null
                    )(value)
                },
            ],
            high: [
                value => validators.maxValue(
                    maxValue,
                    `Value cannot be greater than ${valueStringFormat ? valueStringFormat(maxValue) : maxValue}`,
                    false,
                    true,
                )(value),
                (value, allValue, props, name) => {
                    const originalFieldName = name.replace(/\.high$/, '')

                    return validators.range(
                        dotProp.get(allValue, originalFieldName) || {},
                        'Max cannot be less than min',
                        false,
                        emptyValue => emptyValue === '' || emptyValue == undefined || emptyValue == null
                    )(value)
                },
            ],
        }
    }

    return rangeValidators[key]
}
