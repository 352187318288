import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import cn from 'classnames'

import Button from 'common_components/button'

import RequiredLabel from '../required_label'

import styles from './styles'

export default function ReduxNumberRange({...props}) {
    return <Field component={NumberRange} {...props} />
}

ReduxNumberRange.showNumberRange = (value, exact_match) => `${value == Infinity ? 'Any' : `${value}${exact_match ? '' : '+'}`}`

function NumberRange({
    input: {value, onChange},
    descriptionText,
    descriptionComponent,
    meta: {error, visited, touched, active, ...meta},
    minValue,
    maxValue,
    exact_match,
    noAny,
    isImportant,
    ...props
}) {
    return (
        <div {...props}>
            {
                descriptionComponent
                    || (
                        !!descriptionText && (
                            <div className="flex align-center mb-xs">
                                <p className="text-xs proxima-bold uppercase m-zero">
                                    {descriptionText}
                                </p>
                                {
                                    isImportant && (
                                        <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                                    )
                                }
                            </div>
                        )
                    )
            }
            <div className="flex align-center space-between">
                <Button.small
                    className={cn(
                        styles.button,
                        styles.left,
                        'flex align-center just-center text-lg-on-md-screen'
                    )}
                    title="-"
                    isInvert
                    disabled={noAny ? value <= minValue : value == Infinity}
                    onClick={onClickDown}
                />
                <p
                    className={cn(
                        styles['number-range-value'],
                        'proxima text-dark-grey-blue text-sm text-md-on-md-screen letter-sm text-center',
                        'flex-grow m-zero flex align-center just-center'
                    )}
                >
                    <span>
                        {value == Infinity ? 'Any' : `${value}${exact_match ? '' : '+'}`}
                    </span>
                </p>
                <Button.small
                    className={cn(
                        styles.button,
                        styles.right,
                        'flex align-center just-center text-lg-on-md-screen'
                    )}
                    title="+"
                    isInvert
                    disabled={value == maxValue}
                    onClick={onClickUp}
                />
            </div>
        </div>
    )

    function onClickDown() {
        onChange(value - 1 ? value - 1 : Infinity)
    }

    function onClickUp() {
        onChange(value == Infinity ? minValue : value + 1)
    }
}

NumberRange.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    descriptionText: PropTypes.string,
    descriptionComponent: PropTypes.node,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    exact_match: PropTypes.bool,
    noAny: PropTypes.bool,
    isImportant: PropTypes.bool,
}

NumberRange.defaultProps = {
    analytics: {},
}
