import modelSerialize from 'common_utils/model_serialize'

import {UserService} from '../user'
import {RoutingRuleService} from '../routing_rules/routing_rule'

@modelSerialize([
    'agent',
    'conversion_rate',
    'count_past_due',
    'is_active',
    'routingRules',
])
class AgentService {
    agent = null
    conversion_rate = 0
    count_past_due = 0
    is_active = false
    routingRules = []

    constructor(data) {
        if (data) {
            this.serialize = data

            this.conversion_rate = (this.conversion_rate * 100).toFixed(1)
        }
    }

    static async loadAgentByUUID(agentUuid) {
        const [agent, {data: {results: routingRules}}] = await Promise.all([
            UserService.getUserByUuid(agentUuid),
            RoutingRuleService.getRoutingRulesByAgent(agentUuid),
        ])

        return new AgentService({
            agent: agent,
            routingRules: routingRules,
        })
    }
}

export default AgentService
