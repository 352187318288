import React from 'react'

import Spinner from 'common_components/spinner'

export default function Loader() {
    return (
        <div className="flex flex-row align-center">
            <Spinner />
            <p className="ml-sm">
                Loading...
            </p>
        </div>
    )
}
