import ENVIRONMENT from 'ENVIRONMENT'
import API_DOMAINS from 'API_DOMAINS'

import React from 'react'
import {Route} from 'react-router-dom'
import mixinDeep from 'mixin-deep'
import cloneDeep from 'clone-deep'
import qs from 'qs'

import Page from 'common_components/page'

import QueryMiddleware from 'common_services/query_middleware'
import {UserService} from 'common_services/user'

let currentRoute

export function getRoutes(routesObjList, PageWrapper = Page, server = false) {
    return routesObjList
        .filter(routesObj => routesObj.excludeOnEnvironments
            ? routesObj.excludeOnEnvironments.indexOf(ENVIRONMENT) == -1
            : true
        )
        .map((route, id) => (
            <Route
                key={id}
                {...route}
                component={props => {
                    currentRoute = route
                    return (
                        <PageWrapper
                            pageTitle={route.title}
                            component={route.component}
                            redirects={route.redirects}
                            loadComponent={route.loadComponent}
                            pageProps={route.pageProps}
                            {...props}
                        />
                    )
                }}
            />
        ))
}

export function getCurrentRoute() {
    return currentRoute
}

export const defaultParamsOfRoute = {
    serverRender: true,
    authRequire: true,
    title: 'Raven',
    pageProps: {
        redirectBeforeLoadingRequiredData: true,
    },
}

export function addDefaultParamsToRoutes(routes) {
    return routes.map(route => mixinDeep(
        cloneDeep(defaultParamsOfRoute),
        route
    ))
}

export const redirects = {
    redirect(redirectUrl, saveQuery) {
        return (history, match, user) => saveQuery
            ? createRedirectUrl(redirectUrl, history, match, false, true)
            : redirectUrl
    },
    authRequire(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (user.isAnonymous) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },
    onlyAnonymous(defaultRedirectUrl, redirectBack = true, forceRedirect = false) {
        return (history, match, user) => {
            if (!user.isAnonymous) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack, forceRedirect)
            }
            return false
        }
    },

    licenseNumberRequire(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.hasLicenseNumber) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    mlsIdRequire(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.hasMlsId) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyWithoutLicenseNumber(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (user.hasLicenseNumber) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyWithoutMlsId(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (user.hasMlsId) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyForOwnerOrAdmin(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.permissions.canIchangeMyTeam) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyWhoCanBuyPlan(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.permissions.canIBuyPlan) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyForSuperuser(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.permissions.isSuperuser) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    proPlanCustomer(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            const query = new QueryMiddleware(history)
            if (query.search.subscribe && !user.permissions.hasProFeatures) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    proPlanReqiure(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (!user.permissions.hasProFeatures) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyBasicCustomer(defaultRedirectUrl, redirectBack = true) {
        return (history, match, user) => {
            if (user.permissions.hasProFeatures) {
                return createRedirectUrl(defaultRedirectUrl, history, match, redirectBack)
            }
            return false
        }
    },

    onlyAgents(defaultRedirectUrl) {
        return (history, match, user) => (
            user.permissions.isPartner
                ? createRedirectUrl(defaultRedirectUrl, history, match, false)
                : false
        )
    },

    onlyPartners(defaultRedirectUrl) {
        return (history, match, user) => (
            user.permissions.isAgent
                ? createRedirectUrl(defaultRedirectUrl, history, match, false)
                : false
        )
    },

    checkCorrespondenceOfUserGroupAndProject(availableUserGroup, defaultRedirectUrl) {
        return (history, match, user) => {
            if (user.isAuthorized && user.userGroups.indexOf(availableUserGroup) == -1) {
                let domainName = API_DOMAINS.adminDomain
                let path = '/'

                user.userGroups.some(currentUserGroup => {
                    if (
                        [
                            ...Object.values(UserService.availableUserGroups.agents),
                            ...Object.values(UserService.availableUserGroups.partners),
                        ].indexOf(currentUserGroup) != -1
                    ) {
                        switch (currentUserGroup) {
                            case UserService.availableUserGroups.agents.agent:
                                domainName = API_DOMAINS.adminDomain
                                path = defaultRedirectUrl.adminPath || '/'
                                break
                            case UserService.availableUserGroups.partners.loanOfficer:
                                domainName = API_DOMAINS.partnerDomain
                                path = defaultRedirectUrl.partnerPath || '/'
                                break
                            case UserService.availableUserGroups.partners.titleRep:
                                domainName = API_DOMAINS.titleRepDomain
                                path = defaultRedirectUrl.titleRepPath || '/'
                                break
                        }

                        return true
                    }

                    return false
                })

                return createRedirectUrl(`${domainName}${path}`, history, match, false)
            }

            return false
        }
    },

    onlyWithFollowUsBossIntegration(defaultRedirectUrl) {
        return (history, match, user) => (
            !user.followUsBossIntegration
                ? createRedirectUrl(defaultRedirectUrl, history, match, false)
                : false
        )
    },

    onlyWithoutFollowUsBossIntegration(defaultRedirectUrl) {
        return (history, match, user) => (
            user.followUsBossIntegration
                ? createRedirectUrl(defaultRedirectUrl, history, match, false)
                : false
        )
    },
}

function createRedirectUrl(defaultRedirectUrl, history, match, redirectBack = true, forceRedirect = false) {
    const query = new QueryMiddleware(history)
    const currentPath = `${history.location.pathname}${history.location.search}`
    const queryParams = qs.stringify({
        invite: query.search.invite,
        email: query.search.email,
        first_name: query.search.first_name,
        last_name: query.search.last_name,
        brokerage: query.search.brokerage,
        title_company: query.search.title_company,
        company_rep: query.search.company_rep,
        subscribe: query.search.subscribe,
        plan: query.search.plan,
        context: query.search.context,
        signature: query.search.signature,
        next: redirectBack
            ? currentPath
            : forceRedirect && query.search.next
                ? query.search.next
                : undefined,
    })

    const redirectPath = `${defaultRedirectUrl}${queryParams ? `?${queryParams}` : ''}`

    return forceRedirect
        ? redirectPath
        : query.search.next || redirectPath
}

export function isCurrentPage(path) {
    if (Array.isArray(path)) {
        path = path.map(currentPath =>
            currentPath.substr(0, currentPath.indexOf('?') == -1 ? currentPath.length : currentPath.indexOf('?'))
        )
    }
    else {
        path = path.substr(0, path.indexOf('?') == -1 ? path.length : path.indexOf('?'))
    }

    const pagePath = getCurrentRoute().path

    return Array.isArray(path)
        ? !!path.find(currentPath => isPathsEqual(currentPath, pagePath))
        : isPathsEqual(path, pagePath)

    function isPathsEqual(currentPath, routerPath) {
        if (routerPath) {
            const hasRouterPathParams = routerPath.indexOf('/:') != -1

            return hasRouterPathParams
                ? (new RegExp(`^${routerPath.substr(0, routerPath.indexOf('/:'))}`)).test(currentPath)
                : currentPath == routerPath
        }

        return false
    }
}
