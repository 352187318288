import userService from 'common_services/user'

import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {
    ...userService.serialize,
}

const usersReducer = new Reducer(INITIAL_STATE)

usersReducer.add(actionTypes.users.authenticate)
usersReducer.add(actionTypes.users.register)
usersReducer.add(actionTypes.users.registerMlsAgent)
usersReducer.add(actionTypes.users.login)
usersReducer.add(actionTypes.users.logout)
usersReducer.add(actionTypes.users.loadMyProfile)
usersReducer.add(actionTypes.users.saveMyProfile)
usersReducer.add(actionTypes.users.changePassword)
usersReducer.add(actionTypes.users.loginAsAnotherUser)
usersReducer.add(actionTypes.users.loadInviteTokenInfo)

usersReducer.add(actionTypes.users.team.createTeam)
usersReducer.add(actionTypes.users.team.loadMyTeam)
usersReducer.add(actionTypes.users.team.inviteMembers)
usersReducer.add(actionTypes.users.team.resendInviteMember)
usersReducer.add(actionTypes.users.team.removeInviteMember)
usersReducer.add(actionTypes.users.team.loadMembersInvite)
usersReducer.add(actionTypes.users.team.changeMemberRole)
usersReducer.add(actionTypes.users.team.removeMember)
usersReducer.add(actionTypes.users.team.addMyUserToTheTeam)

usersReducer.add(actionTypes.users.resetPassword)
usersReducer.add(actionTypes.users.resetConfirmPassword)

usersReducer.add(actionTypes.users.generateMagicLoginLink)
usersReducer.add(actionTypes.users.confirmMagicLogin)

usersReducer.add(actionTypes.users.verification.saveLicenseNumber)
usersReducer.add(actionTypes.users.verification.deleteMlsLicenseNumber)
usersReducer.add(actionTypes.users.verification.saveMlsId)
usersReducer.add(actionTypes.users.verification.deleteMlsId)
usersReducer.add(actionTypes.users.verification.saveNmlsId)
usersReducer.add(actionTypes.users.verification.deleteNmlsId)

usersReducer.add(actionTypes.users.generateShortBuyerPortalLink)

usersReducer.add(actionTypes.users.pairWithPartner)
usersReducer.add(actionTypes.users.loadAllMyPairedUsers)
usersReducer.add(actionTypes.users.generatePartnerInviteLink)
usersReducer.add(actionTypes.users.savePartnerPromotionLink)
usersReducer.add(actionTypes.users.sendPartnerInviteLinkToAgents)

usersReducer.add(actionTypes.users.followUsBossIntegration.createFollowUsBossIntegrationObject)
usersReducer.add(actionTypes.users.followUsBossIntegration.loadFollowUsBossIntegrationObject)

usersReducer.add(actionTypes.users.loadNotifications)
usersReducer.add(actionTypes.users.saveNotification)

export default usersReducer.reducersFunction

export {usersReducer}

