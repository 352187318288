import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export default function RequiredLabel({
    className,
    isShort,
    ...props
}) {
    return (
        <span className={cn('text-tomato', className)} {...props}>
            {isShort ? '*' : 'REQUIRED'}
        </span>
    )
}

RequiredLabel.propTypes = {
    className: PropTypes.string,
    isShort: PropTypes.bool,
}
