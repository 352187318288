// import API_DOMAINS from 'API_DOMAINS'

import {readonly} from 'core-decorators'

import modelSerialize from 'common_utils/model_serialize'

// import Api from './api'

// const commonChoicesLeadsApi = new Api(API_DOMAINS.leadsApi, 'common', 'choices')
// const commonChoicesUsersApi = new Api(API_DOMAINS.usersApi, 'common', 'choices')

@modelSerialize([
    'context',
])
class FollowUsBossIntegration {
    @readonly
    static INTEGRATION_NAME = 'FOLLOW_US_BOSS'

    #contextString = ''
    #signature = ''

    get context() {
        if (this.#contextString) {
            try {
                return JSON.parse(atob(this.#contextString))
            }
            catch {
                return null
            }
        }

        return null
    }

    setData(contextString, signature) {
        this.#contextString = contextString
        this.#signature = signature

        return this.serialize
    }
}

export default new FollowUsBossIntegration

export {FollowUsBossIntegration}
