import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.styl'

function FullScreen({className, isBackground, ...props}) {
    return (
        <div
            className={cn(
                styles['full-screen'],
                {
                    [styles['is-background']]: isBackground,
                },
                className
            )}
            {...props}
        />
    )
}

FullScreen.propTypes = {
    className: PropTypes.string,
    isBackground: PropTypes.bool,
}

export default FullScreen
