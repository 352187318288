import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'

import PortalProperty from './portal_property.js'
import {PropertiesListService} from './properties_list'

const portalPropertiesApi = new Api(API_DOMAINS.leadsApi, 'properties', 'buyer-auto-shared', 2)

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
    'extraData',
    'propertyCounts',
    'portalBuyerUUID',
    'keepPreviousList',
    'prevPage',
    'nextPage',
])
class PortalPropertiesListService extends PropertiesListService {
    portalBuyerUUID = null
    keepPreviousList = false

    constructor(Model, api, pageSize = 10, keepPreviousList = false, withPreload = true) {
        super(Model, api, pageSize, keepPreviousList, withPreload)
    }

    loadPage(page, {ordering, search, filters}, pageSize, {cancelSource} = {}) {
        return super.loadPage(page, {ordering, search, filters}, pageSize, {isLoadAgents: false, cancelSource})
    }

    loadMissingItemsOnCurrentPage({ordering, search, filters}, {cancelSource} = {}) {
        return super.loadMissingItemsOnCurrentPage(
            {ordering, search, filters},
            {isLoadAgents: false, cancelSource}
        )
    }

    setLead(portalBuyerUUID, keepPreviousList = false) {
        resetPagination(this)

        if (this.withPreload) {
            resetPagination(this.prevPage)
            resetPagination(this.nextPage)
        }

        return this.setPaginationApi()

        function resetPagination(paginator) {
            paginator.portalBuyerUUID = portalBuyerUUID
            paginator.list = []
            paginator.currentPage = 1
            paginator.count = 0
            paginator.keepPreviousList = keepPreviousList
        }
    }

    setPaginationApi() {
        this.updatePagination(
            PortalProperty,
            portalPropertiesApi.getApiWithName(
                `buyer-auto-shared${this.portalBuyerUUID ? `/${this.portalBuyerUUID}` : ''}`
            ),
            this.pageSize,
            this.keepPreviousList
        )

        return this.serialize
    }
}

export default createNewPortalPropertiesListService(20)

export function createNewPortalPropertiesListService(pageSize, keepPreviousList = false, withPreload = true) {
    return new PortalPropertiesListService(
        PortalProperty,
        portalPropertiesApi,
        pageSize,
        keepPreviousList,
        withPreload,
    )
}
