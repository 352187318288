import {resetSelectedProperty} from 'common_services/properties2/selected_property'
import propertiesListService from 'common_services/properties2/properties_list'
import propertiesSearchService from 'common_services/properties2/properties_search'
import PropertyService from 'common_services/properties2/property'
import {createCancelSource} from 'common_services/http'

import createAction, {createSimpleAction} from '../create_action'
import actionTypes from '../action_types'

import portalPropertiesListActions from './portal_properties_list'
import portalPropertiesListForMapActions from './portal_properties_list_for_map'
import selectedPropertyActions from './selected_property'
import selectedPortalPropertyActions from './selected_portal_property'

export default {
    portalPropertiesListActions,
    portalPropertiesListForMapActions,
    selectedPropertyActions,
    selectedPortalPropertyActions,

    loadPropertiesPage(page, {ordering, search, filters} = {}, pageSize, {isLoadAgents = true, cancelSource} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.loadPropertiesPage,
            action: () => {
                const currentCancelSource = cancelSource || createCancelSource()

                const promise = propertiesListService.loadPage(
                    page,
                    {ordering, search, filters},
                    pageSize,
                    {isLoadAgents, cancelSource: currentCancelSource}
                )

                promise.cancel = currentCancelSource.cancel

                return promise
            },
        })
    },

    loadLightPropertiesPage(page, {search, filters} = {}, pageSize, {cancelSource} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.loadPropertiesPage,
            action: () => {
                const currentCancelSource = cancelSource || createCancelSource()

                const promise = propertiesListService.loadLightPage(
                    page,
                    {search, filters},
                    pageSize,
                    {cancelSource: currentCancelSource}
                )

                promise.cancel = currentCancelSource.cancel

                return promise
            },
        })
    },

    changePageSize(pageSize, {ordering, search, filters} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.changePageSize,
            action: () => propertiesListService.changePageSize(pageSize, {ordering, search, filters}),
        })
    },

    resetSelectedProperty() {
        return createSimpleAction({
            actionType: actionTypes.properties2.resetSelectedProperty,
            action: () => resetSelectedProperty(),
        })
    },

    loadSearchingProperties(page, search, {filters} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.loadSearchingProperties,
            action: () => propertiesSearchService.loadSearchingProperties(page, search, {filters}),
        })
    },

    loadPropertyCounts({ordering, search, filters} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.loadPropertyCounts,
            action: () => propertiesListService.loadPropertyCounts({ordering, search, filters}),
        })
    },

    assignPropertyAgent(propertyUuid, agentUuid) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.assignPropertyAgent,
            action: () => PropertyService.assignPropertyAgent(propertyUuid, agentUuid),
        })
    },

    createPropertyContact({phone, phone2, ...data}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.createPropertyContact,
            action: () => PropertyService.createPropertyContact({phone, phone2, ...data}),
        })
    },
    updatePropertyContact(uuid, {phone, phone2, ...data}) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.updatePropertyContact,
            action: () => PropertyService.updatePropertyContact(uuid, {phone, phone2, ...data}),
        })
    },
    deletePropertyContact(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.deletePropertyContact,
            action: () => PropertyService.deletePropertyContact(uuid),
        })
    },
    createPropertyActivity(data) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.createPropertyActivity,
            action: () => PropertyService.createPropertyActivity(data),
        })
    },
    updatePropertyActivity(uuid, data) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.updatePropertyActivity,
            action: () => PropertyService.updatePropertyActivity(uuid, data),
        })
    },
    deletePropertyActivity(uuid) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.deletePropertyActivity,
            action: () => PropertyService.deletePropertyActivity(uuid),
        })
    },
}
