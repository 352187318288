import PortalPropertyService from './portal_property'

let selectedPortalProperty = new PortalPropertyService

export const resetSelectedPortalProperty = () => {
    selectedPortalProperty = new PortalPropertyService

    return selectedPortalProperty.serialize
}

export default selectedPortalProperty

export function getCurrentSelectedPortalProperty() {
    return selectedPortalProperty
}
