import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'
import {createBackendFilters} from 'common_utils/create_backend_filters'

import Api from '../api'
import {UserService} from '../user'
import PaginationService from '../pagination'

import Property from './property'

const propertiesApi = new Api(API_DOMAINS.leadsApi, 'properties')

export const propertyFilterKeys = {
    price_range: 'price',
    bedrooms: 'bedrooms',
    bathrooms: 'bathrooms',
    size_range_sqft: 'size_sqft',
    lot_size_range_sqft: 'lot_size_sqft',
    hoa_max: 'hoa_max',
    year_built_range: 'year_built',
    agents: 'agent',
    timeframe: 'timeframe',
    lead_source: 'lead_source',
    lead_status: 'lead_status',
    cities: 'city',
    zipcodes: 'zipcode',
    is_matching_active: 'is_matching_active',
    leads_filter: 'leads_filter',
    matching_status: 'matching_status',
    keywords: 'keywords',
    area: 'area',
    not_match_attitude: 'not_match_attitude',
    match_attitude: 'match_attitude',
    stories: 'stories',
    subtypes: 'subtype',
    listing_statuses: 'listing_statuses',
}

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
    'extraData',
    'propertyCounts',
    'prevPage',
    'nextPage',
])
class PropertiesListService extends PaginationService {
    propertyCounts = {}

    async loadPage(page, {ordering, search, filters}, pageSize, {isLoadAgents = true, cancelSource} = {}) {
        const {newOrdering} = await this.createOrderingParams(ordering)

        await super.loadPage(
            page,
            {
                ordering: newOrdering,
                search,
                filters: createBackendFilters(
                    filters,
                    propertyFilterKeys,
                    {
                        removeEmptyString: true,
                        replaceEmptyStringToDefaultFilterValue: true,
                    }
                ),
            },
            pageSize,
            {cancelSource}
        )

        if (isLoadAgents) {
            await this.loadAllAgentsInList(cancelSource)
        }

        return this.serialize
    }

    async loadLightPage(page, {search, filters}, pageSize, {cancelSource} = {}) {
        await super.loadPage(
            page,
            {
                search,
                filters: createBackendFilters(
                    filters,
                    propertyFilterKeys,
                    {
                        removeEmptyString: true,
                        replaceEmptyStringToDefaultFilterValue: true,
                    }
                ),
            },
            pageSize,
            {
                cancelSource,
                lightApi: propertiesApi.getApiWithName('light'),
            }
        )

        return this.serialize
    }

    async loadMissingItemsOnCurrentPage({ordering, search, filters}, {isLoadAgents = true, cancelSource} = {}) {
        const {newOrdering} = await this.createOrderingParams(ordering)

        await super.loadMissingItemsOnCurrentPage(
            {
                ordering: newOrdering,
                search,
                filters: createBackendFilters(
                    filters,
                    propertyFilterKeys,
                    {
                        removeEmptyString: true,
                        replaceEmptyStringToDefaultFilterValue: true,
                    }
                ),
            },
            {cancelSource}
        )

        if (isLoadAgents) {
            await this.loadAllAgentsInList(cancelSource)
        }

        return this.serialize
    }

    async loadPropertyCounts({ordering, search, filters}) {
        const {newOrdering} = await this.createOrderingParams(ordering)
        const currentFilters = createBackendFilters(filters, propertyFilterKeys)

        this.propertyCounts = (await propertiesApi.getApiWithName('counts').get({
            ordering: newOrdering,
            search,
            ...currentFilters,
        })).data

        this.propertyCounts.filters = currentFilters

        return this.serialize
    }

    async changePageSize(...args) {
        await super.changePageSize(...args)

        // need to re-evaluate the last page size.
        // change the page length to 30 and then back to 10
        // last page is not recalculated.
        // We need to again serialize the data to get the new data
        return this.serialize
    }

    async createOrderingParams(ordering) {
        const newOrdering = await super.createOrderingParams(
            ordering,
            {
                'owner.full_name': 'owner',
                'last_activity.due_date': 'due_on',
                'match_category': 'matches',
                'matches_count': ['matches_count', 'in_progress_matches_count'],
            },
            true
        )

        return {newOrdering}
    }

    async loadAllAgentsInList(cancelSource) {
        const agents = await UserService.searchUsers(
            {
                uuid: this.list
                    .map(({agent: propertyAgentUuid}) => propertyAgentUuid)
                    .filter(propertyAgentUuid => !!propertyAgentUuid),
            },
            {cancelSource}
        )

        this.list.forEach(property => {
            property.agent = agents.find(agent => agent.uuid == property.agent)
        })
    }
}

export default new PropertiesListService(Property, propertiesApi, 10)

export {PropertiesListService}
