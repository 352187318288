import * as validators from 'common_utils/validators'

export function required(errorText = 'This field is required.', afterBlur = true) {
    return getStandartValidators(validators.isEmpty, true, errorText, afterBlur)
}

export function name(errorText = 'Please enter your name.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isUserName,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}
name.allowedCharacters = ['a-z', 'A-Z', '\'', '´', '`', '-', 'space']

export function nameWithExpandedCharacters(errorText = 'Please enter your name.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isUserNameWithExpandedCharacters,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}
nameWithExpandedCharacters.allowedCharacters = ['a-z', 'A-Z', '\'', '´', '`', '-', '0-9', 'space', 'dot', 'comma']

export function fullName(errorText = 'Please enter your full name.', afterBlur = true) {
    return getStandartValidators(
        validators.isFullUserName,
        false,
        errorText,
        afterBlur
    )
}

export function email(errorText = 'Please enter a valid email address.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isEmail,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function simplePassword(errorText = 'The password should be at least 8 characters.', afterBlur = true) {
    return getStandartValidators(
        validators.isSimplePassword,
        false,
        errorText,
        afterBlur
    )
}

export function complexPassword(
    errorText = 'The password should be at least 8 characters and include a Capital Letter and a number.',
    afterBlur = true
) {
    return getStandartValidators(
        validators.isComplexPassword,
        false,
        errorText,
        afterBlur
    )
}

export function equalPasswords(firstPassword, errorText = 'Passwords must be equal.', afterBlur = true) {
    return getStandartValidators(
        validators.isEqualPasswords(firstPassword),
        false,
        errorText,
        afterBlur
    )
}

export function phoneNumber(errorText = 'Please enter valid phone number.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isPhoneNumber,
        false,
        errorText,
        afterBlur,
        isEmptyOk
            ? value => (
                typeof value == 'string'
                    ? true
                    : !value?.phoneNumber
            )
            : isEmptyOk
    )
}

export function usaPhoneNumber(errorText = 'Please enter valid phone number.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isUsaPhoneNumber,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

//TODO: Add empty check to. This should be valid incase of empty too
export function range({low, high}, errorText = 'Range should be valid state', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        value => validators.isCorrectRange(parseInt(low || value), parseInt(high || value)),
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function maxValue(upperBoundOfNumber, errorText, afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        value => value <= upperBoundOfNumber,
        false,
        errorText ? errorText : `Value cannot be greater than ${upperBoundOfNumber}`,
        afterBlur,
        isEmptyOk
    )
}

export function minValue(lowerBoundOfNumber, errorText, afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        value => value >= lowerBoundOfNumber,
        false,
        errorText ? errorText : `Value cannot be low than ${lowerBoundOfNumber}`,
        afterBlur,
        isEmptyOk
    )
}

export function alphaNumeric(errorText = 'Only alpha numeric allowed', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.alphaNumeric,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function slug(
    errorText = 'Only contain alphanumeric characters in addition to - and _ allowed',
    afterBlur = true,
    isEmptyOk = false
) {
    return getStandartValidators(
        validators.isSlug,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function zipcode(
    errorText = 'Please enter valid zipcode.',
    afterBlur = true,
    isEmptyOk = false
) {
    return getStandartValidators(
        validators.isZipcode,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function usaState(
    useStrict = true,
    errorText = 'Please enter valid state',
    afterBlur = true,
    isEmptyOk = false
) {
    return getStandartValidators(
        value => validators.isUsaState(value, useStrict),
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function notEmptyArray(
    errorText = 'Please enter valid values.',
    afterBlur = true
) {
    return getStandartValidators(
        validators.isArrayEmpty,
        true,
        errorText,
        afterBlur
    )
}

export function url(errorText = 'Please enter a valid url.', afterBlur = true, isEmptyOk = false) {
    return getStandartValidators(
        validators.isURL,
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

export function containsOnlyCharacters(
    regExpCharacterList,
    errorText = 'Use valid characters',
    afterBlur = true,
    isEmptyOk = false
) {
    return getStandartValidators(
        validators.isContainsOnlyCharacters(regExpCharacterList),
        false,
        errorText,
        afterBlur,
        isEmptyOk
    )
}

function getStandartValidators(
    functionValidator,
    isNegationFunction,
    errorText,
    afterBlur,
    isEmptyOk
) {
    return value => validate(value, isEmptyOk) ? undefined : {
        params: {
            afterBlur: afterBlur,
        },
        text: errorText,
    }

    function validate(value, isEmptyValidation) {
        if (
            isEmptyValidation
            && (
                typeof isEmptyValidation == 'function'
                    ? isEmptyValidation(value)
                    : !value
            )
        ) {
            return true
        }
        if (isNegationFunction) {
            return !functionValidator(value)
        }
        return functionValidator(value)
    }
}
