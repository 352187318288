import PropertyService from './property'

let selectedProperty = new PropertyService

export const resetSelectedProperty = () => {
    selectedProperty = new PropertyService

    return selectedProperty.serialize
}

export default selectedProperty

export function getCurrentSelectedProperty() {
    return selectedProperty
}
