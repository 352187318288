import React from 'react'

import FormFactory from 'common_components/form'

import styles from './styles.styl'

export default function PostalCodeElement() {
    return (
        <FormFactory.Field
            descriptionTextClassName={styles['postal-code']}
            placeholder="5 digits"
            maxLength={5}
            type="tel"
            className={styles['postal-code-input']}
            name="postal_code"
        />
    )
}
