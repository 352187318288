// import ENVIRONMENT from 'ENVIRONMENT'

import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {Provider} from 'react-redux'

export default function clientRender(routes, store, baseUrl = '/') {
    return renderPage(routes, store, baseUrl)
}

function renderPage(routes, store, baseUrl = '/') {
    if (renderPage.storeUnsubscribes) {
        renderPage.storeUnsubscribes()
    }
    renderReactApp(routes, store, baseUrl)

    // if (ENVIRONMENT.indexOf('local') != -1) {
    //     renderPage.storeUnsubscribes = store.subscribe(() => renderReactApp(routes, store, baseUrl))
    // }
}

function renderReactApp(routes, store, baseUrl = '/') {
    ReactDOM.render(
        <Provider store={store}>
            <Router basename={baseUrl}>
                <Switch>
                    {routes}
                </Switch>
            </Router>
        </Provider>,
        document.getElementById('react-app')
    )
}
