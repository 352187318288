import Reducer from './reducer'

import actionTypes from '../actions/action_types'

import buyerSearchService from 'common_services/buyer/buyer_search'
import buyersListService from 'common_services/buyer/buyers_list'

const INITIAL_STATE = {
    buyersList: buyersListService.serialize,
    searchResults: buyerSearchService.serialize,
}

const buyerReducer = new Reducer(INITIAL_STATE)

buyerReducer.add(actionTypes.buyer.buyerList)
buyerReducer.add(actionTypes.buyer.loadBuyersPage, 'buyersList')
buyerReducer.add(actionTypes.buyer.changePageSize, 'buyersList')
buyerReducer.add(actionTypes.buyer.loadBuyerCounts, 'buyersList')

buyerReducer.add(actionTypes.buyer.getBuyer, 'get_buyer')
buyerReducer.add(actionTypes.buyer.saveBuyer, 'save_buyer')
buyerReducer.resetField(actionTypes.buyer.resetSelectedBuyer, 'get_buyer')
buyerReducer.add(actionTypes.buyer.createBuyerContact, 'add_contact')
buyerReducer.add(actionTypes.buyer.updateBuyerContact, 'update_contact')
buyerReducer.add(actionTypes.buyer.createBuyerActivity, 'add_activity')
buyerReducer.add(actionTypes.buyer.updateBuyerActivity, 'update_activity')
buyerReducer.add(actionTypes.buyer.assignBuyerAgent, 'actions')
buyerReducer.add(actionTypes.buyer.loadSearchingBuyers, 'searchResults')
buyerReducer.add(actionTypes.buyer.deleteBuyerContact)
buyerReducer.add(actionTypes.buyer.deleteBuyerActivity)

export default buyerReducer.reducersFunction

export {buyerReducer}
