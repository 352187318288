import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FormFactory from 'common_components/form'

import analyticsService from 'common_services/analytics'

import Separator from 'adminProj/components/separator'

import styles from './styles.styl'

function FeedbackForm({
    handleSubmit,
    reset: resetForm,
    submitting,

    onCancelClick,
}) {
    return (
        <form onSubmit={handleSubmit(sendFeedBack)}>
            <p className="proxima-medium text-lg text-dark-grey-blue my-zero">
                Send Feedback
            </p>
            <p className="proxima text-sm text-dark-grey-blue mt-xs mb-zero">
                Share your thoughts about Raven.
            </p>
            <FormFactory.Textarea
                name="feedback_message"
                placeholder="any feedback is welcome"
                className={cn(styles['feedback-message'], 'mt-sm-md mb-zero')}
                autoSize
            />
            <div className={cn(styles['feedback-emoji-list'], 'flex align-center just-center mt-sm-md')}>
                <FormFactory.Radio
                    name="feedback_emoji"
                    lableClassName={cn(styles['feedback-emoji-radio'], 'pointer')}
                    lableText="😍"
                    value="heart"
                />
                <FormFactory.Radio
                    name="feedback_emoji"
                    lableClassName={cn(styles['feedback-emoji-radio'], 'pointer')}
                    lableText="🙂"
                    value="smile"
                />
                <FormFactory.Radio
                    name="feedback_emoji"
                    lableClassName={cn(styles['feedback-emoji-radio'], 'pointer')}
                    lableText="😕"
                    value="frown"
                />
            </div>
            <Separator.sm className="invert-mx-md mt-sm-md" />
            <div className="invert-mb-sm mt-sm flex align-center space-between">
                <FormFactory.Button.small
                    title="SEND FEEDBACK"
                    type="submit"
                    isWithoutBold
                    disabled={submitting}
                />
                {
                    !!onCancelClick && (
                        <FormFactory.Button.text
                            title="CANCEL"
                            className="proxima-medium text-13 text-turquoise-blue letter-sm"
                            onClick={onCancelClick}
                            disabled={submitting}
                        />
                    )
                }
            </div>
        </form>
    )

    async function sendFeedBack({feedback_message, feedback_emoji}) {
        await analyticsService.track('Feedback Widget Submitted', {feedback_message, feedback_emoji})

        resetForm()
    }
}

FeedbackForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,

    onCancelClick: PropTypes.func,
}

export default FormFactory.registerForm(
    FeedbackForm,
    {
        form: 'FeedbackForm',
    },
    () => ({
        initialValues: {
            feedback_emoji: 'heart',
        },
    }),
)
