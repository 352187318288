import React from 'react'
import {ToastContainer, toast} from 'react-toastify'
import cn from 'classnames'

import Close from './components/close'

import 'react-toastify/dist/ReactToastify.css'

import styles from './styles.styl'

export default function Toast() {
    return (
        <ToastContainer
            autoClose={5000}
            position="top-center"
            className={styles['toast-container']}
            toastClassName={styles.toast}
            hideProgressBar
            closeButton={
                <Close />
            }
        />
    )
}

Toast.show = toast
Toast.show.success = (content, options) => toast(content, {
    type: toast.TYPE.SUCCESS,
    className: cn(styles.toast, styles.success),
    ...options,
})
Toast.show.warning = (content, options) => toast(content, {
    type: toast.TYPE.WARNING,
    className: cn(styles.toast, styles.warning),
    ...options,
})
Toast.show.error = (content, options) => toast(content, {
    type: toast.TYPE.ERROR,
    className: cn(styles.toast, styles.error),
    ...options,
})
