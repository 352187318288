import API_DOMAINS from 'API_DOMAINS'

import classDeprecated from 'common_utils/class_deprecated'

import Api from './api'
import {UserService} from './user'

import {getDefaultFilters} from 'common_utils/create_backend_filters'

const buyerApi = new Api(API_DOMAINS.leadsApi, 'buyers')
const buyerContactApi = new Api(API_DOMAINS.leadsApi, 'buyers-contacts')
const buyerActivityApi = new Api(API_DOMAINS.leadsApi, 'buyers-activities')
const actionsApi = new Api(API_DOMAINS.leadsApi, 'buyers', 'actions')

const defaultFilters = getDefaultFilters()

@classDeprecated('Please use common_services/buyer/buyer')
class BuyerService {
    getBuyerDetail(uuid) {
        return buyerApi.getByKey(uuid)
            .then(async res => {
                if (res.data.contacts) {
                    res.data.contacts = res.data.contacts.map(
                        contact => ({
                            ...contact,
                            phone: contact.phone && contact.phone.indexOf('+') == -1
                                ? `+1 ${contact.phone}`
                                : contact.phone,
                        })
                    )
                }
                res.bedrooms = res.bedrooms || 1
                res.bathrooms = res.bathrooms || 1

                res.data.agent = await UserService.getUserByUuid(res.data.agent)

                return res
            })
    }
    buyerList(offset, limit, filters = {}) {
        return buyerApi.get({
            'offset': offset,
            'limit': limit,
            ...filters,
        })
            .then(async res => {
                const agents = await UserService.searchUsers({
                    uuid: res.data.results
                        .map(({agent: buyerAgentUuid}) => buyerAgentUuid)
                        .filter(buyerAgentUuid => !!buyerAgentUuid),
                })

                res.data.results.forEach(buyer =>
                    buyer.agent = agents.find(agent => agent.uuid == buyer.agent)
                )

                return res
            })
    }
    getBuyer(uuid) {
        return buyerApi.getByKey(uuid)
            .then(async res => {
                res.data.agent = await UserService.getUserByUuid(res.data.agent)
                res.data.area = res.data.area || {type: 'MultiPolygon', coordinates: []}
                res.data.bedrooms = res.data.bedrooms || 1
                res.data.bathrooms = res.data.bathrooms || 1
                return res.data
            })
    }
    saveBuyer({
        uuid,
        lead: {
            owner: {
                phone,
                phone2,
                ...owner
            },
            price_range,
            size_range_sqft,
            lot_size_range_sqft,
            year_built_range,
            ...lead
        },
    }) {
        const currentLead = {
            ...lead,
            owner: {
                ...owner,
                phone: phone?.notEmptyPhone || (typeof phone == 'string' ? phone : undefined),
                phone2: phone2?.notEmptyPhone || (typeof phone2 == 'string' ? phone2 : undefined),
            },
            size_range_sqft: {
                low: size_range_sqft.low !== ''
                    ? size_range_sqft.low
                    : defaultFilters.size_range_sqft.low,
                high: size_range_sqft.high !== ''
                    ? size_range_sqft.high
                    : defaultFilters.size_range_sqft.high,
            },
            price_range: {
                low: price_range.low !== ''
                    ? price_range.low
                    : defaultFilters.price_range.low,
                high: price_range.high !== ''
                    ? price_range.high
                    : defaultFilters.price_range.high,
            },
            lot_size_range_sqft: {
                low: lot_size_range_sqft.low !== ''
                    ? lot_size_range_sqft.low
                    : defaultFilters.lot_size_range_sqft.low,
                high: lot_size_range_sqft.high !== ''
                    ? lot_size_range_sqft.high
                    : defaultFilters.lot_size_range_sqft.high,
            },
            year_built_range: {
                low: year_built_range.low !== ''
                    ? year_built_range.low
                    : defaultFilters.year_built_range.low,
                high: year_built_range.high !== ''
                    ? year_built_range.high
                    : defaultFilters.year_built_range.high,
            },
        }

        return uuid ? buyerApi.put(uuid, currentLead) : buyerApi.post(currentLead)
    }
    updateBuyer(buyerUuid, data) {
        return buyerApi.patch(buyerUuid, data)
            .then(async res => {
                res.data.agent = await UserService.getUserByUuid(res.data.agent)

                return res
            })
    }
    createBuyerContact({phone, phone2, ...data}) {
        return buyerContactApi.post({
            ...data,
            phone: phone?.notEmptyPhone || null,
            phone2: phone2?.notEmptyPhone || null,
        })
    }
    updateBuyerContact(uuid, {phone, phone2, ...data}) {
        return buyerContactApi.put(uuid, {
            ...data,
            phone: phone?.notEmptyPhone || null,
            phone2: phone2?.notEmptyPhone || null,
        })
    }
    createBuyerActivity(data) {
        return buyerActivityApi.post(data)
    }
    updateBuyerActivity(uuid, data) {
        return buyerActivityApi.patch(uuid, data)
    }
    deleteBuyer(uuid) {
        return buyerApi.delete(uuid)
    }
    assignBuyerAgent(uuid, agent, action) {
        return actionsApi.put(uuid, {
            action: action,
            agent,
        })
    }
    deleteBuyerContact(uuid) {
        return buyerContactApi.delete(uuid)
    }
    deleteBuyerActivity(uuid) {
        return buyerActivityApi.delete(uuid)
    }
}

export default new BuyerService
