import modelSerialize from 'common_utils/model_serialize'

import MatchesListService from './matches_list'

@modelSerialize([
    'categories',
    'totalCount',
])
class MatchesCategorizedListService {
    static leadTypes = MatchesListService.leadTypes

    categories = {
        new: new MatchesListService('new', 'needs_review_matches'),
        progress: new MatchesListService('progress', 'in_progress_matches'),
        past: new MatchesListService('past', 'past_matches'),
        successful: new MatchesListService('successful', 'past_matches'),
    }

    get totalCount() {
        return this.categories.new.list.length
            + this.categories.progress.list.length
            + this.categories.past.list.length
            + this.categories.successful.list.length
    }

    setLead(leadUUID, leadType) {
        this.categories = {
            new: new MatchesListService('new', 'needs_review_matches'),
            progress: new MatchesListService('progress', 'in_progress_matches'),
            past: new MatchesListService('past', 'past_matches'),
            successful: new MatchesListService('successful', 'past_matches'),
        }

        this.categories.new.setLead(leadUUID, leadType)
        this.categories.progress.setLead(leadUUID, leadType)
        this.categories.past.setLead(leadUUID, leadType)
        this.categories.successful.setLead(leadUUID, leadType)

        return this.serialize
    }

    async loadAllMatchCategories() {
        await Promise.all([
            this.loadNewMatches(),
            this.loadProgressMatches(),
            this.loadPastMatches(),
            this.loadSuccessfulMatches(),
        ])

        return this.serialize
    }

    async loadNewMatches() {
        await this.categories.new.loadNextPage()

        return this.serialize
    }

    async loadProgressMatches() {
        await this.categories.progress.loadNextPage()

        return this.serialize
    }

    async loadPastMatches() {
        await this.categories.past.loadNextPage()

        return this.serialize
    }

    async loadSuccessfulMatches() {
        await this.categories.successful.loadNextPage()

        return this.serialize
    }
}

export default new MatchesCategorizedListService
