import qs from 'qs'

import http, {createCancelSource} from './http'

export default class Api {
    constructor(domain, apiRootName, apiName, version = 1) {
        this.domain = domain
        this.apiRootName = apiRootName
        this.apiName = apiName
        this.version = version
        this.domainWithVersion = `${this.domain}${version ? `/api/v${version}` : ''}`
        this.createUrl = createUrl.bind(null, this.domainWithVersion, this.apiRootName, this.apiName)
    }

    getApiWithName(apiName) {
        return new Api(this.domain, this.apiRootName, apiName, this.version)
    }

    @apiRequst(2)
    get(params, config = {}) {
        return http.get(this.createUrl(null, params), config)
    }

    getByKey(key, params = {}, config = {}) {
        return http.get(this.createUrl(key, params), config)
    }

    getById(params = {}, config = {}, ...ids) {
        if (typeof config !== 'object' || Array.isArray(config)) {
            ids.unshift(config)
            config = null
        }
        if (typeof params !== 'object' || Array.isArray(params)) {
            ids.unshift(params)
            params = null
        }
        const currentParams = {
            ...params,
            id: ids.reduce((current, el) => current.concat(el), []),
        }
        currentParams.length = currentParams.id.length

        return this.get(currentParams, config)
    }

    patch(key, data, config = {}) {
        return http.patch(this.createUrl(key), data, config)
    }

    @apiRequst(2)
    post(data, config = {}) {
        return http.post(this.createUrl(), data, config)
    }

    put(key, data, config = {}) {
        return http.put(this.createUrl(key), data, config)
    }

    delete(key, config = {}) {
        return http.delete(this.createUrl(key), config)
    }

    http(config, params) {
        return http(config)
    }
}

export function createUrl(domain, apiRootName, apiName, key, params) {
    const paramsStr = qs.stringify({
        ...params,
    }, {
        indices: false,
    })
    let url = `${domain}/${apiRootName}/${apiName ? `${apiName}/` : ''}${key ? `${key}/` : ''}${paramsStr ? `?${paramsStr}` : ''}`

    if (url.lastIndexOf('/') == url.length - 1 && url.indexOf('stripe') != -1) {
        url = url.substr(0, url.length - 1)
    }

    return url
}

function apiRequst(argumentNumber) {
    return (target, property, descriptor) => {
        const originalMethod = descriptor.value

        descriptor.value = function(...args) {
            const source = createCancelSource()
            const config = args[argumentNumber - 1] = args[argumentNumber - 1] || {}

            config.cancelToken = (config.cancelSource || source).token

            const promise = originalMethod.call(this, ...args)
            promise.cancel = (config.cancelSource || source).cancel

            return promise
        }

        return descriptor
    }
}
