import billingPlansList from 'common_services/billing/billing_plans_list'
import customerService from 'common_services/billing/customer'

import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {
    ...billingPlansList.serialize,
    customer: customerService.serialize,
    selectedBillingPlan: null,
}

const billingReducer = new Reducer(INITIAL_STATE)

billingReducer.add(actionTypes.billing.loadAllBillingPlans)
billingReducer.add(actionTypes.billing.loadStripePlanGroups)
billingReducer.add(actionTypes.billing.loadBillingPlanById, 'selectedBillingPlan')

billingReducer.add(actionTypes.billing.addCustomerCard, 'customer')
billingReducer.add(actionTypes.billing.removeCustomerCard, 'customer')
billingReducer.add(actionTypes.billing.loadCustomer, 'customer')
billingReducer.add(actionTypes.billing.loadBillingHistory, 'customer')
billingReducer.add(actionTypes.billing.loadUpcomingInvoice, 'customer')
billingReducer.add(actionTypes.billing.getSetupIntentToken, 'customer')
billingReducer.add(actionTypes.billing.subscribeToPlan, 'customer')
billingReducer.add(actionTypes.billing.cancelSubscription, 'customer')
billingReducer.add(actionTypes.billing.addCouponToSubscription, 'customer')
billingReducer.add(actionTypes.billing.removeCouponFromSubscription, 'customer')
billingReducer.add(actionTypes.billing.markCardAsAuthenticated, 'customer')
billingReducer.add(actionTypes.billing.markCardAsNotAuthenticated, 'customer')

billingReducer.add(actionTypes.billing.getCouponById, 'selectedCoupon')
billingReducer.resetField(actionTypes.billing.removeSelectedCoupon, 'selectedCoupon')

export default billingReducer.reducersFunction
