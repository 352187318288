import selectedMatch from 'common_services/matches/selected_match'
import MatchesCategorizedListService from 'common_services/matches/matches_categorized_list'
import MatchesListService from 'common_services/matches/matches_list'
import portalMatchesList from 'common_services/matches/portal_matches_list'

import actionTypes from '../actions/action_types'

import Reducer from './reducer'

const INITIAL_STATE = {
    selectedMatch: selectedMatch.serialize,
    matchesCategorizedList: MatchesCategorizedListService.serialize,
    matchesList: MatchesListService.serialize,
    portalMatchesList: portalMatchesList.serialize,
}

const matchesReducer = new Reducer(INITIAL_STATE)

matchesReducer.add(actionTypes.matches.loadMatchByUUID, 'selectedMatch')
matchesReducer.add(actionTypes.matches.loadPublicMatchByUUID, 'selectedMatch')
matchesReducer.add(actionTypes.matches.resetSelectedMatch, 'selectedMatch')
matchesReducer.add(actionTypes.matches.selectedMatchAction, 'selectedMatch')
matchesReducer.add(actionTypes.matches.matchReadAction, 'selectedMatch')

matchesReducer.add(actionTypes.matches.matchesCategorizedList.setLead, 'matchesCategorizedList')
matchesReducer.add(actionTypes.matches.matchesCategorizedList.loadAllMatchCategories, 'matchesCategorizedList')
matchesReducer.add(actionTypes.matches.matchesCategorizedList.loadNewMatches, 'matchesCategorizedList')
matchesReducer.add(actionTypes.matches.matchesCategorizedList.loadProgressMatches, 'matchesCategorizedList')
matchesReducer.add(actionTypes.matches.matchesCategorizedList.loadPastMatches, 'matchesCategorizedList')
matchesReducer.add(actionTypes.matches.matchesCategorizedList.loadSuccessfulMatches, 'matchesCategorizedList')

matchesReducer.add(actionTypes.matches.matchesList.setLead, 'matchesList')
matchesReducer.add(actionTypes.matches.matchesList.loadMatchesPage, 'matchesList')
matchesReducer.add(actionTypes.matches.matchesList.loadPublicMatchesPage, 'matchesList')
matchesReducer.add(actionTypes.matches.matchesList.changePageSize, 'matchesList')

matchesReducer.addLocalAction(actionTypes.matches.setPortalMatchesLead, 'portalMatchesList')
matchesReducer.add(actionTypes.matches.loadPortalMatchesPage, 'portalMatchesList')

matchesReducer.add(actionTypes.matches.createMatchBetweenPropertyAndBuyerAutoShare)
matchesReducer.add(actionTypes.matches.sendPublicMatchAction)

export default matchesReducer.reducersFunction

export {matchesReducer}
