import API_DOMAINS from 'API_DOMAINS'

import {oneLine} from 'common-tags'
import {deprecate} from 'core-decorators'

import {UserService} from 'common_services/user'
import MlsAgentService from 'common_services/mls_agents/mls_agent'
import {FollowUsBossIntegration} from 'common_services/follow_us_boss_integration'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'

const mlsDataApi = new Api(API_DOMAINS.leadsApi, 'mls-data', 'mlsproperties', 2)
const oldPropertiesApi = new Api(API_DOMAINS.leadsApi, 'properties')
const propertiesApi = new Api(API_DOMAINS.leadsApi, 'properties', undefined, 2)
const prePropertyApi = new Api(API_DOMAINS.leadsApi, 'pre_property', null, 2)
const propertiesActionsApi = new Api(API_DOMAINS.leadsApi, 'properties', 'actions')
const propertyContactApi = new Api(API_DOMAINS.leadsApi, 'properties-contacts')
const propertyActivityApi = new Api(API_DOMAINS.leadsApi, 'properties-activities')

export const PROPERTY_AUTO_SHARED_WITH_STATUSES = {
    nobody: 'nobody',
    brokerage: 'brokerage',
    all: 'all',
}

@modelSerialize([
    'address',
    'formattedAddress',
    'activities',
    'tax_address',
    'bathrooms',
    'bedrooms',
    'contacts',
    'location',
    'lot_size_sqft',
    'size_sqft',
    'mls_id',
    'mls_status',
    'mls_vendor',
    'photos',
    'price',
    'year_built',
    'uuid',
    'owner',
    'additional_info',
    'address_json',
    'agent',
    'agents',
    'mls_agent',
    'mls_co_agent',
    'category',
    'city',
    'date_created',
    'date_last_synced',
    'date_updated',
    'last_activity',
    'lead_source',
    'lead_status',
    'is_matching_active',
    {
        serializeField: 'auto_shared_with',
        originField: 'auto_shared_with',
        convertValue: auto_shared_with => (
            auto_shared_with == null
                ? PROPERTY_AUTO_SHARED_WITH_STATUSES.nobody
                : auto_shared_with
        ),
    },
    'purpose_of_request',
    'sale_history',
    'team',
    'timeframe',
    'type',
    'mlsData',
    'matches_count',
    'new_matches_count',
    'in_progress_matches_count',
    'past_matches_count',
    'notes',
    'match_category',
    'is_public',
    'share_link',
    'keywords',
    'is_matching_allowed',
    'is_ownership_verified',
    'is_raw',
    'used_sources',
    'listing_agents',
    'mls_originating_system',
    'hoa',
    'remarks',
    'stories',
    'subtype',
    'raven_listing_status',
    'mls_provider',
    'partners',
])
class PropertyService {
    address = ''
    activities = []
    tax_address = ''
    bathrooms = 0
    bedrooms = 0
    contacts = []
    location = null
    lot_size_sqft = 0
    size_sqft = 0
    mls_id = null
    mls_status = ''
    mls_vendor = ''
    photos = []
    price = 0
    year_built = null
    uuid = null
    owner = null
    additional_info = null
    address_json = null
    agent = null
    agents = []
    mls_agent = null
    mls_co_agent = null
    category = null
    city = null
    date_created = null
    date_last_synced = null
    date_updated = null
    last_activity = null
    lead_source = null
    lead_status = null
    is_matching_active = false
    auto_shared_with = PROPERTY_AUTO_SHARED_WITH_STATUSES.nobody
    purpose_of_request = null
    sale_history = null
    team = null
    timeframe = null
    type = null
    mlsData = null
    matches_count = 0
    new_matches_count = 0
    in_progress_matches_count = 0
    past_matches_count = 0
    notes=''
    match_category=''
    is_public = false
    share_link = null
    keywords = []
    is_matching_allowed = false
    is_ownership_verified = false
    is_raw = false
    used_sources = []
    listing_agents = []
    mls_originating_system = null
    hoa = null
    remarks = null
    stories = null
    subtype = null
    raven_listing_status = null
    mls_provider = null
    partners = []

    constructor(data) {
        if (data) {
            if (data.contacts) {
                data.contacts = data.contacts.map(
                    contact => ({
                        ...contact,
                        phone: contact.phone && contact.phone.indexOf('+') == -1
                            ? `+1 ${contact.phone}`
                            : contact.phone,
                    })
                )
            }

            this.serialize = data
        }
    }

    get formattedAddress() {
        const {address_json, address} = this

        if (
            address_json
                && address_json.constructor === Object
                && Object.keys(address_json).length !== 0
        ) {
            const {
                street_number = '',
                street_name = '',
                unit = '',
                neighborhood = '',
                city = '',
                state = '',
                zipcode = '',
            } = address_json

            return oneLine`
                ${street_number || ''}
                ${street_name || ''}
                ${street_number || street_name ? ',' : ''}
                ${unit ? `Unit #${unit}` : ''}
                ${neighborhood || ''}
                ${unit || neighborhood ? ',' : ''}
                ${city && city != neighborhood ? `${city}, ` : ''}
                ${state || ''}
                ${zipcode || ''}
            `
                .trim()
                .replace(/\s+/g, ' ')
                .replace(/\s,\s/g, ', ')
        }

        return address
    }

    async save(newData) {
        const dataToSave = this.createDataToSave({
            ...this,
            ...newData,
        })

        const {agent: currentAgetn} = this
        const currentUUID = this.uuid || newData.uuid

        this.serialize = (await (
            currentUUID
                ? propertiesApi.put(currentUUID, dataToSave)
                : propertiesApi.post(dataToSave)
        )).data

        if (currentAgetn?.uuid != this.agent) {
            await this.loadAllAgent()
        }
        else {
            this.agent = currentAgetn
        }

        return this.serialize
    }

    async updateSomeFields(newData, forceUpdateInstance = false) {
        const dataToSave = this.createDataToSave(newData, true)
        const {agents: currentAgents, agent: currentPrimaryAgent} = this

        if (forceUpdateInstance) {
            this.serialize = dataToSave

            propertiesApi.patch(this.uuid, dataToSave)
        }
        else {
            this.serialize = (await propertiesApi.patch(this.uuid, dataToSave)).data

            if (
                this.agents
                    .every(({agent}) =>
                        [...currentAgents]
                            .concat(currentPrimaryAgent ? {agent: currentPrimaryAgent} : [])
                            .find(currentAgent => currentAgent.agent.uuid == agent)
                    )
            ) {
                this.agent = currentPrimaryAgent
                this.agents = currentAgents
            }
            else {
                await this.loadAllAgent()
            }
        }

        return this.serialize
    }

    async loadPropertyByUUID(uuid, config) {
        this.serialize = await PropertyService.getPropertyByUUID(uuid || this.uuid, config)

        return this.serialize
    }

    async loadPublicPropertyByUUID(uuid) {
        this.serialize = await PropertyService.getPublicPropertyByUUID(uuid || this.uuid)

        if (this.agent) {
            this.agent = await UserService.getPublicUserInfoByUuid(this.agent)
        }
        if (this.mls_agent) {
            this.mls_agent = await MlsAgentService.getPublicMlsAgentByUUID(this.mls_agent)
        }

        return this.serialize
    }

    async loadPublicSearchPropertyByUUID(uuid) {
        this.serialize = await PropertyService.getPublicSearchPropertyByUUID(uuid || this.uuid)

        try {
            if (this.agent) {
                this.agent = await UserService.getPublicUserInfoByUuid(this.agent)
            }
            if (this.mls_agent) {
                this.mls_agent = await MlsAgentService.getPublicMlsAgentByUUID(this.mls_agent)
            }
        }
        catch {} // eslint-disable-line no-empty

        return this.serialize
    }

    async loadMLSpropertiesData(config) {
        if (this.mls_id) {
            this.mlsData = await PropertyService.getMLSpropertiesDataById(this.mls_id, config)
        }

        return this.serialize
    }

    async loadAllAgent() {
        const newAgentsList = []
            .concat(
                typeof this.agent == 'string' ? this.agent : [],
                this.agents.map(({agent: agentUUID}) => typeof agentUUID == 'string' ? agentUUID : null),
                this.partners.map(({user: userUUID}) => typeof userUUID == 'string' ? userUUID : null),
                this.activities.map(({agent: agentUUID}) => typeof agentUUID == 'string' ? agentUUID : null)
            )
            .filter(el => !!el)

        const propertyAgents = newAgentsList.length
            ? await UserService.searchUsers({uuid: newAgentsList})
            : []

        propertyAgents.forEach(currentAgent => {
            if (currentAgent.uuid == this.agent || currentAgent.uuid == this.agent?.uuid) {
                this.agent = currentAgent
            }

            const currentCoAgentIndex = this.agents.findIndex(coAgent => (
                currentAgent.uuid == coAgent.agent || currentAgent.uuid == coAgent.agent?.uuid
            ))

            if (currentCoAgentIndex != -1) {
                this.agents[currentCoAgentIndex].agent = currentAgent
            }

            const currentPartnerIndex = this.partners.findIndex(partner => (
                currentAgent.uuid == partner.user || currentAgent.uuid == partner.user?.uuid
            ))

            if (currentPartnerIndex != -1) {
                this.partners[currentPartnerIndex].user = currentAgent
            }
        })

        this.activities = this.activities.map(activity => ({
            ...activity,
            agent: typeof activity.agent == 'string'
                ? propertyAgents.find(currentAgent => currentAgent.uuid == activity.agent) || activity.agent
                : activity.agent,
        }))

        this.agents = this.agents
            .filter(coagent => coagent.agent && !coagent.is_primary)

        return this.serialize
    }

    async loadAllMlsAgent() {
        const [mlsAgent, mlsCoAgent] = await Promise.all(
            [this.mls_agent, this.mls_co_agent]
                .map(mlsAgentUUID => typeof mlsAgentUUID == 'string' ? mlsAgentUUID : null)
                .map(mlsAgentUUID => mlsAgentUUID ? MlsAgentService.getPublicMlsAgentByUUID(mlsAgentUUID) : null)
        )

        this.mls_agent = mlsAgent || this.mls_agent
        this.mls_co_agent = mlsCoAgent || this.mls_co_agent

        const newAgentsList = [this.mls_agent, this.mls_co_agent]
            .filter(el => !!el)
            .map(({raven_agent}) => raven_agent)
            .map(agentUUID => typeof agentUUID == 'string' ? agentUUID : null)
            .filter(el => !!el)
        const agents = newAgentsList.length
            ? await UserService.searchUsers({uuid: newAgentsList})
            : []

        agents.forEach(currentAgent => {
            if (currentAgent.uuid == this.mls_agent?.raven_agent) {
                this.mls_agent.raven_agent = currentAgent
            }
            else if (currentAgent.uuid == this.mls_co_agent?.raven_agent) {
                this.mls_co_agent.raven_agent = currentAgent
            }
        })

        return this.serialize
    }

    async loadAllPhotoAgents() {
        const photoAgentsList = this.photos
            .map(({agent: agentUUID}) => typeof agentUUID == 'string' ? agentUUID : null)
            .filter(el => !!el)

        const photoAgents = photoAgentsList.length
            ? await UserService.searchUsers({uuid: photoAgentsList})
            : []

        this.photos.forEach(currentPhoto => {
            const currentPhotoAgent = photoAgents.filter(agent => currentPhoto.agent == agent.uuid)
            currentPhoto.agent = currentPhotoAgent.length ? currentPhotoAgent[0] : currentPhoto.agent
        })

        return this.serialize
    }

    setAllMatchesToRead() {
        return this.sendPropertyAction('property_matches_bulk_read')
    }

    shareProperty() {
        return this.sendPropertyAction('get_property_share_link')
    }

    toggleSharePropertyLink() {
        return this.is_public
            ? this.sendPropertyAction('disable_share_link')
            : this.sendPropertyAction('enable_share_link')
    }

    createFollowUsBossIntegrationObject(personID) {
        return this.sendPropertyAction(
            'add_integration',
            {
                name: FollowUsBossIntegration.INTEGRATION_NAME,
                key: personID,
            }
        )
    }

    removeFollowUsBossIntegrationObject(personID) {
        return this.sendPropertyAction(
            'remove_integration',
            {
                name: FollowUsBossIntegration.INTEGRATION_NAME,
                key: personID,
            }
        )
    }

    async changeCoAgents(newCoAgentsList) {
        const coAgents = this.agents

        const listOfChangesInCoAgents = Object.keys(newCoAgentsList).reduce(
            (currentList, nextAgentUuid) => {
                if (newCoAgentsList[nextAgentUuid]) {
                    currentList.addCoAgents.push({agent: nextAgentUuid})
                }
                else {
                    currentList.removeCoAgents.push({agent: nextAgentUuid})
                }

                return currentList
            },
            {
                addCoAgents: [],
                removeCoAgents: [],
            }
        )

        await Promise.all(
            []
                .concat(
                    listOfChangesInCoAgents.addCoAgents.length
                        ? this.sendPropertyAction('add_co_agents', {agents: listOfChangesInCoAgents.addCoAgents})
                        : []
                )
                .concat(
                    listOfChangesInCoAgents.removeCoAgents.length
                        ? this.sendPropertyAction('remove_co_agents', {agents: listOfChangesInCoAgents.removeCoAgents})
                        : []
                )
        )

        this.agents = this.agents.map(({agent: agentUUID, ...data}) => ({
            ...data,
            agent: coAgents.find(({agent}) => agent.uuid == agentUUID)?.agent || agentUUID,
        }))

        return this.loadAllAgent()
    }

    @keepCoAgents
    async sendPropertyAction(actionName, params) {
        this.serialize = (await propertiesApi.getApiWithName('actions').put(this.uuid, {
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    async deleteProperty() {
        this.serialize = (await propertiesApi.delete(this.uuid)).data

        return this.serialize
    }

    async loadPropertyFromMLS(address, place_id, unit, zipcode) {
        this.serialize = await (await prePropertyApi.get({address, place_id, unit, zipcode})).data

        return this.serialize
    }

    async loadAllListingAgents() {
        await this.loadAllMlsAgent()

        const listingAgents = [this.mls_agent, this.mls_co_agent]
            .filter(el => !!el)
            .map(agent => {
                const mlsAgent = agent.raven_agent && typeof agent.raven_agent != 'string'
                    ? agent.raven_agent
                    : agent

                return mlsAgent.firstName && mlsAgent.lastName
                    ? mlsAgent
                    : null
            })
            .filter(el => !!el)

        const mlsAgentsIds = [].concat(
            ...listingAgents.map(agent => ('mls_ids' in agent ? agent.mls_ids : agent.mlsIds))
        )

        const newAgentsList = this.agents
            .map(ravenAgent => typeof ravenAgent.agent == 'string' ? null : ravenAgent.agent)
            .filter(el => !!el)
            .filter(ravenAgent => (
                ravenAgent._isHaveVerifiedMlsId || !(ravenAgent.mlsIds.filter(({mls_id}) => (
                    !!mlsAgentsIds.find(id => (
                        'mls_id' in id
                            ? mls_id == id.mls_id
                            : mls_id == id.name
                    ))
                ))).length
            ))

        this.listing_agents = listingAgents.concat(newAgentsList)

        return this.serialize
    }

    createDataToSave({owner, agent, mls_agent, mls_co_agent, unit, photos, ...lead}, partialData = false) {
        const phone = owner?.phone
        const phone2 = owner?.phone2
        const currentLead = {
            ...lead,
            unit: unit || undefined,
            agent: typeof agent == 'object' ? agent?.uuid : agent,
            mls_agent: typeof mls_agent == 'object' ? mls_agent?.uuid : mls_agent,
            mls_co_agent: typeof mls_co_agent == 'object' ? mls_co_agent?.uuid : mls_co_agent,
            owner: owner
                ? {
                    ...owner,
                    phone: phone?.notEmptyPhone || (typeof phone == 'string' ? phone : undefined),
                    phone2: phone2?.notEmptyPhone || (typeof phone2 == 'string' ? phone2 : undefined),
                }
                : undefined,
            photos: photos
                ? photos
                    .map(
                        photoObj => photoObj.agent && typeof photoObj.agent == 'object'
                            ? {
                                ...photoObj,
                                agent: photoObj.agent.uuid,
                            }
                            : photoObj
                    )
                    .map((photoObj, idx) => ({
                        ...photoObj,
                        order: idx,
                    }))
                : photos,
        }

        if (
            !currentLead.owner
                || (
                    currentLead.owner
                        && Object.keys(currentLead.owner).filter(objKey => !!currentLead.owner[objKey]).length == 0
                )
        ) {
            delete currentLead.owner
        }
        if (partialData) {
            Object.keys(currentLead).forEach(key => {
                if (currentLead[key] === undefined) {
                    delete currentLead[key]
                }
            })
        }

        return currentLead
    }

    sendMessageToAgent({
        full_name,
        phone,
        email,
        message,
        propertyUUID,
        buyerUUID,
        agentUUID,
    }) {
        // return Promise.resolve(this.serialize)

        return PropertyService.sendCustomerActions(
            propertyUUID || this.uuid,
            'contact_agent',
            {
                full_name,
                phone,
                email,
                message,
                buyer: buyerUUID || undefined,
                recipient: agentUUID || undefined,
            }
        )
    }

    static async sendCustomerActions(propertyUUID, actionName, params) {
        this.serialize = (await oldPropertiesApi.getApiWithName('customer-actions').put(propertyUUID, {
            action: actionName,
            ...params,
        })).data

        return this.serialize
    }

    static async getPropertyFromMLS(address, place_id, unit, zipcode) {
        return new PropertyService(PropertyService.getPrePropertyFromMLS(address, place_id, unit, zipcode))
    }

    static async getPrePropertyFromMLS(address, place_id, unit, zipcode) {
        return (await prePropertyApi.get({address, place_id, unit, zipcode})).data
    }

    static async assignPropertyAgent(propertyUuid, agentUuid) {
        return propertiesActionsApi.put(propertyUuid, {
            action: agentUuid ? 'assign_agent' : 'un_assign_agent',
            agent: agentUuid,
        })
    }

    static async getPropertyByUUID(uuid, config) {
        return new PropertyService((await propertiesApi.getByKey(uuid, undefined, config)).data)
    }

    static async getPublicPropertyByUUID(uuid) {
        return new PropertyService((await propertiesApi.getApiWithName('shared').getByKey(uuid)).data)
    }

    static async getMLSpropertiesDataById(mls_id, config) {
        return (await mlsDataApi.getByKey(mls_id, undefined, config)).data.extra_details
    }

    static async getPublicSearchPropertyByUUID(propertyUUID) {
        return new PropertyService(
            (await propertiesApi.getApiWithName('search-portal/shared').getByKey(propertyUUID)).data
        )
    }

    @deprecate('The method (PropertyService.createPropertyContact) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static createPropertyContact({phone, phone2, ...data}) {
        return propertyContactApi.post({
            ...data,
            phone: phone?.notEmptyPhone || null,
            phone2: phone2?.notEmptyPhone || null,
        })
    }
    @deprecate('The method (PropertyService.updatePropertyContact) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static updatePropertyContact(uuid, {phone, phone2, ...data}) {
        return propertyContactApi.put(uuid, {
            ...data,
            phone: phone?.notEmptyPhone || null,
            phone2: phone2?.notEmptyPhone || null,
        })
    }
    @deprecate('The method (PropertyService.deletePropertyContact) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static deletePropertyContact(uuid) {
        return propertyContactApi.delete(uuid)
    }
    @deprecate('The method (PropertyService.createPropertyActivity) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static createPropertyActivity(data) {
        return propertyActivityApi.post(data)
    }
    @deprecate('The method (PropertyService.updatePropertyActivity) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static updatePropertyActivity(uuid, data) {
        return propertyActivityApi.patch(uuid, data)
    }
    @deprecate('The method (PropertyService.deletePropertyActivity) is deprecated. It needs to be updated. You need to use an instance of the class') // eslint-disable-line max-len
    static deletePropertyActivity(uuid) {
        return propertyActivityApi.delete(uuid)
    }
}

export default PropertyService

function keepCoAgents(target, property, descriptor) {
    const originalMethod = descriptor.value

    descriptor.value = async function(...args) {
        const {
            agents: currentAgents,
            agent: currentPrimaryAgent,
            partners: currentPartners,
            activities,
        } = this
        const oldActivitiesAgents = activities
            .map(({agent}) => agent)
            .filter(Boolean)

        await originalMethod.call(this, ...args)

        let newActivitiesAgentsUUID = this.activities.map(({agent}) => agent)

        if (newActivitiesAgentsUUID.some(agent => !(typeof agent == 'string'))) {
            newActivitiesAgentsUUID = newActivitiesAgentsUUID
                .map(agent => agent?.uuid)
                .filter(Boolean)
        }

        if (
            JSON.stringify(
                [currentPrimaryAgent?.uuid, ...currentAgents.map(({agent: {uuid}}) => uuid)]
                    .filter(Boolean)
                    .sort()
            )
                == JSON.stringify(this.agents.map(({agent}) => agent).sort())
                && (
                    JSON.stringify(currentPartners.map(({user: {uuid}}) => uuid).sort())
                        == JSON.stringify(this.partners.map(({user}) => user).sort())
                )
                && (
                    JSON.stringify(oldActivitiesAgents.map(({uuid}) => uuid).sort())
                        == JSON.stringify(newActivitiesAgentsUUID.sort())
                )
        ) {
            this.agent = currentPrimaryAgent
            this.agents = currentAgents
            this.partners = currentPartners
            this.activities = this.activities.map(activity => ({
                ...activity,
                agent: typeof activity.agent == 'string'
                    ? oldActivitiesAgents.find(currentAgent => currentAgent.uuid == activity.agent)
                        || activity.agent
                    : activity.agent,
            }))
        }
        else {
            await this.loadAllAgent()
        }

        return this.serialize
    }

    return descriptor
}
