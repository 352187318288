import 'scrollingelement'

import clientRender from 'common_utils/client_render'

import store from 'adminProj/store'

import {setProjectStoreDispatch} from 'common_services/http'
import routes from './routes'
import './raven'
import commonMainStyles from 'common_styles/main.styl' // eslint-disable-line no-unused-vars
import projectMainStyles from 'admin/styles/main.styl' // eslint-disable-line no-unused-vars

setProjectStoreDispatch(store.dispatch)

clientRender(routes, store)
