import {createNewPortalPropertiesListService} from 'common_services/properties2/portal_properties_list'
import {createNewSearchPropertiesListService} from 'common_services/properties2/portal_search_properties_list'

import createAction, {createSimpleAction} from '../create_action'
import actionTypes from '../action_types'

import {createCancelSource} from 'common_services/http'

const portalPropertiesListServiceForMap = createNewPortalPropertiesListService(100, false, false)
const searchPropertiesListServiceForMap = createNewSearchPropertiesListService(100, false, false)

export default {
    setLead(portalBuyerUUID, keepPreviousList) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesListForMap.setLead,
            action: () => portalPropertiesListServiceForMap.setLead(portalBuyerUUID, keepPreviousList),
        })
    },

    loadPortalPropertiesPage(page, {ordering, search, filters} = {}, pageSize, isBuyerPortal) {
        return createAction({
            actionTypeSpace: actionTypes.properties2.portalPropertiesListForMap.loadPortalPropertiesPage,
            action: () => {
                const cancelSource = createCancelSource()

                const propertiesListService = isBuyerPortal
                    ? portalPropertiesListServiceForMap
                    : searchPropertiesListServiceForMap

                const promise = propertiesListService.loadPage(
                    page,
                    {ordering, search, filters},
                    pageSize,
                    {cancelSource}
                )

                promise.cancel = cancelSource.cancel

                return promise
            },
        })
    },

    localUpdatePortalPropertyInList(newProperty) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesListForMap.localUpdatePortalPropertyInList,
            action: () => newProperty,
            createPayload: () => portalPropertiesListServiceForMap.updateObjectInList(newProperty),
        })
    },

    localRemovePortalPropertyInList(propertyUUID) {
        return createSimpleAction({
            actionType: actionTypes.properties2.portalPropertiesListForMap.localRemovePortalPropertyInList,
            action: () => propertyUUID,
            createPayload: () => portalPropertiesListServiceForMap.removeFromList({
                uuid: propertyUUID,
            }),
        })
    },
}

export {portalPropertiesListServiceForMap}
