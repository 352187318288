import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../../api'

import {UserService} from '../index'

const teamsApi = new Api(API_DOMAINS.usersApi, 'teams')

@modelSerialize([
    'uuid',
    'name',
    'members',
    'membersInvite',
])
class TeamService {
    uuid = null
    name = ''
    members = []
    membersInvite = []

    constructor(data = {}) {
        this.serialize = data
        this.createUserObjects()
    }

    async loadTeam() {
        this.serialize = (await teamsApi.getByKey(this.uuid)).data
        this.createUserObjects()
        return this.serialize
    }

    createUserObjects() {
        this.members = this.members.map(member => ({
            ...member,
            user: new UserService(member.user),
        }))
    }

    async saveTeam() {
        const {data} = await teamsApi.patch(this.uuid, this.serialize)

        this.serialize = data
        this.createUserObjects()
        return this.serialize
    }

    updateTeam(data) {
        this.serialize = data

        return this.saveTeam()
    }

    addMembers(addMembers = []) {
        return Promise.all(addMembers.map(member => this.addMember(member)))
    }

    async addMember({uuid, email, role, ...data_json}) {
        return this.sendTeamAction('add_member', {
            agent: uuid,
            agent_email: email,
            role,
            data_json,
        })
    }

    inviteMembers(inviteMembers = []) {
        return Promise.all(inviteMembers.map(member => this.inviteMember(member)))
    }

    async inviteMember({uuid, email, role, ...data_json}) {
        return this.sendTeamAction('invite_member', {
            agent: uuid,
            agent_email: email,
            role,
            data_json,
        })
    }

    async resendInviteMember({uuid, email, target_agent_email, role, data_json}) {
        await this.sendTeamAction('reinvite_member', {
            agent: uuid || undefined,
            agent_email: email || target_agent_email,
            role,
            data_json,
        })

        return this.loadMembersInvite()
    }

    async removeInviteMember(inviteToken) {
        await teamsApi.getApiWithName('invite').delete(inviteToken)

        this.membersInvite = this.membersInvite.filter(inviteMember => inviteMember.token != inviteToken)

        return this.serialize
    }

    async loadMembersInvite() {
        this.membersInvite = (await teamsApi.getApiWithName('invite').get({
            is_valid: true,
            is_used: false,
        })).data.map(memberInvite => ({
            ...memberInvite,
            user: new UserService(memberInvite.target),
            data_json: {
                ...memberInvite.data_json,
                fullName: new UserService(memberInvite.data_json).fullName,
            },
        }))

        return this.serialize
    }

    async changeMemberRole({user: {uuid}}, role) {
        return this.sendTeamAction('set_member_role', {
            agent: uuid,
            role,
        })
    }

    async removeMember({user: {uuid}}) {
        return this.sendTeamAction('remove_member', {
            agent: uuid,
        })
    }

    async createTeam(teamName, inviteMembers = []) {
        this.name = teamName
        const {data} = await teamsApi.post(this.serialize)

        this.serialize = data
        this.createUserObjects()
        return this.inviteMembers(inviteMembers)
    }

    async addMyUserToTheTeam(token, newUserObj) {
        if (newUserObj) {
            return teamsApi.getApiWithName('new-member-invite').post({
                token,
                first_name: newUserObj.firstName,
                last_name: newUserObj.lastName,
                email: newUserObj.email,
                phone: newUserObj.phone?.notEmptyPhone || undefined,
                new_password1: newUserObj.password,
                new_password2: newUserObj.confirmPassword,
                udid: newUserObj.udid,
            }, {
                withCredentials: true,
            })
        }

        return teamsApi.getApiWithName('invite').put(token)
    }

    async activateMember(memberUuid) {
        return this.sendTeamAction('activate_member', {
            agent: memberUuid,
        })
    }

    async deactivateMember(memberUuid) {
        return this.sendTeamAction('deactivate_member', {
            agent: memberUuid,
        })
    }

    async sendTeamAction(actionName, params) {
        this.serialize = (await teamsApi.getApiWithName('actions').patch(this.uuid, {
            action: actionName,
            ...params,
        })).data

        this.createUserObjects()

        return this.serialize
    }
}

export default TeamService
