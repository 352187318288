import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {autobind} from 'core-decorators'
import cn from 'classnames'

import billingActions from 'common_redux/actions/billing'

import Button from 'common_components/button'
import Modal from 'common_components/modal'

import Dropdown from 'adminProj/components/dropdown'

class CustomerCards extends Component {
    static propTypes = {
        customer: PropTypes.object.isRequired,
        removeCustomerCard: PropTypes.func.isRequired,

        className: PropTypes.string,
        onAddCardButtonClick: PropTypes.func,
        hideRemoveCard: PropTypes.bool,
    }

    state = {
        showConfirmDeleteCard: false,
    }

    render() {
        const {
            customer: {defaultCustomerCard},
            onAddCardButtonClick,
            className,
            hideRemoveCard,
        } = this.props

        return (
            <div className={className}>
                <div className="flex align-center space-between">
                    <div>
                        <p
                            className={cn(
                                'text-dark-grey proxima-medium text-md',
                                {
                                    'my-zero': defaultCustomerCard,
                                    'my-sm': !defaultCustomerCard,
                                }
                            )}
                        >
                            {
                                defaultCustomerCard
                                    ? `**** **** **** ${defaultCustomerCard.last4}`
                                    : 'No credit card added'
                            }
                        </p>
                        {
                            defaultCustomerCard && (
                                <p className="text-dark-grey proxima text-sm mt-zero mb-sm">
                                    Expires
                                    {' '}
                                    <span className="proxima-bold">
                                        {defaultCustomerCard.exp_month}/{defaultCustomerCard.exp_year % 100}
                                    </span>
                                </p>
                            )
                        }
                    </div>
                    {
                        defaultCustomerCard
                            ? !hideRemoveCard && (
                                <Dropdown
                                    content={(
                                        <Button.text
                                            className="text-turquoise-blue proxima-medium text-sm"
                                            title="Delete Card"
                                            onClick={this.openConfirmDeleteCard}
                                        />
                                    )}
                                    isOverflow
                                />
                            )
                            : (
                                <Button.tiny.warning
                                    title="ADD CARD"
                                    onClick={onAddCardButtonClick}
                                    isInvert
                                />
                            )
                    }
                </div>
                <Modal.confirm
                    mainHeader="Delete Card"
                    subHeader="Are you sure you want to delete this card permanently? You will need a valid card to continue using Raven’s Pro features."
                    cancelFunc={this.closeConfirmDeleteCard}
                    doneText="DELETE"
                    doneFunc={this.removeCustomerCard}
                    inProgressText="DELETING..."
                    show={this.state.showConfirmDeleteCard}
                    onClose={this.closeConfirmDeleteCard}
                    cancelText="CANCEL"
                />
            </div>
        )
    }

    @autobind
    async removeCustomerCard() {
        const {customer: {defaultCustomerCard}, removeCustomerCard} = this.props
        await removeCustomerCard(defaultCustomerCard.id)

        this.closeConfirmDeleteCard()
    }

    @autobind
    openConfirmDeleteCard() {
        this.setState({
            showConfirmDeleteCard: true,
        })
    }

    @autobind
    closeConfirmDeleteCard() {
        this.setState({
            showConfirmDeleteCard: false,
        })
    }
}

export default connect(
    ({billing: {customer}}) => ({customer}),
    dispatch => bindActionCreators(billingActions, dispatch),
)(CustomerCards)
