import React, {Fragment, Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'

import Modal from 'common_components/modal'

import PlanSubscription from 'adminProj/pages/billing/subscription/components/plan_subscription'

import SubscriptionSuccess from './components/subscription_success'

import styles from './styles.styl'

class SubscriptionModal extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    state = {
        showSuccessModal: false,
    }

    render() {
        const {
            showModal,
            closeModal,
        } = this.props

        return (
            <Fragment>
                <Modal
                    size={Modal.size.lg}
                    dialogClassName={styles['subscription-modal']}
                    show={showModal}
                    bodyClassName="p-zero"
                    onClose={closeModal}
                    content={
                        <PlanSubscription
                            onSubmitSuccess={this.openSuccessModal}
                            withBasicPlan={false}
                            isModal
                        />
                    }
                />
                <Modal
                    size={Modal.size.xs}
                    show={this.state.showSuccessModal}
                    onClose={this.closeSuccessModal}
                    content={
                        <SubscriptionSuccess
                            closeModal={this.closeSuccessModal}
                            scheduleTraining={() => window.open('https://raven.re/training', '_blank')}
                        />
                    }
                />
            </Fragment>
        )
    }

    @autobind
    openSuccessModal() {
        this.props.closeModal()

        this.setState({
            showSuccessModal: true,
        })
    }

    @autobind
    closeSuccessModal() {
        this.setState({
            showSuccessModal: false,
        })

        this.props.onSubmitSuccess()
    }
}

export default SubscriptionModal
