import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from 'common_components/button'

import CloseSvg from 'common_svg/close.svg?jsx'

import styles from './styles.styl'

export default function FullScreenModal({
    onClose,
    content,

    show,
    customHeader,
    className,
}) {
    return (
        <div
            className={cn(
                styles['full-screen-images-gallery'],
                {
                    hide: !show,
                },
                className
            )}
        >
            {
                customHeader
                    ? customHeader
                    : (
                        <div className={cn(styles['header'], 'flex align-center just-end')}>
                            <Button.text
                                onClick={onClose}
                                className="flex align-center"
                            >
                                <CloseSvg className={cn(styles['close-button'], 'ml-sm')} />
                            </Button.text>
                        </div>
                    )
            }
            {content}
        </div>
    )
}

FullScreenModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    content: PropTypes.node.isRequired,

    show: PropTypes.bool,
    customHeader: PropTypes.node,
    className: PropTypes.node,
}
