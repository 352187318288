import sha256 from 'sha256'

export default createUDID(getUserIdentificator())

export function createUDID(userIdentificator) {
    return sha256(userIdentificator)
}

function getUserIdentificator() {
    return navigator.userAgent
}
