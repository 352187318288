import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import parseStylusExport from 'common_utils/parse_stylus_export'

import SvgLogo from './svg/logo.svg?jsx'
import Raven from './svg/raven.svg?jsx'

import styles from './styles'

const stylesVariable = parseStylusExport(styles)

function Logo({withoutName, color, size, className}) {
    return (
        <div
            className={cn(
                styles.logo,
                styles[`logo-${size}`],
                styles[`logo-${color}`],
                'flex flex-row align-center space-between',
                className
            )}
        >
            {
                withoutName && (
                    <SvgLogo />
                )
            }
            {
                !withoutName && (
                    <Raven className="my-zero" />
                )
            }
        </div>
    )
}

Logo.sizes = Object.keys(stylesVariable.sizes).reduce((current, next) => ({...current, [next]: next}), {})

Logo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(Object.keys(stylesVariable.colors)),
    size: PropTypes.oneOf(Object.keys(stylesVariable.sizes)),
    withoutName: PropTypes.bool,
}

Logo.defaultProps = {
    color: 'deepAqua',
    size: 'normal',
    withoutName: false,
}

Object.keys(stylesVariable.colors).forEach(logoColor => {
    Logo[logoColor] = props => ( // eslint-disable-line react/display-name
        <Logo color={logoColor} {...props} />
    )
    // display-name for previos component
    Logo[logoColor].displayName = `Logo.${logoColor}`
})

export default Logo
