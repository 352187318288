import mlsVendorSearch from 'common_services/mls_vendor_search'

import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {
    ...mlsVendorSearch.serialize,
}

const mlsVendorSearchReducer = new Reducer(INITIAL_STATE)

mlsVendorSearchReducer.add(actionTypes.searchMlsVendors)

export default mlsVendorSearchReducer.reducersFunction
