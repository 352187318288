export default class Reducer {
    constructor(INITIAL_STATE = {}, defaultFunction = null) {
        this.INITIAL_STATE = INITIAL_STATE
        this.reducers = {
            defaultFunction: defaultFunction || Reducer.defaultReducer,
        }
        this.subscriptionList = []
    }

    add(actionTypeSpace, fieldName, reducers = {}) {
        this.reducers[actionTypeSpace.start] = {
            func: reducers.start || Reducer.defaultStartReducer,
            fieldName: fieldName,
        }

        this.reducers[actionTypeSpace.success] = {
            func: reducers.success || Reducer.defaultSuccessReducer,
            fieldName: fieldName,
        }

        this.reducers[actionTypeSpace.error] = {
            func: reducers.error || Reducer.defaultErrorReducer,
            fieldName: fieldName,
        }
    }

    addLocalAction(actionType, fieldName, reducer, force) {
        this.reducers[actionType] = {
            func: reducer || ((state, action) => Reducer.defaultLocalReducer(state, action, force)),
            fieldName: fieldName,
        }
    }

    resetField(actionType, fieldName, reducer) {
        this.reducers[actionType] = {
            func: reducer || Reducer.defaultResetField,
            fieldName: fieldName,
        }
    }

    get reducersFunction() {
        return (state = this.INITIAL_STATE, action) => {
            if (this.reducers[action.type]) {
                const {func, fieldName} = this.reducers[action.type]

                this.runSubscription(state, action)

                return fieldName
                    ? {
                        ...state,
                        [fieldName]: func(state[fieldName], action),
                    }
                    : func(state, action)
            }
            return this.reducers.defaultFunction(state, action)
        }
    }

    subscribeToAction(actionName, cb) {
        if (Array.isArray(actionName)) {
            actionName.forEach(currentActionName => this.subscribeToAction(currentActionName, cb))
        }
        else {
            if (!this.subscriptionList[actionName]) {
                this.subscriptionList[actionName] = []
            }

            this.subscriptionList[actionName].push(cb)
        }
    }

    unsubscribeToAction(actionName, cb) {
        if (Array.isArray(actionName)) {
            actionName.forEach(currentActionName => this.unsubscribeToAction(currentActionName, cb))
        }
        else if (this.subscriptionList[actionName]) {
            this.subscriptionList[actionName] = this.subscriptionList[actionName]
                .filter(currentCB => currentCB != cb)
        }
    }

    runSubscription(store, action) {
        const currentSubscriptionList = this.subscriptionList[action.type]

        if (currentSubscriptionList) {
            currentSubscriptionList.forEach(cb => setTimeout(() => cb(store, action), 10))
        }
    }

    static defaultReducer = (state, action) => state

    static defaultLocalReducer = (state = {}, action, force = false) => {
        const newState = force
            ? action.payload
            : {
                ...state,
                ...action.payload,
            }

        return newState
    }

    static defaultStartReducer = (state = {}, action) => {
        const newState = {
            ...state,
            _requests: {
                ...state?._requests,
                [action.type]: action.promise,
            },
        }

        if (newState.errors) {
            delete newState.errors[action.actionTypeSpace.error]
        }

        return newState
    }
    static defaultSuccessReducer = (state = {}, action) => {
        const newState = {
            ...state,
            ...action.payload,
        }

        if (newState._requests) {
            delete newState._requests[action.actionTypeSpace.start]
        }
        if (newState.errors) {
            delete newState.errors[action.actionTypeSpace.error]
        }

        return newState
    }
    static defaultErrorReducer = (state = {}, action) => {
        const newState = {
            ...state,
            errors: {
                ...state?.errors,
                [action.type]: action.error,
            },
        }

        if (newState._requests) {
            delete newState._requests[action.actionTypeSpace.start]
        }

        return newState
    }

    static defaultResetField = (state, action) => {
        const newState = {...state}

        return resetField(newState)

        function resetField(fieldValue) {
            if (Array.isArray(fieldValue)) {
                return fieldValue.forEach((el, idx) => fieldValue[idx] = resetField(fieldValue[idx]))
            }

            switch (typeof fieldValue) {
                case 'number':
                    return 0
                case 'string':
                    return ''
                case 'object':
                    if (fieldValue) {
                        Object.keys(fieldValue).forEach(key => {
                            const descriptor = Object.getOwnPropertyDescriptor(fieldValue, key)

                            if (descriptor.writable || descriptor.set) {
                                fieldValue[key] = resetField(fieldValue[key])
                            }
                        })
                    }

                    return fieldValue
                case 'boolean':
                    return false
            }

            return null
        }
    }
}
