import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import SpinnerSVG from './svg/spinner.svg?jsx'

import styles from './styles.styl'

export default function Spinner({isLineSpinner, className}) {
    return isLineSpinner
        ? (
            <div className={cn(styles['line-spinner'], className)}>
                <div className={cn(styles['spinner'])}>
                    <div className={cn(styles['circle'], styles['circle-1'])}>
                        <div className={cn(styles['circle-inner'])} />
                    </div>
                    <div className={cn(styles['circle'], styles['circle-2'])}>
                        <div className={cn(styles['circle-inner'])} />
                    </div>
                </div>
            </div>
        )
        : (
            <SpinnerSVG className={cn(styles.spinner, className)} />
        )
}

Spinner.propTypes = {
    isLineSpinner: PropTypes.bool,
    className: PropTypes.string,
}
