import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Button from 'common_components/button'

function SubscriptionSuccess({closeModal, scheduleTraining, hasUsedProTrial}) {
    return (
        <div>
            <p className="text-center proxima-bold text-xl letter-sm mt-zero mb-md">
                Congrats! Your Raven Pro {hasUsedProTrial ? 'subscription' : 'free trial'} has started.
            </p>
            <p className="text-center proxima text-lg mt-zero mb-md-lg">
                You now have unlimited buyer portals, enhanced search, and much more.
            </p>
            <Button
                title="SCHEDULE TRAINING"
                className="mb-sm"
                onClick={scheduleTraining}
                isInvert
            />
            <Button
                title="DONE"
                onClick={closeModal}
            />
        </div>
    )
}

export default connect(
    ({user: {hasUsedProTrial}}) => ({hasUsedProTrial}),
    () => ({})
)(SubscriptionSuccess)

SubscriptionSuccess.propTypes = {
    hasUsedProTrial: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    scheduleTraining: PropTypes.func.isRequired,
}
