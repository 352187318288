import * as Sentry from '@sentry/react'
import axios, {CancelToken} from 'axios'
import {setupCache} from 'axios-cache-adapter'

import userActions from 'common_redux/actions/user'

import userService from './user'

let projectStoreDispatch

const instances = []
const cache = setupCache({
    maxAge: 0,
})

export const globalInterceptors = {
    _interceptors: [],
    addRequestInterceptor(res, rej) {
        const id = createInterceptorId()
        this._interceptors.push({
            id, res, rej,
            type: 'request',
            axiosInstances: [],
        })
        this.addInterceptorsToAllAxiosInstances()
        return () => {
            this.removeRequestInterceptor(this._interceptors.find(interceptor => interceptor.id == id))
        }
    },
    addResponseInterceptor(res, rej) {
        const id = createInterceptorId()
        this._interceptors.push({
            id, res, rej,
            type: 'response',
            axiosInstances: [],
        })
        this.addInterceptorsToAllAxiosInstances()
        return () => {
            this.removeResponseInterceptor(this._interceptors.find(interceptor => interceptor.id == id))
        }
    },
    addInterceptorsToAllAxiosInstances() {
        instances.forEach(instance => {
            this._interceptors.forEach(interceptor => {
                const instanceHasInterceptor = interceptor.axiosInstances.find(currentInstance =>
                    currentInstance.instance == instance
                )
                if (!instanceHasInterceptor) {
                    const newInterceptor = instance.interceptors[interceptor.type].use(interceptor.res, interceptor.rej)
                    interceptor.axiosInstances.push({
                        instance,
                        interceptor: newInterceptor,
                    })
                }
            })
        })
    },
    removeRequestInterceptor(interceptor) {
        this.removeInterceptorsFromAllAxiosInstances(interceptor)
    },
    removeResponseInterceptor(interceptor) {
        this.removeInterceptorsFromAllAxiosInstances(interceptor)
    },
    removeInterceptorsFromAllAxiosInstances(interceptor) {
        interceptor.axiosInstances.forEach(axiosInstance => {
            axiosInstance.instance.interceptors[interceptor.type].eject(axiosInstance.interceptor)
        })
        this._interceptors.splice(this._interceptors.indexOf(interceptor), 1)
    },
}

const globalAxiosInstance = createAxiosInstance({
    headers: {
        'Content-Type': 'application/json',
    },
    adapter: cache.adapter,
})

let authenticateConfig
let authenticatePromice = []
let isWaitAuthenticate = false

globalInterceptors.addRequestInterceptor(
    config => {
        if (!isWaitAuthenticate && config.method == 'post' && config.url.indexOf('accounts/jwt') != -1) {
            isWaitAuthenticate = true
            authenticateConfig = config
            return config
        }

        if (authenticateConfig != config && isWaitAuthenticate && authenticatePromice) {
            return new Promise((res, rej) => {
                authenticatePromice.push({
                    resolve: res,
                    reject: rej,
                    config: config,
                })
            }).then(() => config)
        }

        return config
    },
    error => Promise.reject(error)
)

const listRepeatRequest = []

globalInterceptors.addResponseInterceptor(
    response => {
        if (authenticateConfig == response.config) {
            authenticatePromice.forEach(config => config.resolve())
            isWaitAuthenticate = false
            authenticatePromice = []
            authenticateConfig = null
        }

        return response
    },
    error => {
        if (!axios.isCancel(error) && error.response && error.request.status != 0) {
            const stringConfig = JSON.stringify(error.config)

            if (error.response.status == 401 && listRepeatRequest.indexOf(stringConfig) == -1) {
                listRepeatRequest.push(stringConfig)

                return userActions.authenticate()(projectStoreDispatch)
                    .then(res => {
                        error.config.headers['Authorization'] = globalAxiosInstance.defaults.headers['Authorization']

                        return axios.request(error.config)
                    })
                    .then(res => {
                        listRepeatRequest.splice(listRepeatRequest.indexOf(stringConfig), 1)

                        return res
                    })
            }
        }

        return Promise.reject(error)
    }
)

export default globalAxiosInstance

export function setAuthorizationToken(token) {
    token = token || userService.authToken.jwt
    globalAxiosInstance.defaults.headers['Authorization'] = `JWT ${token}`

    if (Sentry) {
        Sentry.setUser(userService.serialize)
    }

    return token
}

export function createAxiosInstance(config) {
    const newInstance = axios.create(config)

    instances.push(newInstance)
    globalInterceptors.addInterceptorsToAllAxiosInstances()

    return newInstance
}

export function setProjectStoreDispatch(dispatch) {
    projectStoreDispatch = dispatch
}

export function createCancelSource() {
    return CancelToken.source()
}

function createInterceptorId() {
    return Math.trunc(Math.random() * 1000000000)
}
