import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'
import membersListService from '../user/team/members_list'

import Agent from './agent'

const agentsApi = new Api(API_DOMAINS.leadsApi, 'agents', 'agents')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
])
class AgentsListService {
    list = []

    get currentPage() {
        return membersListService.currentPage
    }

    get pageSize() {
        return membersListService.pageSize
    }

    get count() {
        return membersListService.count
    }

    get lastPage() {
        return membersListService.lastPage
    }

    async loadPage(...args) {
        const membersData = await membersListService.loadPage(...args)

        const {data: agentsData} = await agentsApi.get({
            uuid: membersData.list.map(({user: {uuid}}) => uuid),
        })

        this.serialize = {
            ...membersData,
            list: membersData.list.map(({user, ...member}) => new Agent({
                ...member,
                ...agentsData.find(agent => agent.agent == user.uuid),
                agent: user,
            })),
        }

        return this.serialize
    }

    async changePageSize(pageSize) {
        membersListService.setPageSize(pageSize)

        return this.loadPage()
    }
}

export default new AgentsListService
