import Reducer from './reducer'

import actionTypes from '../actions/action_types'

import routingRuleService from 'common_services/routing_rules/routing_rule'

const INITIAL_STATE = {
    ...routingRuleService.serialize,
    routingRulesList: {},
}

const routingRulesReducer = new Reducer(INITIAL_STATE)

routingRulesReducer.add(actionTypes.routingRules.getRoutingRules, 'routingRulesList')
routingRulesReducer.add(actionTypes.routingRules.changeRoutingRulePageLength, 'routingRulesList')

routingRulesReducer.add(actionTypes.routingRules.createLeadRoutingRule, 'createdLeadRoutingRule')
routingRulesReducer.add(actionTypes.routingRules.getRoutingRuleDetail, 'routingRuleDetails')
routingRulesReducer.add(actionTypes.routingRules.deleteRoutingRule, 'deleteRoutingRule')
routingRulesReducer.add(actionTypes.routingRules.updateRoutingRule, 'updateRoutingRule')
routingRulesReducer.add(actionTypes.routingRules.updateRoutingRuleStatus, 'updateRoutingRuleStatus')
routingRulesReducer.add(actionTypes.routingRules.assignRoutingRuleAgent, 'assign_agent')
routingRulesReducer.add(actionTypes.routingRules.unassignRoutingRuleAgent, 'unassign_agent')

export default routingRulesReducer.reducersFunction
