import mlsVendorSearch from 'common_services/mls_vendor_search'

import createAction from './create_action'

import actionTypes from './action_types'

export default {
    searchMlsVendors(search) {
        return createAction({
            actionTypeSpace: actionTypes.searchMlsVendors,
            action: () => mlsVendorSearch.searchMlsVendors(search),
        })
    },
}
