import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'
import PaginationService from '../pagination'

import Buyer from './buyer'

const buyerApi = new Api(API_DOMAINS.leadsApi, 'buyers')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
])
class BuyerSearchService extends PaginationService {
    async loadSearchingBuyers(page, search) {
        await super.loadPage(page, {search})

        return this.serialize
    }
}

export default new BuyerSearchService(Buyer, buyerApi, 10)
