import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Link from 'common_components/link'
import Button from 'common_components/button'

import BackIcon from 'common_svg/back.svg?jsx'

import styles from './styles.styl'

export default function EmptyList({
    selectedBuyerUUID,
    header,
    subHeader,
    showSearchLink,
    showNavLinks,
    backLinkText,
    searchLinkUrl,
    withoutResult,
    className,
    openFilterComponent,
    hideLikedHomesLink,
    isWithoutMap,
}) {
    const interestedPropertiesLink = `/interested-properties/${selectedBuyerUUID}${
        sessionStorage.getItem(`${selectedBuyerUUID}-interested-properties`)
            ? `?${sessionStorage.getItem(`${selectedBuyerUUID}-interested-properties`)}`
            : ''
    }`
    const notInterestedPropertiesLink = `/not-interested-properties/${selectedBuyerUUID}${
        sessionStorage.getItem(`${selectedBuyerUUID}-notInterested-properties`)
            ? `?${sessionStorage.getItem(`${selectedBuyerUUID}-notInterested-properties`)}`
            : ''
    }`

    searchLinkUrl = searchLinkUrl || `/properties/${selectedBuyerUUID}${
        sessionStorage.getItem(`${selectedBuyerUUID}-all-properties`)
            ? `?${sessionStorage.getItem(`${selectedBuyerUUID}-all-properties`)}`
            : ''
    }`

    if (withoutResult) {
        return (
            <div className="flex flex-column align-center mt-xxl">
                <p
                    className="proxima text-20 text-md-on-md-screen text-dark-grey-blue m-zero mx-sm-on-md-screen text-center"
                >
                    Expand your search criteria to see properties.
                    {
                        !hideLikedHomesLink && (
                            <Fragment>
                                {' '}Or see your{' '}
                                <Link
                                    text="liked homes"
                                    path={interestedPropertiesLink}
                                    className={styles['link-normal']}
                                />
                                .
                            </Fragment>
                        )
                    }
                </p>
                <div
                    className={cn(
                        styles['tips'],
                        'proxima px-sm-md py-sm-md px-sm-on-md-screen py-sm-on-md-screen letter-sm mt-xxl'
                    )}
                >
                    <p className="proxima-bold text-lg text-md-on-md-screen m-zero">
                        Search tips:
                    </p>
                    <ul className="mt-sm mb-zero py-zero pl-md-lg text-sm">
                        {
                            openFilterComponent && (
                                <li>
                                    Try{' '}
                                    <Button.text
                                        title="modifying your filters"
                                        onClick={event => {
                                            event.stopPropagation()

                                            openFilterComponent()
                                        }}
                                        className="proxima-medium text-turquoise-blue mx-zero px-zero"
                                    />
                                    {' '}to expand your search criteria.
                                </li>
                            )
                        }
                        <li>Try searching in a different region.</li>
                        {
                            !isWithoutMap && (
                                <li>Try zooming out to expand your map region.</li>
                            )
                        }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className={cn('flex flex-column align-center text-center px-sm pt-xxl', className)}>
            <p className="proxima-medium text-xxl text-dark-grey-blue m-zero">
                {header}
            </p>
            {
                !!subHeader && (
                    <p className="proxima-medium text-20 text-dark-grey-blue mb-zero mt-sm-md">
                        {subHeader}
                    </p>
                )
            }
            {
                showNavLinks && (
                    <div className="mt-md-lg flex flex-wrap">
                        <Link
                            text="SEE INTERESTED HOMES"
                            path={interestedPropertiesLink}
                            className={cn(styles['link'], 'flex-grow mx-xs my-xs')}
                        />
                        <Link
                            text="SEE NOT INTERESTED HOMES"
                            path={notInterestedPropertiesLink}
                            className={cn(styles['link-invert'], 'flex-grow mx-xs my-xs')}
                        />
                    </div>
                )
            }
            {
                showSearchLink && (
                    <Fragment>
                        <Link
                            text={backLinkText}
                            path={searchLinkUrl}
                            className={cn(styles['link'], 'mt-md-lg md-hide')}
                        />
                        <Link
                            className={cn(styles['link'], 'mt-md-lg flex align-center just-center md-visible')}
                            path={searchLinkUrl}
                        >
                            <BackIcon className="mr-sm" />
                            <span>{backLinkText}</span>
                        </Link>
                    </Fragment>
                )
            }
        </div>
    )
}

EmptyList.propTypes = {
    selectedBuyerUUID: PropTypes.string.isRequired,

    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    subHeader: PropTypes.string,
    showSearchLink: PropTypes.bool,
    showNavLinks: PropTypes.bool,
    withoutResult: PropTypes.bool,
    backLinkText: PropTypes.string,
    searchLinkUrl: PropTypes.string,
    className: PropTypes.string,
    openFilterComponent: PropTypes.func,
    hideLikedHomesLink: PropTypes.bool,
    isWithoutMap: PropTypes.bool,
}

EmptyList.defaultProps = {
    header: 'List is Empty',
    backLinkText: 'BACK TO SEARCH',
}
