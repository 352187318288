import ReactPhoneInput from 'react-phone-input-2'

import states from 'common_constants/states.json'

import curry from './curry'

export function isEmpty(value) {
    return !(
        value && value.trim
            ? `${value}`.trim()
            : value
    )
}

export function isArrayEmpty(value) {
    return !(
        value && Array.isArray(value)
            ? value.length
            : value
    )
}

export function isEmail(value) {
    return value
        && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .test(`${value}`.trim())
}

export function isSimplePassword(value) {
    return value && (/^.{8,}$/).test(`${value}`.trim())
}

export function isComplexPassword(value) {
    return value && (/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/).test(`${value}`.trim())
}

export const isEqualPasswords = curry((firstPassword, secondPassword) => firstPassword == secondPassword)

export function isUserName(value) {
    return value && (/^[a-zA-Z\u00C0-\u02AB'´`\s-]+\.?$/i).test(`${value}`.trim())
}

export function isUserNameWithExpandedCharacters(value) {
    return value && (/^[a-zA-Z\u00C0-\u02AB'´`\s\-0-9.,]+\.?$/i).test(`${value}`.trim())
}

export function isFullUserName(value) {
    return value && (
        value
            .trim()
            .replace(/\s+/g, ' ')
            .split(' ')
            .every(isUserName)
    )
}

window.isUserName = isUserName
window.isFullUserName = isFullUserName

export function isPhoneNumber(value, isUsa) {
    const countryCode = value?.countryData?.countryCode
    const phoneNumber = value?.phoneNumber || value
    const rawPhone = value?.rawPhone || (value?.replace ? value.replace(/\D/g, '') : value)

    return isUsa
        ? isUsaPhoneNumber(value)
        : (
            countryCode == 'us' || (typeof phoneNumber == 'string' && phoneNumber.indexOf('+1') != -1)
                ? isUsaPhoneNumber(value) && ReactPhoneInput.defaultProps.isValid(rawPhone)
                : isOtherCountryPhoneNumber(value) && ReactPhoneInput.defaultProps.isValid(rawPhone)
        )
}

export function isOtherCountryPhoneNumber(value) {
    return value && (
        value.hasOwnProperty('rawPhone')
            ? value.rawPhone.length >= 8
            : value.replace(/\D/g, '').length >= 8
    )
}

export function isUsaPhoneNumber(value) {
    return value && (
        value.hasOwnProperty('rawPhone')
            ? value.rawPhone.length == 11
            : /^\+1\s?[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/.test(`${value}`.trim())
    )
}

export function isCorrectRange(low, high) {
    return high >= low
}

export function alphaNumeric(value) {
    return /^[a-z0-9]+$/i.test(`${value}`.trim())
}

export function isSlug(value) {
    return /^[a-zA-Z0-9\-_]+$/.test(`${value}`.trim())
}

export function isZipcode(value) {
    return value && (/^[0-9]{5}$/).test(`${value}`.trim())
}

export function isUsaState(value, useStrict = true) {
    value = value.toLowerCase()

    return states
        .find(state => (
            useStrict
                ? state.name.toLowerCase() == value
                    || state.uuid.toLowerCase() == value
                : state.name.toLowerCase().indexOf(value) != -1
                    || state.uuid.toLowerCase().indexOf(value) != -1
        ))
}

export function isURL(value) {
    return value && (/^(http(s)?:\/\/)?(www.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig)
        .test(`${value}`.trim())
}

export function isContainsOnlyCharacters(regExpCharacterList) {
    return value => new RegExp(`^[${regExpCharacterList}]+$`).test(`${value}`.trim())
}
