import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from './api'

const mlsproviderApi = new Api(API_DOMAINS.usersApi, 'users', 'mlsprovider')
const mlsVendorApi = new Api(API_DOMAINS.usersApi, 'users', 'mls-vendor')

@modelSerialize([
    'count',
    'list',
])
class MlsVendorSearch {
    count = 0
    list = []

    async searchMlsVendors(search) {
        const {data} = await mlsproviderApi.get({
            search,
        })

        this.serialize = {
            count: data.length,
            list: data,
        }
        return this.serialize
    }

    static getMlsVendorByName(name, params, config) {
        return mlsVendorApi.getByKey(name, params, config)
    }
}

export default new MlsVendorSearch

export {MlsVendorSearch}
