import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Spinner from 'common_components/spinner'

import styles from './styles.styl'

export default function DataLoader({mainText, secondText, isLineSpinner, className}) {
    return (
        <div className={cn('text-center flex flex-column flex-component-center', className)}>
            <Spinner
                isLineSpinner={isLineSpinner}
                className={cn(styles.spinner, 'my-md-lg')}
            />
            <p className="proxima-bold text-lg letter-xs text-dark-grey">
                {mainText}
            </p>
            <p className="proxima text-md letter-xs text-bluey-grey">
                {secondText}
            </p>
        </div>
    )
}

DataLoader.propTypes = {
    mainText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,

    secondText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    isLineSpinner: PropTypes.bool,
    className: PropTypes.string,
}
