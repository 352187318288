import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap-modal'
import cn from 'classnames'

import analyticsService from 'common_services/analytics'

import CloseIcon from 'common_svg/close.svg?jsx'
import ArrowIcon from 'common_svg/arrow.svg?jsx'

import styles from './styles'

let selectedElement
const listOfModals = {}

export default function BaseModal({
    modalID,
    modalLevel = 1,
    show,
    onClose,
    hideClose,
    content,
    positionClassName,
    modalSizeClassName,
    dialogClassName,
    size,
    position,
    bodyClassName,
    analytics,
    customCloseButton,
    ...props
}) {
    const [currentModalId] = useState(modalID || `MODAL_ID_${Math.trunc(Math.random() * 100000)}`)

    if (customCloseButton) {
        customCloseButton = React.cloneElement(
            customCloseButton,
            {
                onClick: closeModal,
            }
        )
    }

    if (!listOfModals[currentModalId]) {
        listOfModals[currentModalId] = {
            id: currentModalId,
            currentModalLevel: 1,
            updateOtherModalLevel: [],
            closeFunctions: [],
        }
    }

    const [currentModalLevel, setCurrentModalLevel] = useState(
        modalLevel || listOfModals[currentModalId].currentModalLevel
    )

    if (listOfModals[currentModalId].updateOtherModalLevel.indexOf(setCurrentModalLevel) == -1) {
        listOfModals[currentModalId].updateOtherModalLevel.push(setCurrentModalLevel)
    }
    if (listOfModals[currentModalId].closeFunctions.indexOf(onClose) == -1) {
        listOfModals[currentModalId].closeFunctions.push(onClose)
    }

    if (currentModalLevel > listOfModals[currentModalId].currentModalLevel && show) {
        listOfModals[currentModalId].currentModalLevel = modalLevel

        listOfModals[currentModalId].updateOtherModalLevel.forEach(
            cb => cb != setCurrentModalLevel && cb(currentModalLevel)
        )
    }

    return (
        <div
            onMouseDown={event => selectedElement = event.target}
            onClick={onClickOutside}
            className="hide"
        >
            <Modal
                show={show}
                onHide={closeModal}
                backdrop={
                    modalLevel == currentModalLevel
                        ? 'static'
                        : false
                }
                backdropClassName={styles['mobile-max-size']}
                aria-labelledby="modal-body"
                attentionClass=""
                className={cn('mob-modal-white', styles['mobile-max-size'], {hide: modalLevel != currentModalLevel})}
                dialogClassName={cn(
                    positionClassName || styles[position],
                    modalSizeClassName || styles[`modal-size-${size}`],
                    dialogClassName
                )}
                onEntered={() => trackEvent(analytics.onOpenModal)}
                onExited={() => trackEvent(analytics.onCloseModal)}
                {...props}
            >
                <Modal.Body
                    id={`modal-body_${currentModalId}`}
                    className={cn(
                        bodyClassName,
                        {
                            'mb-sm-md': position != 'center',
                        },
                    )}
                >
                    {
                        modalLevel > 1 && (
                            <button
                                className={styles['back-button']}
                                onClick={goBack}
                            >
                                <ArrowIcon />
                            </button>
                        )
                    }
                    {
                        customCloseButton || (
                            <button
                                className={cn(styles.close, 'close', {'hide': hideClose})}
                                onClick={closeModal}
                            >
                                <span className={styles['close-icon']}><CloseIcon /></span>
                            </button>
                        )
                    }
                    {content}
                </Modal.Body>
            </Modal>
        </div>
    )

    function trackEvent(eventObj) {
        if (eventObj?.eventName) {
            analyticsService.track(eventObj.eventName, eventObj.properties)
        }
    }

    function onClickOutside(event) {
        const isMainComponent = (
            selectedElement == event.target
                && event.target.getAttribute('aria-labelledby') == 'modal-body'
        )

        selectedElement = null

        if (isMainComponent) {
            closeModal()
        }
    }

    function closeModal() {
        onClose()

        if (listOfModals[currentModalId].currentModalLevel != 1) {
            listOfModals[currentModalId].currentModalLevel = 1

            listOfModals[currentModalId].updateOtherModalLevel.forEach(
                cb => cb != setCurrentModalLevel && cb(listOfModals[currentModalId].currentModalLevel)
            )
            listOfModals[currentModalId].closeFunctions.forEach(
                currentCloseFunction => currentCloseFunction != onClose && currentCloseFunction()
            )
        }
    }

    function goBack() {
        onClose()

        listOfModals[currentModalId].currentModalLevel--

        listOfModals[currentModalId].updateOtherModalLevel.forEach(
            cb => cb != setCurrentModalLevel && cb(listOfModals[currentModalId].currentModalLevel)
        )
    }
}

BaseModal.position = {
    left: 'left',
    right: 'right',
    center: 'center',
    topCenter: 'top-center',
}

BaseModal.size = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    fixedXsSm: 'fixed-xs-sm',
    fixedSm: 'fixed-sm',
}

BaseModal.propTypes = {
    modalID: PropTypes.string,
    modalLevel: PropTypes.number,
    show: PropTypes.bool,
    hideClose: PropTypes.bool,
    onClose: PropTypes.func,
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    position: PropTypes.oneOf(Object.values(BaseModal.position)),
    size: PropTypes.oneOf(Object.values(BaseModal.size)),
    positionClassName: PropTypes.string,
    modalSizeClassName: PropTypes.string,
    dialogClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    analytics: PropTypes.shape({
        onOpenModal: PropTypes.shape({
            eventName: PropTypes.string.isRequired,
            properties: PropTypes.object,
        }),
        onCloseModal: PropTypes.shape({
            eventName: PropTypes.string.isRequired,
            properties: PropTypes.object,
        }),
    }),
    customCloseButton: PropTypes.node,
}

BaseModal.defaultProps = {
    hideClose: false,
    position: BaseModal.position.center,
    size: BaseModal.size.md,
    analytics: {},
}

Object.keys(BaseModal.position).forEach(modalPosition => {
    BaseModal[modalPosition] = props => ( // eslint-disable-line react/display-name
        <BaseModal position={BaseModal.position[modalPosition]} {...props} />
    )
    // display-name for previos component
    BaseModal[modalPosition].displayName = `BaseModal.${modalPosition}`
})
