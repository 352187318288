import API_DOMAINS from 'API_DOMAINS'

import {UserService} from 'common_services/user'
import MlsAgentService from 'common_services/mls_agents/mls_agent'
import Api from 'common_services/api'
import PaginationService from 'common_services/pagination'

import modelSerialize from 'common_utils/model_serialize'

import MatchService from './match'

const matchingApi = new Api(API_DOMAINS.leadsApi, 'matching')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
    'extraData',
    'leadUUID',
    'leadType',
])
class MatchesListService extends PaginationService {
    static leadTypes = {
        property: 'property',
        buyer: 'buyer',
    }

    leadUUID = null
    leadType = null
    createApi = null

    constructor(createApi) {
        super()

        this.createApi = createApi

        this.setPaginationApi()
    }

    async loadPage(page, {ordering, search, filters} = {}, pageSize, {isLoadAgents = true, cancelSource} = {}) {
        this.setPaginationApi()

        await super.loadPage(
            page,
            {
                lead: this.leadUUID,
                ordering,
                search,
                filters,
            },
            pageSize,
            {cancelSource}
        )

        return isLoadAgents
            ? this.loadAllAgent()
            : this.serialize
    }

    async loadPublicPage(page, {ordering, search, filters} = {}, pageSize, {isLoadAgents = true, cancelSource} = {}) {
        this.setPaginationApi()

        await super.loadPage(
            page,
            {
                lead: this.leadUUID,
                ordering,
                search,
                filters,
            },
            pageSize,
            {
                cancelSource,
                lightApi: matchingApi.getApiWithName('property-public-matches'),
            }
        )

        return isLoadAgents
            ? this.loadAllAgent()
            : this.serialize
    }

    async changePageSize(
        newPageSize,
        {ordering, search, filters} = {},
        pageSize,
        {isLoadAgents = true, cancelSource} = {}
    ) {
        super.setPageSize(newPageSize)

        return this.loadPage(1, {ordering, search, filters}, pageSize, {isLoadAgents, cancelSource})
    }

    setLead(leadUUID, leadType) {
        this.leadUUID = leadUUID
        this.leadType = leadType
        this.list = []
        this.currentPage = 1
        // this.pageSize = 100
        this.pageSize = this.pageSize || 10
        this.count = 0

        return this.setPaginationApi()
    }

    setPaginationApi() {
        this.updatePagination(
            (...args) => new MatchService(this.leadType, ...args),
            matchingApi.getApiWithName(`${this.leadType || MatchService.all}/categorized`),
            this.pageSize
        )

        return this.serialize
    }

    async loadAllAgent() {
        const mlsAgentsPromise = []

        if (this.list.find(match => !!match.property.mls_agent)) {
            const maxMlsAgentInRequest = 50
            const mlsAgentUuids = this.list
                .map(match => match.property.mls_agent)
                .filter(mlsAgentUuid => !!mlsAgentUuid && typeof mlsAgentUuid == 'string')

            for (let i = 0; i < mlsAgentUuids.length ; i += maxMlsAgentInRequest) {
                mlsAgentsPromise.push(MlsAgentService.searchPublicMlsAgent({
                    uuid: mlsAgentUuids.slice(i, i + maxMlsAgentInRequest),
                    limit: maxMlsAgentInRequest,
                }))
            }
        }

        const tempMlsAgents = await Promise.all(mlsAgentsPromise)

        const mlsAgents = tempMlsAgents.reduce((current, next) => current.concat(next), [])

        const agentsUUIDs =
            [
                ...[
                    this.list
                        .map(match => match.buyer?.agent)
                        .flat(),
                    this.list
                        .map(match => (
                            match.buyer?.agents?.length
                                ? match.buyer.agents.map(({agent}) => agent)
                                : []
                        ))
                        .flat(),
                    this.list
                        .map(match => (
                            match.buyer?.partners?.length
                                ? match.buyer.partners
                                    .filter(({partner_type}) =>
                                        partner_type == UserService.availableUserGroups.partners.loanOfficer
                                    )
                                    .map(({user}) => user)
                                : []
                        ))
                        .flat(),
                    this.list
                        .map(match => (
                            match.property?.agents?.length
                                ? match.property.agents.map(({agent}) => agent)
                                : []
                        ))
                        .flat(),
                ],
                ...(mlsAgents.find(agent => typeof agent.raven_agent == 'string')
                    ? [
                        ...mlsAgents.map(agent => agent.raven_agent),
                    ]
                    : []
                ),
            ]
                .flat()
                .filter(agentUUID => !!agentUUID && typeof agentUUID == 'string')

        const agents = agentsUUIDs?.length
            ? await UserService.searchPublicUserInfo(undefined, {uuid: agentsUUIDs})
            : []

        this.list
            .filter(match => (
                typeof match.buyer.agent == 'string'
                    || typeof match.property.agent == 'string'
                    || typeof match.property.mls_agent == 'string'
            ))
            .forEach((match, idx) => {
                if (typeof match.buyer != 'string') {
                    agents.forEach(currentAgent => {
                        if (currentAgent.uuid == match.buyer.agent || currentAgent.uuid == match.buyer.agent?.uuid) {
                            match.buyer.agent = currentAgent
                        }

                        const currentCoAgentIndex = match.buyer.agents.findIndex(coAgent => (
                            currentAgent.uuid == coAgent.agent || currentAgent.uuid == coAgent.agent?.uuid
                        ))

                        if (currentCoAgentIndex != -1) {
                            match.buyer.agents[currentCoAgentIndex].agent = currentAgent
                        }

                        const currentPartnerIndex = match.buyer.partners.findIndex(partner => (
                            currentAgent.uuid == partner.user || currentAgent.uuid == partner.user?.uuid
                        ))

                        if (currentPartnerIndex != -1) {
                            match.buyer.partners[currentPartnerIndex].user = currentAgent
                        }
                    })
                }
                if (typeof match.property != 'string') {
                    match.property.mls_agent = mlsAgents.find(mlsAgent => mlsAgent.uuid == match.property.mls_agent)

                    agents.forEach(currentAgent => {
                        if (
                            currentAgent.uuid == match.property.agent
                                || currentAgent.uuid == match.property.agent?.uuid
                        ) {
                            match.property.agent = currentAgent
                        }

                        const currentCoAgentIndex = match.property.agents.findIndex(coAgent => (
                            currentAgent.uuid == coAgent.agent || currentAgent.uuid == coAgent.agent?.uuid
                        ))

                        if (currentCoAgentIndex != -1) {
                            match.property.agents[currentCoAgentIndex].agent = currentAgent
                        }
                    })

                    if (typeof match.property.mls_agent?.raven_agent == 'string') {
                        const ravenAgent = agents.find(agent => agent.uuid == match.property.mls_agent.raven_agent)

                        match.property.mls_agent = ravenAgent ? ravenAgent : match.property.mls_agent
                    }
                }
            })

        // if (this.leadType == MatchesListService.leadTypes.property) {
        //     this.list = this.list.filter(match => !!(
        //         match.buyer.agent
        //             && typeof match.buyer.agent != 'string'
        //     ))
        // }

        return this.serialize
    }
}

export {MatchesListService}

export default new MatchesListService
