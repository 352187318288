export function priceFormat(value, maximumFractionDigits) {
    let priceStr = fullPriceFormat(value)
    let numberOfDischarges = priceStr.match(/,/g)?.length || 0

    if (numberOfDischarges >= 1) {
        if (maximumFractionDigits) {
            value = Math.round(
                parseInt(value) / Math.pow(10, numberOfDischarges * 3 - numberOfDischarges)
            ) * Math.pow(10, numberOfDischarges * 3 - numberOfDischarges)
            priceStr = fullPriceFormat(value)
            numberOfDischarges = priceStr.match(/,/g)?.length || 0

            return `${fullPriceFormat(
                parseInt(value) / Math.pow(10, numberOfDischarges * 3),
                {maximumFractionDigits}
            )}${numberOfDischarges == 2 ? 'M' : 'K'}`
        }

        return priceStr.replace(/,.*/g, numberOfDischarges == 2 ? 'M' : 'K')
    }

    return priceStr
}

export function fullPriceFormat(
    value,
    {
        minimumFractionDigits = 0,
        hideFractionDigitsIfItIsZero = true,
        maximumFractionDigits,
    } = {}
) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // minimumFractionDigits,
        minimumFractionDigits: hideFractionDigitsIfItIsZero
            ? value % 1 ? minimumFractionDigits : 0
            : minimumFractionDigits,
        maximumFractionDigits,
    }).format(value)
}

window.fullPriceFormat = fullPriceFormat
window.priceFormat = priceFormat

export function numberFormat(value) {
    return value ? new Intl.NumberFormat('en-US').format(value) : value
}

export default {
    phoneNumber: value => stringFormat('(###) ###-####', value.replace(/\D+/g, '')),
    numberNormalize: value => value ? Number(`${value}`.replace(/\D+/g, '')) : value,
    licenseNumberNormalize: value => value ? `${value}`.replace(/\D+/g, '') : value,
}

export function stringFormat(mask, value, ...args) {
    mask = Array.from(mask)
    value = Array.from(value)

    let maskIndex, valueIndex

    for (
        maskIndex = 0, valueIndex = 0;
        maskIndex < mask.length && valueIndex < value.length;
        maskIndex++
    ) {
        if (mask[maskIndex] == '#') {
            mask[maskIndex] = value[valueIndex++]
        }
    }

    return mask.join('').substring(0, maskIndex)
}

export function sentences(str = '') {
    return str.split(/\s?\.\s?/)
        .map(currentSentence => currentSentence.replace(/^\w/, letter => letter.toUpperCase()))
        .join('. ')
}

export function mlsStatusFormater(value) {
    switch (value) {
        case 'needs_review':
            return 'Needs Review'
        case 'in_progress':
            return 'In Progress'
        default:
            return ''
    }
}

export function cityAddress(city, useShortName) {
    return city
        ? useShortName ? city.name : `${city.name}${city.state ? `, ${city.state}` : ''}`
        : null
}

export function zipcodeAddress(zipcode, useShortName) {
    return zipcode
        ? (
            useShortName
                ? zipcode.name
                : (
                    [
                        zipcode.name,
                        zipcode.city?.name,
                        zipcode.city?.state,
                    ]
                        .filter(el => !!el)
                        .join(', ')
                )
        )
        : null
}
