import {UserService} from 'common_services/user'

import modelSerialize from 'common_utils/model_serialize'

@modelSerialize([
    'uuid',
    'invitedAgent',
    {
        serializeField: 'dateCreated',
        originField: 'date_created',
        type: Date,
    },
    'invitedAgentUUID',
    {
        serializeField: 'agentInviteUuid',
        originField: 'agent_invite',
    },
])
class PartnerInvitedAgentService {
    uuid = null
    invitedAgent = null
    dateCreated = null
    invitedAgentUUID = null
    agentInviteUuid = null

    constructor({
        email,
        first_name,
        last_name,
        invited_agent,
        ...data
    } = {}) {
        const invitedAgent = new UserService({
            ...(typeof invited_agent == 'object' ? invited_agent : {}),
            first_name,
            last_name,
            email,
        })
        invitedAgent.userGroups = invitedAgent.userGroups || [UserService.availableUserGroups.agents.agent]

        this.serialize = {
            ...data,
            invitedAgent,
            invitedAgentUUID: (
                typeof invited_agent == 'string'
                    ? invited_agent
                    : invitedAgent.uuid
            ) || null,
        }
    }
}

export default PartnerInvitedAgentService
