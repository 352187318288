/* global analytics, Intercom */

import ENVIRONMENT from 'ENVIRONMENT'

import {autobind} from 'core-decorators'
import * as Sentry from '@sentry/react'

import USA_states from 'common_constants/states.json'

import userService from './user'

const ANALYTICS_SERVICE_ERRORS = {
    timeout: 'TIMEOUT',
}

class AnalyticsService {
    static defaultEventProps = {
        platform: 'web',
        environment: ENVIRONMENT,
    }

    trackQueue = []

    constructor() {
        setInterval(this.runTrackFromQueue, 5000)
    }

    get _segmentApi() {
        return analytics
    }

    get userKey() {
        return userService.serialize.email.toLowerCase()
    }

    get userData() {
        const {serialize: userData} = userService

        return {
            userId: this.userKey,
            email: userData.email.toLowerCase(),
            firstName: userData.firstName,
            lastName: userData.lastName,
            name: userData.fullName,
            phone: userData.phone,
            createdAt: userData.dateJoined,
            office: userData.profile?.office_address_json?.address,
            officeUnit: userData.profile?.office_address_json?.suite,
            userType: userData.userGroups[0],
            state: USA_states.find(({uuid}) => uuid == (userData.stateID))?.name,
            specialties: userData.profile?.specialties?.map(({name}) => name)?.join(', '),
            bio: userData.profile?.bio,
            website: userData.profile?.website,
        }
    }

    @autobind
    async runTrackFromQueue() {
        const [currentTrack] = this.trackQueue

        if (currentTrack) {
            if (currentTrack.error.status == ANALYTICS_SERVICE_ERRORS.timeout) {
                try {
                    await this.isReady()

                    await currentTrack.track()

                    this.trackQueue.shift()
                }
                catch (error) {
                    if (error.status != ANALYTICS_SERVICE_ERRORS.timeout) {
                        throw error
                    }
                }
            }
        }
    }

    @analyticsServiceTimeoutError
    async setCurrentUser(extraProps = {}, force = false) {
        await this.isReady()

        if (((!analytics.user().id() || force) || (analytics.user().id() != this.userKey)) && this.userKey) {
            return new Promise(res => {
                analytics.identify(
                    this.userKey,
                    {
                        ...this.userData,
                        ...extraProps,
                    },
                    {
                        Intercom: {
                            hideDefaultLauncher: true,
                            user_hash: userService.serialize.intercomId,
                        },
                    },
                    res
                )
            })
        }

        return Promise.resolve()
    }

    @analyticsServiceTimeoutError
    async track(event, properties, options) {
        await this.isReady()

        return new Promise((res, rej) => {
            analytics.track(event, {
                ...AnalyticsService.defaultEventProps,
                ...properties,
            }, options, res)
        })
    }

    @analyticsServiceTimeoutError
    async page(category, name, properties, options) {
        await this.isReady()

        return new Promise((res, rej) => {
            analytics.page(category, name, {
                ...AnalyticsService.defaultEventProps,
                ...properties,
            }, options, res)
        })
    }

    @analyticsServiceTimeoutError
    async resetUser() {
        await this.isReady()

        analytics.reset()
    }

    @analyticsServiceTimeoutError
    async updateUserEmail(oldUserId) {
        await this.isReady()

        return new Promise((res, rej) => {
            if (analytics.user().id() != this.userKey || oldUserId) {
                if (analytics.Integrations.Intercom) {
                    Intercom('shutdown')
                }

                analytics.alias(
                    this.userKey,
                    oldUserId,
                    null,
                    (...args) => {
                        if (analytics.Integrations.Intercom) {
                            Intercom('boot')
                        }

                        res(...args)
                    }
                )
            }
            else {
                res()
            }
        })
    }

    isReady() {
        let isReadyTimeout

        return new Promise((res, rej) => {
            analytics.ready((...args) => {
                clearTimeout(isReadyTimeout)
                res(...args)
            })
            isReadyTimeout = setTimeout(() => {
                if (analytics?._integrations && analytics._integrations['Segment.io']?._ready) {
                    res()
                }
                else {
                    rej({
                        service: 'AnalyticsService',
                        status: ANALYTICS_SERVICE_ERRORS.timeout,
                    })
                }
            }, 5000)
        })
    }
}

const analyticsServiceSingleton = new AnalyticsService

export default analyticsServiceSingleton

function analyticsServiceTimeoutError(target, property, descriptor) {
    const originalMethod = descriptor.value.bind(target)

    descriptor.value = async (...args) => {
        try {
            return await originalMethod(...args)
        }
        catch (error) {
            if (error.status == ANALYTICS_SERVICE_ERRORS.timeout) {
                const sentryError = new Error()
                sentryError.name = 'ANALYTICS_SERVICE_ERRORS'
                Sentry.captureException(sentryError, {
                    extra: {
                        status: ANALYTICS_SERVICE_ERRORS.timeout,
                    },
                })

                analyticsServiceSingleton.trackQueue.push({
                    error: error,
                    track: () => originalMethod(...args),
                })

                return Promise.resolve()
            }

            throw error
        }
    }

    return descriptor
}
