import {nonenumerable} from 'core-decorators'

import modelSerialize from 'common_utils/model_serialize'

@modelSerialize([
    'authTokenData.first_name',
    'authTokenData.last_name',
    'authTokenData.email',
    'authTokenData.team',
    'authTokenData.uuid',
    'authTokenData.exp',
    'authTokenData.billing',
    'authTokenData.is_superuser',
    'authTokenData.user_groups',
    'authTokenData.is_ibuyer_paid',
    'authTokenData.is_ibuyer_trained',
])
class Token {
    @nonenumerable
    _token = null

    authTokenData = parseJwt(this._token)

    constructor(tokenName) {
        this.tokenName = tokenName
    }

    get jwt() {
        return this._token
    }

    set jwt(newToken) {
        this._token = newToken
        this.authTokenData = parseJwt(this.jwt)

        return this.jwt
    }

    clear() {
        this.jwt = null
    }

    get isNotExpired() {
        return !this.jwt
            ? false
            : new Date(this.authTokenData.exp * 1000) > Date.now()
    }
}

export default Token

function parseJwt(jwtToken) {
    if (jwtToken) {
        const base64Url = jwtToken.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')

        return JSON.parse(window.atob(base64))
    }

    return {}
}
