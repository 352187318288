import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {}

const searchKeywordsReducer = new Reducer(INITIAL_STATE)

searchKeywordsReducer.add(actionTypes.keywords.searchKeyword)

export default searchKeywordsReducer.reducersFunction
