import {autobind} from 'core-decorators'

import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from 'common_services/api'

import BillingPlan from './billing_plan'

const billingApi = new Api(API_DOMAINS.usersApi, 'billing')

@modelSerialize([
    'plans',
    'stripePlanGroups',
])
class BillingPlansList {
    plans = []
    stripePlanGroups = {}

    @autobind
    async loadStripePlanGroups({userGroup, preloadStripePlan = false}) {
        const stripePlanGroups = (await billingApi.getApiWithName('plan-groups').get({
            user_group: userGroup,
        })).data
            .filter(({plan_id}) => plan_id != 'free_plan')
        const plans = {}

        if (preloadStripePlan) {
            (await Promise.all(stripePlanGroups.map(({plan_id}) => BillingPlan.loadBillingPlanById(plan_id))))
                .forEach(currentPlan => plans[currentPlan.id] = currentPlan)
        }

        this.stripePlanGroups = stripePlanGroups
            .reduce(
                (currentObj, nextPlan) => ({
                    ...currentObj,
                    [nextPlan.slug]: {
                        ...nextPlan,
                        plan: plans[nextPlan.plan_id],
                    },
                }),
                {}
            )

        return this.serialize
    }

    @autobind
    async loadAllBillingPlans() {
        this.plans = (await billingApi.getApiWithName('plans').get({
            limit: 100,
        })).data.data
            .map(billingPlan => new BillingPlan(billingPlan))

        return this.serialize
    }
}

export default new BillingPlansList
