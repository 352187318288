import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.styl'

export default function Separator({className}) {
    return <div className={cn(styles.separator, className)} />
}

Separator.propTypes = {
    className: PropTypes.string,
}
