import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import ReactPhoneInput from 'react-phone-input-2'
import cn from 'classnames'

import 'react-phone-input-2/dist/style.css'

import ErrorMessage from '../error_message'
import RequiredLabel from '../required_label'

import styles from './styles.styl'

export default function CustomField({...props}) {
    return <Field component={PhoneField} {...props} />
}

export function PhoneField({
    id,
    input: {name, value, ...eventListener},
    descriptionText,
    descriptionTextClassName,
    className,
    externalTextError,
    meta: {error, visited, touched, active, ...meta},
    isImportant,
    ...props
}) {
    const isError = !!getError(error)

    return (
        <div
            className={cn(
                styles['input-block'],
                className
            )}
        >
            <div className={styles['input-label']} invalid={`${isError}`}>
                <div className="flex align-center mb-xs">
                    {
                        descriptionText && (
                            <p
                                className={
                                    cn(
                                        'text-xs proxima-bold uppercase m-zero',
                                        descriptionTextClassName
                                    )
                                }
                            >
                                {descriptionText}
                            </p>
                        )
                    }
                    {
                        isImportant && (
                            <RequiredLabel className="text-xs proxima my-zero ml-xs" />
                        )
                    }
                </div>
                <Phone
                    inputExtraProps={{
                        name: name,
                        id: id,
                    }}
                    value={value.phone || value}
                    eventListener={eventListener}
                    countryCodeEditable={false}
                    {...props}
                />
                {
                    externalTextError
                        ? (
                            <ErrorMessage>
                                {externalTextError}
                            </ErrorMessage>
                        )
                        : (
                            isError && error && error.text && (
                                <ErrorMessage text={error.text} />
                            )
                        )
                }
            </div>
        </div>
    )

    function getError() {
        if (error) {
            if (error.params && error.params.afterBlur) {
                return touched && error.text
            }
            return visited && error.text
        }
        return null
    }
}

PhoneField.propTypes = {
    id: PropTypes.any,
    input: PropTypes.object,
    meta: PropTypes.object,
    descriptionText: PropTypes.string,
    descriptionTextClassName: PropTypes.string,
    className: PropTypes.string,
    externalTextError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    isImportant: PropTypes.bool,
}

export class Phone extends Component {
    static propTypes = {
        value: PropTypes.any,
        eventListener: PropTypes.object,
        inputClass: PropTypes.string,
        forceInputClass: PropTypes.string,
    }

    reactPhoneInput = React.createRef()

    render() {
        const {inputClass, forceInputClass, eventListener: {onChange, onBlur, ...eventListener}, ...props} = this.props

        return (
            <ReactPhoneInput
                ref={this.reactPhoneInput}
                preferredCountries={['us']}
                defaultCountry="us"
                placeholder="+1 (555) 555-5555"
                disableAreaCodes
                dropdownClass={styles['country-dropdown']}
                inputClass={
                    forceInputClass
                        ? forceInputClass
                        : cn(
                            styles.input,
                            'text-md letter-md text-dark-grey proxima-medium',
                            inputClass
                        )
                }
                buttonClass={styles['button-country-dropdown']}
                {...props}
                {...eventListener}
                onChange={(phoneNumber, countryData) => onChange(this.createPhoneObject(phoneNumber, countryData))}
                onBlur={(phoneNumber, countryData) => onBlur(this.createPhoneObject(phoneNumber, countryData))}
            />
        )
    }

    componentDidMount() {
        const countryData = this.reactPhoneInput.current.getCountryData()

        this.reactPhoneInput.current.numberInputRef.setAttribute('autocomplete', 'off')
        this.reactPhoneInput.current.numberInputRef.setAttribute('name', `tel-${Math.trunc(Math.random() * 1000)}`)

        this.reactPhoneInput.current.dropdownContainerRef.setAttribute('tabindex', -1)

        setTimeout(
            () => this.props.eventListener.onChange(
                this.createPhoneObject(
                    this.props.value || `+${countryData.dialCode}`,
                    countryData
                )
            )
        )
    }

    createPhoneObject(phone, countryData) {
        const event = typeof phone == 'string' ? null : phone
        phone = typeof phone == 'string' ? phone : phone.target.value
        const phoneNumber = phone.replace(/\D/g, '').replace(new RegExp(`^${countryData.dialCode}`), '')

        return {
            targetId: event?.target?.id,
            phone: phone,
            rawPhone: phone.replace(/\D/g, ''),
            phoneNumber: phoneNumber,
            notEmptyPhone: phoneNumber ? phone : '',
            countryData,
        }
    }
}
