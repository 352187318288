import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import MlsAgentService from 'common_services/mls_agents/mls_agent'

import {UserService} from '../user'
import Api from '../api'
import PropertyService from '../properties2/property'
import BuyerService from '../buyer/buyer'

const matchingApi = new Api(API_DOMAINS.leadsApi, 'matching')
const matchingV2Api = new Api(API_DOMAINS.leadsApi, 'matching', undefined, 2)

@modelSerialize([
    'uuid',
    'type',
    'property',
    'buyer',
    'uuid',
    'score',
    'details',
    'status',
    {
        serializeField: 'date_link_expired',
        originField: 'date_link_expired',
        type: Date,
    },
    {
        serializeField: 'expired_in_seconds',
        originField: 'expired_in_seconds',
        type: seconds => new Date(seconds * 1000),
    },
    'short_link_data',
])
class MatchService {
    static matchTypes = {
        all: 'matches',
        matchForProperty: 'property',
        matchForBuyer: 'buyer',
    }
    static matchCategory = {
        new: 'new',
        progress: 'progress',
        past: 'past',
        successful: 'successful',
    }

    uuid = ''
    type = null
    property = null
    buyer = null
    uuid = ''
    score = 0
    details = null
    status = ''
    date_link_expired = null
    expired_in_seconds = null
    short_link_data = null

    constructor(matchType = MatchService.matchTypes.all, data) {
        this.matchType = matchType
        if (data) {
            if (typeof data.property != 'string') {
                data.property = new PropertyService(data.property)
            }

            if (typeof data.buyer != 'string') {
                data.buyer = new BuyerService(data.buyer)
            }

            this.serialize = data
        }
    }

    async loadMatchByUUID(matchUUID, matchType = MatchService.matchTypes.all) {
        this.serialize = await MatchService.getMatchByUUID(matchUUID || this.uuid, matchType)

        return this.serialize
    }

    async loadPublicMatchByUUID(matchUUID) {
        this.serialize = await MatchService.getPublicMatchByUUID(matchUUID || this.uuid)

        this.buyer = await BuyerService.getAutoShareBuyerByUUID(this.buyer)
        if (this.buyer.agent) {
            this.buyer.agent = await UserService.getPublicUserInfoByUuid(this.buyer.agent)
        }

        this.property = new PropertyService(this.property)

        await this.property.loadMLSpropertiesData()
        if (this.property.agent) {
            this.property.agent = await UserService.getPublicUserInfoByUuid(this.property.agent)
        }
        if (this.property.mls_agent) {
            this.property.mls_agent = await MlsAgentService.getPublicMlsAgentByUUID(this.property.mls_agent)
        }

        return this.serialize
    }

    async matchAction(actionName, params) {
        const {
            property, // eslint-disable-line no-unused-vars
            buyer, // eslint-disable-line no-unused-vars
            ...newMatchData
        } = (await matchingApi.getApiWithName('actions').patch(this.uuid, {
            action: actionName,
            ...params,
        })).data

        this.serialize = newMatchData

        return this.serialize
    }

    async matchReadAction(actionKey, matchUUID) {
        return (await matchingApi.getApiWithName('matches/action').post({
            action: actionKey,
            matches: [matchUUID],
        })).data
    }

    static async getMatchByUUID(matchUUID, matchType = MatchService.matchTypes.all) {
        const currentApi = matchType == MatchService.matchTypes.all
            ? matchingApi.getApiWithName('matches')
            : matchingApi.getApiWithName(`${matchType}/categorized`)

        return new MatchService(
            matchType,
            (await currentApi.getByKey(matchUUID)).data
        )
    }

    static async getPublicMatchByUUID(matchUUID) {
        return new MatchService(
            MatchService.matchTypes.matchForBuyer,
            (await matchingApi.getApiWithName('matches/shared').getByKey(matchUUID)).data
        )
    }

    static async createMatchBetweenPropertyAndBuyerAutoShare(buyerUUID, propertyUUID, matchStatus) {
        return new MatchService(
            MatchService.matchTypes.all,
            (
                await (
                    matchingV2Api.getApiWithName('create-match/buyer-auto-shared').post({
                        status: matchStatus,
                        buyer: buyerUUID,
                        property: propertyUUID,
                    })
                )
            ).data
        )
    }

    static async sendPublicMatchAction(matchUUID, actionName, actionData) {
        return new MatchService(
            MatchService.matchTypes.all,
            (
                await (
                    matchingApi.getApiWithName('public-actions').patch(
                        matchUUID,
                        {
                            action: actionName,
                            ...actionData,
                        }
                    )
                )
            ).data
        )
    }
}

export default MatchService
