import {combineReducers} from 'redux'

import {reducer as formReducer} from 'redux-form'

export default combineReducers({
    user: require('common_redux/reducers/user.js').default,
    commonChoices: require('common_redux/reducers/common_choices.js').default,
    buyer: require('common_redux/reducers/buyer.js').default,
    buyers2: require('common_redux/reducers/buyers2.js').default,
    buyerDetail: require('common_redux/reducers/buyer_detail.js').default,
    routingRules: require('common_redux/reducers/routing_rules.js').default,
    form: formReducer,

    mlsVendors: require('common_redux/reducers/mls_vendor_search.js').default,

    properties2: require('common_redux/reducers/properties2.js').default,
    matches: require('common_redux/reducers/matches.js').default,

    searchKeyword: require('common_redux/reducers/keywords.js').default,
    billing: require('common_redux/reducers/billing.js').default,

    agents: require('common_redux/reducers/agents.js').default,
    mlsAgents: require('common_redux/reducers/mls_agents.js').default,
})
