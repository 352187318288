import IS_DEMO_ENVIRONMENT from 'IS_DEMO_ENVIRONMENT'

import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import cn from 'classnames'

import Toast from 'common_components/toast'
import Modal from 'common_components/modal'
import Button from 'common_components/button'
import Link from 'common_components/link'
import DemoBar from 'common_components/demo_bar'

import OpenLinkIcon from 'common_svg/open_link.svg?jsx'

import NavBar from '../nav_bar'
import Footer from '../footer'

import styles from './styles.styl'

function AdminPageWrapper({pageComponent, isLoading, query, history, match, pageProps = {}, ...props}) {
    const [showSuccessfulSubscription, setShowSuccessfulSubscription] = useState(
        query.search.showSuccessfulSubscription
    )
    const {isProUser} = query.search

    return (
        <Fragment>
            <div className={styles.content}>
                {
                    IS_DEMO_ENVIRONMENT && <DemoBar />
                }
                {
                    !isLoading && !pageProps.hideMenu && (
                        <NavBar
                            query={query}
                            history={history}
                            className={cn({
                                'md-hide': pageProps.mobileHideMenu,
                            })}
                        />
                    )
                }
                {pageComponent}
            </div>
            {
                !isLoading && !pageProps.hideFooter && <Footer className={styles.footer} />
            }
            <Toast />
            <Modal
                size={Modal.size.xs}
                show={showSuccessfulSubscription}
                content={
                    <div className="text-center text-dark-grey-blue">
                        <p className="proxima-bold text-xl letter-lg mt-zero mb-md">
                            Welcome to Raven {isProUser && 'Pro'}
                        </p>
                        <p className="proxima text-lg mt-zero mb-md-lg">
                            {
                                isProUser
                                    ? `
                                        Get started below by going through our Pro Tour
                                        or schedule a 1-on-1 training with our team.
                                    `
                                    : 'Get started below by schedule a 1-on-1 training with our team.'
                            }
                        </p>
                        {
                            isProUser && (
                                <Link
                                    text={
                                        <Fragment>
                                            PRO TOUR
                                            <OpenLinkIcon className={cn(styles['link-out-icon'], 'ml-xs')} />
                                        </Fragment>
                                    }
                                    path="/properties?product_tour_id=58772"
                                    className={cn(styles['link-out'], 'text-center mt-md-lg mb-sm')}
                                    target="_blank"
                                />
                            )
                        }
                        <Link
                            text={
                                <Fragment>
                                    SCHEDULE TRAINING
                                    <OpenLinkIcon className={cn(styles['link-out-icon'], 'ml-xs')} />
                                </Fragment>
                            }
                            path="https://raven.re/training/?utm_source=in-app&utm_medium=modal&utm_campaign=pro-upgrade"
                            className={cn(styles['link-out'], 'text-center mb-sm')}
                            target="_blank"
                        />
                        <Button.full
                            title="DONE"
                            onClick={closeSuccessfulSubscriptionModal}
                        />
                    </div>
                }
                onClose={closeSuccessfulSubscriptionModal}
            />
        </Fragment>
    )

    function closeSuccessfulSubscriptionModal() {
        const newQueryString = qs.stringify({
            ...query.search,
            subscribe: undefined,
            plan: undefined,
            showSuccessfulSubscription: undefined,
            isProUser: undefined,
        })

        history.replace(`${location.pathname}${newQueryString ? `?${newQueryString}` : ''}`)

        setShowSuccessfulSubscription(false)
    }
}

AdminPageWrapper.propTypes = {
    pageComponent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
    isLoading: PropTypes.bool,
    query: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    pageProps: PropTypes.object,
}

export default AdminPageWrapper
