import selectedBuyer from 'common_services/buyer/selected_buyer'

import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {
    selectedBuyer: selectedBuyer.serialize,
}

const buyerReducer = new Reducer(INITIAL_STATE)

buyerReducer.add(actionTypes.buyer.selectedBuyerActions.loadBuyerByUUID, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.loadAllAgent, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.changeCoAgents, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.changePartners, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.saveBuyer, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.updateSomeFields, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.loadSearchPortalShortLink, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.sendInviteToAgent, 'selectedBuyer')
buyerReducer.add(actionTypes.buyer.selectedBuyerActions.removeAgentsInvite, 'selectedBuyer')

buyerReducer.add(actionTypes.buyer.updateBuyerDetail)
buyerReducer.add(actionTypes.buyer.deleteBuyer)

export default buyerReducer.reducersFunction

export {buyerReducer}
