import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'
import PaginationService from '../pagination'

import Property from './property'

const propertiesApi = new Api(API_DOMAINS.leadsApi, 'properties')

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
])
class PropertiesSearchService extends PaginationService {
    async loadSearchingProperties(page, search, {filters} = {}) {
        await super.loadPage(page, {search, filters})

        return this.serialize
    }
}

export default new PropertiesSearchService(Property, propertiesApi, 10)
