import BuyerService from './buyer'

let selectedBuyer = new BuyerService

export const resetSelectedBuyer = () => {
    selectedBuyer = new BuyerService

    return selectedBuyer.serialize
}

export default selectedBuyer

export function getCurrentSelectedBuyer() {
    return selectedBuyer
}
