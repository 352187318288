/* global module */

import {hot} from 'react-hot-loader'

import {getRoutes, addDefaultParamsToRoutes, redirects} from 'common_utils/routes_utils'

import {UserService} from 'common_services/user'

import AdminPage from 'adminProj/components/page'

import EmptyPage from 'adminProj/pages/default'
import StyleGuidePage from 'adminProj/pages/style_guide'

import AuthenticationPage from 'adminProj/pages/user/authentication'
import TeamInvitationRegisterPage from 'adminProj/pages/user/team_invitation_register'
import MlsAgentInvitationRegisterPage from 'adminProj/pages/user/mls_agent_invitation_register'
import UserSettingsPage from 'adminProj/pages/user/user_settings'
import TeamSettingsPage from 'adminProj/pages/user/team_settings'
import TeamInvitationPage from 'adminProj/pages/user/team_invitation'
import PartnerInvitationPage from 'adminProj/pages/user/partner_invitation'
import PartnersListPage from 'adminProj/pages/partners_list'
import PropertyList from 'adminProj/pages/property_list'
import propertyListConfigs from 'adminProj/pages/property_list/property_list_configs'
import GoogleMapPage from 'adminProj/pages/map'
import BuyerList from 'adminProj/pages/buyer_list'
import buyerListConfigs from 'adminProj/pages/buyer_list/buyer_list_configs.js'
import UserDataPage from 'adminProj/pages/user/data'
import LeadRoutingList from 'adminProj/pages/lead_routing'
import ForgotPasswordPage from 'adminProj/pages/reset_password/forgot_password'
import ResetPassword from 'adminProj/pages/reset_password/reset'
import LicenseNumber from 'adminProj/pages/user/user_verification/license_number'
import MlsId from 'adminProj/pages/user/user_verification/mls_id'
import MagicLoginPage from 'adminProj/pages/user/magic_login'
import TrainingPage from 'adminProj/pages/training'
import Page404 from 'adminProj/pages/404'

import ReviewPlanPage from 'adminProj/pages/billing/review_plan'
import PricePlans from 'adminProj/pages/billing/price_plans'
import SubscriptionPage from 'adminProj/pages/billing/subscription'
import TeamPlanPage from 'adminProj/pages/billing/subscription/team_plan'
import CorePlanPage from 'adminProj/pages/billing/subscription/core_plan'
import BookDemo from 'adminProj/pages/book_demo'

// TODO: move component to common space
import PropertiesSearchList from 'buyerPortalsProj/pages/properties/properties_list'
import propertySearchListConfigs from 'buyerPortalsProj/pages/properties/properties_list/property_list_configs'

// TODO: uncomment when the design and API will be updated
// import AgentsListPage from 'adminProj/pages/agents_list'
import MlsAgentsListPage from 'adminProj/pages/mls_agents_list'

const routesObj = getRoutesObj()
const routes = getRoutes(routesObj, AdminPage)

function getRoutesObj() {
    const currentRoutes = [
        redirect('/', '/login'),
        {
            path: '/style_guide',
            title: 'Style Guide - Raven',
            pageName: 'style_guide',
            component: StyleGuidePage,
        },

        {
            path: '/login',
            partnerPath: null,
            titleRepPath: null,
            title: 'Authorization - Raven',
            pageName: 'authorization',
            component: AuthenticationPage,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
            pageProps: {
                mobileHideMenu: true,
                hideFooter: true,
                isLoginView: true,
                analytics: {
                    openPage: {
                        eventName: 'Viewed Login Screen',
                    },
                },
            },
        },
        {
            path: '/register',
            partnerPath: null,
            titleRepPath: null,
            title: 'Authorization - Raven',
            pageName: 'register',
            component: AuthenticationPage,
            redirects: [],
            exact: true,
            pageProps: {
                mobileHideMenu: true,
                hideFooter: true,
                isLoginView: false,
                analytics: {
                    openPage: {
                        eventName: 'Viewed Signup Screen',
                    },
                },
            },
        },
        {
            path: '/register/invitation',
            partnerPath: null,
            titleRepPath: null,
            title: 'Team Invitation - Raven',
            pageName: 'team_invitation_register',
            component: TeamInvitationRegisterPage,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
        },
        {
            path: '/register/mls-agent-invitation',
            partnerPath: null,
            titleRepPath: null,
            title: 'Mls Agent Invitation - Raven',
            pageName: 'mls_agent_invitation_register',
            component: MlsAgentInvitationRegisterPage,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
        },

        redirect('/settings', '/settings/profile'),
        {
            path: '/settings/profile',
            partnerPath: '/settings/profile',
            titleRepPath: '/settings/profile',
            title: 'Settings - Raven',
            pageName: 'user_settings',
            component: UserSettingsPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                requiredData: {
                    requiredCommonChoices: {
                        leads: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Settings Screen',
                    },
                },
            },
        },
        {
            path: '/team-settings',
            partnerPath: '/settings/profile',
            titleRepPath: '/settings/profile',
            title: 'Team Settings - Raven',
            pageName: 'team_settings',
            component: TeamSettingsPage,
            exact: true,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyForOwnerOrAdmin('/properties', false),
            ],
            pageProps: {
                requiredData: {
                    requiredCommonChoices: {
                        users: true,
                    },
                    requiredBillingData: {
                        stripePlanGroups: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Team Settings Screen',
                    },
                },
            },
        },
        {
            path: '/team-settings/invitation',
            partnerPath: '/settings/profile',
            titleRepPath: '/settings/profile',
            title: 'Team Invitation - Raven',
            pageName: 'team_invitation',
            component: TeamInvitationPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                hideMenu: true,
                hideFooter: true,
            },
        },

        {
            path: '/properties',
            partnerPath: '/clients/sellers',
            title: 'Sellers List - Raven',
            pageName: 'property_list',
            component: PropertyList,
            redirects: [
                redirects.authRequire('/register'),
                redirects.proPlanCustomer('/review-plan'),
            ],
            pageProps: {
                pageConfig: propertyListConfigs.sellers,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Properties Screen',
                    },
                },
            },
        },
        {
            path: '/active-listing',
            partnerPath: '/clients/sellers',
            title: 'Active Listing List - Raven',
            pageName: 'active_listing_list',
            component: PropertyList,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                pageConfig: propertyListConfigs.activeListing,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Active Listing Screen',
                    },
                },
            },
        },
        {
            path: '/matches-listing',
            partnerPath: '/clients/sellers',
            title: 'Matches Listing List - Raven',
            pageName: 'matches_listing_list',
            component: PropertyList,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                pageConfig: propertyListConfigs.matchesListing,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Matches Listing Screen',
                    },
                },
            },
        },

        {
            path: '/map',
            title: 'Map - Raven',
            pageName: 'map',
            component: GoogleMapPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
        },

        {
            path: '/buyers',
            partnerPath: '/clients/buyers',
            title: 'Buyers List - Raven',
            pageName: 'buyer_list',
            component: BuyerList,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                pageConfig: buyerListConfigs.leads,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Buyers Screen',
                    },
                },
            },
        },
        {
            path: '/matches-buyers',
            partnerPath: '/clients/buyers',
            title: 'Matches Buyers List - Raven',
            pageName: 'matches_Buyers_list',
            component: BuyerList,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                pageConfig: buyerListConfigs.matchesListing,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
                analytics: {
                    openPage: {
                        eventName: 'Viewed Matches Buyers Screen',
                    },
                },
            },
        },

        {
            path: '/user-data',
            title: 'User data - Raven',
            pageName: 'user_data',
            component: UserDataPage,
        },

        {
            path: '/lead-routing',
            title: 'Lead Routing - Raven',
            pageName: 'lead_routing',
            component: LeadRoutingList,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyForOwnerOrAdmin('/properties', false),
            ],
        },

        {
            path: '/forgot-password',
            partnerPath: null,
            titleRepPath: null,
            title: 'Forgot Password - Raven',
            pageName: 'forgot_password',
            component: ForgotPasswordPage,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
        },

        {
            path: '/reset-password',
            partnerPath: null,
            titleRepPath: null,
            title: 'Reset Password - Raven',
            pageName: 'reset',
            component: ResetPassword,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
        },

        {
            path: '/magic-login',
            partnerPath: null,
            titleRepPath: null,
            title: 'Magic Login - Raven',
            pageName: 'magic_login',
            component: MagicLoginPage,
            redirects: [
                redirects.onlyAnonymous('/properties', false),
            ],
        },

        {
            path: '/license-number',
            title: 'License Number - Raven',
            pageName: 'license',
            component: LicenseNumber,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyWithoutLicenseNumber('/mls-id', false),
            ],
        },
        {
            path: '/mls-id',
            title: 'Mls Id - Raven',
            pageName: 'mlsId',
            component: MlsId,
            redirects: [
                redirects.authRequire('/register'),
                redirects.proPlanCustomer('/review-plan'),
                redirects.onlyWithoutMlsId('/properties', false),
            ],
            pageProps: {
                analytics: {
                    openPage: {
                        eventName: 'Viewed MLS Verification',
                    },
                },
            },
        },

        {
            path: '/price-plans',
            title: 'Price Plans - Raven',
            pageName: 'price_plans',
            component: PricePlans,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyWhoCanBuyPlan('/properties', false),
            ],
            pageProps: {
                requiredData: {
                    requiredBillingData: {
                        stripePlanGroups: true,
                    },
                },
            },
        },

        {
            path: '/subscription',
            title: 'Subscription - Raven',
            pageName: 'subscription',
            component: SubscriptionPage,
            redirects: [
                redirects.authRequire('/register', false),
            ],
            pageProps: {
                hideFooter: true,
            },
        },
        {
            path: '/subscription/team-plan/:planId?',
            title: 'Team Plan - Raven',
            pageName: 'team_plan',
            component: TeamPlanPage,
            redirects: [
                redirects.authRequire('/register', false),
            ],
            pageProps: {
                requiredData: {
                    requiredBillingData: {
                        stripePlanGroups: true,
                    },
                },
            },
        },
        {
            path: '/subscription/core-plan/:planId?',
            title: 'Core Plan - Raven',
            pageName: 'core_plan',
            component: CorePlanPage,
            redirects: [
                redirects.authRequire('/register', false),
            ],
            pageProps: {
                requiredData: {
                    requiredBillingData: {
                        stripePlanGroups: true,
                    },
                },
            },
        },

        {
            path: '/book-demo',
            title: 'Book Demo - Raven',
            pageName: 'book_demo',
            component: BookDemo,
        },

        // TODO: uncomment when the design and API will be updated
        // {
        //     path: '/agents',
        //     title: 'Agents - Raven',
        //     pageName: 'agents_list',
        //     component: AgentsListPage,
        //     redirects: [
        //         redirects.authRequire('/register'),
        //     ],
        // },
        {
            path: '/mls-agents',
            title: 'MLS Agents - Raven',
            pageName: 'mls_agents_list',
            component: MlsAgentsListPage,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyForSuperuser('/properties', false),
            ],
            pageProps: {
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
            },
        },

        {
            path: '/search',
            title: 'Search Properties - Raven',
            pageName: 'search_properties',
            component: PropertiesSearchList,
            redirects: [
                redirects.authRequire('/register'),
                redirects.proPlanReqiure('/properties'),
            ],
            pageProps: {
                hideFooter: true,
                pageConfig: propertySearchListConfigs.adminSearchTab,
                requiredData: {
                    requiredCommonChoices: {
                        all: true,
                    },
                },
            },
        },

        {
            path: '/review-plan',
            title: 'Review Plan - Raven',
            pageName: 'review_plan',
            component: ReviewPlanPage,
            redirects: [
                redirects.authRequire('/register'),
                redirects.onlyBasicCustomer('/properties', false),
            ],
        },

        {
            path: '/partner-invite',
            title: 'Partner Invitation - Raven',
            pageName: 'partner_invitation',
            component: PartnerInvitationPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                mobileHideMenu: true,
            },
        },

        {
            path: '/partners',
            title: 'Partners - Raven',
            pageName: 'partners_list',
            component: PartnersListPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
        },

        {
            path: '/training',
            partnerPath: '/training/lenders',
            title: 'Training - Raven',
            pageName: 'training_page',
            component: TrainingPage,
            redirects: [
                redirects.authRequire('/register'),
            ],
            pageProps: {
                header: 'Learn How to Get Started with Raven',
                subheader: 'Click the thumbnails to watch.',
                iframeUrl: 'https://raven.re/agent-quick-start-no-header/',
            },
        },

        {
            title: '404',
            pageName: '404',
            component: Page404,
        },
    ]
        .map(route => {
            route.redirects = route.redirects || []

            const hasProjectRedirect = route.redirects
                .map(currentRedirect => currentRedirect.toString())
                .indexOf(redirects.checkCorrespondenceOfUserGroupAndProject().toString()) != -1

            if (!hasProjectRedirect && (route.partnerPath !== null || route.titleRepPath !== null)) {
                route.redirects.unshift(
                    redirects.checkCorrespondenceOfUserGroupAndProject(
                        UserService.availableUserGroups.agents.agent,
                        {
                            partnerPath: route.partnerPath || '',
                            titleRepPath: route.titleRepPath || '',
                        }
                    )
                )
            }

            return route
        })
        .map(route => {
            if (module.hot) {
                route.component = hot(module)(route.component)
            }
            else {
                route.loadComponent = route.component
                delete route.component
            }
            return route
        })

    return addDefaultParamsToRoutes(currentRoutes)
}

export default routes
export {routesObj, getRoutesObj, getRoutes}

function redirect(fromPath, toPath) {
    return {
        exact: true,
        path: fromPath,
        pageName: 'default',
        component: EmptyPage,
        redirects: [
            redirects.redirect(toPath),
        ],
    }
}
