import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import {bindActionCreators} from 'redux'
import {SubmissionError} from 'redux-form'
import cn from 'classnames'

import Toast from 'common_components/toast'

import billingActions from 'common_redux/actions/billing'

import FormFactory from 'common_components/form'

class CouponForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        getCouponById: PropTypes.func.isRequired,

        className: PropTypes.string,
        formData: PropTypes.object,
    }

    static couponIdValidators = [
        FormFactory.validators.slug(
            'A Promo Code may only contain alphanumeric characters in addition to - and _. The length is also restricted to 200 characters.', // eslint-disable-line max-len
            true,
            true
        ),
    ]

    render() {
        const {className, handleSubmit, formData} = this.props

        return (
            <form
                className={cn('flex flex-column space-between', className)}
                onSubmit={handleSubmit(this.getCouponById)}
            >
                <FormFactory.Field
                    name="couponId"
                    descriptionText="PROMO CODE"
                    placeholder="enter promo code"
                    withButton={
                        formData?.values?.couponId
                            ? {
                                type: 'submit',
                                title: 'apply',
                                disabled: !formData?.values?.couponId,
                            }
                            : null
                    }
                    validate={CouponForm.couponIdValidators}
                />
            </form>
        )
    }

    @autobind
    async getCouponById({couponId}) {
        try {
            const coupon = await this.props.getCouponById(couponId)

            if (!coupon.valid) {
                Toast.show.error('Invalid promo code.')
            }

            return coupon
        }
        catch ({response}) {
            const {data, status: resStatus} = response

            if (resStatus == 404 && data.code == 'resource_missing') {
                Toast.show.error('Invalid promo code.')
            }
            else {
                Toast.show.error(response.data.detail)
            }

            throw new SubmissionError(response)
        }
    }
}

export default FormFactory.registerForm(
    CouponForm,
    {
        form: 'CouponForm',
    },
    () => ({}),
    dispatch => bindActionCreators(billingActions, dispatch)
)
