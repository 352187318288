const ACTION_TYPES = {
    users: {
        authenticate: {
            start: 'USERS:AUTHENTICATE:START_AUTHENTICATE',
            success: 'USERS:AUTHENTICATE:SUCCESS_AUTHENTICATE',
            error: 'USERS:AUTHENTICATE:ERROR_AUTHENTICATE',
        },
        login: {
            start: 'USERS:LOGIN:START_LOGIN',
            success: 'USERS:LOGIN:SUCCESS_LOGIN',
            error: 'USERS:LOGIN:ERROR_LOGIN',
        },
        register: {
            start: 'USERS:REGISTER:START_REGISTER',
            success: 'USERS:REGISTER:SUCCESS_REGISTER',
            error: 'USERS:REGISTER:ERROR_REGISTER',
        },
        registerMlsAgent: {
            start: 'USERS:REGISTER_MLS_AGENT:START_REGISTER_MLS_AGENT',
            success: 'USERS:REGISTER_MLS_AGENT:SUCCESS_REGISTER_MLS_AGENT',
            error: 'USERS:REGISTER_MLS_AGENT:ERROR_REGISTER_MLS_AGENT',
        },
        logout: {
            start: 'USERS:LOGOUT:START_LOGOUT',
            success: 'USERS:LOGOUT:SUCCESS_LOGOUT',
            error: 'USERS:LOGOUT:ERROR_LOGOUT',
        },
        loadMyProfile: {
            start: 'USERS:LOAD_MY_PROFILE:START_LOAD_MY_PROFILE',
            success: 'USERS:LOAD_MY_PROFILE:SUCCESS_LOAD_MY_PROFILE',
            error: 'USERS:LOAD_MY_PROFILE:ERROR_LOAD_MY_PROFILE',
        },
        saveMyProfile: {
            start: 'USERS:SAVE_MY_PROFILE:START_SAVE_MY_PROFILE',
            success: 'USERS:SAVE_MY_PROFILE:SUCCESS_SAVE_MY_PROFILE',
            error: 'USERS:SAVE_MY_PROFILE:ERROR_SAVE_MY_PROFILE',
        },
        changePassword: {
            start: 'USERS:CHANGE_PASSWORD:START_CHANGE_PASSWORD',
            success: 'USERS:CHANGE_PASSWORD:SUCCESS_CHANGE_PASSWORD',
            error: 'USERS:CHANGE_PASSWORD:ERROR_CHANGE_PASSWORD',
        },
        resetPassword: {
            start: 'USERS:RESET:RESET_PASSWORD:START_RESET_PASSWORD',
            success: 'USERS:RESET:RESET_PASSWORD:SUCCESS_RESET_PASSWORD',
            error: 'USERS:RESET:RESET_RASSWORD:ERROR_RESET_PASSWORD',
        },
        resetConfirmPassword: {
            start: 'USERS:RESET:RESET_CONFIRM_PASSWORD:START_RESET_CONFIRM_PASSWORD',
            success: 'USERS:RESET:RESET_CONFIRM_PASSWORD:SUCCESS_RESET_CONFIRM_PASSWORD',
            error: 'USERS:RESET:RESET_CONFIRM_RASSWORD:ERROR_RESET_CONFIRM_PASSWORD',
        },
        generateMagicLoginLink: {
            start: 'USERS:GENERATE_MAGIC_LOGIN_LINK:START_GENERATE_MAGIC_LOGIN_LINK',
            success: 'USERS:GENERATE_MAGIC_LOGIN_LINK:SUCCESS_GENERATE_MAGIC_LOGIN_LINK',
            error: 'USERS:GENERATE_MAGIC_LOGIN_LINK:ERROR_GENERATE_MAGIC_LOGIN_LINK',
        },
        confirmMagicLogin: {
            start: 'USERS:CONFIRM_MAGIC_LOGIN:START_CONFIRM_MAGIC_LOGIN',
            success: 'USERS:CONFIRM_MAGIC_LOGIN:SUCCESS_CONFIRM_MAGIC_LOGIN',
            error: 'USERS:CONFIRM_MAGIC_LOGIN:ERROR_CONFIRM_MAGIC_LOGIN',
        },
        team: {
            createTeam: {
                start: 'USERS:TEAM:CREATE_TEAM:START_CREATE_TEAM',
                success: 'USERS:TEAM:CREATE_TEAM:SUCCESS_CREATE_TEAM',
                error: 'USERS:TEAM:CREATE_TEAM:ERROR_CREATE_TEAM',
            },
            loadMyTeam: {
                start: 'USERS:TEAM:LOAD_MY_TEAM:START_LOAD_MY_TEAM',
                success: 'USERS:TEAM:LOAD_MY_TEAM:SUCCESS_LOAD_MY_TEAM',
                error: 'USERS:TEAM:LOAD_MY_TEAM:ERROR_LOAD_MY_TEAM',
            },
            updateTeam: {
                start: 'USERS:TEAM:UPDATE_TEAM:START_UPDATE_TEAM',
                success: 'USERS:TEAM:UPDATE_TEAM:SUCCESS_UPDATE_TEAM',
                error: 'USERS:TEAM:UPDATE_TEAM:ERROR_UPDATE_TEAM',
            },
            inviteMembers: {
                start: 'USERS:TEAM:INVITE_MEMBERS:START_INVITE_MEMBERS',
                success: 'USERS:TEAM:INVITE_MEMBERS:SUCCESS_INVITE_MEMBERS',
                error: 'USERS:TEAM:INVITE_MEMBERS:ERROR_INVITE_MEMBERS',
            },
            addMembers: {
                start: 'USERS:TEAM:ADD_MEMBERS:START_ADD_MEMBERS',
                success: 'USERS:TEAM:ADD_MEMBERS:SUCCESS_ADD_MEMBERS',
                error: 'USERS:TEAM:ADD_MEMBERS:ERROR_ADD_MEMBERS',
            },
            resendInviteMember: {
                start: 'USERS:TEAM:RESEND_INVITE_MEMBER:START_RESEND_INVITE_MEMBER',
                success: 'USERS:TEAM:RESEND_INVITE_MEMBER:SUCCESS_RESEND_INVITE_MEMBER',
                error: 'USERS:TEAM:RESEND_INVITE_MEMBER:ERROR_RESEND_INVITE_MEMBER',
            },
            removeInviteMember: {
                start: 'USERS:TEAM:REMOVE_INVITE_MEMBER:START_REMOVE_INVITE_MEMBER',
                success: 'USERS:TEAM:REMOVE_INVITE_MEMBER:SUCCESS_REMOVE_INVITE_MEMBER',
                error: 'USERS:TEAM:REMOVE_INVITE_MEMBER:ERROR_REMOVE_INVITE_MEMBER',
            },
            loadMembersInvite: {
                start: 'USERS:TEAM:LOAD_MEMBERS_INVITE:START_LOAD_MEMBERS_INVITE',
                success: 'USERS:TEAM:LOAD_MEMBERS_INVITE:SUCCESS_LOAD_MEMBERS_INVITE',
                error: 'USERS:TEAM:LOAD_MEMBERS_INVITE:ERROR_LOAD_MEMBERS_INVITE',
            },
            changeMemberRole: {
                start: 'USERS:TEAM:CHANGE_MEMBER_ROLE:START_CHANGE_MEMBER_ROLE',
                success: 'USERS:TEAM:CHANGE_MEMBER_ROLE:SUCCESS_CHANGE_MEMBER_ROLE',
                error: 'USERS:TEAM:CHANGE_MEMBER_ROLE:ERROR_CHANGE_MEMBER_ROLE',
            },
            removeMember: {
                start: 'USERS:TEAM:REMOVE_MEMBER:START_REMOVE_MEMBER',
                success: 'USERS:TEAM:REMOVE_MEMBER:SUCCESS_REMOVE_MEMBER',
                error: 'USERS:TEAM:REMOVE_MEMBER:ERROR_REMOVE_MEMBER',
            },
            addMyUserToTheTeam: {
                start: 'USERS:TEAM:ADD_MY_USER_TO_THE_TEAM:START_ADD_MY_USER_TO_THE_TEAM',
                success: 'USERS:TEAM:ADD_MY_USER_TO_THE_TEAM:SUCCESS_ADD_MY_USER_TO_THE_TEAM',
                error: 'USERS:TEAM:ADD_MY_USER_TO_THE_TEAM:ERROR_ADD_MY_USER_TO_THE_TEAM',
            },
        },
        verification: {
            saveLicenseNumber: {
                start: 'USERS:VERIFICATION:SAVE_MLS_LICENSE:START_SAVE_MLS_LICENSE',
                success: 'USERS:VERIFICATION:SAVE_MLS_LICENSE:SUCCESS_SAVE_MLS_LICENSE',
                error: 'USERS:VERIFICATION:SAVE_MLS_LICENSE:ERROR_SAVE_MLS_LICENSE',
            },
            saveMlsId: {
                start: 'USERS:VERIFICATION:SAVE_MLS_ID:START_SAVE_MLS_ID',
                success: 'USERS:VERIFICATION:SAVE_MLS_ID:SUCCESS_SAVE_MLS_ID',
                error: 'USERS:VERIFICATION:SAVE_MLS_ID:ERROR_SAVE_MLS_ID',
            },
            deleteMlsLicenseNumber: {
                start: 'USERS:VERIFICATION:DELETE_LICENSE_NUMBER:START_DELETE_LICENSE_NUMBER',
                success: 'USERS:VERIFICATION:DELETE_LICENSE_NUMBER:SUCCESS_DELETE_LICENSE_NUMBER',
                error: 'USERS:VERIFICATION:DELETE_LICENSE_NUMBER:ERROR_DELETE_LICENSE_NUMBER',
            },
            deleteMlsId: {
                start: 'USERS:VERIFICATION:DELETE_MLS_ID:START_DELETE_MLS_ID',
                success: 'USERS:VERIFICATION:DELETE_MLS_ID:SUCCESS_DELETE_MLS_ID',
                error: 'USERS:VERIFICATION:DELETE_MLS_ID:ERROR_DELETE_MLS_ID',
            },
            saveNmlsId: {
                start: 'USERS:VERIFICATION:SAVE_NMLS_ID:START',
                success: 'USERS:VERIFICATION:SAVE_NMLS_ID:SUCCESS',
                error: 'USERS:VERIFICATION:SAVE_NMLS_ID:ERROR',
            },
            deleteNmlsId: {
                start: 'USERS:VERIFICATION:DELETE_NMLS_ID:START',
                success: 'USERS:VERIFICATION:DELETE_NMLS_ID:SUCCESS',
                error: 'USERS:VERIFICATION:DELETE_NMLS_ID:ERROR',
            },
        },
        loginAsAnotherUser: {
            start: 'USERS:LOGIN_AS_ANOTHER_USER:START_LOGIN_AS_ANOTHER_USER',
            success: 'USERS:LOGIN_AS_ANOTHER_USER:SUCCESS_LOGIN_AS_ANOTHER_USER',
            error: 'USERS:LOGIN_AS_ANOTHER_USER:ERROR_LOGIN_AS_ANOTHER_USER',
        },
        loadInviteTokenInfo: {
            start: 'USERS:LOAD_INVITE_TOKEN_INFO:START_LOAD_INVITE_TOKEN_INFO',
            success: 'USERS:LOAD_INVITE_TOKEN_INFO:SUCCESS_LOAD_INVITE_TOKEN_INFO',
            error: 'USERS:LOAD_INVITE_TOKEN_INFO:ERROR_LOAD_INVITE_TOKEN_INFO',
        },
        generateShortBuyerPortalLink: {
            start: 'USERS:GENERATE_SHORT_BUYER_PORTAL_LINK:START',
            success: 'USERS:GENERATE_SHORT_BUYER_PORTAL_LINK:SUCCESS',
            error: 'USERS:GENERATE_SHORT_BUYER_PORTAL_LINK:ERROR',
        },
        pairWithPartner: {
            start: 'USERS:PAIR_WITH_PARTNER:START',
            success: 'USERS:PAIR_WITH_PARTNER:SUCCESS',
            error: 'USERS:PAIR_WITH_PARTNER:ERROR',
        },
        loadAllMyPairedUsers: {
            start: 'USERS:LOAD_ALL_MY_PAIRED_USERS:START',
            success: 'USERS:LOAD_ALL_MY_PAIRED_USERS:SUCCESS',
            error: 'USERS:LOAD_ALL_MY_PAIRED_USERS:ERROR',
        },
        generatePartnerInviteLink: {
            start: 'USERS:GENERATE_PARTNER_INVITE_LINK:START',
            success: 'USERS:GENERATE_PARTNER_INVITE_LINK:SUCCESS',
            error: 'USERS:GENERATE_PARTNER_INVITE_LINK:ERROR',
        },
        sendPartnerInviteLinkToAgents: {
            start: 'USERS:SEND_PARTNER_INVITE_LINK_TO_AGENTS:START',
            success: 'USERS:SEND_PARTNER_INVITE_LINK_TO_AGENTS:SUCCESS',
            error: 'USERS:SEND_PARTNER_INVITE_LINK_TO_AGENTS:ERROR',
        },
        savePartnerPromotionLink: {
            start: 'USERS:SAVE_PARTNER_PROMOTION_LINK:START',
            success: 'USERS:SAVE_PARTNER_PROMOTION_LINK:SUCCESS',
            error: 'USERS:SAVE_PARTNER_PROMOTION_LINK:ERROR',
        },
        followUsBossIntegration: {
            createFollowUsBossIntegrationObject: {
                start: 'USERS:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                success: 'USERS:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                error: 'USERS:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
            },
            loadFollowUsBossIntegrationObject: {
                start: 'USERS:FOLLOW_US_BOSS_INTEGRATION:LOAD_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                success: 'USERS:FOLLOW_US_BOSS_INTEGRATION:LOAD_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                error: 'USERS:FOLLOW_US_BOSS_INTEGRATION:LOAD_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
            },
        },
        loadNotifications: {
            start: 'USERS:LOAD_NOTIFICATIONS:START',
            success: 'USERS:LOAD_NOTIFICATIONS:SUCCESS',
            error: 'USERS:LOAD_NOTIFICATIONS:ERROR',
        },
        saveNotification: {
            start: 'USERS:SAVE_NOTIFICATION:START',
            success: 'USERS:SAVE_NOTIFICATION:SUCCESS',
            error: 'USERS:SAVE_NOTIFICATION:ERROR',
        },
    },
    searchMlsVendors: {
        start: 'USERS:VERIFICATION:SEARCH_MLS_VENDORS:START_SEARCH_MLS_VENDORS',
        success: 'USERS:VERIFICATION:SEARCH_MLS_VENDORS:SUCCESS_SEARCH_MLS_VENDORS',
        error: 'USERS:VERIFICATION:SEARCH_MLS_VENDORS:ERROR_SEARCH_MLS_VENDORS',
    },
    billing: {
        loadAllBillingPlans: {
            start: 'BILLING:LOAD_ALL_BILLING_PLANS:START_LOAD_ALL_BILLING_PLANS',
            success: 'BILLING:LOAD_ALL_BILLING_PLANS:SUCCESS_LOAD_ALL_BILLING_PLANS',
            error: 'BILLING:LOAD_ALL_BILLING_PLANS:ERROR_LOAD_ALL_BILLING_PLANS',
        },
        loadBillingPlanById: {
            start: 'BILLING:LOAD_BILLING_PLAN_BY_ID:START_LOAD_BILLING_PLAN_BY_ID',
            success: 'BILLING:LOAD_BILLING_PLAN_BY_ID:SUCCESS_LOAD_BILLING_PLAN_BY_ID',
            error: 'BILLING:LOAD_BILLING_PLAN_BY_ID:ERROR_LOAD_BILLING_PLAN_BY_ID',
        },
        loadStripePlanGroups: {
            start: 'BILLING:LOAD_STRIPE_PLAN_GROUPS:START_LOAD_STRIPE_PLAN_GROUPS',
            success: 'BILLING:LOAD_STRIPE_PLAN_GROUPS:SUCCESS_LOAD_STRIPE_PLAN_GROUPS',
            error: 'BILLING:LOAD_STRIPE_PLAN_GROUPS:ERROR_LOAD_STRIPE_PLAN_GROUPS',
        },
        addCustomerCard: {
            start: 'BILLING:ADD_CUSTOMER_CARD:START_ADD_CUSTOMER_CARD',
            success: 'BILLING:ADD_CUSTOMER_CARD:SUCCESS_ADD_CUSTOMER_CARD',
            error: 'BILLING:ADD_CUSTOMER_CARD:ERROR_ADD_CUSTOMER_CARD',
        },
        removeCustomerCard: {
            start: 'BILLING:REMOVE_CUSTOMER_CARD:START_REMOVE_CUSTOMER_CARD',
            success: 'BILLING:REMOVE_CUSTOMER_CARD:SUCCESS_REMOVE_CUSTOMER_CARD',
            error: 'BILLING:REMOVE_CUSTOMER_CARD:ERROR_REMOVE_CUSTOMER_CARD',
        },
        loadCustomer: {
            start: 'BILLING:LOAD_CUSTOMER:START_LOAD_CUSTOMER',
            success: 'BILLING:LOAD_CUSTOMER:SUCCESS_LOAD_CUSTOMER',
            error: 'BILLING:LOAD_CUSTOMER:ERROR_LOAD_CUSTOMER',
        },
        loadBillingHistory: {
            start: 'BILLING:LOAD_BILLING_HISTORY:START_LOAD_BILLING_HISTORY',
            success: 'BILLING:LOAD_BILLING_HISTORY:SUCCESS_LOAD_BILLING_HISTORY',
            error: 'BILLING:LOAD_BILLING_HISTORY:ERROR_LOAD_BILLING_HISTORY',
        },
        loadUpcomingInvoice: {
            start: 'BILLING:LOAD_UPCOMING_INVOICE:START',
            success: 'BILLING:LOAD_UPCOMING_INVOICE:SUCCESS',
            error: 'BILLING:LOAD_UPCOMING_INVOICE:ERROR',
        },
        subscribeToPlan: {
            start: 'BILLING:SUBSCRIBE_TO_PLAN:START_SUBSCRIBE_TO_PLAN',
            success: 'BILLING:SUBSCRIBE_TO_PLAN:SUCCESS_SUBSCRIBE_TO_PLAN',
            error: 'BILLING:SUBSCRIBE_TO_PLAN:ERROR_SUBSCRIBE_TO_PLAN',
        },
        cancelSubscription: {
            start: 'BILLING:CANCEL_SUBSCRIBTION:START_CANCEL_SUBSCRIBTION',
            success: 'BILLING:CANCEL_SUBSCRIBTION:SUCCESS_CANCEL_SUBSCRIBTION',
            error: 'BILLING:CANCEL_SUBSCRIBTION:ERROR_CANCEL_SUBSCRIBTION',
        },
        getCouponById: {
            start: 'BILLING:GET_COUPON_BY_ID:START_GET_COUPON_BY_ID',
            success: 'BILLING:GET_COUPON_BY_ID:SUCCESS_GET_COUPON_BY_ID',
            error: 'BILLING:GET_COUPON_BY_ID:ERROR_GET_COUPON_BY_ID',
        },
        removeSelectedCoupon: {
            start: 'BILLING:REMOVE_SELECTED_COUPON:START_REMOVE_SELECTED_COUPON',
            success: 'BILLING:REMOVE_SELECTED_COUPON:SUCCESS_REMOVE_SELECTED_COUPON',
            error: 'BILLING:REMOVE_SELECTED_COUPON:ERROR_REMOVE_SELECTED_COUPON',
        },
        addCouponToSubscription: {
            start: 'BILLING:ADD_COUPON_TO_SUBSCRIPTION:START_ADD_COUPON_TO_SUBSCRIPTION',
            success: 'BILLING:ADD_COUPON_TO_SUBSCRIPTION:SUCCESS_ADD_COUPON_TO_SUBSCRIPTION',
            error: 'BILLING:ADD_COUPON_TO_SUBSCRIPTION:ERROR_ADD_COUPON_TO_SUBSCRIPTION',
        },
        removeCouponFromSubscription: {
            start: 'BILLING:REMOVE_COUPON_FROM_SUBSCRIPTION:START_REMOVE_COUPON_FROM_SUBSCRIPTION',
            success: 'BILLING:REMOVE_COUPON_FROM_SUBSCRIPTION:SUCCESS_REMOVE_COUPON_FROM_SUBSCRIPTION',
            error: 'BILLING:REMOVE_COUPON_FROM_SUBSCRIPTION:ERROR_REMOVE_COUPON_FROM_SUBSCRIPTION',
        },
        getSetupIntentToken: {
            start: 'BILLING:GET_SETUP_INTENT_TOKEN:START_GET_SETUP_INTENT_TOKEN',
            success: 'BILLING:GET_SETUP_INTENT_TOKEN:SUCCESS_GET_SETUP_INTENT_TOKEN',
            error: 'BILLING:GET_SETUP_INTENT_TOKEN:ERROR_GET_SETUP_INTENT_TOKEN',
        },
        markCardAsAuthenticated: {
            start: 'BILLING:MARK_CARD_AUTHENTICATED:START_MARK_CARD_AUTHENTICATED',
            success: 'BILLING:MARK_CARD_AUTHENTICATED:SUCCESS_MARK_CARD_AUTHENTICATED',
            error: 'BILLING:MARK_CARD_AUTHENTICATED:ERROR_MARK_CARD_AUTHENTICATED',
        },
        markCardAsNotAuthenticated: {
            start: 'BILLING:MARK_CARD_NOT_AUTHENTICATED:START_MARK_CARD_NOT_AUTHENTICATED',
            success: 'BILLING:MARK_CARD_NOT_AUTHENTICATED:SUCCESS_MARK_CARD_NOT_AUTHENTICATED',
            error: 'BILLING:MARK_CARD_NOT_AUTHENTICATED:ERROR_MARK_CARD_NOT_AUTHENTICATED',
        },
    },
    properties2: {
        loadPropertyByUUID: {
            start: 'PROPERTIES_2:LOAD_PROPERTY_BY_UUID:START_LOAD_PROPERTY_BY_UUID',
            success: 'PROPERTIES_2:LOAD_PROPERTY_BY_UUID:SUCCESS_LOAD_PROPERTY_BY_UUID',
            error: 'PROPERTIES_2:LOAD_PROPERTY_BY_UUID:ERROR_LOAD_PROPERTY_BY_UUID',
        },
        loadPublicPropertyByUUID: {
            start: 'PROPERTIES_2:LOAD_PUBLIC_PROPERTY_BY_UUID:START_LOAD_PUBLIC_PROPERTY_BY_UUID',
            success: 'PROPERTIES_2:LOAD_PUBLIC_PROPERTY_BY_UUID:SUCCESS_LOAD_PUBLIC_PROPERTY_BY_UUID',
            error: 'PROPERTIES_2:LOAD_PUBLIC_PROPERTY_BY_UUID:ERROR_LOAD_PUBLIC_PROPERTY_BY_UUID',
        },
        loadBuyerMatches: {
            start: 'PROPERTIES_2:LOAD_BUYER_MATCHES:START_LOAD_BUYER_MATCHES',
            success: 'PROPERTIES_2:LOAD_BUYER_MATCHES:SUCCESS_LOAD_BUYER_MATCHES',
            error: 'PROPERTIES_2:LOAD_BUYER_MATCHES:ERROR_LOAD_BUYER_MATCHES',
        },
        loadAllAgent: {
            start: 'PROPERTIES_2:LOAD_ALL_AGENT:START_LOAD_ALL_AGENT',
            success: 'PROPERTIES_2:LOAD_ALL_AGENT:SUCCESS_LOAD_ALL_AGENT',
            error: 'PROPERTIES_2:LOAD_ALL_AGENT:ERROR_LOAD_ALL_AGENT',
        },
        loadAllPhotoAgents: {
            start: 'PROPERTIES_2:LOAD_ALL_PHOTO_AGENTS:START_LOAD_ALL_PHOTO_AGENTS',
            success: 'PROPERTIES_2:LOAD_ALL_PHOTO_AGENTS:SUCCESS_LOAD_ALL_PHOTO_AGENTS',
            error: 'PROPERTIES_2:LOAD_ALL_PHOTO_AGENTS:ERROR_LOAD_ALL_PHOTO_AGENTS',
        },
        loadAllMlsAgent: {
            start: 'PROPERTIES_2:LOAD_ALL_MLS_AGENT:START_LOAD_ALL_MLS_AGENT',
            success: 'PROPERTIES_2:LOAD_ALL_MLS_AGENT:SUCCESS_LOAD_ALL_MLS_AGENT',
            error: 'PROPERTIES_2:LOAD_ALL_MLS_AGENT:ERROR_LOAD_ALL_MLS_AGENT',
        },
        loadMLSpropertiesData: {
            start: 'PROPERTIES_2:LOAD_MLS_PROPERTIES_DATA:START_LOAD_MLS_PROPERTIES_DATA',
            success: 'PROPERTIES_2:LOAD_MLS_PROPERTIES_DATA:SUCCESS_LOAD_MLS_PROPERTIES_DATA',
            error: 'PROPERTIES_2:LOAD_MLS_PROPERTIES_DATA:ERROR_LOAD_MLS_PROPERTIES_DATA',
        },
        resetSelectedProperty: 'PROPERTIES_2:RESET_SELECTED_PROPERTY',
        loadPropertiesPage: {
            start: 'PROPERTIES_2:LOAD_PROPERTIES_PAGE:START_LOAD_PROPERTIES_PAGE',
            success: 'PROPERTIES_2:LOAD_PROPERTIES_PAGE:SUCCESS_LOAD_PROPERTIES_PAGE',
            error: 'PROPERTIES_2:LOAD_PROPERTIES_PAGE:ERROR_LOAD_PROPERTIES_PAGE',
        },
        loadLightPropertiesPage: {
            start: 'PROPERTIES_2:LOAD_LIGHT_PROPERTIES_PAGE:START',
            success: 'PROPERTIES_2:LOAD_LIGHT_PROPERTIES_PAGE:SUCCESS',
            error: 'PROPERTIES_2:LOAD_LIGHT_PROPERTIES_PAGE:ERROR',
        },
        changePageSize: {
            start: 'PROPERTIES_2:CHANGE_PAGE_SIZE:START_CHANGE_PAGE_SIZE',
            success: 'PROPERTIES_2:CHANGE_PAGE_SIZE:SUCCESS_CHANGE_PAGE_SIZE',
            error: 'PROPERTIES_2:CHANGE_PAGE_SIZE:ERROR_CHANGE_PAGE_SIZE',
        },
        updateAndSaveProperty: {
            start: 'PROPERTIES_2:UPDATE_AND_SAVE_PROPERTY:START_UPDATE_AND_SAVE_PROPERTY',
            success: 'PROPERTIES_2:UPDATE_AND_SAVE_PROPERTY:SUCCESS_UPDATE_AND_SAVE_PROPERTY',
            error: 'PROPERTIES_2:UPDATE_AND_SAVE_PROPERTY:ERROR_UPDATE_AND_SAVE_PROPERTY',
        },
        loadSearchingProperties: {
            start: 'PROPERTIES_2:SEARCH_PROPERTY_LEAD:START_LOAD_SEARCHING_PROPERTIES',
            success: 'PROPERTIES_2:SEARCH_PROPERTY_LEAD:SUCCESS_LOAD_SEARCHING_PROPERTIES',
            error: 'PROPERTIES_2:SEARCH_PROPERTY_LEAD:ERROR_LOAD_SEARCHING_PROPERTIES',
        },
        shareProperty: {
            start: 'PROPERTIES_2:SHARE_PROPERTY:START_SHARE_PROPERTY',
            success: 'PROPERTIES_2:SHARE_PROPERTY:SUCCESS_SHARE_PROPERTY',
            error: 'PROPERTIES_2:SHARE_PROPERTY:ERROR_SHARE_PROPERTY',
        },
        toggleSharePropertyLink: {
            start: 'PROPERTIES_2:TOGGLE_SHARE_PROPERTY_LINK:START_TOGGLE_SHARE_PROPERTY_LINK',
            success: 'PROPERTIES_2:TOGGLE_SHARE_PROPERTY_LINK:SUCCESS_TOGGLE_SHARE_PROPERTY_LINK',
            error: 'PROPERTIES_2:TOGGLE_SHARE_PROPERTY_LINK:ERROR_TOGGLE_SHARE_PROPERTY_LINK',
        },
        loadPropertyCounts: {
            start: 'PROPERTIES_2:LOAD_PROPERTY_COUNTS:START_LOAD_PROPERTY_COUNTS',
            success: 'PROPERTIES_2:LOAD_PROPERTY_COUNTS:SUCCESS_LOAD_PROPERTY_COUNTS',
            error: 'PROPERTIES_2:LOAD_PROPERTY_COUNTS:ERROR_LOAD_PROPERTY_COUNTS',
        },
        deleteProperty: {
            start: 'PROPERTIES_2:DELETE_PROPERTY:START_DELETE_PROPERTY',
            success: 'PROPERTIES_2:DELETE_PROPERTY:SUCCESS_DELETE_PROPERTY',
            error: 'PROPERTIES_2:DELETE_PROPERTY:ERROR_DELETE_PROPERTY',
        },
        assignPropertyAgent: {
            start: 'PROPERTIES_2:ASSIGN_PROPERTY_AGENT:START_ASSIGN_PROPERTY_AGENT',
            success: 'PROPERTIES_2:ASSIGN_PROPERTY_AGENT:SUCCESS_ASSIGN_PROPERTY_AGENT',
            error: 'PROPERTIES_2:ASSIGN_PROPERTY_AGENT:ERROR_ASSIGN_PROPERTY_AGENT',
        },
        unassignPropertyAgent: {
            start: 'PROPERTIES_2:UNASSIGN_PROPERTY_AGENT:START_UNASSIGN_PROPERTY_AGENT',
            success: 'PROPERTIES_2:UNASSIGN_PROPERTY_AGENT:SUCCESS_UNASSIGN_PROPERTY_AGENT',
            error: 'PROPERTIES_2:UNASSIGN_PROPERTY_AGENT:ERROR_UNASSIGN_PROPERTY_AGENT',
        },
        loadPropertyFromMLS: {
            start: 'PROPERTIES_2:LOAD_PROPERTY_FROM_MLS:START_LOAD_PROPERTY_FROM_MLS',
            success: 'PROPERTIES_2:LOAD_PROPERTY_FROM_MLS:SUCCESS_LOAD_PROPERTY_FROM_MLS',
            error: 'PROPERTIES_2:LOAD_PROPERTY_FROM_MLS:ERROR_LOAD_PROPERTY_FROM_MLS',
        },
        saveProperty: {
            start: 'PROPERTIES_2:SAVE_PROPERTY:START_SAVE_PROPERTY',
            success: 'PROPERTIES_2:SAVE_PROPERTY:SUCCESS_SAVE_PROPERTY',
            error: 'PROPERTIES_2:SAVE_PROPERTY:ERROR_SAVE_PROPERTY',
        },
        createPropertyContact: {
            start: 'PROPERTIES_2CREATE_PROPERTY_CONTACT:START_CREATE_PROPERTY_CONTACT',
            success: 'PROPERTIES_2:CREATE_PROPERTY_CONTACT:SUCCESS_CREATE_PROPERTY_CONTACT',
            error: 'PROPERTIES_2:CREATE_PROPERTY_CONTACT:ERROR_CREATE_CONTACT',
        },
        updatePropertyContact: {
            start: 'PROPERTIES_2:UPDATE_PROPERTY_CONTACT:START_UPDATE_PROPERTY_CONTACT',
            success: 'PROPERTIES_2:UPDATE_PROPERTY_CONTACT:SUCCESS_UPDATE_PROPERTY_CONTACT',
            error: 'PROPERTIES_2:UPDATE_PROPERTY_CONTACT:ERROR_UPDATE_CONTACT',
        },
        deletePropertyContact: {
            start: 'PROPERTIES_2:DELETE_PROPERTIES_CONTACT:START_DELETE_PROPERTIES_CONTACT',
            success: 'PROPERTIES_2:DELETE_PROPERTIES_CONTACT:SUCCESS_DELETE_PROPERTIES_CONTACT',
            error: 'PROPERTIES_2:DELETE_PROPERTIES_CONTACT:ERROR_DELETE_PROPERTIES_CONTACT',
        },
        createPropertyActivity: {
            start: 'PROPERTIES_2:CREATE_PROPERTY_ACTIVITY:START_CREATE_PROPERTY_ACTIVITY',
            success: 'PROPERTIES_2:CREATE_PROPERTY_ACTIVITY:SUCCESS_CREATE_PROPERTY_ACTIVITY',
            error: 'PROPERTIES_2:CREATE_PROPERTY_ACTIVITY:ERROR_CREATE_ACTIVITY',
        },
        updatePropertyActivity: {
            start: 'PROPERTIES_2:UPDATE_PROPERTY_ACTIVITY:START_UPDATE_PROPERTY_ACTIVITY',
            success: 'PROPERTIES_2:UPDATE_PROPERTY_ACTIVITY:SUCCESS_UPDATE_PROPERTY_ACTIVITY',
            error: 'PROPERTIES_2:UPDATE_PROPERTY_ACTIVITY:ERROR_UPDATE_ACTIVITY',
        },
        deletePropertyActivity: {
            start: 'PROPERTIES_2:DELETE_PROPERTY_ACTIVITY:START_DELETE_PROPERTY_ACTIVITY',
            success: 'PROPERTIES_2:DELETE_PROPERTY_ACTIVITY:SUCCESS_DELETE_PROPERTY_ACTIVITY',
            error: 'PROPERTIES_2:DELETE_PROPERTY_ACTIVITY:ERROR_DELETE_ACTIVITY',
        },
        changeCoAgents: {
            start: 'PROPERTIES_2:CHANGE_CO_AGENTS:START_CHANGE_CO_AGENTS',
            success: 'PROPERTIES_2:CHANGE_CO_AGENTS:SUCCESS_CHANGE_CO_AGENTS',
            error: 'PROPERTIES_2:CHANGE_CO_AGENTS:ERROR_CHANGE_CO_AGENTS',
        },
        loadAllListingAgents: {
            start: 'PROPERTIES_2:LOAD_ALL_LISTING_AGENTS:START_LOAD_ALL_LISTING_AGENTS',
            success: 'PROPERTIES_2:LOAD_ALL_LISTING_AGENTS:SUCCESS_LOAD_ALL_LISTING_AGENTS',
            error: 'PROPERTIES_2:LOAD_ALL_LISTING_AGENTS:ERROR_LOAD_ALL_LISTING_AGENTS',
        },
        portalPropertiesList: {
            setLead: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:SET_LEAD',
            loadPortalPropertiesPage: {
                start: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_PORTAL_PROPERTIES_PAGE:START',
                success: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_PORTAL_PROPERTIES_PAGE:SUCCESS',
                error: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_PORTAL_PROPERTIES_PAGE:ERROR',
            },
            loadMissingItemsOnCurrentPage: {
                start: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_MISSING_ITEMS_ON_CURRENT_PAGE:START',
                success: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_MISSING_ITEMS_ON_CURRENT_PAGE:SUCCESS',
                error: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOAD_MISSING_ITEMS_ON_CURRENT_PAGE:ERROR',
            },
            localUpdatePortalPropertyInList: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOCAL_UPDATE_PORTAL_PROPERTY_IN_LIST',
            localRemovePortalPropertyInList: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST:LOCAL_REMOVE_PORTAL_PROPERTY_IN_LIST',
        },
        portalPropertiesListForMap: {
            setLead: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:SET_LEAD',
            loadPortalPropertiesPage: {
                start: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:LOAD_PORTAL_PROPERTIES_PAGE:START',
                success: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:LOAD_PORTAL_PROPERTIES_PAGE:SUCCESS',
                error: 'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:LOAD_PORTAL_PROPERTIES_PAGE:ERROR',
            },
            localUpdatePortalPropertyInList:
                'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:LOCAL_UPDATE_PORTAL_PROPERTY_IN_LIST',
            localRemovePortalPropertyInList:
                'PROPERTIES_2:PORTAL_PROPERTIES_LIST_FOR_MAP:LOCAL_REMOVE_PORTAL_PROPERTY_IN_LIST',
        },
        loadPortalPropertyByUUID: {
            start: 'PROPERTIES_2:LOAD_PORTAL_PROPERTY_BY_UUID:START_LOAD_PORTAL_PROPERTY_BY_UUID',
            success: 'PROPERTIES_2:LOAD_PORTAL_PROPERTY_BY_UUID:SUCCESS_LOAD_PORTAL_PROPERTY_BY_UUID',
            error: 'PROPERTIES_2:LOAD_PORTAL_PROPERTY_BY_UUID:ERROR_LOAD_PORTAL_PROPERTY_BY_UUID',
        },
        localUpdateSelectedProperty: 'PROPERTIES_2:LOCAL_UPDATE_SELECTED_PROPERTY',
        sharePortalProperty: {
            start: 'PROPERTIES_2:SHARE_PORTAL_PROPERTY:START_SHARE_PORTAL_PROPERTY',
            success: 'PROPERTIES_2:SHARE_PORTAL_PROPERTY:SUCCESS_SHARE_PORTAL_PROPERTY',
            error: 'PROPERTIES_2:SHARE_PORTAL_PROPERTY:ERROR_SHARE_PORTAL_PROPERTY',
        },
        loadPublicSearchPropertyByUUID: {
            start: 'PROPERTIES_2:LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID:START_LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID',
            success: 'PROPERTIES_2:LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID:SUCCESS_LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID',
            error: 'PROPERTIES_2:LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID:ERROR_LOAD_PUBLIC_SEARCH_PROPERTY_BY_UUID',
        },
        followUsBossIntegration: {
            createFollowUsBossIntegrationObject: {
                start: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                success: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                error: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
            },
            removeFollowUsBossIntegrationObject: {
                start: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                success: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                error: 'PROPERTIES_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
            },
        },
        setAllMatchesToRead: {
            start: 'PROPERTIES_2:SET_ALL_MATCHES_TO_READ:START',
            success: 'PROPERTIES_2:SET_ALL_MATCHES_TO_READ:SUCCESS',
            error: 'PROPERTIES_2:SET_ALL_MATCHES_TO_READ:ERROR',
        },
        sendMessageToAgent: {
            start: 'PROPERTIES_2:SEND_MESSAGE_TO_AGENT:START',
            success: 'PROPERTIES_2:SEND_MESSAGE_TO_AGENT:SUCCESS',
            error: 'PROPERTIES_2:SEND_MESSAGE_TO_AGENT:ERROR',
        },
    },
    buyer: {
        buyerList: {
            start: 'BUYER:LIST_BUYER:START_LIST_BUYER',
            success: 'BUYER:LIST_BUYER:SUCCESS_LIST_BUYER',
            error: 'BUYER:LIST_BUYER:ERROR_LIST_BUYER',
        },
        loadBuyersPage: {
            start: 'BUYER:LOAD_BUYERS_PAGE:START_LOAD_BUYERS_PAGE',
            success: 'BUYER:LOAD_BUYERS_PAGE:SUCCESS_LOAD_BUYERS_PAGE',
            error: 'BUYER:LOAD_BUYERS_PAGE:ERROR_LOAD_BUYERS_PAGE',
        },
        changePageSize: {
            start: 'BUYER:CHANGE_PAGE_SIZE:START_CHANGE_PAGE_SIZE',
            success: 'BUYER:CHANGE_PAGE_SIZE:SUCCESS_CHANGE_PAGE_SIZE',
            error: 'BUYER:CHANGE_PAGE_SIZE:ERROR_CHANGE_PAGE_SIZE',
        },
        getBuyer: {
            start: 'BUYER:GET_BUYER:START_GET_BUYER',
            success: 'BUYER:GET_BUYER:SUCCESS_GET_BUYER',
            error: 'BUYER:GET_BUYER:ERROR_GET_BUYER',
        },
        saveBuyer: {
            start: 'BUYER:SAVE_BUYER:START_SAVE_BUYER',
            success: 'BUYER:SAVE_BUYER:SUCCESS_SAVE_BUYER',
            error: 'BUYER:SAVE_BUYER:ERROR_SAVE_BUYER',
        },
        updateBuyerDetail: {
            start: 'BUYER:UPDATE_BUYER_DETAIL:START_UPDATE_BUYER_DETAIL',
            success: 'BUYER:UPDATE_BUYER_DETAIL:SUCCESS_UPDATE_BUYER_DETAIL',
            error: 'BUYER:UPDATE_BUYER_DETAIL:ERROR_UPDATE_BUYER_DETAIL',
        },
        resetSelectedBuyer: {
            reset: 'BUYER:RESET_SELECTED_BUYER:RESET_SELECTED_BUYER',
        },
        createBuyerContact: {
            start: 'BUYER:CREATE_BUYER_CONTACT:START_CREATE_BUYER_CONTACT',
            success: 'BUYER:CREATE_BUYER_CONTACT:SUCCESS_CREATE_BUYER_CONTACT',
            error: 'BUYER:CREATE_BUYER_CONTACT:ERROR_CREATE_CONTACT',
        },
        updateBuyerContact: {
            start: 'BUYER:UPDATE_BUYER_CONTACT:START_UPDATE_BUYER_CONTACT',
            success: 'BUYER:UPDATE_BUYER_CONTACT:SUCCESS_UPDATE_BUYER_CONTACT',
            error: 'BUYER:UPDATE_BUYER_CONTACT:ERROR_UPDATE_CONTACT',
        },
        createBuyerActivity: {
            start: 'BUYER:CREATE_BUYER_ACTIVITY:START_CREATE_BUYER_ACTIVITY',
            success: 'BUYER:CREATE_BUYER_ACTIVITY:SUCCESS_CREATE_BUYER_ACTIVITY',
            error: 'BUYER:CREATE_BUYER_ACTIVITY:ERROR_CREATE_ACTIVITY',
        },
        updateBuyerActivity: {
            start: 'BUYER:UPDATE_BUYER_ACTIVITY:START_UPDATE_BUYER_ACTIVITY',
            success: 'BUYER:UPDATE_BUYER_ACTIVITY:SUCCESS_UPDATE_BUYER_ACTIVITY',
            error: 'BUYER:UPDATE_BUYER_ACTIVITY:ERROR_UPDATE_ACTIVITY',
        },
        deleteBuyer: {
            start: 'BUYER:DELETE_BUYER:START_DELETE_BUYER',
            success: 'BUYER:DELETE_BUYER:SUCCESS_DELETE_BUYER',
            error: 'BUYER:DELETE_BUYER:ERROR_DELETE_BUYER',
        },
        assignBuyerAgent: {
            start: 'BUYER:ASSIGN_BUYER_AGENT:START_ASSIGN_BUYER_AGENT',
            success: 'BUYER:ASSIGN_BUYER_AGENT:SUCCESS_ASSIGN_BUYER_AGENT',
            error: 'BUYER:ASSIGN_BUYER_AGENT:ERROR_ASSIGN_BUYER_AGENT',
        },
        loadSearchingBuyers: {
            start: 'BUYER:SEARCH_BUYER_LEAD:START_LOAD_SEARCHING_BUYERS',
            success: 'BUYER:SEARCH_BUYER_LEAD:SUCCESS_LOAD_SEARCHING_BUYERS',
            error: 'BUYER:SEARCH_BUYER_LEAD:ERROR_LOAD_SEARCHING_BUYERS',
        },
        deleteBuyerContact: {
            start: 'BUYER:DELETE_BUYER_CONTACT:START_DELETE_BUYER_CONTACT',
            success: 'BUYER:DELETE_BUYER_CONTACT:SUCCESS_DELETE_BUYER_CONTACT',
            error: 'BUYER:DELETE_BUYER_CONTACT:ERROR_DELETE_BUYER_CONTACT',
        },
        deleteBuyerActivity: {
            start: 'BUYER:DELETE_BUYER_ACTIVITY:START_DELETE_BUYER_ACTIVITY',
            success: 'BUYER:DELETE_BUYER_ACTIVITY:SUCCESS_DELETE_BUYER_ACTIVITY',
            error: 'BUYER:DELETE_BUYER_ACTIVITY:ERROR_DELETE_ACTIVITY',
        },
        loadBuyerCounts: {
            start: 'BUYER:LOAD_BUYER_COUNTS:START_LOAD_BUYER_COUNTS',
            success: 'BUYER:LOAD_BUYER_COUNTS:SUCCESS_LOAD_BUYER_COUNTS',
            error: 'BUYER:LOAD_BUYER_COUNTS:ERROR_LOAD_BUYER_COUNTS',
        },
        selectedBuyerActions: {
            loadBuyerByUUID: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:ERROR',
            },
            loadAllAgent: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_ALL_AGENT:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_ALL_AGENT:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_ALL_AGENT:ERROR',
            },
            changeCoAgents: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_CO_AGENTS:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_CO_AGENTS:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_CO_AGENTS:ERROR',
            },
            changePartners: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_PARTNERS:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_PARTNERS:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:CHANGE_PARTNERS:ERROR',
            },
            saveBuyer: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:ERROR',
            },
            updateSomeFields: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:ERROR',
            },
            loadSearchPortalShortLink: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_SEARCH_PORTAL_SHORT_LINK:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_SEARCH_PORTAL_SHORT_LINK:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_SEARCH_PORTAL_SHORT_LINK:ERROR',
            },
            sendInviteToAgent: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_INVITE_TO_AGENT:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_INVITE_TO_AGENT:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_INVITE_TO_AGENT:ERROR',
            },
            removeAgentsInvite: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:REMOVE_AGENTS_INVITE:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:REMOVE_AGENTS_INVITE:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:REMOVE_AGENTS_INVITE:ERROR',
            },
        },
    },
    buyers2: {
        loadAutoShareBuyerByUUID: {
            start: 'BUYERS2:LOAD_AUTO_SHARE_BUYER_BY_UUID:START_LOAD_AUTO_SHARE_BUYER_BY_UUID',
            success: 'BUYERS2:LOAD_AUTO_SHARE_BUYER_BY_UUID:SUCCESS_LOAD_AUTO_SHARE_BUYER_BY_UUID',
            error: 'BUYERS2:LOAD_AUTO_SHARE_BUYER_BY_UUID:ERROR_LOAD_AUTO_SHARE_BUYER_BY_UUID',
        },
        createBuyerForPortal: {
            start: 'BUYERS2:CREATE_BUYER_FOR_PORTAL:START_CREATE_BUYER_FOR_PORTAL',
            success: 'BUYERS2:CREATE_BUYER_FOR_PORTAL:SUCCESS_CREATE_BUYER_FOR_PORTAL',
            error: 'BUYERS2:CREATE_BUYER_FOR_PORTAL:ERROR_CREATE_BUYER_FOR_PORTAL',
        },
        updatePortalBuyer: {
            start: 'BUYERS2:UPDATE_PORTAL_BUYER:START_UPDATE_PORTAL_BUYER',
            success: 'BUYERS2:UPDATE_PORTAL_BUYER:SUCCESS_UPDATE_PORTAL_BUYER',
            error: 'BUYERS2:UPDATE_PORTAL_BUYER:ERROR_UPDATE_PORTAL_BUYER',
        },
        setAllMatchesToRead: {
            start: 'BUYERS2:SET_ALL_MATCHES_TO_READ:START',
            success: 'BUYERS2:SET_ALL_MATCHES_TO_READ:SUCCESS',
            error: 'BUYERS2:SET_ALL_MATCHES_TO_READ:ERROR',
        },
        loadPrimaryAgent: {
            start: 'BUYERS2:LOAD_PRIMARY_AGENT:START_LOAD_PRIMARY_AGENT',
            success: 'BUYERS2:LOAD_PRIMARY_AGENT:SUCCESS_LOAD_PRIMARY_AGENT',
            error: 'BUYERS2:LOAD_PRIMARY_AGENT:ERROR_LOAD_PRIMARY_AGENT',
        },
        loadAllAgent: {
            start: 'BUYERS2:LOAD_ALL_AGENT:START',
            success: 'BUYERS2:LOAD_ALL_AGENT:SUCCESS',
            error: 'BUYERS2:LOAD_ALL_AGENT:ERROR',
        },
        loadBuyersPage: {
            start: 'BUYER:LOAD_BUYERS_PAGE:START_LOAD_BUYERS_PAGE',
            success: 'BUYER:LOAD_BUYERS_PAGE:SUCCESS_LOAD_BUYERS_PAGE',
            error: 'BUYER:LOAD_BUYERS_PAGE:ERROR_LOAD_BUYERS_PAGE',
        },
        changePageSize: {
            start: 'BUYER:CHANGE_PAGE_SIZE:START_CHANGE_PAGE_SIZE',
            success: 'BUYER:CHANGE_PAGE_SIZE:SUCCESS_CHANGE_PAGE_SIZE',
            error: 'BUYER:CHANGE_PAGE_SIZE:ERROR_CHANGE_PAGE_SIZE',
        },
        selectedBuyerActions: {
            loadBuyerByUUID: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_BY_UUID:ERROR',
            },
            saveBuyer: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SAVE_BUYER:ERROR',
            },
            updateSomeFields: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:UPDATE_SOME_FIELDS:ERROR',
            },
            sendGetPreQualifiedRequest: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_PRE_QUAL_REQUEST:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_PRE_QUAL_REQUEST:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_PRE_QUAL_REQUEST:ERROR',
            },
            loadBuyerMlsVendors: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_MLS_VENDORS:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_MLS_VENDORS:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:LOAD_BUYER_MLS_VENDORS:ERROR',
            },
            sendRequestTour: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_REQUEST_TOUR:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_REQUEST_TOUR:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_REQUEST_TOUR:ERROR',
            },
            sendMessageToAgent: {
                start: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_MESSAGE_TO_AGENT:START',
                success: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_MESSAGE_TO_AGENT:SUCCESS',
                error: 'BUYER:SELECTED_BUYER_ACTIONS:SEND_MESSAGE_TO_AGENT:ERROR',
            },
            followUsBossIntegration: {
                createFollowUsBossIntegrationObject: {
                    start: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                    success: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                    error: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:CREATE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
                },
                removeFollowUsBossIntegrationObject: {
                    start: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:START',
                    success: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:SUCCESS',
                    error: 'BUYERS_2:FOLLOW_US_BOSS_INTEGRATION:REMOVE_FOLLOW_US_BOSS_INTEGRATION_OBJECT:ERROR',
                },
            },
            localUpdateSelectedBuyer: 'BUYERS_2:LOCAL_UPDATE_SELECTED_BUYER',
        },
    },
    keywords: {
        searchKeyword: {
            start: 'SEARCH_KEYWORD:LOAD_SEARCH_KEYWORD:START_LOAD_SEARCH_KEYWORD',
            success: 'SEARCH_KEYWORD:LOAD_SEARCH_KEYWORD:SUCCESS_LOAD_SEARCH_KEYWORD',
            error: 'SEARCH_KEYWORD:LOAD_SEARCH_KEYWORD:ERROR_LOAD_SEARCH_KEYWORDS',
        },
    },
    commonChoices: {
        loadCommonLeadsChoices: {
            start: 'COMMON_CHOICES:LOAD_COMMON_LEADS_CHOICES:START_LOAD_COMMON_LEADS_CHOICES',
            success: 'COMMON_CHOICES:LOAD_COMMON_LEADS_CHOICES:SUCCESS_LOAD_COMMON_LEADS_CHOICES',
            error: 'COMMON_CHOICES:LOAD_COMMON_LEADS_CHOICES:ERROR_LOAD_COMMON_LEADS_CHOICES',
        },
        loadCommonUserChoices: {
            start: 'COMMON_CHOICES:LOAD_COMMON_USER_CHOICES:START_LOAD_COMMON_USER_CHOICES',
            success: 'COMMON_CHOICES:LOAD_COMMON_USER_CHOICES:SUCCESS_LOAD_COMMON_USER_CHOICES',
            error: 'COMMON_CHOICES:LOAD_COMMON_USER_CHOICES:ERROR_LOAD_COMMON_USER_CHOICES',
        },
        loadCommonChoices: {
            start: 'COMMON_CHOICES:LOAD_COMMON_CHOICES:START_LOAD_COMMON_CHOICES',
            success: 'COMMON_CHOICES:LOAD_COMMON_CHOICES:SUCCESS_LOAD_COMMON_CHOICES',
            error: 'COMMON_CHOICES:LOAD_COMMON_CHOICES:ERROR_LOAD_COMMON_CHOICES',
        },
    },
    s3: {
        uploadFilesToS3: {
            start: 'S3:UPLOAD_FILES_TO_S3:START_UPLOAD_FILES_TO_S3',
            success: 'S3:UPLOAD_FILES_TO_S3:SUCCESS_UPLOAD_FILES_TO_S3',
            error: 'S3:UPLOAD_FILES_TO_S3:ERROR_UPLOAD_FILES_TO_S3',
        },
    },
    routingRules: {
        getRoutingRules: {
            start: 'ROUTING_RULES:GET_ROUTING_RULES:START_GET_ROUTING_RULES',
            success: 'ROUTING_RULES:GET_ROUTING_RULES:SUCCESS_GET_ROUTING_RULES',
            error: 'ROUTING_RULES:GET_ROUTING_RULES:ERROR_GET_ROUTING_RULES',
        },
        changeRoutingRulePageLength: {
            start: 'ROUTING_RULES:CHANGE_ROUTING_RULE_PAGE_LENGTH:START_CHANGE_ROUTING_RULE_PAGE_LENGTH',
            success: 'ROUTING_RULES:CHANGE_ROUTING_RULE_PAGE_LENGTH:SUCCESS_CHANGE_ROUTING_RULE_PAGE_LENGTH',
            error: 'ROUTING_RULES:CHANGE_ROUTING_RULE_PAGE_LENGTH:ERROR_CHANGE_ROUTING_RULE_PAGE_LENGTH',
        },
        createLeadRoutingRule: {
            start: 'ROUTING_RULES:CREATE_LEAD_ROUTING_RULE:START_CREATE_LEAD_ROUTING_RULE',
            success: 'ROUTING_RULES:CREATE_LEAD_ROUTING_RULE:SUCCESS_CREATE_LEAD_ROUTING_RULE',
            error: 'ROUTING_RULES:CREATE_LEAD_ROUTING_RULE:ERROR_CREATE_ROUTING_RULES',
        },
        getRoutingRuleDetail: {
            start: 'ROUTING_RULES:GET_ROUTING_RULE_DETAIL:START_ROUTING_RULE_DETAIL',
            success: 'ROUTING_RULES:ROUTING_RULE_DETAIL:SUCCESS_ROUTING_RULE_DETAIL',
            error: 'ROUTING_RULES:ROUTING_RULE_DETAIL:ERROR_GET_ROUTING_RULES_DETAIL',
        },
        deleteRoutingRule: {
            start: 'ROUTING_RULES:DELETE_ROUTING_RULE:START_DELETE_ROUTING_RULE',
            success: 'ROUTING_RULES:DELETE_ROUTING_RULE:SUCCESS_DELETE_ROUTING_RULE',
            error: 'ROUTING_RULES:DELETE_ROUTING_RULE:ERROR_DELETE_ROUTING_RULE',
        },
        updateRoutingRule: {
            start: 'ROUTING_RULES:UPDATE_ROUTING_RULES_DETAIL:START_UPDATE_ROUTING_RULES_DETAIL',
            success: 'ROUTING_RULES:UPDATE_ROUTING_RULES_DETAIL:SUCCESS_UPDATE_ROUTING_RULES_DETAIL',
            error: 'ROUTING_RULES:UPDATE_ROUTING_RULES_DETAIL:ERROR_UPDATE_ROUTING_RULES_DETAIL',
        },
        updateRoutingRuleStatus: {
            start: 'ROUTING_RULES:UPDATE_ROUTING_RULES_STATUS:START_UPDATE_ROUTING_RULES_STATUS',
            success: 'ROUTING_RULES:UPDATE_ROUTING_RULES_STATUS:SUCCESS_UPDATE_ROUTING_RULES_STATUS',
            error: 'ROUTING_RULES:UPDATE_ROUTING_RULES_STATUS:ERROR_UPDATE_ROUTING_RULES_STATUS',
        },
        assignRoutingRuleAgent: {
            start: 'ROUTING_RULES:ASSIGN_ROUTING_RULES_AGENT:START_ASSIGN_ROUTING_RULES_AGENT',
            success: 'ROUTING_RULES:ASSIGN_ROUTING_RULES_AGENT:SUCCESS_ASSIGN_ROUTING_RULES_AGENT',
            error: 'ROUTING_RULES:ASSIGN_ROUTING_RULES_AGENT:ERROR_ASSIGN_ROUTING_RULES_AGENT',
        },
        unassignRoutingRuleAgent: {
            start: 'ROUTING_RULES:UNASSIGN_ROUTING_RULES_AGENT:START_UNASSIGN_ROUTING_RULES_AGENT',
            success: 'ROUTING_RULES:UNASSIGN_ROUTING_RULES_AGENT:SUCCESS_UNASSIGN_ROUTING_RULES_AGENT',
            error: 'ROUTING_RULES:UNASSIGN_ROUTING_RULES_AGENT:ERROR_UNASSIGN_ROUTING_RULES_AGENT',
        },
    },
    matches: {
        loadMatchByUUID: {
            start: 'MATCHES:LOAD_MATCH_BY_UUID:START_LOAD_MATCH_BY_UUID',
            success: 'MATCHES:LOAD_MATCH_BY_UUID:SUCCESS_LOAD_MATCH_BY_UUID',
            error: 'MATCHES:LOAD_MATCH_BY_UUID:ERROR_LOAD_MATCH_BY_UUID',
        },
        loadPublicMatchByUUID: {
            start: 'MATCHES:LOAD_PUBLIC_MATCH_BY_UUID:START_LOAD_PUBLIC_MATCH_BY_UUID',
            success: 'MATCHES:LOAD_PUBLIC_MATCH_BY_UUID:SUCCESS_LOAD_PUBLIC_MATCH_BY_UUID',
            error: 'MATCHES:LOAD_PUBLIC_MATCH_BY_UUID:ERROR_LOAD_PUBLIC_MATCH_BY_UUID',
        },
        selectedMatchAction: {
            start: 'MATCHES:SELECTED_MATCH_ACTION:START_SELECTED_MATCH_ACTION',
            success: 'MATCHES:SELECTED_MATCH_ACTION:SUCCESS_SELECTED_MATCH_ACTION',
            error: 'MATCHES:SELECTED_MATCH_ACTION:ERROR_SELECTED_MATCH_ACTION',
        },
        resetSelectedMatch: 'MATCHES:RESET_SELECTED_MATCH',
        matchesCategorizedList: {
            setLead: 'MATCHES:MATCHES_CATEGORIZED_LIST:SET_LEAD',
            loadAllMatchCategories: {
                start: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_ALL_MATCH_CATEGORIES:START',
                success: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_ALL_MATCH_CATEGORIES:SUCCESS',
                error: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_ALL_MATCH_CATEGORIES:ERROR',
            },
            loadNewMatches: {
                start: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_NEW_MATCHES:START',
                success: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_NEW_MATCHES:SUCCESS',
                error: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_NEW_MATCHES:ERROR',
            },
            loadProgressMatches: {
                start: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PROGRESS_MATCHES:START',
                success: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PROGRESS_MATCHES:SUCCESS',
                error: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PROGRESS_MATCHES:ERROR',
            },
            loadPastMatches: {
                start: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PAST_MATCHES:START',
                success: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PAST_MATCHES:SUCCESS',
                error: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_PAST_MATCHES:ERROR',
            },
            loadSuccessfulMatches: {
                start: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_SUCCESSFUL_MATCHES:START',
                success: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_SUCCESSFUL_MATCHES:SUCCESS',
                error: 'MATCHES:MATCHES_CATEGORIZED_LIST:LOAD_SUCCESSFUL_MATCHES:ERROR',
            },
        },
        matchesList: {
            setLead: 'MATCHES:MATCHES_LIST:SET_LEAD',
            loadMatchesPage: {
                start: 'MATCHES:MATCHES_LIST:LOAD_MATCHES_PAGE:START',
                success: 'MATCHES:MATCHES_LIST:LOAD_MATCHES_PAGE:SUCCESS',
                error: 'MATCHES:MATCHES_LIST:LOAD_MATCHES_PAGE:ERROR',
            },
            loadPublicMatchesPage: {
                start: 'MATCHES:MATCHES_LIST:LOAD_PUBLIC_MATCHES_PAGE:START',
                success: 'MATCHES:MATCHES_LIST:LOAD_PUBLIC_MATCHES_PAGE:SUCCESS',
                error: 'MATCHES:MATCHES_LIST:LOAD_PUBLIC_MATCHES_PAGE:ERROR',
            },
            changePageSize: {
                start: 'MATCHES:MATCHES_LIST:CHANGE_PAGE_SIZE:START',
                success: 'MATCHES:MATCHES_LIST:CHANGE_PAGE_SIZE:SUCCESS',
                error: 'MATCHES:MATCHES_LIST:CHANGE_PAGE_SIZE:ERROR',
            },
        },
        loadPortalMatchesPage: {
            start: 'MATCHES:LOAD_PORTAL_MATCHES_PAGE:START_LOAD_PORTAL_MATCHES_PAGE',
            success: 'MATCHES:LOAD_PORTAL_MATCHES_PAGE:SUCCESS_LOAD_PORTAL_MATCHES_PAGE',
            error: 'MATCHES:LOAD_PORTAL_MATCHES_PAGE:ERROR_LOAD_PORTAL_MATCHES_PAGE',
        },
        setPortalMatchesLead: 'MATCHES:SET_PORTAL_MATCHES_LEAD',
        createMatchBetweenPropertyAndBuyerAutoShare: {
            start: 'MATCHES:CREATE_MATCH_BETWEEN_PROPERTY_AND_BUYER_AUTO_SHARE:START',
            success: 'MATCHES:CREATE_MATCH_BETWEEN_PROPERTY_AND_BUYER_AUTO_SHARE:SUCCESS',
            error: 'MATCHES:CREATE_MATCH_BETWEEN_PROPERTY_AND_BUYER_AUTO_SHARE:ERROR',
        },
        sendPublicMatchAction: {
            start: 'MATCHES:SEND_PUBLIC_MATCH_ACTION:START',
            success: 'MATCHES:SEND_PUBLIC_MATCH_ACTION:SUCCESS',
            error: 'MATCHES:SEND_PUBLIC_MATCH_ACTION:ERROR',
        },
        matchReadAction: {
            start: 'MATCHES:MATCHES_READ_ACTION:START_MATCHES_READ_ACTION',
            success: 'MATCHES:MATCHES_READ_ACTION:SUCCESS_MATCHES_READ_ACTION',
            error: 'MATCHES:MATCHES_READ_ACTION:ERROR_MATCHES_READ_ACTION',
        },
    },
    agents: {
        loadAgentsPage: {
            start: 'AGENTS:LOAD_AGENTS_PAGE:START_LOAD_AGENTS_PAGE',
            success: 'AGENTS:LOAD_AGENTS_PAGE:SUCCESS_LOAD_AGENTS_PAGE',
            error: 'AGENTS:LOAD_AGENTS_PAGE:ERROR_LOAD_AGENTS_PAGE',
        },
        changePageSize: {
            start: 'AGENTS:CHANGE_PAGE_SIZE:START_CHANGE_PAGE_SIZE',
            success: 'AGENTS:CHANGE_PAGE_SIZE:SUCCESS_CHANGE_PAGE_SIZE',
            error: 'AGENTS:CHANGE_PAGE_SIZE:ERROR_CHANGE_PAGE_SIZE',
        },
        activateAgent: {
            start: 'AGENTS:ACTIVATE_AGENT:START_ACTIVATE_AGENT',
            success: 'AGENTS:ACTIVATE_AGENT:SUCCESS_ACTIVATE_AGENT',
            error: 'AGENTS:ACTIVATE_AGENT:ERROR_ACTIVATE_AGENT',
        },
        deactivateAgent: {
            start: 'AGENTS:DEACTIVATE_AGENT:START_DEACTIVATE_AGENT',
            success: 'AGENTS:DEACTIVATE_AGENT:SUCCESS_DEACTIVATE_AGENT',
            error: 'AGENTS:DEACTIVATE_AGENT:ERROR_DEACTIVATE_AGENT',
        },
        loadAgentByUUID: {
            start: 'AGENTS:LOAD_AGENT_BY_UUID:START_LOAD_AGENT_BY_UUID',
            success: 'AGENTS:LOAD_AGENT_BY_UUID:SUCCESS_LOAD_AGENT_BY_UUID',
            error: 'AGENTS:LOAD_AGENT_BY_UUID:ERROR_LOAD_AGENT_BY_UUID',
        },
    },
    mlsAgents: {
        loadMlsAgentsPage: {
            start: 'MLS_AGENTS:LOAD_MLS_AGENTS_PAGE:START_LOAD_MLS_AGENTS_PAGE',
            success: 'MLS_AGENTS:LOAD_MLS_AGENTS_PAGE:SUCCESS_LOAD_MLS_AGENTS_PAGE',
            error: 'MLS_AGENTS:LOAD_MLS_AGENTS_PAGE:ERROR_LOAD_MLS_AGENTS_PAGE',
        },
        changePageSize: {
            start: 'MLS_AGENTS:CHANGE_PAGE_SIZE:START_CHANGE_PAGE_SIZE',
            success: 'MLS_AGENTS:CHANGE_PAGE_SIZE:SUCCESS_CHANGE_PAGE_SIZE',
            error: 'MLS_AGENTS:CHANGE_PAGE_SIZE:ERROR_CHANGE_PAGE_SIZE',
        },
        loadMlsAgentByUUID: {
            start: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_UUID:START_LOAD_MLS_AGENT_BY_UUID',
            success: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_UUID:SUCCESS_LOAD_MLS_AGENT_BY_UUID',
            error: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_UUID:ERROR_LOAD_MLS_AGENT_BY_UUID',
        },
        resetSelectedMlsAgent: 'MLS_AGENTS:RESET_SELECTED_MLS_AGENT',
        loadMlsAgentByInviteToken: {
            start: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_INVITE_TOKEN:START_LOAD_MLS_AGENT_BY_INVITE_TOKEN',
            success: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_INVITE_TOKEN:SUCCESS_LOAD_MLS_AGENT_BY_INVITE_TOKEN',
            error: 'MLS_AGENTS:LOAD_MLS_AGENT_BY_INVITE_TOKEN:ERROR_LOAD_MLS_AGENT_BY_INVITE_TOKEN',
        },
        loadMlsAgentProperties: {
            start: 'MLS_AGENTS:LOAD_MLS_AGENT_PROPERTIES:START_LOAD_MLS_AGENT_PROPERTIES',
            success: 'MLS_AGENTS:LOAD_MLS_AGENT_PROPERTIES:SUCCESS_LOAD_MLS_AGENT_PROPERTIES',
            error: 'MLS_AGENTS:LOAD_MLS_AGENT_PROPERTIES:ERROR_LOAD_MLS_AGENT_PROPERTIES',
        },
        saveMlsAgent: {
            start: 'MLS_AGENTS:SAVE_MLS_AGENT:START_SAVE_MLS_AGENT',
            success: 'MLS_AGENTS:SAVE_MLS_AGENT:SUCCESS_SAVE_MLS_AGENT',
            error: 'MLS_AGENTS:SAVE_MLS_AGENT:ERROR_SAVE_MLS_AGENT',
        },
        createMlsAgent: {
            start: 'MLS_AGENTS:CREATE_MLS_AGENT:START_CREATE_MLS_AGENT',
            success: 'MLS_AGENTS:CREATE_MLS_AGENT:SUCCESS_CREATE_MLS_AGENT',
            error: 'MLS_AGENTS:CREATE_MLS_AGENT:ERROR_CREATE_MLS_AGENT',
        },
        deleteMlsAgent: {
            start: 'MLS_AGENTS:DELETE_MLS_AGENT:START_DELETE_MLS_AGENT',
            success: 'MLS_AGENTS:DELETE_MLS_AGENT:SUCCESS_DELETE_MLS_AGENT',
            error: 'MLS_AGENTS:DELETE_MLS_AGENT:ERROR_DELETE_MLS_AGENT',
        },
    },
    partnerAgents: {
        loadPartnerAgentsPage: {
            start: 'PARTNER_AGENTS:LOAD_PARTNER_AGENTS_PAGE:START_LOAD_PARTNER_AGENTS_PAGE',
            success: 'PARTNER_AGENTS:LOAD_PARTNER_AGENTS_PAGE:SUCCESS_LOAD_PARTNER_AGENTS_PAGE',
            error: 'PARTNER_AGENTS:LOAD_PARTNER_AGENTS_PAGE:ERROR_LOAD_PARTNER_AGENTS_PAGE',
        },
        loadPartnerAgentByUUID: {
            start: 'PARTNER_AGENTS:LOAD_PARTNER_AGENT_BY_UUID:START_LOAD_PARTNER_AGENT_BY_UUID',
            success: 'PARTNER_AGENTS:LOAD_PARTNER_AGENT_BY_UUID:SUCCESS_LOAD_PARTNER_AGENT_BY_UUID',
            error: 'PARTNER_AGENTS:LOAD_PARTNER_AGENT_BY_UUID:ERROR_LOAD_PARTNER_AGENT_BY_UUID',
        },
        generateShortAgentBuyerPortalLink: {
            start: 'PARTNER_AGENTS:GENERATE_SHORT_AGENT_BUYER_PORTAL_LINK:START',
            success: 'PARTNER_AGENTS:GENERATE_SHORT_AGENT_BUYER_PORTAL_LINK:SUCCESS',
            error: 'PARTNER_AGENTS:GENERATE_SHORT_AGENT_BUYER_PORTAL_LINK:ERROR',
        },
        resetSelectedPartnerAgent: 'PARTNER_AGENTS:RESET_SELECTED_PARTNER_AGENT',
        loadPartnerInvitedAgentsPage: {
            start: 'PARTNER_AGENTS:LOAD_PARTNER_INVITED_AGENTS_PAGE:START',
            success: 'PARTNER_AGENTS:LOAD_PARTNER_INVITED_AGENTS_PAGE:SUCCESS',
            error: 'PARTNER_AGENTS:LOAD_PARTNER_INVITED_AGENTS_PAGE:ERROR',
        },
        loadAllAgentsPage: {
            start: 'PARTNER_AGENTS:LOAD_ALL_AGENTS_PAGE:START',
            success: 'PARTNER_AGENTS:LOAD_ALL_AGENTS_PAGE:SUCCESS',
            error: 'PARTNER_AGENTS:LOAD_ALL_AGENTS_PAGE:ERROR',
        },
    },
    followUsBossIntegration: {
        setData: 'FOLLOW_US_BOSS_INTEGRATION:SET_DATA',
    },
}

export default ACTION_TYPES
