import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {fullPriceFormat} from 'common_utils/string_format'

import styles from './styles.styl'

export default function PlanBlock({
    checkboxComponent,
    onSelect,
    checked,
    billingPlan,
    discount,
    disabled,
}) {
    const mainMonthlyPricePlan = billingPlan.interval == 'year'
        ? billingPlan.metadata?.monthly_price || billingPlan.mainMonthlyPricePlan
        : billingPlan.mainMonthlyPricePlan

    return (
        <div
            className={cn(
                styles['container'],
                {
                    [styles['checked']]: checked,
                    pointer: !disabled,
                },
                'flex align-center space-between mb-xs px-sm'
            )}
            onClick={!disabled ? onSelect : null}
        >
            <div className="flex-line">
                {checkboxComponent}
                {
                    !!discount && (
                        <p className="proxima text-pigment-green ml-xs">
                            save {fullPriceFormat(discount, {minimumFractionDigits: 2})}/yr
                        </p>
                    )
                }
            </div>
            <p className="proxima text-dark-grey-blue">
                {`${fullPriceFormat(mainMonthlyPricePlan, {minimumFractionDigits: 2})}/mo`}
            </p>
        </div>
    )
}

PlanBlock.propTypes = {
    checkboxComponent: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    billingPlan: PropTypes.object.isRequired,

    disabled: PropTypes.bool,
    discount: PropTypes.number,
}
