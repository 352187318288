import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from 'common_services/api'

const billingCouponsApi = new Api(API_DOMAINS.usersApi, 'billing', 'coupons')

@modelSerialize([
    'id',
    'object',
    'amount_off',
    'created',
    'currency',
    'duration',
    'duration_in_months',
    'livemode',
    'max_redemptions',
    'metadata',
    'name',
    'percent_off',
    'redeem_by',
    'times_redeemed',
    'valid',
])
class CouponService {
    id = null
    object = 'coupon'
    amount_off = 0
    created = null
    currency = null
    duration = null
    duration_in_months = null
    livemode = false
    max_redemptions = null
    metadata = {}
    name = null
    percent_off = null
    redeem_by = null
    times_redeemed = 0
    valid = false

    constructor(data = {}) {
        this.serialize = data

        Object.keys(this.metadata).map(key => {
            const currentValue = typeof this.metadata[key] == 'string'
                ? this.metadata[key].toLowerCase()
                : this.metadata[key]

            try {
                this.metadata[key] = JSON.parse(currentValue)
            }
            catch {} // eslint-disable-line no-empty
        })

        if (this.amount_off) {
            this.amount_off = this.amount_off / 100
        }
        if (this.created) {
            this.created = new Date(this.created * 1000)
        }
        if (this.redeem_by) {
            this.redeem_by = new Date(this.redeem_by * 1000)
        }
    }

    static async getCouponById(couponId) {
        return new CouponService((await billingCouponsApi.getByKey(couponId)).data)
    }
}

export default CouponService
