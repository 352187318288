import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Dropdown from 'adminProj/components/dropdown'

import styles from './styles'

export default function RoleDropdown({
    teamMemberRoles,
    currentMemberRole,
    onClickRole,
    additionalContent,
    className,
    hideCurrentRole,
    ...props
}) {
    return (
        <Dropdown
            className={cn(styles['role-selector'], className)}
            contentClassName={'mt-xxs'}
            trigger={(
                <p
                    className={cn(
                        'proxima text-md my-zero',
                        {
                            'text-dark-grey': !!teamMemberRoles[currentMemberRole],
                            'text-cool-grey': !teamMemberRoles[currentMemberRole],
                            hide: hideCurrentRole,
                        }
                    )}
                >
                    {teamMemberRoles[currentMemberRole] || 'Role'}
                </p>
            )}
            content={(
                <div className={styles['role-list']}>
                    <p className="mt-zero text-dark-grey proxima text-13">
                        Choose the role of this member within your team.
                    </p>
                    {
                        Object.keys(teamMemberRoles)
                            .filter(memberRole => RoleDropdown.rolesDescription[memberRole])
                            .map((memberRole, idx) => (
                                <div
                                    key={idx}
                                    className={styles['role-select']}
                                    onClick={() => onClickRole(memberRole)}
                                >
                                    <p
                                        className="my-zero text-turquoise-blue proxima-semibold text-sm"
                                    >
                                        {teamMemberRoles[memberRole]}
                                    </p>
                                    <p className="my-zero text-dark-grey proxima text-13">
                                        {RoleDropdown.rolesDescription[memberRole]}
                                    </p>
                                </div>
                            ))
                    }
                    {additionalContent}
                </div>
            )}
            {...props}
        />
    )
}

RoleDropdown.rolesDescription = {
    admin: 'Has access to agents, leads, and routing.',
    agent: 'Has access to leads.',
}

RoleDropdown.propTypes = {
    teamMemberRoles: PropTypes.object.isRequired,
    onClickRole: PropTypes.func.isRequired,
    currentMemberRole: PropTypes.string,
    className: PropTypes.string,
    hideCurrentRole: PropTypes.bool,
    additionalContent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
}
