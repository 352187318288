import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from 'common_services/api'

const billingApi = new Api(API_DOMAINS.usersApi, 'billing')

@modelSerialize([
    'active',
    'aggregate_usage',
    'amount',
    'billing_scheme',
    'created',
    'currency',
    'id',
    'interval',
    'interval_count',
    'livemode',
    'metadata',
    'nickname',
    'object',
    'product',
    'tiers',
    'tiers_mode',
    'transform_usage',
    'trial_period_days',
    'usage_type',
    'mainPricePlan',
    'mainMonthlyPricePlan',
])
class BillingPlan {
    active = false
    aggregate_usage = null
    amount = 0
    billing_scheme = null
    created = null
    currency = null
    id = null
    interval = null
    interval_count = 0
    livemode = false
    metadata = {}
    nickname = null
    object = null
    product = null
    tiers = null
    tiers_mode = null
    transform_usage = null
    trial_period_days = null
    usage_type = null

    constructor(data = {}) {
        this.serialize = data

        this.amount = this.amount / 100

        Object.keys(this.metadata).map(key => {
            const currentValue = typeof this.metadata[key] == 'string'
                ? this.metadata[key].toLowerCase()
                : this.metadata[key]

            try {
                this.metadata[key] = JSON.parse(currentValue)
            }
            catch {} // eslint-disable-line no-empty
        })

        if (this.tiers) {
            this.tiers = this.tiers.map(tier => ({
                ...tier,
                amount: tier.amount / 100,
            }))
        }

        if (this.metadata.features) {
            this.metadata.features = this.metadata.features.split(',')
        }
        if (this.metadata.demo_link) {
            this.metadata.demo_link = this.metadata.demo_link.replace('https://calendly.com', '/book-demo')
        }
        if (this.metadata.demo_link_web) {
            this.metadata.demo_link_web = this.metadata.demo_link_web.replace('https://calendly.com', '/book-demo')
        }
    }

    get mainPricePlan() {
        return this.amount
            ? this.amount
            : (
                Array.isArray(this.tiers)
                    ? this.tiers[0].amount
                    : this.amount
            )
    }

    get mainMonthlyPricePlan() {
        return this.interval == 'month'
            ? this.mainPricePlan
            : Math.round((this.mainPricePlan / 12) * 100) / 100
    }

    static async loadBillingPlanById(planId) {
        return new BillingPlan((await billingApi.getApiWithName('plans').getByKey(planId)).data)
    }
}

export default BillingPlan
