import RAVEN_CONFIG_DSN from 'RAVEN_CONFIG_DSN'
import ENVIRONMENT from 'ENVIRONMENT'

import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

if (RAVEN_CONFIG_DSN) {
    const linkToVersionedFile = document.querySelector('script[src*=".raven.re/v"]')?.src
    const releaseVersion = linkToVersionedFile?.match(/v\d+\.\d+\.\d+/)?.[0] || 'localhost'

    Sentry.init({
        dsn: RAVEN_CONFIG_DSN,
        release: `ADMIN - ${ENVIRONMENT} - ${releaseVersion} - projFront`,
        integrations: [new Integrations.BrowserTracing({
            // shouldCreateSpanForRequest: () => true,
            // tracingOrigins: [/.*\.raven\.re/],
        })],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,

        // Ignores the error caused by Microsoft Web crawler
        ignoreErrors: [
            'Non-Error promise rejection captured with value: Object Not Found Matching',
        ],
    })
    Sentry.setTag('projectName', `ADMIN - ${ENVIRONMENT} - projFront`)
    Sentry.setTag('framework', 'REACT')
}
