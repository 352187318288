export default fields => target => {
    Object.defineProperties(target.prototype, {
        serialize: {
            configurable: false,
            enumerable: false,
            get() {
                return serializeObj(this, fields)
            },
            set(valueObj) {
                if (valueObj) {
                    fields
                        .concat(
                            valueObj instanceof this.constructor
                                ? Object.keys(this)
                                : []
                        )
                        .forEach(field => {
                            const {
                                serializeField = field,
                                originField = field,
                                convertIfIsNull = true,
                                convertingToType = null,
                                convertValue = null,
                                readOnly = false,
                            } = field

                            if (!readOnly) {
                                const fieldDescriptor = (
                                    Object.getOwnPropertyDescriptor(this, serializeField)
                                        || Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), serializeField)
                                )
                                if (
                                    fieldDescriptor && (
                                        fieldDescriptor.set || fieldDescriptor.writable
                                    ) && valueObj.hasOwnProperty(originField)
                                ) {
                                    this[serializeField] = valueObj[originField] == null && !convertIfIsNull
                                        ? valueObj[originField]
                                        : convertType(valueObj[originField], {Type: convertingToType, convertValue})
                                }
                            }
                        })
                }

                return this.serialize
            },
        },
        originalObject: {
            configurable: false,
            enumerable: false,
            get() {
                return serializeObj(this, fields, true)
            },
        },
    })

    Object.defineProperties(target, {
        serializedFields: {
            configurable: false,
            enumerable: false,
            get() {
                return fields
            },
        },
    })

    return target
}

function serializeObj(obj, fields, revert = false) {
    const serializeData = {}
    fields.forEach(field => {
        const {
            serializeField = field,
            originField = field,
            type = null,
            convertValue = null,
        } = field
        let {fieldName} = field
        const fieldPath = serializeField.split('.')

        let currentObj = obj

        fieldName = (fieldName || serializeField).substring((fieldName || serializeField).lastIndexOf('.') + 1)

        for (let i = 0; i < fieldPath.length; i++) {
            const currentPath = fieldPath[i]
            currentObj = currentObj[currentPath]

            if (i == fieldPath.length - 1) {
                if (Array.isArray(currentObj)) {
                    serializeData[fieldName] = currentObj.map(el => (
                        hasSerialize(el)
                            ? el.serialize
                            : convertType(el, {Type: type, convertValue})
                    ))
                }
                else {
                    serializeData[fieldName] = hasSerialize(currentObj)
                        ? currentObj.serialize
                        : convertType(currentObj, {Type: type, convertValue})
                }
            }
            else if (currentObj == undefined) {
                break
            }
        }

        if (revert && serializeField != originField) {
            serializeData[originField] = serializeData[serializeField]

            delete serializeData[serializeField]
        }
    })
    return serializeData
}

function hasSerialize(currentObj) {
    return !!currentObj && (
        currentObj.hasOwnProperty('serialize')
            || Object.getPrototypeOf(currentObj).hasOwnProperty('serialize')
    )
}

function convertType(value, {Type, convertValue}) {
    const newValue = convertValue ? convertValue(value) : value

    return Type
        ? new Type(newValue)
        : newValue
}
