import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import parseStylusExport from 'common_utils/parse_stylus_export'

import styles from './styles.styl'

const stylesVariable = parseStylusExport(styles)

export default function Tooltip({
    text,
    variant,
    className,
    tooltipIcon,
    isTimeout,
    tooltipWidth,
    textClassName,
    tooltipBlockProps,
    tooltipPosition = 'bottom',
    ...props
}) {
    if (tooltipIcon) {
        tooltipIcon = React.cloneElement(
            Array.isArray(tooltipIcon)
                ? <div>{tooltipIcon}</div>
                : tooltipIcon,
            {
                className: cn(tooltipIcon?.props?.className, styles['tooltip-icon-element']),
            }
        )
    }

    return (
        <div
            className={cn(
                styles['tooltip-block'],
                styles[`tooltip-block-${variant}`],
                {
                    [styles['auto']]: tooltipIcon,
                },
                className
            )}
            {...props}
        >
            {
                tooltipIcon
                    ? tooltipIcon
                    : (
                        <div
                            className={cn(
                                styles['tooltip-icon'],
                                styles[`tooltip-icon-${variant}`]
                            )}
                        />
                    )
            }
            <div
                className={cn(
                    styles.tooltip,
                    styles[`tooltip-position-${tooltipPosition}`],
                    {[styles['timeout-tooltip']]: isTimeout}
                )}
                style={{
                    width: tooltipWidth,
                }}
                {...tooltipBlockProps}
            >
                <p className={textClassName || 'text-sm text-center proxima-medium my-xs mx-sm'}>
                    {text}
                </p>
            </div>
        </div>
    )
}

Tooltip.variants = {
    white: 'white',
    dark: 'dark',
    error: 'error',
}

Tooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,

    className: PropTypes.string,
    textClassName: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(Tooltip.variants)),
    tooltipIcon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
    isTimeout: PropTypes.bool,
    tooltipWidth: PropTypes.number,
    tooltipBlockProps: PropTypes.object,
    tooltipPosition: PropTypes.string,
}

Object.keys(Tooltip.variants).forEach(variantName => {
    Tooltip[variantName] = props => ( // eslint-disable-line react/display-name
        <Tooltip variant={Tooltip.variants[variantName]} {...props} />
    )
    // display-name for previos component
    Tooltip[variantName].displayName = `Tooltip.${variantName}`
})

Object.keys(stylesVariable.tooltipPositions).forEach(tooltipPosition => {
    Tooltip[tooltipPosition] = props => ( // eslint-disable-line react/display-name
        <Tooltip tooltipPosition={tooltipPosition} {...props} />
    )
    // display-name for previos component
    Tooltip[tooltipPosition].displayName = `Tooltip.${tooltipPosition}`
})
