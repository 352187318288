import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {}

const commonChoicesReducer = new Reducer(INITIAL_STATE)

commonChoicesReducer.add(actionTypes.commonChoices.loadCommonLeadsChoices)
commonChoicesReducer.add(actionTypes.commonChoices.loadCommonUserChoices)
commonChoicesReducer.add(actionTypes.commonChoices.loadCommonChoices)

export default commonChoicesReducer.reducersFunction
