import API_DOMAINS from 'API_DOMAINS'

import {UserService} from 'common_services/user'

import modelSerialize from 'common_utils/model_serialize'
import {createBackendFilters} from 'common_utils/create_backend_filters'

import Api from '../api'
import PaginationService from '../pagination'

import Buyer from './buyer'

const buyersApi = new Api(API_DOMAINS.leadsApi, 'buyers')

export const buyerFilterKeys = {
    price_range: 'price_range',
    bedrooms: 'bedrooms',
    bathrooms: 'bathrooms',
    size_range_sqft: 'size_range_sqft',
    lot_size_range_sqft: 'lot_size_range_sqft',
    hoa_max: 'hoa_max',
    year_built_range: 'year_built_range',
    agents: 'agent',
    timeframe: 'timeframe',
    lead_source: 'lead_source',
    lead_status: 'lead_status',
    cities: 'cities',
    leads_filter: 'leads_filter',
    keywords: 'keywords',
    stories: 'stories',
    subtypes: 'subtypes',
}

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'lastPage',
    'extraData',
    'buyerCounts',
])
class BuyerListService extends PaginationService {
    buyerCounts = {}

    async loadPage(page, {ordering, search, filters} = {}, pageSize, {isLoadAgents = true, cancelSource} = {}) {
        const newOrdering = await super.createOrderingParams(
            ordering,
            {
                'owner.fullName': 'owner',
                'last_activity.due_date': 'due_on',
                'match_category': 'matches',
                'matches_count': ['matches_count', 'in_progress_matches_count'],
            },
            true
        )

        await super.loadPage(
            page,
            {
                ordering: newOrdering,
                search,
                filters: createBackendFilters(filters, buyerFilterKeys),
            },
            pageSize,
            {cancelSource}
        )

        if (isLoadAgents) {
            const agents = await UserService.searchUsers(
                {
                    uuid: this.list
                        .map(({agent: buyerAgentUuid, invites}) => [
                            buyerAgentUuid,
                            invites.map(({invitedAgentUUID}) => invitedAgentUUID)
                                .filter(Boolean),
                        ])
                        .flat()
                        .filter(Boolean),
                },
                {cancelSource}
            )

            this.list.forEach(buyer => {
                buyer.agent = agents.find(agent => agent.uuid == buyer.agent)
                buyer.invites.forEach(partnerInvitedAgent => {
                    partnerInvitedAgent.invitedAgent = (
                        agents.find(agent => agent.uuid == partnerInvitedAgent.invitedAgentUUID)
                            || partnerInvitedAgent.invitedAgent
                    )
                })
            })
        }

        return this.serialize
    }

    async loadBuyerCounts({ordering, search, filters}) {
        const {newOrdering} = await this.createOrderingParams(ordering)
        const currentFilters = createBackendFilters(filters, buyerFilterKeys)

        this.buyerCounts = (await buyersApi.getApiWithName('counts').get({
            ordering: newOrdering,
            search,
            ...currentFilters,
        })).data

        this.buyerCounts.filters = currentFilters

        return this.serialize
    }

    async changePageSize(...args) {
        await super.changePageSize(...args)

        // need to re-evaluate the last page size.
        // change the page length to 30 and then back to 10
        // last page is not recalculated.
        // We need to again serialize the data to get the new data
        return this.serialize
    }
}

export default new BuyerListService(Buyer, buyersApi, 10)
