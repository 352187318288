import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'

import ConfirmModal from './components/confirm'
import AlertModal from './components/alert'
import BaseModal from './components/base_modal'

export default class ShowModal extends Component {
    static confirm = ConfirmModal
    static alert = AlertModal

    static position = BaseModal.position
    static size = BaseModal.size

    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
            PropTypes.node,
        ]).isRequired,

        onClose: PropTypes.func,
        isEditModal: PropTypes.bool,
        isForwardRef: PropTypes.bool,
    }

    state = {
        showConfirmCloseEditModal: false,
    }

    currentContent = React.createRef()

    render() {
        const {
            isEditModal,
            content,
            isForwardRef,
            ...props
        } = this.props
        let newContent

        if (isEditModal) {
            newContent = React.cloneElement(
                content,
                {
                    [isForwardRef ? 'formRef' : 'ref']: this.currentContent,
                }
            )
        }

        return (
            <Fragment>
                <BaseModal
                    {...props}
                    content={newContent || content}
                    onClose={this.closeModal}
                />
                {
                    isEditModal && (
                        <ConfirmModal
                            mainHeader="Confirm Navigation"
                            subHeader="Your changes have not been saved."
                            cancelFunc={this.closeConfirmCloseEditModal}
                            doneText="LEAVE"
                            cancelText="STAY"
                            doneFunc={this.close}
                            show={this.state.showConfirmCloseEditModal}
                            onClose={this.closeConfirmCloseEditModal}
                        />
                    )
                }
            </Fragment>
        )
    }

    @autobind
    openConfirmCloseEditModal() {
        this.setState({
            showConfirmCloseEditModal: true,
        })
    }

    @autobind
    closeConfirmCloseEditModal() {
        this.setState({
            showConfirmCloseEditModal: false,
        })
    }

    @autobind
    closeModal() {
        if (this.props.isEditModal && this.formIsChanged) {
            this.openConfirmCloseEditModal()
        }
        else {
            this.props.onClose()
        }
    }

    @autobind
    close() {
        this.closeConfirmCloseEditModal()
        this.props.onClose()
    }

    get formIsChanged() {
        if (this.props.isEditModal) {
            const formData = this.currentContent?.current?.props?.formData
            const initialFormValues = formData?.initial
            const currentFormValues = formData?.values

            if (currentFormValues && JSON.stringify(initialFormValues) != JSON.stringify(currentFormValues)) {
                return true
            }
        }

        return false
    }
}

Object.keys(BaseModal.position).forEach(currentModalPosition => {
    ShowModal[currentModalPosition] = props => ( // eslint-disable-line react/display-name
        <ShowModal position={BaseModal.position[currentModalPosition]} {...props} />
    )
    // display-name for previos component
    ShowModal[currentModalPosition].displayName = `ShowModal.${currentModalPosition}`
})
