import React from 'react'
import PropTypes from 'prop-types'

import {fullPriceFormat} from 'common_utils/string_format'

import Button from 'common_components/button'

export default function CouponInfo({
    coupon,
    discount,
    removeSelectedCoupon,

    isYearly,
    className,
}) {
    const intervalText = isYearly ? 'year' : 'month'

    return (
        <div className={className}>
            <p className="flex space-between mt-zero mb-xs">
                Promo Code
                <span>
                    -{fullPriceFormat(discount, {minimumFractionDigits: 2})}
                </span>
            </p>
            <p className="text-xs letter-xs my-zero">
                Save {fullPriceFormat(discount, {minimumFractionDigits: 2})}{
                    coupon.duration == 'forever'
                        ? `/${intervalText}`
                        : (
                            coupon.duration == 'repeating'
                                ? (
                                    isYearly
                                        ? (
                                            Math.ceil(coupon.duration_in_months / 12)
                                                ? `/${intervalText} (${Math.ceil(coupon.duration_in_months / 12)} ${intervalText}s)`
                                                : `/${intervalText}`
                                        )
                                        : `/${intervalText} (${coupon.duration_in_months} ${intervalText}s)`
                                )
                                : ` first ${intervalText}`
                        )
                }
                <Button.text
                    title="remove"
                    className="text-turquoise-blue proxima-medium text-xs ml-sm px-zero py-zero"
                    onClick={removeSelectedCoupon}
                />
            </p>
        </div>
    )
}

CouponInfo.propTypes = {
    coupon: PropTypes.object.isRequired,
    discount: PropTypes.number.isRequired,
    removeSelectedCoupon: PropTypes.func.isRequired,

    isYearly: PropTypes.bool,
    className: PropTypes.string,
}
