import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import cn from 'classnames'

import PaymentForm, {PaymentFormClass} from 'common_components/billing/payment_form'

import FeaturesDetails from './components/features_details'

import styles from './styles.styl'

function PlanSubscription({currentUser, onSubmitSuccess, isModal, ...props}) {
    const [withProPlan, setWithProPlan] = useState(true)
    const [selectedPlan, setSelectedPlan] = useState(null)

    return (
        <div className={cn(styles['auth-block-wrapper'], 'row row--no-gutter')}>
            <div className={cn(styles['auth-block'], 'flex')}>
                <div
                    className={cn(
                        styles['features-details-wrapper'],
                        'mob-hidden col col--m-6-of-12',
                        {[styles['is-modal']]: isModal}
                    )}
                >
                    <FeaturesDetails
                        className={cn(styles['features-details'], 'text-white')}
                        withProPlan={withProPlan}
                    />
                </div>
                <div
                    className={cn(
                        styles['form-container'],
                        'col col--m-6-of-12',
                        {[styles['is-modal']]: isModal}
                    )}
                >
                    <div className={cn(styles['form-wrapper'], 'flex px-huge py-md-lg')}>
                        <div className={styles['form']}>
                            <PaymentForm
                                onSubmitSuccess={onSubmitSuccess}
                                withBasicPlan
                                hasUsedProTrial={currentUser.hasUsedProTrial}
                                basicPlanProps={{
                                    nickname: 'Agent Basic',
                                }}
                                onChangeBillingPlan={
                                    plan => {
                                        setSelectedPlan(plan)
                                        setWithProPlan(plan.id != PaymentFormClass.BasicPlan.id)
                                    }
                                }
                                formHeader={
                                    <p
                                        className="proxima-bold text-30 text-dark-grey-blue mt-zero mb-md-lg text-center"
                                    >
                                        {
                                            selectedPlan?.id == PaymentFormClass.BasicPlan.id
                                                ? 'Get Started for Free'
                                                : (
                                                    selectedPlan?.trial_period_days && !currentUser.hasUsedProTrial
                                                        ? `Redeem Your ${freeTrialPeriodString()} Free Trial`
                                                        : 'Review Plan'
                                                )
                                        }
                                    </p>
                                }
                                withCoupon
                                {...props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function freeTrialPeriodString() {
        const {trial_period_days} = selectedPlan

        return trial_period_days % 30 == 0
            ? `${trial_period_days / 30} ${trial_period_days / 30 == 1 ? 'Month' : 'Months'}`
            : `${trial_period_days} Day`
    }
}

export default connect(
    ({user}) => ({currentUser: user})
)(PlanSubscription)

PlanSubscription.propTypes = {
    currentUser: PropTypes.object.isRequired,

    onSubmitSuccess: PropTypes.func,
    isModal: PropTypes.bool,
}
