import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import {connect} from 'react-redux'
import {submit} from 'redux-form'
import cn from 'classnames'

import componentActionCreators from 'common_redux/component_action_creators'
import billingActions from 'common_redux/actions/billing'

import BillingPlan from 'common_services/billing/billing_plan'

import Toast from 'common_components/toast'
import TextHeader from 'common_components/text_header'
import Link from 'common_components/link'
import PaymentDetailsForm from 'common_components/billing/payment_details'
import CustomerCards from 'common_components/billing/customer_cards'
import CouponForm from 'common_components/billing/coupon_form'
import {Checkbox} from 'common_components/form/components/checkbox'
import Button from 'common_components/button'

import DataLoader from 'adminProj/components/data_loader'
import CouponInfo from 'adminProj/pages/billing/subscription/team_plan/components/team_create/components/coupon_info'

import PlanBlock from './components/plan_block'
import BillingProvider from '../provider'

import styles from './styles.styl'

export class PaymentFormClass extends Component {
    static propTypes = {
        billingPlans: PropTypes.array.isRequired,
        subscribeToPlan: PropTypes.func.isRequired,
        loadAllBillingPlans: PropTypes.func.isRequired,
        loadCustomer: PropTypes.func.isRequired,
        markCardAsAuthenticated: PropTypes.func.isRequired,
        markCardAsNotAuthenticated: PropTypes.func.isRequired,
        customer: PropTypes.object.isRequired,
        submitForm: PropTypes.func.isRequired,
        hasUsedProTrial: PropTypes.bool.isRequired,
        removeSelectedCoupon: PropTypes.func.isRequired,

        onSubmitSuccess: PropTypes.func,
        defaultPlan: PropTypes.string,
        onChangeBillingPlan: PropTypes.func,
        withBasicPlan: PropTypes.bool,
        basicPlanProps: PropTypes.shape({
            nickname: PropTypes.string.isRequired,
        }),
        formHeader: PropTypes.node,
        withCoupon: PropTypes.bool,
        selectedCoupon: PropTypes.object,
    }

    static defaultProps = {
        formHeader: 'Review Plan',
    }

    static BasicPlan = (new BillingPlan({
        id: 'basic_plan',
        active: true,
        interval: 'month',
        amount: 0,
    })).serialize

    state={
        selectedPlan: 'yearly_plan',
        submitButtonText: this.defaultSubmitButtonText,
        isLoading: true,
        isSubscriptionInProgress: false,
    }

    render() {
        const {
            customer: {defaultCustomerCard},
            hasUsedProTrial,
            withBasicPlan,
            basicPlanProps,
            formHeader,
            withCoupon,
            selectedCoupon,
            removeSelectedCoupon,
        } = this.props
        const {isSubscriptionInProgress, isLoading, selectedPlan, submitButtonText} = this.state
        const discount = selectedCoupon && selectedCoupon.valid
            ? (
                selectedCoupon?.percent_off
                    ? this.selectedPlan.mainPricePlan * selectedCoupon.percent_off / 100
                    : (
                        selectedCoupon?.amount_off
                            ? selectedCoupon.amount_off
                            : 0
                    )
            )
            : 0

        return isLoading
            ? <DataLoader mainText="Loading..." />
            : (
                <div className={cn(styles['payment-container'], 'flex flex-column space-around')}>
                    {
                        typeof formHeader == 'string'
                            ? (
                                <TextHeader.h2
                                    text={formHeader}
                                    className={cn(
                                        styles['form-header'],
                                        'text-dark-grey-blue text-center mb-md-lg mt-md-lg'
                                    )}
                                />
                            )
                            : formHeader
                    }
                    <div className="px-md-lg">
                        <PlanBlock
                            checkboxComponent={(
                                <Checkbox
                                    name="yearly_plan"
                                    lableText="Agent Pro - Yearly"
                                    checked={selectedPlan == 'yearly_plan'}
                                    onChange={({target: {name}}) => this.onPlanSelect(name)}
                                    disabled={isSubscriptionInProgress}
                                />
                            )}
                            onSelect={() => this.onPlanSelect('yearly_plan')}
                            checked={selectedPlan == 'yearly_plan'}
                            disabled={isSubscriptionInProgress}
                            discount={this.discount}
                            billingPlan={this.yearlyPlan}
                        />
                        <PlanBlock
                            checkboxComponent={(
                                <Checkbox
                                    name="monthly_plan"
                                    lableText="Agent Pro - Monthly"
                                    checked={selectedPlan == 'monthly_plan'}
                                    onChange={({target: {name}}) => this.onPlanSelect(name)}
                                    disabled={isSubscriptionInProgress}
                                />
                            )}
                            onSelect={() => this.onPlanSelect('monthly_plan')}
                            checked={selectedPlan == 'monthly_plan'}
                            disabled={isSubscriptionInProgress}
                            billingPlan={this.monthlyPlan}
                        />
                        {
                            withBasicPlan && (
                                <PlanBlock
                                    checkboxComponent={(
                                        <Checkbox
                                            name="basic_plan"
                                            lableText={basicPlanProps?.nickname}
                                            checked={selectedPlan == PaymentFormClass.BasicPlan.id}
                                            onChange={({target: {name}}) => this.onPlanSelect(name)}
                                            disabled={isSubscriptionInProgress}
                                        />
                                    )}
                                    onSelect={() => this.onPlanSelect(PaymentFormClass.BasicPlan.id)}
                                    checked={selectedPlan == PaymentFormClass.BasicPlan.id}
                                    disabled={isSubscriptionInProgress}
                                    billingPlan={{
                                        ...PaymentFormClass.BasicPlan,
                                        ...basicPlanProps,
                                    }}
                                />
                            )
                        }
                        {
                            defaultCustomerCard
                                ? (
                                    <CustomerCards
                                        hideRemoveCard={isSubscriptionInProgress}
                                        className={cn(
                                            'mt-md-lg',
                                            {
                                                hide: selectedPlan == PaymentFormClass.BasicPlan.id,
                                            }
                                        )}
                                    />
                                )
                                : (
                                    <PaymentDetailsForm
                                        onSubmitFail={this.resetSubscribeFlow}
                                        onSubmitSuccess={() => this.subscribeFlow.next()}
                                        className={cn(
                                            'mt-md-lg',
                                            {
                                                hide: selectedPlan == PaymentFormClass.BasicPlan.id,
                                            }
                                        )}
                                    />
                                )
                        }
                        {
                            withCoupon && !discount && (
                                <CouponForm
                                    className={cn({
                                        hide: selectedPlan == PaymentFormClass.BasicPlan.id,
                                    })}
                                />
                            )
                        }
                        <div className="proxima text-dark-grey-blue mt-md">
                            <div className="flex space-between mt-sm">
                                <p className="m-zero">Plan</p>
                                <p className="m-zero">
                                    {
                                        selectedPlan == PaymentFormClass.BasicPlan.id
                                            ? 'Agent Basic'
                                            : `Agent Pro ${selectedPlan == 'monthly_plan' ? 'Monthly' : 'Yearly'}`
                                    }
                                </p>
                            </div>
                            {
                                (!hasUsedProTrial && !!this.selectedPlan?.trial_period_days) && (
                                    <div className="flex space-between mt-sm">
                                        <p className="m-zero">{this.freeTrialPeriodString}</p>
                                        <p className="m-zero">FREE</p>
                                    </div>
                                )
                            }
                            <div className="flex space-between mt-sm">
                                <p className="m-zero">Renewal Amount</p>
                                <p className="m-zero">
                                    ${this.selectedPlan?.amount}/{this.selectedPlan?.interval == 'year' ? 'yr' : 'mo'}
                                </p>
                            </div>
                            {
                                !!discount && (
                                    <CouponInfo
                                        className="my-sm"
                                        coupon={selectedCoupon}
                                        discount={discount}
                                        removeSelectedCoupon={removeSelectedCoupon}
                                        isYearly={this.selectedPlan.interval == 'year'}
                                    />
                                )
                            }
                            {
                                selectedPlan != PaymentFormClass.BasicPlan.id && (
                                    <div className="flex space-between mt-xs">
                                        <p className="text-xs m-zero">
                                            Billing starts
                                            {' '}
                                            <span className="proxima-bold">
                                                {this.billingDate}
                                            </span>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <Button.big
                        type="submit"
                        title={submitButtonText}
                        className="mt-md mx-md-lg"
                        onClick={this.runSubscription}
                        disabled={isSubscriptionInProgress}
                    />
                    <p className="proxima text-sm text-center mt-sm-md text-bluey-grey">
                        By continuing, you accept the {
                            <Link
                                path="https://raven.re/terms"
                                text="Terms of Use"
                                target="_blank"
                            />
                        } and {
                            <Link
                                path="https://raven.re/privacy"
                                text="Privacy Policy"
                                target="_blank"
                            />
                        }
                    </p>
                </div>
            )
    }

    async componentDidMount() {
        const {
            defaultPlan,
            loadAllBillingPlans,
            loadCustomer,
            onChangeBillingPlan,
            removeSelectedCoupon,
        } = this.props

        removeSelectedCoupon()

        await loadAllBillingPlans()

        this.setState(
            {
                isLoading: false,
                selectedPlan: (
                    defaultPlan == 'monthly' || defaultPlan == 'yearly'
                        ? `${defaultPlan}_plan`
                        : defaultPlan
                ) || 'yearly_plan',
                submitButtonText: this.defaultSubmitButtonText,
            },
            () => {
                if (onChangeBillingPlan) {
                    onChangeBillingPlan(this.selectedPlan)
                }
            }
        )

        await loadCustomer()
    }

    @autobind
    runSubscription() {
        this.setState({
            isSubscriptionInProgress: true,
        })

        if (!this.subscribeFlow) {
            this.subscribeFlow = this.subscribeToSelectedPlan()
        }

        this.subscribeFlow.next()
    }

    @autobind
    async * subscribeToSelectedPlan() {
        const {
            submitForm,
            subscribeToPlan,
            customer: {defaultCustomerCard},
            onSubmitSuccess,
            markCardAsAuthenticated,
            markCardAsNotAuthenticated,
            stripe,
            selectedCoupon,
        } = this.props

        if (this.selectedPlan?.id == PaymentFormClass.BasicPlan.id) {
            if (onSubmitSuccess) {
                return onSubmitSuccess(this.selectedPlan)
            }
        }

        if (!defaultCustomerCard) {
            this.setState({
                submitButtonText: 'SAVING CARD ...',
            })

            yield submitForm('PaymentDetailsForm')
        }

        try {
            this.setState({
                submitButtonText: 'SUBSCRIBING ...',
            })

            try {
                const {subscriptions: {data}} = await subscribeToPlan(
                    this.selectedPlan?.id,
                    selectedCoupon?.valid ? selectedCoupon.id : null
                )

                const paymentIntent = !!data.length &&
                    data[0].latest_invoice.payment_intent

                if (paymentIntent) {
                    if (paymentIntent.status == 'requires_source_action') {
                        const paymentIntentSecret = paymentIntent.client_secret
                        const confirmResponse = await stripe.confirmCardPayment(paymentIntentSecret)
                        if (confirmResponse.error) {
                            await markCardAsNotAuthenticated()
                            throw confirmResponse.error
                        }
                        else {
                            await markCardAsAuthenticated()
                        }
                    }
                    else if (paymentIntent.last_payment_error) {
                        throw paymentIntent.last_payment_error
                    }
                }
            }
            catch (error) {
                let errorMessage = ''
                if (error.response) {
                    const {response: {data}} = error
                    errorMessage = data.detail
                }
                else if (error.message) {
                    errorMessage = error.message
                }

                Toast.show.error(errorMessage)

                throw error
            }

            if (onSubmitSuccess) {
                yield onSubmitSuccess(this.selectedPlan)
            }
        }
        catch (res) {
            this.setState({
                submitError: res.message
                    ? res.message
                    : res.response.data,
            })
            this.resetSubscribeFlow()
            return Promise.reject(res)
        }
    }

    @autobind
    resetSubscribeFlow() {
        this.subscribeFlow = null
        this.setState({
            isSubscriptionInProgress: false,
            submitButtonText: this.defaultSubmitButtonText,
        })
    }

    @autobind
    onPlanSelect(plan) {
        const {onChangeBillingPlan} = this.props

        this.setState(
            {
                selectedPlan: plan,
                submitButtonText: plan == PaymentFormClass.BasicPlan.id
                    ? 'CONTINUE WITH BASIC'
                    : this.defaultSubmitButtonText,
            },
            () => {
                if (onChangeBillingPlan) {
                    onChangeBillingPlan(this.selectedPlan)
                }
            }
        )
    }

    get defaultSubmitButtonText() {
        return !this.props.hasUsedProTrial && this.selectedPlan?.trial_period_days
            ? 'START FREE TRIAL'
            : 'UPGRADE TO PROFESSIONAL'
    }

    get monthlyPlan() {
        return this.props.billingPlans.find(plan => plan.id == 'plan_paid_monthly')
    }

    get yearlyPlan() {
        return this.props.billingPlans.find(plan => plan.id == 'plan_paid_yearly')
    }

    get selectedPlan() {
        switch (this.state?.selectedPlan) {
            case 'monthly_plan':
                return this.monthlyPlan
            case 'yearly_plan':
                return this.yearlyPlan
            default:
                return {
                    ...PaymentFormClass.BasicPlan,
                    ...this.props.basicPlanProps,
                }
        }
    }

    get discount() {
        return Math.round((this.monthlyPlan?.mainMonthlyPricePlan - this.yearlyPlan?.mainMonthlyPricePlan) * 12)
    }

    get billingDate() {
        const options = {year: 'numeric', month: 'short', day: 'numeric'}

        return (this.selectedPlan.trial_period_days && !this.props.hasUsedProTrial)
            ? new Date(Date.now() + 86400000 * this.selectedPlan.trial_period_days).toLocaleDateString('en-US', options)
            : new Date(Date.now()).toLocaleDateString('en-US', options)
    }

    get freeTrialPeriodString() {
        const {trial_period_days} = this.selectedPlan

        return trial_period_days % 30 == 0
            ? `${trial_period_days / 30}-${trial_period_days / 30 == 1 ? 'month' : 'months'} Trial`
            : `${trial_period_days}-${trial_period_days == 1 ? 'day' : 'days'} Trial`
    }
}

export default BillingProvider(connect(
    ({billing: {plans, customer, selectedCoupon}}) => ({
        billingPlans: plans,
        customer,
        selectedCoupon,
    }),
    dispatch => componentActionCreators({...billingActions, submitForm: submit}, dispatch)
)(PaymentFormClass))
