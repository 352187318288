import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import cn from 'classnames'

import {Field} from 'redux-form'

import Tooltip from 'common_components/tooltip'

import styles from './styles.styl'

class Switch extends Component {
    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        labelText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        labelClassName: PropTypes.string,
        descriptionText: PropTypes.string,
        containerClassName: PropTypes.string,
        input: PropTypes.shape({
            value: PropTypes.bool,
            onChange: PropTypes.func,
        }),
        onClick: PropTypes.func,
        noColorChangeOnActivate: PropTypes.bool,
        isNotForm: PropTypes.bool,
        checked: PropTypes.bool,
        switchContainerClassName: PropTypes.string,
        withoutLable: PropTypes.bool,
        isCheckbox: PropTypes.bool,
        isSmall: PropTypes.bool,
        tooltip: PropTypes.string,
        tooltipProps: PropTypes.object,
        moveTooltipToSwitch: PropTypes.bool,
    }

    static defaultProps = {
        input: {
            value: false,
        },
        disabled: false,
        labelText: 'Use exact match',
        labelClassName: 'proxima text-md mt-sm-md mb-zero',
        containerClassName: '',
        onClick: () => {},
        tooltipProps: {},
    }

    @autobind
    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value !== undefined ? value : !this.props.checked)
        }
        if (!this.props.isNotForm) {
            this.props.input.onChange(value !== undefined ? value : !this.props.input.value)
        }
    }

    render() {
        const {
            labelText,
            labelClassName,
            descriptionText,
            containerClassName,
            disabled,
            name,
            checked,
            input: {value},
            onClick,
            noColorChangeOnActivate,
            switchContainerClassName,
            withoutLable,
            isNotForm, // eslint-disable-line no-unused-vars
            isCheckbox,
            isSmall,
            tooltip,
            tooltipProps,
            moveTooltipToSwitch,
            ...props
        } = this.props

        const checkboxValue = checked !== undefined ? checked : value

        const switchComponent = (
            <div
                className={
                    cn(
                        'ml-md flex align-center',
                        {
                            [styles.switch]: !isCheckbox,
                            [styles.checkbox]: isCheckbox,
                            [styles['activated']]: checkboxValue && !noColorChangeOnActivate,
                            [styles['disabled']]: disabled,
                            [styles.small]: isSmall,
                        },
                        switchContainerClassName
                    )
                }
                onClick={() => !this.props.disabled && this.onChange(onClick(checkboxValue))}
            >
                <div className={cn(styles.oval, {[ styles['activated']]: checkboxValue})} />
            </div>
        )

        return (
            <div className={cn(containerClassName, 'flex')} {...props}>
                {
                    !withoutLable && (
                        <div>
                            <div className="flex align-center">
                                <p className={cn(labelClassName, 'flex mt-xs mb-zero')}>
                                    {labelText}
                                </p>
                                {
                                    !!tooltip && !moveTooltipToSwitch && (
                                        <Tooltip
                                            className="ml-xs"
                                            text={tooltip}
                                            {...tooltipProps}
                                        />
                                    )
                                }
                            </div>
                            {
                                !!descriptionText && (
                                    <p className="proxima text-dark-grey-blue text-sm mt-sm mb-zero">
                                        {descriptionText}
                                    </p>
                                )
                            }
                        </div>
                    )
                }
                <input
                    component="input"
                    type="checkbox"
                    name={name}
                    className="hide"
                />

                {
                    !!tooltip && moveTooltipToSwitch
                        ? (
                            <Tooltip
                                tooltipIcon={switchComponent}
                                text={tooltip}
                                {...{
                                    ...tooltipProps,
                                    style: {
                                        height: 25,
                                        ...tooltipProps?.style,
                                    },
                                }}
                            />
                        )
                        : switchComponent
                }
            </div>
        )
    }
}

export default function ReduxSwitch({isNotForm, ...props}) {
    const format = value => !!value

    return isNotForm
        ? (
            <Switch
                isNotForm={isNotForm}
                {...props}
            />
        )
        : (
            <Field
                component={Switch}
                format={format}
                isNotForm={isNotForm}
                {...props}
            />
        )
}

ReduxSwitch.propTypes = {
    isNotForm: PropTypes.bool,
}
