import React, {Fragment} from 'react'

import DoneArrowWithBorder from 'common_svg/done_arrow_with_border.svg?jsx'

import EmptyList from './components/empty_list'

export default {
    all: {
        pageIdentifier: 'all-properties',
        requiredOrdering: [],
        requiredFilters: {
            not_match_attitude: ['not_interested', 'interested'],
        },
        textInformation: {
            defaultCurrentCityHeader: 'Homes For Sale',
        },
        tinderRemoveAnimationValue: ['not_interested', 'interested'],
        EmptyListComponent: function SearchEmptyListComponent(props) {
            return (
                <EmptyList
                    header={
                        <Fragment>
                            <DoneArrowWithBorder className="mb-sm-md" />
                            <br />
                            <span>
                                All Done!
                            </span>
                        </Fragment>
                    }
                    subHeader="You don’t have any more properties to review."
                    showNavLinks
                    {...props}
                />
            )
        },
    },
    interested: {
        pageIdentifier: 'interested-properties',
        requiredOrdering: [],
        requiredFilters: {
            match_attitude: 'interested',
            only_matches: true,
        },
        withoutAnyFilters: true,
        textInformation: {
            defaultCurrentCityHeader: 'Likes',
        },
        tinderRemoveAnimationValue: ['not_interested'],
        EmptyListComponent: function InterestedEmptyListComponent(props) {
            return (
                <EmptyList
                    subHeader="You don’t have any homes you like."
                    showSearchLink
                    {...props}
                    withoutResult={false}
                />
            )
        },
    },
    notInterested: {
        pageIdentifier: 'notInterested-properties',
        requiredOrdering: [
            {
                id: 'matches',
                desc: true,
            },
        ],
        requiredFilters: {
            match_attitude: 'not_interested',
            only_matches: true,
        },
        withoutAnyFilters: true,
        textInformation: {
            defaultCurrentCityHeader: 'Dislikes',
        },
        tinderRemoveAnimationValue: ['interested'],
        EmptyListComponent: function NotInterestedEmptyListComponent(props) {
            return (
                <EmptyList
                    subHeader="You don’t have any homes you dislike."
                    showSearchLink
                    {...props}
                    withoutResult={false}
                />
            )
        },
    },
    adminSearchTab: {
        pageIdentifier: 'search-properties',
        requiredOrdering: [],
        requiredFilters: {},
        textInformation: {},
        EmptyListComponent: function SearchEmptyListComponent(props) {
            return (
                <EmptyList
                    header="No Results"
                    subHeader="We did not find any properties based on your search criteria"
                    hideLikedHomesLink
                    {...props}
                />
            )
        },
    },
}
