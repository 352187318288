import BillingPlan from 'common_services/billing/billing_plan'
import billingPlansList from 'common_services/billing/billing_plans_list'
import customerService from 'common_services/billing/customer'
import CouponService from 'common_services/billing/coupon'

import createAction, {createEmptyAction} from './create_action'

import actionTypes from './action_types'

export default {
    loadAllBillingPlans() {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadAllBillingPlans,
            action: billingPlansList.loadAllBillingPlans,
        })
    },

    loadBillingPlanById(planId) {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadBillingPlanById,
            action: async () => (await BillingPlan.loadBillingPlanById(planId)).serialize,
        })
    },

    loadStripePlanGroups({userGroup, preloadStripePlan = false} = {}) {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadStripePlanGroups,
            action: () => billingPlansList.loadStripePlanGroups({userGroup, preloadStripePlan}),
        })
    },

    addCustomerCard(cardToken) {
        return createAction({
            actionTypeSpace: actionTypes.billing.addCustomerCard,
            action: () => customerService.addCustomerCard(cardToken),
        })
    },

    removeCustomerCard(cardId) {
        return createAction({
            actionTypeSpace: actionTypes.billing.removeCustomerCard,
            action: () => customerService.removeCustomerCard(cardId),
        })
    },

    loadCustomer(params, config) {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadCustomer,
            action: () => customerService.loadCustomer(params, config),
        })
    },

    loadBillingHistory(config) {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadBillingHistory,
            action: () => customerService.loadBillingHistory(config),
        })
    },

    loadUpcomingInvoice(config) {
        return createAction({
            actionTypeSpace: actionTypes.billing.loadUpcomingInvoice,
            action: () => customerService.loadUpcomingInvoice(config),
        })
    },

    getSetupIntentToken() {
        return createAction({
            actionTypeSpace: actionTypes.billing.getSetupIntentToken,
            action: () => customerService.getSetupIntentToken(),
        })
    },

    subscribeToPlan(planId, couponId) {
        return createAction({
            actionTypeSpace: actionTypes.billing.subscribeToPlan,
            action: () => customerService.subscribeToPlan(planId, couponId),
        })
    },

    cancelSubscription() {
        return createAction({
            actionTypeSpace: actionTypes.billing.cancelSubscription,
            action: () => customerService.cancelSubscription(),
        })
    },

    getCouponById(couponId) {
        return createAction({
            actionTypeSpace: actionTypes.billing.getCouponById,
            action: async () => (await CouponService.getCouponById(couponId)).serialize,
        })
    },

    removeSelectedCoupon() {
        return createEmptyAction({
            actionType: actionTypes.billing.removeSelectedCoupon,
        })
    },

    addCouponToSubscription(couponId) {
        return createAction({
            actionTypeSpace: actionTypes.billing.addCouponToSubscription,
            action: () => customerService.addCouponToSubscription(couponId),
        })
    },

    removeCouponFromSubscription() {
        return createAction({
            actionTypeSpace: actionTypes.billing.removeCouponFromSubscription,
            action: () => customerService.removeCouponFromSubscription(),
        })
    },

    markCardAsAuthenticated() {
        return createAction({
            actionTypeSpace: actionTypes.billing.markCardAsAuthenticated,
            action: () => customerService.markCardAsAuthenticated(),
        })
    },

    markCardAsNotAuthenticated() {
        return createAction({
            actionTypeSpace: actionTypes.billing.markCardAsNotAuthenticated,
            action: () => customerService.markCardAsNotAuthenticated(),
        })
    },
}
