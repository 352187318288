import ENVIRONMENT from 'ENVIRONMENT'

import React from 'react'

export default function AppVersion(props) {
    const linkToVersionedFile = document.querySelector('script[src*=".raven.re/v"]')?.src

    return linkToVersionedFile && ENVIRONMENT != 'production'
        ? (
            <span {...props}>
                {
                    linkToVersionedFile.match(/v\d+\.\d+\.\d+/)?.[0]
                }
            </span>
        )
        : null
}
