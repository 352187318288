import React, {useState} from 'react'
import cn from 'classnames'

import MessageCloudIcon from 'common_svg/message_cloud.svg?jsx'

import Toast from 'common_components/toast'

import Dropdown, {closeSomeDropdown} from 'adminProj/components/dropdown'

import FeedbackForm from './components/feedback_form'

import styles from './styles.styl'

export default function FeedbackDropdown({...props}) {
    const id = 'FEEDBACK_DROPDOWN'
    const [isTriggerActive, setIsTriggerActive] = useState(false)

    return (
        <Dropdown
            id={id}
            trigger={(
                <MessageCloudIcon
                    className={cn(
                        styles['trigger-icon'],
                        {
                            [styles['trigger-icon-active']]: isTriggerActive,
                        }
                    )}
                />
            )}
            content={isTriggerActive && (
                <FeedbackForm
                    onCancelClick={() => closeSomeDropdown(id)}
                    onSubmitSuccess={() => {
                        Toast.show.success(
                            <p
                                className="text-white proxima letter-xxs text-md m-zero"
                            >
                                <span className="proxima-bold">
                                    Feedback Sent!
                                </span>
                                {' '}
                                We appreciate your feedback.
                            </p>
                        )

                        closeSomeDropdown(id)
                    }}
                />
            )}
            contentClassName={styles['dropdown-feedback-form-block']}
            hideArrow
            isNotCloseAfterAction
            onToggle={() => setIsTriggerActive(prevValue => !prevValue)}
            {...props}
        />
    )
}
