import modelSerialize from 'common_utils/model_serialize'

@modelSerialize([
    'list',
    'currentPage',
    'pageSize',
    'count',
    'keepPreviousList',
    'lastPage',
    'prevPage',
    'nextPage',
])
class PaginationService {
    list = []
    currentPage = 1
    pageSize = 10
    count = 0
    keepPreviousList = false
    withPreload = false

    cancelSourceList = []

    constructor(Model, api, pageSize = 10, keepPreviousList = false, withPreload = false) {
        this.Model = Model
        this.api = api
        this.pageSize = pageSize
        this.keepPreviousList = keepPreviousList
        this.withPreload = withPreload

        if (withPreload) {
            this.prevPage = new PaginationService(Model, api, pageSize, keepPreviousList, false)
            this.nextPage = new PaginationService(Model, api, pageSize, keepPreviousList, false)
        }
    }

    get lastPage() {
        return Math.ceil(this.count / this.pageSize)
    }

    updatePagination(Model = this.Model, api = this.api, pageSize = 10, keepPreviousList = false) {
        this.Model = Model
        this.api = api
        this.pageSize = pageSize
        this.keepPreviousList = keepPreviousList

        if (this.withPreload) {
            this.prevPage.updatePagination(Model, api, pageSize, keepPreviousList)
            this.nextPage.updatePagination(Model, api, pageSize, keepPreviousList)
        }
    }

    async loadPage(page, {filters, ...pageParams} = {}, pageSize, {cancelSource, lightApi} = {}) {
        if (pageSize) {
            this.setPageSize(pageSize)
        }
        page = page || this.currentPage

        // await this.cancelAllLoading()

        if (cancelSource) {
            this.cancelSourceList.push(cancelSource)
        }

        if (
            this.withPreload
                && (
                    (this.prevPage.currentPage == page && !!this.prevPage.list.length)
                        || (this.nextPage.currentPage == page && !!this.nextPage.list.length)
                )
        ) {
            this.serialize = this.prevPage.currentPage == page
                ? this.prevPage.serialize
                : this.nextPage.serialize

            this.prevPage = new PaginationService(this.Model, this.api, this.pageSize, this.keepPreviousList, false)
            this.nextPage = new PaginationService(this.Model, this.api, this.pageSize, this.keepPreviousList, false)
        }
        else {
            const currentApi = lightApi || this.api
            const {data} = await currentApi.get(
                {
                    offset: (page - 1) * this.pageSize,
                    limit: this.pageSize,
                    ...filters,
                    ...pageParams,
                },
                {cancelSource}
            )
            const {count, results, ...extraData} = data
            this.currentPage = page
            this.count = count
            this.list = [].concat(
                this.keepPreviousList && page != 1 ? this.list : [],
                results.map(el => new this.Model(el))
            )
            this.extraData = extraData
        }

        if (this.withPreload) {
            // await this.prevPage.cancelAllLoading()
            // await this.nextPage.cancelAllLoading()

            if (this.currentPage != 1) {
                this.prevPage.loadPage(page - 1, {filters, ...pageParams}, pageSize, {cancelSource})
            }
            if (this.currentPage != this.lastPage) {
                this.nextPage.loadPage(page + 1, {filters, ...pageParams}, pageSize, {cancelSource})
            }
        }

        return this
    }

    loadNextPage(...params) {
        return this.list.length == 0 && this.currentPage == 1
            ? this.loadPage(1, ...params)
            : this.loadPage(this.currentPage + 1, ...params)
    }

    async loadMissingItemsOnCurrentPage({filters, ...pageParams} = {}, {cancelSource} = {}) {
        const {currentPage, pageSize, list: currentList} = this

        const {data} = await this.api.get(
            {
                offset: (currentPage - 1) * pageSize + currentList.length,
                limit: pageSize - currentList.length,
                ...filters,
                ...pageParams,
            },
            {cancelSource}
        )
        const {count, results, ...extraData} = data
        this.count = count
        this.list = []
            .concat(
                currentList,
                results.map(el => new this.Model(el))
            )
            .reduce(
                (current, next) => {
                    if (!current.find(({uuid}) => uuid == next.uuid)) {
                        current.push(next)
                    }

                    return current
                },
                []
            )
        this.extraData = extraData

        return this
    }

    async changePageSize(pageSize, pageParams) {
        this.setPageSize(pageSize)

        return this.loadPage(null, pageParams)
    }

    setPageSize(pageSize) {
        this.pageSize = Number(pageSize)
        this.currentPage = 1
    }

    async createOrderingParams(orderingArray = [], aliases, withoutMergeArray = false) {
        const res = []

        for (let idx = 0; idx < orderingArray.length; idx++) {
            const el = orderingArray[idx]

            let key

            if (aliases && aliases[el.id] && typeof aliases[el.id] == 'function') {
                key = await aliases[el.id]()
            }
            key = key || (aliases && aliases[el.id] ? aliases[el.id] : el.id)

            res[idx] = Array.isArray(key)
                ? key.map(keyEl => `${el.desc ? '' : '-'}${keyEl}`)
                : `${el.desc ? '' : '-'}${key}`

            if (!withoutMergeArray && Array.isArray(res[idx])) {
                res[idx] = res[idx].join(',')
            }
        }

        return res
    }

    addToList(object) {
        this.list = [...this.list, object]
        this.count++

        return this.serialize
    }

    updateObjectInList(newObject) {
        this.list = this.list.map(currentObject => {
            if (currentObject.uuid == newObject.uuid) {
                currentObject.serialize = newObject
            }

            return currentObject
        })

        return this.serialize
    }

    removeFromList(object) {
        this.list = this.list.filter(currentObject => currentObject.uuid != object.uuid)
        this.count--

        return this.serialize
    }

    async cancelAllLoading() {
        if (this.cancelSourceList.length) {
            await Promise.all(
                this.cancelSourceList.map(
                    currentCancelSource => currentCancelSource('Cancel loading due to changed filters')
                )
            )

            this.cancelSourceList = []
        }
    }
}

export default PaginationService
