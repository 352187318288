import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {autobind} from 'core-decorators'
import {bindActionCreators} from 'redux'

import {sentences} from 'common_utils/string_format'

import commonChoicesActions from 'common_redux/actions/common_choices'
import userActions from 'common_redux/actions/user'

import FormFactory from 'common_components/form'
import TextHeader from 'common_components/text_header'

import InviteMembersComponent from '../invite_members_component'

class TeamCreate extends Component {
    static propTypes = {
        commonUserChoices: PropTypes.object,
        user: PropTypes.object,
        loadCommonUserChoices: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        createTeam: PropTypes.func.isRequired,
        inviteMembers: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        isNewUser: PropTypes.bool.isRequired,
    }

    static teamNameValidators = [
        FormFactory.validators.required(),
    ]

    state = {
        submitError: {},
        inviteMembersError: [],
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.createTeam)}>
                <TextHeader.h3 className="mt-zero" text="Team Setup" />
                <FormFactory.Field
                    name="teamName"
                    type="text"
                    placeholder="Seth’s Awesome Team"
                    validate={TeamCreate.teamNameValidators}
                    descriptionText="TEAM NAME"
                    externalTextError={
                        this.state.submitError.fields?.name
                            ? sentences(this.state.submitError.fields.name[0])
                            : ''
                    }
                />
                {
                    this.props.commonUserChoices && (
                        <InviteMembersComponent
                            name="inviteMembers"
                            commonUserChoices={this.props.commonUserChoices}
                            className="my-md-lg"
                            inviteMembersError={this.state.inviteMembersError}
                        />
                    )
                }
                <FormFactory.Button
                    type="submit"
                    title="SEND INVITES"
                />
                {
                    this.props.isNewUser && (
                        <div className="flex just-center align-center">
                            <FormFactory.Button.text
                                className="text-md proxima-bold letter-sm text-turquoise-blue text-center mt-md"
                                onClick={this.props.onClose}
                                type="submit"
                                title="SKIP"
                            />
                        </div>
                    )
                }
            </form>
        )
    }

    componentDidMount() {
        this.props.loadCommonUserChoices()
    }

    @autobind
    createTeam({teamName, inviteMembers}) {
        this.setState({
            submitError: {},
            inviteMembersError: [],
        })

        const promise = this.props.user?.team?.uuid
            ? Promise.resolve()
            : this.props.createTeam(teamName)

        return promise
            .then(() => Promise.all(inviteMembers.map(inviteMember => (
                this.props.inviteMembers([inviteMember])
                    .catch(res => {
                        this.setState(({inviteMembersError}) => ({
                            inviteMembersError: inviteMembersError.concat({
                                email: inviteMember.email,
                                text: res.response.data.agent,
                            }),
                        }))

                        return Promise.reject(res)
                    })
            ))))
            .catch(res => {
                this.setState({
                    submitError: res.response.data,
                })
                return Promise.reject(res)
            })
    }
}

export default FormFactory.registerForm(
    TeamCreate,
    {
        form: 'TeamCreate',
    },
    state => ({
        user: state.user,
        commonUserChoices: state.commonChoices.users,
    }),
    dispatch => bindActionCreators({...commonChoicesActions, ...userActions}, dispatch),
)
