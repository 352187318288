import API_DOMAINS from 'API_DOMAINS'

import Api from 'common_services/api'

import {MatchesListService} from './matches_list'

const portalMatchingApi = new Api(API_DOMAINS.leadsApi, 'matching', 'matches/buyer-auto-shared', 2)

class PortalMatchesList extends MatchesListService {
    constructor() {
        super(leadUUID => (
            portalMatchingApi.getApiWithName(
                `matches/buyer-auto-shared${leadUUID ? `/${leadUUID}` : ''}`
            )
        ))
    }
}

export default new PortalMatchesList
