import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ProBadge from 'common_svg/pro.svg?jsx'

import {getCurrentRoute} from 'common_utils/routes_utils'

import Link from 'common_components/link'

import styles from './styles.styl'

function NavBarLink({className, path, text, button, badge, showSubNavBar, subNavBarLinks, dropdownLinks, ...props}) {
    const allLinksPath = [].concat(
        path,
        subNavBarLinks
            ? subNavBarLinks.map(({path: linkPath}) => linkPath)
            : []
    )
    const isLinkActive = isCurrentPage(allLinksPath)
    const linkComponent = (
        <Link
            className={cn(
                'ml-sm',
                styles['link'],
                {
                    [styles['link-with-count']]: subNavBarLinks
                        ? subNavBarLinks.reduce((current, {count = 0}) => current + count, 0)
                        : false,
                    'flex align-center': badge,
                    [styles.active]: isLinkActive,
                },
                className
            )}
            path={path}
            text={(
                <Fragment>
                    {text}
                    {badge && (
                        <ProBadge className="ml-xs" />
                    )}
                </Fragment>
            )}
            button={
                button ? button : (
                    isCurrentPage(allLinksPath) ? [
                        Link.Button.turquoiseBlueInvert,
                        Link.Button.noBorder,
                    ] : [
                        Link.Button.turquoiseBlueInvertHover,
                        Link.Button.noBorder,
                        Link.Button.noBorderHover,
                    ]
                )
            }
            {...props}
        />
    )

    if (showSubNavBar && subNavBarLinks && isCurrentPage(allLinksPath)) {
        setTimeout(() => showSubNavBar(subNavBarLinks))
    }

    return dropdownLinks
        ? (
            <div className={styles['link-wrapper']}>
                {linkComponent}
                <div className={cn(styles['dropdown-links-wrapper'], 'px-md py-md')}>
                    {
                        dropdownLinks.map(link => (
                            <Link
                                key={link.path}
                                className={cn(styles['dropdown-link'], 'mb-md no-wrap')}
                                path={link.path}
                                text={link.text}
                            />
                        ))
                    }
                </div>
            </div>
        )
        : linkComponent
}

NavBarLink.propTypes = {
    className: PropTypes.string,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    showSubNavBar: PropTypes.func,
    subNavBarLinks: PropTypes.array,
    badge: PropTypes.bool,
    dropdownLinks: PropTypes.array,
}

const icons = [
    {
        className: styles['sellers-link'],
        displayName: 'Sellers',
    },
    {
        className: styles['buyers-link'],
        displayName: 'Buyers',
    },
    {
        className: styles['search-link'],
        displayName: 'Search',
    },
    {
        className: styles['partners-link'],
        displayName: 'Partners',
    },
    {
        className: styles['training-link'],
        displayName: 'Training',
    },
]

icons.forEach(({className: iconClassName, displayName}) => {
    NavBarLink[displayName] = ({className, ...props}) => ( // eslint-disable-line
        <NavBarLink className={cn(iconClassName, className)} {...props} />
    )
    // display-name for previos component
    NavBarLink[displayName].displayName = `NavBarLink.${displayName}`
})

export default NavBarLink

function isCurrentPage(path) {
    const pagePath = getCurrentRoute().path

    return Array.isArray(path)
        ? !!path.find(currentPath => currentPath.includes(pagePath))
        : pagePath == path
}
