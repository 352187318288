import API_DOMAINS from 'API_DOMAINS'

import modelSerialize from 'common_utils/model_serialize'

import Api from '../api'
import PaginationService from '../pagination'
import Property from '../properties2/property'

const mlsAgentsApi = new Api(API_DOMAINS.leadsApi, 'mls-data', 'mls-agents')
const publicMlsAgentsApi = new Api(API_DOMAINS.leadsApi, 'mls-data', 'mls-agents/read-only')
const propertiesApi = new Api(API_DOMAINS.leadsApi, 'properties/light')
const userMlsAgentInviteTokenApi = new Api(API_DOMAINS.usersApi, 'users', 'mls-agent-invites')

@modelSerialize([
    {
        serializeField: 'firstName',
        originField: 'first_name',
    },
    {
        serializeField: 'lastName',
        originField: 'last_name',
    },
    'fullName',
    'phones',
    'phone',
    {
        serializeField: 'dateCreated',
        originField: 'date_created',
        convertIfIsNull: false,
        convertingToType: Date,
    },
    {
        serializeField: 'dateNotified',
        originField: 'date_notified',
        convertIfIsNull: false,
        convertingToType: Date,
    },
    {
        serializeField: 'dateRegistered',
        originField: 'date_registered',
        convertIfIsNull: false,
        convertingToType: Date,
    },
    {
        serializeField: 'dateUpdated',
        originField: 'date_updated',
        convertIfIsNull: false,
        convertingToType: Date,
    },
    'email',
    'license_number',
    'license_state',
    'listings_count',
    'matches_count',
    'mls_ids',
    'office_address',
    'office_name',
    'raven_agent',
    'raven_team',
    'uuid',
    {
        serializeField: 'properties',
        originField: 'properties',
        readOnly: true,
    },
    'matches_interested_count',
])
class MlsAgentService {
    phones = []
    properties = new PaginationService(Property, propertiesApi, 20, true)
    dateCreated = null
    dateNotified = null
    dateRegistered = null
    dateUpdated = null
    email = null
    firstName = null
    lastName = null
    license_number = null
    license_state = null
    listings_count = 0
    matches_count = 0
    mls_ids = []
    office_address = null
    office_name = null
    raven_agent = null
    raven_team = null
    uuid = null
    matches_interested_count = 0

    constructor(data) {
        if (data) {
            if (data.phones) {
                data.phones = data.phones.map(
                    phone => phone && phone.indexOf('+') == -1
                        ? `+1 ${phone}`
                        : phone
                )
            }

            this.serialize = data
        }
    }

    get fullName() {
        return `${this.firstName || ''} ${this.lastName || ''}`.trim()
    }

    get phone() {
        return this.phones[0]
    }

    async loadMlsAgentByUUID(mlsAgentsUuid) {
        this.serialize = await MlsAgentService.getMlsAgentByUUID(mlsAgentsUuid || this.uuid)

        return this.serialize
    }

    async loadMlsAgentByInviteToken(mlsAgentInviteToken) {
        this.serialize = await MlsAgentService.getMlsAgentByInviteToken(mlsAgentInviteToken)

        return this.serialize
    }

    async loadMlsAgentProperties(mlsAgentsUuid) {
        await this.properties.loadNextPage({
            filters: {
                mls_agent_or_co_agent: mlsAgentsUuid || this.uuid,
                mls_status: 'active',
                is_ownership_verified: true,
                is_matching_active: true,
            },
        })

        return this.serialize
    }

    async saveMlsAgent(newMlsAgentData) {
        // remove empty phones
        newMlsAgentData.phones = newMlsAgentData.phones
            .filter(phone => !!phone && (typeof phone == 'string' ? !!phone : phone.phoneNumber))
            .map(phone => phone?.phone || phone)

        // remove empty mls_id
        newMlsAgentData.mls_ids = newMlsAgentData.mls_ids.filter(mls_id => !!mls_id.name)

        this.serialize = (await mlsAgentsApi.patch(this.uuid, {
            ...this.originalObject,
            ...newMlsAgentData,
            first_name: newMlsAgentData.firstName == undefined ? this.firstName : newMlsAgentData.firstName,
            last_name: newMlsAgentData.lastName == undefined ? this.lastName : newMlsAgentData.lastName,
        })).data

        return this.serialize
    }

    deleteMlsAgent() {
        return MlsAgentService.deleteMlsAgent(this.uuid)
    }

    static async deleteMlsAgent(mlsAgentUuid) {
        return mlsAgentsApi.delete(mlsAgentUuid)
    }

    static async getMlsAgentByUUID(mlsAgentUuid) {
        return new MlsAgentService((await mlsAgentsApi.getByKey(mlsAgentUuid)).data)
    }

    static async getPublicMlsAgentByUUID(mlsAgentUuid, config) {
        return new MlsAgentService((await publicMlsAgentsApi.getByKey(mlsAgentUuid, undefined, config)).data)
    }

    static async searchPublicMlsAgent({uuid, ...params}) {
        return (
            await publicMlsAgentsApi.get({
                uuid: uuid.reduce(
                    (currentUuidList, nextUuid) => (
                        currentUuidList.indexOf(nextUuid) == -1
                            ? currentUuidList.concat(nextUuid)
                            : currentUuidList
                    ),
                    []
                ),
                ...params,
            })
        )
            .data.results.map(mlsAgent => new MlsAgentService(mlsAgent))
    }

    static async getMlsAgentByInviteToken(mlsAgentInviteToken) {
        return new MlsAgentService((await userMlsAgentInviteTokenApi.getByKey(mlsAgentInviteToken)).data.data_json)
    }

    static async createMlsAgent(mlsAgentData) {
        // remove empty phones
        mlsAgentData.phones = mlsAgentData.phones
            .filter(phone => !!phone && (typeof phone == 'string' ? !!phone : phone.phoneNumber))
            .map(phone => phone?.phone || phone)

        // remove empty mls_id
        mlsAgentData.mls_ids = mlsAgentData.mls_ids.filter(mls_id => !!mls_id.name)

        return new MlsAgentService(
            (
                await mlsAgentsApi.post({
                    ...mlsAgentData,
                    first_name: mlsAgentData.firstName || this.firstName,
                    last_name: mlsAgentData.lastName || this.lastName,
                })
            ).data
        )
    }
}

export default MlsAgentService
