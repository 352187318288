import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from 'common_components/button'

import SmileCloudIcon from 'common_svg/smile_cloud.svg?jsx'

import HowItWorksVideo from 'adminProj/components/how_it_works_video'

import styles from './styles.styl'

export default function EmptyList({subHeader, isProperty, currentUser, openAddModal}) {
    return (
        <div
            className={cn(
                styles['container-styling'],
                'flex flex-column just-center align-center text-center mx-auto'
            )}
        >
            <SmileCloudIcon />
            <p className="mb-zero text-dark-grey proxima text-20 mt-md">
                {subHeader}
            </p>
            {
                !!openAddModal && (
                    <Button.big
                        title={`ADD NEW ${isProperty ? 'SELLER' : 'BUYER'} `}
                        className={cn(styles['button-styling'], 'px-xs mt-md-lg mx-md')}
                        onClick={openAddModal}
                        isWithoutBold
                    />
                )
            }
            {
                (
                    subHeader == EmptyList.subHeader.property
                        || subHeader == EmptyList.subHeader.propertyActiveListing
                        || subHeader == EmptyList.subHeader.propertyMatchesListing
                )
                    && (
                        <HowItWorksVideo
                            videoUrl="https://player.vimeo.com/video/498112204?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"

                            className="mt-md"
                        />
                    )
            }
            {
                (
                    subHeader == EmptyList.subHeader.buyer
                        || subHeader == EmptyList.subHeader.buyerMatchesListing
                )
                    && (
                        <HowItWorksVideo
                            videoUrl={
                                currentUser?.permissions?.isPartner
                                    ? 'https://player.vimeo.com/video/499833100?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                                    : 'https://player.vimeo.com/video/498109670?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                            }

                            className="mt-md"
                        />
                    )
            }
        </div>
    )
}

EmptyList.propTypes = {
    subHeader: PropTypes.string,
    openAddModal: PropTypes.func,
    currentUser: PropTypes.object,
    isProperty: PropTypes.bool,
}

EmptyList.subHeader = {
    property: (
        <Fragment>
            Your sellers will appear here. Add any seller to see matching buyers agents,
            without sharing property details.
            <br />
            <br />
            Update the status on any seller to share within your brokerage or with all brokerages.
        </Fragment>
    ),
    propertyActiveListing: 'Add a seller to your listings list.',
    propertyMatchesListing: 'Add a seller to start generating matches.',
    buyer: 'Your buyer needs will appear here. Add buyer needs to find inventory and connect with other agents who have upcoming listings.',
    buyerMatchesListing: 'Your buyer matches will appear here. Make sure to enable matching on your leads to start generating matches.',
}

Object.keys(EmptyList.subHeader).forEach(subHeader => {
    EmptyList[subHeader] = props => { // eslint-disable-line react/display-name
        const currentConfig = EmptyList.subHeader[subHeader]

        return (
            <EmptyList
                subHeader={currentConfig}
                isProperty={subHeader.indexOf('property') != -1}
                {...props}
            />
        )
    }
    EmptyList[subHeader].displayName = `EmptyList.${subHeader}`
})

