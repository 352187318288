import buyersListService from 'common_services/buyer/buyers_list'
import selectedBuyer from 'common_services/buyer/selected_buyer'

import Reducer from './reducer'

import actionTypes from '../actions/action_types'

const INITIAL_STATE = {
    buyersList: buyersListService.serialize,

    selectedBuyer: selectedBuyer.serialize,
}

const buyers2Reducer = new Reducer(INITIAL_STATE)

buyers2Reducer.add(actionTypes.buyers2.createBuyerForPortal, 'portalBuyer')

buyers2Reducer.add(actionTypes.buyers2.loadAutoShareBuyerByUUID, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.updatePortalBuyer, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.setAllMatchesToRead, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.loadPrimaryAgent, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.loadAllAgent, 'selectedBuyer')

buyers2Reducer.add(actionTypes.buyers2.selectedBuyerActions.loadBuyerByUUID, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.selectedBuyerActions.saveBuyer, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.selectedBuyerActions.updateSomeFields, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.selectedBuyerActions.sendGetPreQualifiedRequest, 'selectedBuyer')
buyers2Reducer.add(actionTypes.buyers2.selectedBuyerActions.loadBuyerMlsVendors, 'selectedBuyer')
buyers2Reducer.resetField(actionTypes.buyers2.selectedBuyerActions.resetSelectedBuyer, 'selectedBuyer')
buyers2Reducer.add(
    actionTypes.buyers2.selectedBuyerActions.followUsBossIntegration.createFollowUsBossIntegrationObject,
    'selectedBuyer'
)
buyers2Reducer.add(
    actionTypes.buyers2.selectedBuyerActions.followUsBossIntegration.removeFollowUsBossIntegrationObject,
    'selectedBuyer'
)
buyers2Reducer.addLocalAction(
    actionTypes.buyers2.selectedBuyerActions.localUpdateSelectedBuyer,
    'selectedBuyer',
    undefined,
    true
)

buyers2Reducer.add(actionTypes.buyers2.loadBuyersPage, 'buyersList')
buyers2Reducer.add(actionTypes.buyers2.changePageSize, 'buyersList')

export default buyers2Reducer.reducersFunction

export {buyers2Reducer}
