import React from 'react' // eslint-disable-line no-unused-vars

import EmptyList from 'adminProj/components/empty_list'

export default {
    sellers: {
        requiredOrdering: [],
        defaultOrdering: [
            {
                id: 'date_created',
                desc: false,
            },
        ],

        requiredFilters: {},
        defaultFilters: {},

        analytics: {
            openFilterEventName: 'Viewed Property Filter Screen',
            applyFilterEventName: 'Applied Property Filter',
            openAddLeadEventName: 'Viewed Add Property Screen',
            propertyDetailsModelName: 'Property Lead',
        },
        textInformation: {
            searchInputPlaceholder: 'Search Properties',
            listTitle: 'Properties',
            EmptyListComponent: EmptyList.property,
            loaderText: 'Loading Properties ...',
            editPropertyLeadHeader: 'Edit Property',
            emptyMatchesList: 'No buyer matches found for this property yet. Please check back again later.',
        },
        createFuncAllowed: true,
        tableColumnsListConfig: [
            'ADDRESS',
            'MLS_STATUS',
            'MATCHES',
            'PRICE',
            'DATE_CREATED',
            'PRIMARY_AGENT',
        ],
    },
    activeListing: {
        requiredOrdering: [],
        defaultOrdering: [
            {
                id: 'date_created',
                desc: false,
            },
        ],

        requiredFilters: {
            is_listing: true,
        },
        defaultFilters: {},

        analytics: {
            openFilterEventName: 'Viewed Listing Filter Screen',
            applyFilterEventName: 'Applied Listing Filter',
            openAddLeadEventName: 'Viewed Add Listing Screen',
            propertyDetailsModelName: 'Listing',
        },
        textInformation: {
            searchInputPlaceholder: 'Search Active Listing',
            listTitle: 'Listings',
            EmptyListComponent: EmptyList.propertyActiveListing,
            loaderText: 'Loading Listings ...',
            editPropertyLeadHeader: 'Edit Seller',
            emptyMatchesList: 'No buyer matches found for this property yet. Please check back again later.',
            shareLeadText: 'Listing',
        },
        createFuncAllowed: true,
        tableColumnsListConfig: [
            'ADDRESS',
            'MLS_STATUS',
            'MATCHES',
            'PRICE',
            'DATE_CREATED',
            'PRIMARY_AGENT',
        ],
    },
    matchesListing: {
        requiredOrdering: [],
        defaultOrdering: [
            {
                id: 'matches_count', // aliasing there common_services/properties2/properties_list.js:142
                desc: false,
            },
        ],

        requiredFilters: {
            has_matches: true,
            is_matching_active: true,
        },
        defaultFilters: {},

        analytics: {
            openFilterEventName: 'Viewed Match Filter Screen',
            applyFilterEventName: 'Applied Match Filter',
            openAddLeadEventName: 'Viewed Add Match Screen',
            propertyDetailsModelName: 'Match Listing',
        },
        textInformation: {
            searchInputPlaceholder: 'Search Matches',
            listTitle: 'Matches',
            EmptyListComponent: EmptyList.propertyMatchesListing,
            loaderText: 'Loading Matches ...',
            editPropertyLeadHeader: 'Edit Seller',
            emptyMatchesList: 'No buyer matches found for this property yet. Please check back again later.',
        },
        createFuncAllowed: true,
        tableColumnsListConfig: [
            'ADDRESS',
            'MLS_STATUS',
            'MATCHES',
            'PRICE',
            'DATE_CREATED',
            'PRIMARY_AGENT',
        ],
    },
}
