import React, {useState, useRef} from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import VimeoPlayer from '@vimeo/player'

import PlayIcon from 'common_svg/play.svg?jsx'

import Button from 'common_components/button'
import FullScreenModal from 'common_components/full_screen_modal'

import styles from './styles'

export default function HowItWorksVideo({videoUrl, linkText, className, ...props}) {
    const [showHowItWorksVideo, setShowHowItWorksVideo] = useState(false)

    const iframeVideo = useRef(null)

    return (
        <div className={cn(styles['how-it-works-video-button-wrapper'], className)} {...props}>
            <Button.text
                className="proxima-medium text-sm p-zero text-turquoise-blue"
                title={
                    <span className="flex align-center">
                        <PlayIcon className="mr-xs" />
                        {linkText}
                    </span>
                }
                onClick={() => setShowHowItWorksVideo(true)}
            />
            <FullScreenModal
                show={showHowItWorksVideo}
                content={
                    <div className={styles['how-it-works-video-wrapper']}>
                        <iframe
                            ref={iframeVideo}
                            src={videoUrl}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullscreen
                            title="Sellers"
                        />
                    </div>
                }
                onClose={closeHowItWorksVideoModal}
            />
        </div>
    )

    function closeHowItWorksVideoModal() {
        setShowHowItWorksVideo(false)

        if (iframeVideo.current) {
            const player = new VimeoPlayer(iframeVideo.current)
            player.pause()
        }
    }
}

HowItWorksVideo.propTypes = {
    videoUrl: PropTypes.string.isRequired,

    linkText: PropTypes.string,
    className: PropTypes.string,
}

HowItWorksVideo.defaultProps = {
    linkText: 'SEE HOW IT WORKS',
}
