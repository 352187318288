import React, {useState} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
} from 'react-stripe-elements'

import ErrorMessage from 'common_components/form/components/error_message'
import RequiredLabel from 'common_components/form/components/required_label'

import colors from 'common_styles/colors.styl'
import opacity from 'common_styles/opacity.styl'

import PostalCodeElement from './components/postal_code'

import styles from './styles.styl'

export default function BillingElement({
    stripeElement,
    descriptionText,
    className,
    externalTextError,
    required,
    onFocus,
    onChange,
    onBlur,
}) {
    const [isFocus, setIsFocus] = useState(false)
    const [errorMessagee, setErrorMessagee] = useState('')
    const customStripeElement = React.cloneElement(
        stripeElement,
        {
            className: cn(
                stripeElement.props.className,
                styles['stripe-input'],
                {
                    [styles['stripe-input-focus']]: isFocus,
                    [styles['stripe-input-error']]: errorMessagee || externalTextError,
                }
            ),
            style: {
                base: {
                    letterSpacing: '0.6px',
                    '::placeholder': {
                        color: colors.blueyGrey,
                        opacity: opacity.opacityMedium,
                    },
                },
                invalid: {
                    color: colors.tomato,
                },
            },
            onFocus: () => {
                setIsFocus(true)

                onFocus && onFocus()
            },
            onChange: event => {
                setErrorMessagee(event?.error?.message)

                onChange && onChange(event)
            },
            onBlur: () => {
                setIsFocus(false)

                onBlur && onBlur()
            },
        }
    )

    return (
        <label className={className}>
            <div className="flex align-center mb-xs">
                <p className="text-xs proxima-bold uppercase m-zero">
                    {descriptionText}
                </p>
                {
                    required && (
                        <RequiredLabel isShort className="text-xs proxima my-zero ml-xs" />
                    )
                }
            </div>
            {customStripeElement}
            {
                externalTextError || errorMessagee
                    ? (
                        <ErrorMessage>
                            {externalTextError || errorMessagee}
                        </ErrorMessage>
                    )
                    : null
            }
        </label>
    )
}

BillingElement.propTypes = {
    stripeElement: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    descriptionText: PropTypes.string.isRequired,

    className: PropTypes.string,
    externalTextError: PropTypes.string,
    required: PropTypes.bool,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
}

BillingElement.defaultElements = [
    {
        descriptionText: 'CARD NUMBER',
        placeholder: '16-digit number',
        name: 'CardNumberElement',
        element: CardNumberElement,
        required: true,
    },
    {
        descriptionText: 'EXPIRATION',
        placeholder: 'mm/yy',
        name: 'CardExpiryElement',
        element: CardExpiryElement,
        required: true,
    },
    {
        descriptionText: 'CVV CODE',
        placeholder: '3-4 digits',
        name: 'CardCVCElement',
        element: CardCVCElement,
        required: true,
    },
    {
        descriptionText: 'ZIP CODE',
        placeholder: '5 digits',
        name: 'PostalCodeElement',
        element: PostalCodeElement,
    },
]

BillingElement.defaultElements.forEach(elementObj => {
    BillingElement[elementObj.name] = props => ( // eslint-disable-line react/display-name
        <BillingElement
            descriptionText={elementObj.descriptionText}
            required={elementObj.required}
            stripeElement={
                <elementObj.element
                    placeholder={elementObj.placeholder}
                    {...props}
                />
            }
            {...props}
        />
    )
    // display-name for previos component
    BillingElement[elementObj.name].displayName = elementObj.name
})
