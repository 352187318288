export function getDefaultFilters() {
    return {
        price_range: {
            low: 0,
            high: 500 * Math.pow(10, 6),
        },
        bedrooms: {
            low: 1,
            high: 6,
        },
        bathrooms: {
            low: 1,
            high: 6,
        },
        hoa_max: {
            low: 0,
            high: 10000,
        },
        lead_status: '',
        timeframe: '',
        size_range_sqft: {
            low: 0,
            high: 50000,
        },
        lot_size_range_sqft: {
            low: 0,
            high: 250000,
        },
        year_built_range: {
            low: 1900,
            high: new Date().getFullYear() + 1,
        },
        agents: [],
        keywords: [],
        leads_filter: 'all',
        matching_status: 'all',
        cities: [],
        zipcodes: [],
        stories: 0,
        subtypes: [],
    }
}

export function createBackendFilters(
    filters = {},
    filterKeys,
    {
        removeEmptyString,
        replaceEmptyStringToDefaultFilterValue,
    } = {}
) {
    return Object.keys(filters).map(key => {
        if (Array.isArray(filters[key])) {
            return {
                [filterKeys[key]]: filters[key].map(el => parseArray(key, el)),
            }
        }

        if (typeof filters[key] === 'object') {
            return parseObect(key, filters, filterKeys, replaceEmptyStringToDefaultFilterValue)
        }

        if (key == 'leads_filter') {
            switch (filters[key]) {
                case 'past_due':
                    return {
                        past_due: true,
                    }
                case 'up_to_date':
                    return {
                        new_leads: true,
                    }
            }
        }
        if (key == 'matching_status') {
            switch (filters[key]) {
                case 'enabled':
                    return {
                        is_matching_active: true,
                    }
                case 'disabled':
                    return {
                        is_matching_active: false,
                    }
            }
        }

        return {
            [filterKeys[key] || key]: filters[key],
        }
    })
        .map(filterList =>
            removeEmptyString
                ? Object.keys(filterList).reduce(
                    (currentParamsObj, key) => (
                        filterList[key] === ''
                            ? currentParamsObj
                            : (
                                Array.isArray(filterList[key])
                                    ? {
                                        ...currentParamsObj,
                                        [key]: filterList[key].filter(el => el !== ''),
                                    }
                                    : {
                                        ...currentParamsObj,
                                        [key]: filterList[key],
                                    }
                            )
                    ),
                    {}
                )
                : filterList
        )
        .reduce((currentParamsObj, nextParams) => ({
            ...currentParamsObj,
            ...nextParams,
        }), {})
}

export function getCustomFilters(filters = {}) {
    const initialValues = getDefaultFilters()

    const diffKeys = Object.keys(filters)
        .filter(key => JSON.stringify(filters[key]) != JSON.stringify(initialValues[key]))

    const queryParams = {}

    for (let i = 0 ; i < diffKeys.length ; i++) {
        queryParams[diffKeys[i]] = filters[diffKeys[i]]
    }

    return queryParams
}

function parseObect(key, value, filter, replaceEmptyStringToDefaultFilterValue) {
    const initialValues = getDefaultFilters()
    const {low, high} = value[key]

    switch (key) {
        case 'bathrooms':
        case 'bedrooms':
            if (parseInt(high) === 6) {
                return {
                    [`${filter[key]}_min`]: low,
                }
            }
            break
        case 'hoa_max':
            return {
                hoa_max: high,
            }
    }

    return {
        [`${filter[key]}_min`]: replaceEmptyStringToDefaultFilterValue
            ? (
                initialValues[key] && typeof initialValues[key].low == 'number' && low === ''
                    ? initialValues[key].low
                    : low
            )
            : low,
        [`${filter[key]}_max`]: replaceEmptyStringToDefaultFilterValue
            ? (
                initialValues[key] && typeof initialValues[key].high == 'number' && high === ''
                    ? initialValues[key].high
                    : high
            )
            : high,
    }
}

function parseArray(key, value) {
    switch (key) {
        case 'cities':
            return `${value.name},${value.state}`
        case 'zipcodes':
            return `${value.name}`
        case 'agents':
            return value.uuid === '' ? 'null' : value.uuid
        default:
            return value
    }
}
